import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UnauthorizedError } from '@markmachine/features/account/exceptions/authorization-error';
import { CaseStatus } from '@markmachine/features/case-status/models/status.model';
import { Case } from '@markmachine/features/case/models/case.model';
import { UserCase } from '@markmachine/features/user-case/models/user-case.model';
import { Update } from '@ngrx/entity';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AddUserCasesMutation, AddUserCasesResponse } from './operations/add-user-cases.mutation';
import { AllUserCasesQuery, AllUserCasesResponse } from './operations/all-user-cases.query';
import { DeleteUserCasesMutation, DeleteUserCasesResponse } from './operations/delete-user-cases.mutation';
import { UpdateUserCaseMutation, UpdateUserCaseResponse } from './operations/update-user-case.mutation';

const URL = environment.GRAPHQL;

@Injectable({
  providedIn: 'root'
})
export class UserCaseService {
  constructor(private http: HttpClient) { }

  /**
   * Fetch the current user's cases from the Mark Machine API
   */
  requestAllUserCases(): Observable<{ userCase: UserCase, status: CaseStatus, case_: Case }[]> {
    return this.http.post<AllUserCasesResponse>(URL, new AllUserCasesQuery({})).pipe(
      tap(({ data, errors }) => {
        if (data.allUserCases === null) {
          throw new UnauthorizedError();
        } else if (errors) {
          const message = errors[0].message;
          throw new UnauthorizedError();
        }
      }),
      map(({ data: { allUserCases: { nodes } } }) => nodes),
      map((nodes: any) => nodes.map(
        ({ caseByCaseId: { status, ...case_ }, ...userCase }) => ({
          userCase: { ...userCase, other: userCase.other || {} }, case_, status
        }))
      )
    );
  }

  /**
   * Request the addition of cases to profile by serial numbers.
   * Returns user cases that were really added, ignoring any already added to profile.
   * @param serialNumbers Serial numbers to add
   */
  requestAddUserCasesBySerialNumbers(
    serialNumbers: number[]
  ): Observable<{ userCase: UserCase, status: CaseStatus, case_: Case }[]> {
    return this.http
      .post<AddUserCasesResponse>(URL, new AddUserCasesMutation({ serialNumbers }))
      .pipe(
        tap(({ data, errors }) => {
          if (data.createUserCasesBySerialNumbers === null) {
            throw new UnauthorizedError();
          } else if (errors) {
            const message = errors[0].message;
            throw new UnauthorizedError();
          }
        }),
        map(({ data }) => data.createUserCasesBySerialNumbers.userCases),
        map((nodes: any) => nodes.map(
          ({ caseByCaseId: { status, ...case_ }, ...userCase }) => ({ userCase, case_, status })
        ))
      );
  }

  /**
   * Request the removal of users cases from profile.
   * Returns user cases that were really removed, ignoring any already added to profile.
   * @param caseIds User case nodes to remove
   */
  requestRemoveUserCases(caseIds: string[]): Observable<UserCase[]> {
    return this.http
      .post<DeleteUserCasesResponse>(URL, new DeleteUserCasesMutation({ caseIds }))
      .pipe(
        tap(({ data, errors }) => {
          if (data.deleteUserCasesByCaseIds === null) {
            throw new UnauthorizedError();
          } else if (errors) {
            const message = errors[0].message;
            throw new UnauthorizedError();
          }
        }),
        map(({ data }) => data.deleteUserCasesByCaseIds.userCases)
      );
  }

  /**
   * Request the update of a user case
   * @param nodeId case's nodeId
   * @param userCasePatch patch to user case
   */
  requestUpdateUserCase(patch: Update<UserCase>): Observable<UserCase> {
    return this.http
      .post<UpdateUserCaseResponse>(URL, new UpdateUserCaseMutation(patch))
      .pipe(
        tap(({ data, errors }) => {
          if (errors) {
            const message = errors[0].message;
            throw new UnauthorizedError();
          } else if (data.updateUserCase === null) {
            throw new UnauthorizedError();
          }
        }),
        map(({ data }) => data.updateUserCase.userCase)
      );
  }
}
