import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DATE_REGEX } from '@markmachine/features/uspto-indefinite-date/constants';
import { isString } from 'lodash-es';

/**
 * Handles the textual rendering of the indefinite USPTO date format
 *
 * The indefinite date looks like RFC 3339 or ISO 8601 (yyyyMMDD), however
 * months, days, or years may all be '00' or '0000' respectively.
 */
@Pipe({
  name: 'usptoIndefiniteDate'
})
export class UsptoIndefiniteDatePipe implements PipeTransform {
  private datePipe = new DatePipe('en-us');

  transform(value: any, format: 'text' | 'slashes' = 'text'): any {
    if (isString(value) && DATE_REGEX.test(value)) {
      const [year, month, day, other] = (DATE_REGEX.exec(value) as RegExpExecArray).slice(1);
      if (other) {
        return other;
      }
      if (format === 'text') {
        if (day !== '00' && month !== '00') {
          return this.datePipe.transform(`${year}-${month}-${day}`, 'dd MMMM y');
        } else if (month !== '00') {
          return this.datePipe.transform(`${year}-${month}-01`, 'MMMM y');
        } else {
          return this.datePipe.transform(`${year}-01-01`, 'y');
        }
      } else if (format === 'slashes') {
        return `${month}/${day}/${year}`;
      }
    }
    return value;
  }
}
