/**
 * Supertable Module
 *
 * Provides an advanced table component.
 */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedMaterialModule } from '@markmachine/shared/materials/shared-materials.module';
import { SharedModule } from '@markmachine/shared/shared.module';
import { AdvancedFilterComponent } from './components/advanced-filter/advanced-filter.component';
// tslint:disable-next-line: max-line-length
import { ClearanceSearchResultsDetailsComponent } from './components/clearance-search-results-details/clearance-search-results-details.component';
// tslint:disable-next-line: max-line-length
import { ClearanceSearchResultsGoodsServicesComponent } from './components/clearance-search-results-goods-services/clearance-search-results-goods-services.component';
import { ClearanceSearchResultsMarkComponent } from './components/clearance-search-results-mark/clearance-search-results-mark.component';
import { ClearanceSearchResultsOwnerComponent } from './components/clearance-search-results-owner/clearance-search-results-owner.component';
import { DataCellComponent } from './components/data-cell/data-cell.component';
import { HeaderCellComponent } from './components/header-cell/header-cell.component';
import { InlineInputComponent } from './components/inline-input/inline-input.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { ResizeHandleComponent } from './components/resize-handle/resize-handle.component';
import { SortIndicatorComponent } from './components/sort-indicator/sort-indicator.component';
import { SupertableComponent } from './components/supertable/supertable.component';
import { TableComponent } from './components/table/table.component';
import { VellipsisButtonComponent } from './components/vellipsis-button/vellipsis-button.component';
import { VisibilityMenuComponent } from './components/visibility-menu/visibility-menu.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { AutosizeTextareaDirective } from './directives/autosize-textarea.directive';
import { FrozenTableDirective } from './directives/frozen-table.directive';
import { HeaderDragResizeDirective } from './directives/header-drag-resize.directive';
import { HeaderInitialWidthDirective } from './directives/header-initial-width.directive';
import { ResizeColumnDirective } from './directives/resize-column.directive';

@NgModule({
  declarations: [
    SupertableComponent,
    SortIndicatorComponent,
    HeaderDragResizeDirective,
    AutofocusDirective,
    AdvancedFilterComponent,
    InlineInputComponent,
    FrozenTableDirective,
    DataCellComponent,
    HeaderCellComponent,
    TableComponent,
    PaginatorComponent,
    VisibilityMenuComponent,
    HeaderInitialWidthDirective,
    AutosizeTextareaDirective,
    VellipsisButtonComponent,
    ClearanceSearchResultsGoodsServicesComponent,
    ClearanceSearchResultsMarkComponent,
    ClearanceSearchResultsDetailsComponent,
    ClearanceSearchResultsOwnerComponent,
    ResizeHandleComponent,
    ResizeColumnDirective
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    SharedMaterialModule
  ],
  exports: [
    SupertableComponent,
    TableComponent,
    InlineInputComponent
  ]
})
export class SupertableModule {}
