import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'mm-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorDialogComponent implements OnInit {
  multipleDialogs: boolean;
  message: string;
  title: string;
  details: Error;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ErrorDialogComponent>
  ) {}

  ngOnInit() {
    this.multipleDialogs = this.errorDialogs.length > 1;
    this.title = this.data.title;
    this.message = this.data.message;
    this.details = this.data.details;
  }

  get errorDialogs(): MatDialogRef<ErrorDialogComponent, any>[] {
    return this.dialog.openDialogs.filter(d => d.componentInstance instanceof ErrorDialogComponent);
  }

  closeErrors() {
    this.errorDialogs.forEach(dialog => dialog.close());
  }
}
