import { Tip } from '@markmachine/core/models/tip';
import { createAction, props } from '@ngrx/store';

export const TipDocument = createAction(
  '[Tip] Tip',
  props<{ tip: Tip }>()
);

export const TipChildrenDocuments = createAction(
  '[Tip] TipChildren',
  props<{ tips: Tip[] }>()
);

export const LoadTipError = createAction(
  '[Tip] LoadTipError',
  props<{ error: any }>()
);

export const LoadTipChildrenError = createAction(
  '[Tip] LoadTipChildrenError',
  props<{ error: any }>()
);

export const LoadTip = createAction(
  '[Tip] LoadTip',
  props<{ id: string; }>()
);

export const LoadTipByAnchor = createAction(
  '[Tip] LoadTipByAnchor',
  props<{ anchor: string; }>()
);

export const LoadTipChildren = createAction(
  '[Tip] LoadTipChildren',
  props<{ id: string }>()
);

export const DialogOpen = createAction(
  '[Tip] DialogOpen',
  props<{ tipIds: string[] }>()
);

export const DialogOpenByAnchors = createAction(
  '[Tip] DialogOpenAnchor',
  props<{ anchors: string[] }>()
);

export const DialogOpenByAnchorsReady = createAction(
  '[Tip] DialogOpenAnchorReady',
  props<{ tips: Tip[] }>()
);

export const DialogClose = createAction(
  '[Tip] DialogClose'
);

export const DialogFollowAnchor = createAction(
  '[Tip] DialogFollowAnchor',
  props<{ anchor: string }>()
);

export const DialogFollowAnchorReady = createAction(
  '[Tip] DialogFollowAnchorReady',
  props<{ anchor: string; tip: Tip; children: Tip[] }>()
);

export const DialogHistoryBack = createAction(
  '[Tip] DialogHistoryBack'
);

export const DialogHistoryForward = createAction(
  '[Tip] DialogHistoryForward'
);

export const FollowAnchor = createAction(
  '[Tip] FollowAnchor',
  props<{ anchor: string }>()
);
