import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { InputOptionService } from '@markmachine/features/case/services/input-option.service';
import { Class } from '@markmachine/features/version/models/version-content.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { getSerialNumber } from '@markmachine/views/submission-page/reducers';
import { select, Store } from '@ngrx/store';
import { AppState } from '@markmachine/interfaces';

@Component({
  selector: 'mm-basis-44e-edit',
  templateUrl: './basis-44e-edit.component.html',
  styleUrls: ['./basis-44e-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Basis44eEditComponent implements OnDestroy, OnInit {
  @Input() group: FormGroup;
  @ViewChild(MatCheckbox) checkbox: MatCheckbox;
  destroy$ = new Subject();
  required$: Observable<boolean>;
  hasSerialNumber$: Observable<boolean>;

  constructor(
    private cd: ChangeDetectorRef,
    public options: InputOptionService,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.required$ = this.group.valueChanges.pipe(map((value: Class) => !!value['filing-basis-current-44e-in']));
    this.hasSerialNumber$ = this.store.pipe(select(getSerialNumber), map(sn => !!sn));
  }

  ngOnDestroy() {
    this.destroy$.next();
  }
}
