
export const CLICK_DEBOUNCE_TIME = 100;
export const CONCURRENT_REQUEST_LIMIT = 25;
export const NETWORK_DEBOUNCE_TIME = 250;
export const INPUT_DEBOUNCE_TIME = 250;
export const EXPANSION_PANEL_SCROLL_DELAY = 250;
export const CASE_FIELD_SCROLL_DELAY = 150;
export const BOILERPLATE_DEBOUNCE_TIME = 2000;

export const MAX_STATUS_AGE = 60 * 60e3; // 1 hr

export const POLLING_INTERVAL = 5000; // 5 s
export const POLLING_LIMIT = 2 * 60e3; // 2 min

export const ACCOUNT_ACCESS_KEY = 'account_access_token';
export const ACCOUNT_FLAGS_KEY = 'flags';

export const FALLBACK_MARK_IMAGE = 'assets/images/no-image.png';

export const DIALOG_OPTIONS = {
  maxWidth: '960px'
};

export const PAGE_SIZE_OPTIONS = [5, 10, 20, 50, 100];
