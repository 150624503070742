import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountService } from '@markmachine/features/account/services/account.service';
import { unlockVersion } from '@markmachine/features/version/actions/version.actions';
import { Version } from '@markmachine/features/version/models/version.model';
import { unpackGraphqlResponse } from '@markmachine/core/graphql-operators';
import { AppState } from '@markmachine/interfaces';
import { submitVersion } from '@markmachine/views/submission-page/actions/submission-page.actions';
import * as fromSubmissionPage from '@markmachine/views/submission-page/reducers';
import { select, Store } from '@ngrx/store';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, take } from 'rxjs/operators';
import { Case } from '../models/case.model';
import { CaseBySerialNumberQuery, CaseBySerialNumberResponse } from './operations/case-by-serial-number.query';
import { CaseByIdQuery, CaseByIdResponse } from './operations/case-by-id.query';
const { GRAPHQL } = environment;

@Injectable()
export class CaseService {
  constructor(private _account: AccountService, private http: HttpClient, private store: Store<AppState>) {}

  /**
   * Fetch a Case from the server.
   *
   * Meant for checking if newly added analysis is completed.
   */
  getCase(serialNumber: number): Observable<Case> {
    return this.http
      .post<CaseBySerialNumberResponse>(GRAPHQL, new CaseBySerialNumberQuery({ serialNumber }))
      .pipe(unpackGraphqlResponse('data.caseBySerialNumber'));
  }

  /**
   * Fetch a Case from the server by caseId.
   *
   * Meant for retrieving cases on demand after loading a new UserCase.
   */
  getCaseById(caseId: string): Observable<Case> {
    return this.http
      .post<CaseByIdResponse>(GRAPHQL, new CaseByIdQuery({ caseId }))
      .pipe(unpackGraphqlResponse('data.caseById'));
  }

  /**
   * Get current USPTO version
   */
  get currentVersion$(): Observable<Version> {
    return this.store.pipe(select(fromSubmissionPage.getCurrentVersion));
  }

  /**
   * Get user's draft version
   */
  get draftVersion$(): Observable<Version> {
    return this.store.pipe(select(fromSubmissionPage.getDraftVersion));
  }

  /**
   * Get the version ID of the draft
   */
  get draftVersionId$(): Observable<string> {
    return this.store.pipe(select(fromSubmissionPage.getDraftId)) as Observable<string>;
  }

  /**
   * Get a list of all versions
   */
  get versionList$(): Observable<Partial<Version>[]> {
    return this.store.pipe(select(fromSubmissionPage.getVersionList));
  }

  /**
   * Get a particular verison (metadata + content)
   * @param versionId primary key of version
   */
  getVersion$(versionId: string): Observable<Version> {
    return this.store.pipe(select(fromSubmissionPage.getVersions), map(versions => versions[versionId] as Version), distinctUntilChanged());
  }

  /**
   * Submit the draft version.
   */
  submitDraft(): void {
    this.tapDraft(version => this.store.dispatch(submitVersion({ version })));
  }

  /**
   * Subscribe to a single value of the draft version then complete.
   * @param fn thing to do with the draft
   */
  tapDraft(fn): void {
    this.draftVersion$.pipe(take(1)).subscribe(fn);
  }

  /**
   * Cancel the submissions of the draft version.
   */
  unlockDraft(): void {
    this.tapDraft(({ id }) => this.store.dispatch(unlockVersion({ id })));
  }

  /**
   * Get whether case is added to user cases
   */
  get isAdded$(): Observable<boolean> {
    return this.store.pipe(select(fromSubmissionPage.getIsAdded));
  }

  addToUserCases(): void {
    this.store
      .pipe(select(fromSubmissionPage.getSerialNumber), take(1))
      .subscribe(sn => {
        this._account.addUserCasesBySerialNumbers([sn]);
      });
  }

  removeFromUserCases(): void {
    this.store
      .pipe(select(fromSubmissionPage.getUserCaseNodeId), take(1))
      .subscribe(id => this._account.removeUserCases([id]));
  }
}
