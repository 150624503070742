export const caseFragmentFields = `
createdBy
createdAt
id
isAdded
latestUsptoOrigin
serialNumber
updatedAt
`;

export const caseFragment = `
fragment caseFragment on Case {
  ${caseFragmentFields}
}
`;
