import { Directive, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
  selector: '[mmFollowAnchor]'
})
export class FollowAnchorDirective {
  @Output() mmFollowAnchor = new EventEmitter();

  @HostListener('click', ['$event'])
  click(event: MouseEvent) {
    let elem = event.target as any; // HTMLElement;
    let isAnchor = false;
    // Walk ancestors looking for anchor tags
    do {
      isAnchor = elem.tagName.toUpperCase() === 'A';
      if (isAnchor) {
        break;
      }
    // tslint:disable-next-line: no-conditional-assignment
    } while ((elem = elem.parentElement));

    // If we didn't find an anchor, quit
    if (!isAnchor) {
      return;
    }

    // Check the href
    const href = elem.getAttribute('href');
    if (href.startsWith('#')) {
      // If the href is a tip link, swallow the click and navigate
      event.stopPropagation();
      event.preventDefault();
      const anchor = href.slice(1);
      this.mmFollowAnchor.emit(anchor);
    }
  }
}
