import { GraphQLQuery, GraphQLResponse } from 'app/interfaces';
import { ContextMenuItem } from '../../models/context-menu-item.model';
import { contextMenuItemFragment } from './context-menu.fragment';

const QUERY = `

mutation DeleteContextMenuByPathAndLabel($input: DeleteContextMenuByPathAndLabelInput!) {
  deleteContextMenuByPathAndLabel(input: $input) {
    contextMenu {
      ...contextMenuFields
   }
 }
}
${contextMenuItemFragment}
`;

export class DeleteContextMenuByPathAndLabelMutation implements GraphQLQuery {
  query = QUERY;
  constructor(public variables: { input: { path: string, label: string } }) {}
}

export type DeleteContextMenuByPathAndLabelResponse = GraphQLResponse<{
  deleteContextMenuByPathAndLabel: {
    contextMenu: ContextMenuItem;
  }
}>;
