import { ChangeDetectionStrategy, Component, OnInit, Optional, Self, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ContextMenuPathDirective } from '../../directives/context-menu-path.directive';
import { ContextMenuItem } from '../../models/context-menu-item.model';
import { ContextMenuService } from '../../services/context-menu.service';
import { AccountService } from '@markmachine/features/account/services/account.service';

@Component({
  selector: 'mm-context-menu-content',
  templateUrl: './context-menu-content.component.html',
  styleUrls: ['./context-menu-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContextMenuContentComponent implements OnInit {
  menuItems$: Observable<ContextMenuItem[]>;
  menuItemsNotEmpty$: Observable<boolean>;
  mockMenuItems$: Observable<ContextMenuItem[]>;

  constructor(
    private account: AccountService,
    private service: ContextMenuService,
    @Optional() private menuPath: ContextMenuPathDirective,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit() {
    this.menuItems$ = this.menuPath.valueChanges.pipe(switchMap(({ path, props }) => this.service.contextMenuItems(path, props)));
    this.menuItemsNotEmpty$ = this.menuItems$.pipe(map(items => items.length > 0));
  }

  get isAdmin$() {
    return this.account.isAdmin$;
  }

  get path() {
    return this.menuPath.fullPath;
  }

  setClipboard() {
    navigator.clipboard.writeText(this.menuPath.fullPath);
    this.snackbar.open(`Copied “${this.menuPath.fullPath}” to clipboard`, undefined, { duration: 2000 });
  }
}
