<div class="table-container">
  <table>
    <thead>
      <tr #headerRow
        cdkDropList
        [cdkDropListDisabled]="isColReorderDisabled()"
        [cdkDropListOrientation]="'horizontal'"
        (cdkDropListDropped)="onDropHeader($event)">
        <th *ngIf="isSelectionEnabled() || hasActions() || showRowNumber()"
          [ngClass]="getHeaderClass(0)"
          [style.width.px]="checkboxColumnWidth()">
          <span *ngIf="showRowNumber()" class="row-number row-number--header">Row</span>
          <mat-checkbox *ngIf="isSelectionEnabled()"
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [style.z-index]="headers.length"></mat-checkbox>
        </th>
        <th *ngFor="let header of headers; index as headerIndex; let count = count"
          #th
          [id]="getTableHeaderId(header)"
          [ngClass]="getHeaderClass(headerIndex)"

          cdkDrag
          [cdkDragDisabled]="isColReorderDisabled()"

          mmResizeColumn
          (widthChanged)="onHeaderResize($event, header.id)"
          [initialWidth]="initialWidth(header.id)"
          [style.z-index]="count - headerIndex">
          <mm-resize-handle></mm-resize-handle>
          <!-- New cdkDropList context protects resize from being disabled if re-order is disabled -->
          <div class="cell-container"
            cdkDropList
            [cdkDropListDisabled]="isColResizeDisabled()">
            <mm-header-cell [header]="header"
              [rows]="filteredRows">
            </mm-header-cell>
            <!-- Pass this.features to update options when this.features changes -->
            <mm-vellipsis-button
              [vellipsisMenuOptions]="getHeaderVellipsisMenuOptions(header)"
              (vellipsisMenuClick)="onHeaderVellipsisMenuClick(headerIndex - 1, $event)">
            </mm-vellipsis-button>
            <mm-resize-handle></mm-resize-handle>
          </div>
          <div *cdkDragPlaceholder></div>
          <!-- DRAG PLACEHOLDER -->
          <table *cdkDragPreview>
            <thead>
              <tr>
                <th [ngClass]="getHeaderClass(headerIndex)">
                  <mm-header-cell [header]="header"
                    [rows]="filteredRows">
                  </mm-header-cell>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of pagedRows; index as rowIndex; trackBy: trackBy">
                <td [ngClass]="getCellClass(headerIndex, rowIndex)">
                  <mm-data-cell [header]="header"
                    [row]="row">
                  </mm-data-cell>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- END DRAG PLACEHOLDER -->
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of pagedRows; index as rowIndex" #dataRow>
        <td *ngIf="isSelectionEnabled() || hasActions() || showRowNumber()"
          [ngClass]="getCellClass(0, rowIndex)">
          <span *ngIf="showRowNumber()" class="row-number row-number--cell">{{ rowNumber(rowIndex) }}</span>
          <mat-checkbox *ngIf="isSelectionEnabled()"
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row.id) : null"
            [checked]="selection.isSelected(row.id)"></mat-checkbox>
          <ng-container *ngFor="let action of actions">
            <mm-data-cell [header]="action"
              [row]="row">
            </mm-data-cell>
          </ng-container>
        </td>
        <td *ngFor="let header of headers; index as headerIndex"
          [ngClass]="getCellClass(headerIndex, rowIndex)">
          <div class="cell-container">
            <mm-data-cell [header]="header"
              [row]="row">
            </mm-data-cell>
            <mm-vellipsis-button
              [vellipsisMenuOptions]="getVellipsisMenuOptions(header)"
              (vellipsisMenuClick)="onVellipsisMenuClick(headerIndex, $event)">
            </mm-vellipsis-button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
