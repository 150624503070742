import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'mm-revert-confirm-dialog',
  templateUrl: './revert-confirm-dialog.component.html',
  styleUrls: ['./revert-confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RevertConfirmDialogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
