import { CaseStatus } from '@markmachine/features/case-status/models/status.model';
import { getStatusEntities } from '@markmachine/features/case-status/reducers/status.reducer';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { max } from 'lodash-es';
import * as CaseActions from '../actions/case.actions';
import { Case } from '../models/case.model';

// **************************************************************************
// State
// **************************************************************************
export interface State extends EntityState<Case> {
  // additional entities state properties
  pollUnanalyzedUntil: Date | null;
}

export const adapter: EntityAdapter<Case> = createEntityAdapter<Case>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  pollUnanalyzedUntil: null
});

// **************************************************************************
// Reducers
// **************************************************************************
export const reducer = createReducer(
  initialState,
  on(CaseActions.addCase, (state, { case: case_ }) => adapter.addOne(case_, state)),
  on(CaseActions.upsertCase, (state, { case: case_ }) => adapter.upsertOne(case_, state)),
  on(CaseActions.addCases, (state, { cases }) => adapter.addMany(cases, state)),
  on(CaseActions.upsertCases, (state, { cases }) => adapter.upsertMany(cases, state)),
  on(CaseActions.updateCase, (state, { case: case_ }) => adapter.updateOne(case_, state)),
  on(CaseActions.updateCases, (state, { cases }) => adapter.updateMany(cases, state)),
  on(CaseActions.deleteCase, (state, { id }) => adapter.removeOne(id, state)),
  on(CaseActions.deleteCases, (state, { ids }) => adapter.removeMany(ids, state)),
  on(CaseActions.loadCases, (state, { cases }) => adapter.setAll(cases, state)),
  on(CaseActions.clearCases, state => adapter.removeAll(state)),
  on(CaseActions.pollUnanalyzed, (state, { until }) => {
    // We might be asked to poll until a timestamp that is less than
    // our current limit. Always use the later timestamp.
    const pollUnanalyzedUntil = max([state.pollUnanalyzedUntil || new Date(), until]);
    return { ...state, pollUnanalyzedUntil } as State;
  })
);


// **************************************************************************
// Selectors
// **************************************************************************
export const storeKey = 'cases';
export const getCaseState = createFeatureSelector<State>(storeKey);

export const {
  selectIds: getCaseIds,
  selectEntities: getCaseEntities,
  selectAll: getAllCases,
  selectTotal: getTotalCases,
} = adapter.getSelectors(getCaseState);

const hasPermanentError = (status?: CaseStatus) => {
  return status
    && status.error
    && !status.error.startsWith('Permanent error');
};

export const getUnanalyzedCaseSerialNumbers = createSelector(
  getAllCases,
  getStatusEntities,
  (cases, statuses) => cases
    .filter(c => (!c.latestUsptoOrigin && c.serialNumber))
    .map(c => c.serialNumber)
    .filter(serialNumber => !hasPermanentError(statuses[serialNumber]))
);

export const getPollUnanalyzedUntil = createSelector(getCaseState, s => s.pollUnanalyzedUntil);
