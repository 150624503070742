import { OfficeActionStatistics } from '@markmachine/core/models/office-action-statistics';
import { CaseId } from '@markmachine/features/case/models/case.model';
import { SubmissionMethod, Version, VersionId } from '@markmachine/features/version/models/version.model';
import { createAction, props } from '@ngrx/store';

export const deactivateSubmission = createAction(
  '[SubmissionPage] DeactivateSubmission',
);

export const activateSubmission = createAction(
  '[SubmissionPage] ActivateSubmission',
);

export const activateSubmissionCompleted = createAction(
  '[SubmissionPage] ActivateSubmissionCompleted',
  props<{ caseId: CaseId, draftId: VersionId, currentId: VersionId }>()
);

export const setLatest = createAction(
  '[SubmissionPage] SetLatest',
  props<{ statistics: OfficeActionStatistics[], updatedDocumentsAt: string }>()
);

export const setLatestFailed = createAction(
  '[SubmissionPage] SetLatestFailed',
);

export const payUsptoFeesAction = createAction(
  '[SubmissionPage] PayUsptoFeesAction',
  props<{ stripeToken: any }>()
);

export const chooseSubmissionMethod = createAction(
  '[SubmissionPage] ChooseSubmissionMethod',
  props<{ method: SubmissionMethod }>()
);

export const updateSubmissionScript = createAction(
  '[SubmissionPage] UpdateSubmissionScript',
  props<{ script: string }>()
);

export const revertVersion = createAction(
  '[SubmissionPage] RevertVersion',
);

export const submitVersion = createAction(
  '[SubmissionPage] SubmitVersion',
  props<{ version: Version }>()
);

export const expandGuide = createAction(
  '[SubmissionPage] ExpandGuide',
  props<{ guideId: string }>()
);

export const collapseGuide = createAction(
  '[SubmissionPage] CollapseGuide',
  props<{ guideId: string }>()
);

export const refreshStatistics = createAction(
  '[SubmissionPage] RefreshStatistics',
  props<{ caseId: string }>()
);

export const refreshStatisticsFailure = createAction(
  '[SubmissionPage] RefreshStatisticsFailure',
  props<{ errorMessage: string }>()
);

export const pollingStatisticsInit = createAction(
  '[SubmissionPage] PollingStatisticsInit',
  props<{ caseId: string, until: string }>()
);

export const pollingFailure = createAction(
  '[SubmissionPage] PollingFailure',
  props<{ errorMessage: string }>()
);

export const selectGuides = createAction(
  '[SubmissionPage] SelectGuides',
  props<{ guideIds: string[] }>()
);

export const deselectGuides = createAction(
  '[SubmissionPage] DeselectGuides',
  props<{ guideIds: string[] }>()
);

export const selectIssues = createAction(
  '[SubmissionPage] SelectIssues',
  props<{ issueIds: string[] }>()
);
