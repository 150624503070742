import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaseHeaderComponent } from './components/case-header/case-header.component';
import { SharedModule } from '@markmachine/shared/shared.module';
import { SharedMaterialModule } from '@markmachine/shared/materials/shared-materials.module';
import { ContextMenuModule } from '../context-menu/context-menu.module';

@NgModule({
  declarations: [CaseHeaderComponent],
  imports: [
    CommonModule,
    SharedModule,
    SharedMaterialModule,
    ContextMenuModule
  ],
  exports: [CaseHeaderComponent]
})
export class CaseHeaderModule { }
