import { UserCase } from '@markmachine/features/user-case/models/user-case.model';
import { Update } from '@ngrx/entity';
import { GraphQLQuery, GraphQLResponse } from 'app/interfaces';
import { userCaseLargeFragment } from './user-case.fragment';

const graphqlQuery = `
mutation UpdateUserCase($id: ID!, $changes: UserCasePatch!) {
  updateUserCase(input:{nodeId:$id, userCasePatch: $changes}) {
    userCase {
      ...userCaseLargeFragment
    }
  }
}
${userCaseLargeFragment}
`;

export class UpdateUserCaseMutation implements GraphQLQuery {
  query = graphqlQuery;

  constructor(public variables: Update<UserCase>) {}
}

export type UpdateUserCaseResponse = GraphQLResponse<{
  updateUserCase: { userCase: UserCase };
}>;
