import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import * as FileActions from '../actions/file.actions';
import { CaseFile } from '../models/file.model';

// **************************************************************************
// State
// **************************************************************************
export interface State extends EntityState<CaseFile> {
  // additional entities state properties
}

export const adapter: EntityAdapter<CaseFile> = createEntityAdapter<CaseFile>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});
export const { selectAll } = adapter.getSelectors();

// **************************************************************************
// Reducers
// **************************************************************************
export const reducer = createReducer(
  initialState,
  on(FileActions.addFile, (state, { file }) => adapter.addOne(file, state)),
  on(FileActions.upsertFile, (state, { file }) => adapter.upsertOne(file, state)),
  on(FileActions.addFiles, (state, { files }) => adapter.addMany(files, state)),
  on(FileActions.upsertFiles, (state, { files }) => adapter.upsertMany(files, state)),
  on(FileActions.updateFile, (state, { file, }) => adapter.updateOne(file, state)),
  on(FileActions.updateFiles, (state, { files }) => adapter.updateMany(files, state)),
  on(FileActions.deleteFile, (state, { id }) => adapter.removeOne(id, state)),
  on(FileActions.deleteFiles, (state, { ids }) => adapter.removeMany(ids, state)),
  on(FileActions.loadFiles, (state, { files }) => adapter.setAll(files, state)),
  on(FileActions.clearFiles, (state) => adapter.removeAll(state)),
  on(FileActions.removeFileSuccess, (state, { casefile }) => adapter.removeOne(casefile.id, state)),

  on(FileActions.removeFileFailure, (state, { idCase, idCaseFile: id }) => {
    const changes: CaseFile = {
      ...state.entities[id] as CaseFile,
      status: 'error',
      statusMessage: 'Delete Failed'
    };
    return adapter.updateOne({ id, changes }, state);
  }),

  on(FileActions.removeFile, (state, { idCase, idCaseFile: id }) => {
    const changes: CaseFile = {
      ...state.entities[id] as CaseFile,
      status: 'deleting'
    };
    return adapter.updateOne({ id, changes }, state);
  }),

  on(FileActions.uploadFile, (state, { path, file, idCase }) => {
    const filepath = path ? `${path}/${file.name}` : file.name;
    const casefile: CaseFile = { id: filepath, idCase, createdAt: undefined, filepath, progress: -1, file, status: 'uploading' };
    return adapter.addOne(casefile, state);
  }),

  on(FileActions.uploadFileSuccess, (state, { casefile, file }) => {
    const cf: CaseFile = { ...selectAll(state).find(f => f.file === file), ...casefile, status: undefined };
    return adapter.addOne(cf as CaseFile, adapter.removeOne(cf.filepath, state));
  }),

  on(FileActions.uploadFileFailure, (state, { file }) => {
    const cf = selectAll(state).find(f => f.file === file) as CaseFile;
    const changes: CaseFile = { ...cf, status: 'error', statusMessage: 'Upload Failed'};
    return adapter.updateOne({ id: cf.id, changes }, state);
  }),

  on(FileActions.uploadFileProgress, (state, { file, progress }) => {
    const cf = selectAll(state).find(f => f.file === file) as CaseFile;
    const changes: CaseFile = { ...cf, progress };
    return adapter.updateOne({ id: cf.id, changes }, state);
  }),
);


// **************************************************************************
// Selectors
// **************************************************************************
export const storeKey = 'files';
export const getFileState = createFeatureSelector<State>(storeKey);

export const {
  selectIds: getFileIds,
  selectEntities: getFileEntities,
  selectAll: getAllFiles,
  selectTotal: getTotalFiles,
} = adapter.getSelectors(getFileState);
