import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';
import { AccountService } from '@markmachine/features/account/services/account.service';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

const { GRAPHQL, REST_API } = environment;
const ENDPOINTS = [GRAPHQL, REST_API];

@Injectable()
export class JwtTokenInterceptor implements HttpInterceptor {
  constructor(public account: AccountService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = AccountService.tokenGetter();
    const { url } = request;
    const isApiUrl = !ENDPOINTS.every(api => !url.includes(api));
    const isExpired = AccountService.tokenExpired(token);
    if (!token || !isApiUrl || isExpired) {
      return next.handle(request);
    }
    const clone = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
    return next.handle(clone);
  }
}
