import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { go } from '@markmachine/core/actions/router.actions';
import { POLLING_LIMIT } from '@markmachine/core/core.config';
import { uuidv4 } from '@markmachine/core/functions/utilities';
import { throwGraphqlErrors, unpackGraphqlResponse } from '@markmachine/core/graphql-operators';
import * as StatusActions from '@markmachine/features/case-status/actions/status.actions';
import * as CaseActions from '@markmachine/features/case/actions/case.actions';
import { Case } from '@markmachine/features/case/models/case.model';
import { AppState } from '@markmachine/interfaces';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Update } from '@ngrx/entity';
import { select, Store } from '@ngrx/store';
import { environment } from 'environments/environment';
import { merge, Observable, of } from 'rxjs';
import { catchError, concatMap, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as UserCaseActions from '../actions/user-case.actions';
import { NewUserCase, UserCase } from '../models/user-case.model';
import { getUserCaseByNodeId, getUserCaseEntities } from '../reducers/user-case.reducer';
import { CreateNewUserCaseMutation } from '../services/operations/create-new-case.mutation';
import { CreateUserCaseMutation, CreateUserCaseResponse } from '../services/operations/create-user-case.mutation';
import { UserCaseService } from '../services/user-case.service';


const { GRAPHQL } = environment;

@Injectable()
export class UserCaseEffects {
  // **************************************************************************
  // User Cases Effects
  // **************************************************************************

  /**
   * Add cases to user profile via API
   */
  AddUserCasesBySerialNumbers$ = createEffect(() => this.actions$.pipe(
    ofType(UserCaseActions.addUserCasesBySerialNumbers),
    // Perform HTTP request to add user cases
    switchMap(({ serialNumbers }) => this.addUserCasesBySerialNumbers(serialNumbers))
  ));

  AddUserCasesBySerialNumbersSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(UserCaseActions.addUserCasesBySerialNumbersSuccess),
    tap(({ payload }) => this.snack(`Added ${payload.length === 1 ? '1 case' : payload.length + ' cases'}!`))
  ), { dispatch: false });

  AddUserCasesBySerialNumbersFailure$ = createEffect(() => this.actions$.pipe(
    ofType(UserCaseActions.addUserCasesBySerialNumbersFailure),
    tap(() => this.snack('Cases were not added!'))
  ), { dispatch: false });

  DeleteUserCases$ = createEffect(() => this.actions$.pipe(
    ofType(UserCaseActions.deleteUserCases),
    // Translate nodeIds to caseIds for HTTP request
    withLatestFrom(this.store.pipe(select(getUserCaseEntities))),
    map(([{ nodeIds }, userCases]) => nodeIds.map(nodeId => userCases[nodeId]?.caseId).filter(Boolean) as string[]),
    // Perform HTTP request to remove cases
    switchMap(caseIds => this.deleteUserCasesByCaseIds(caseIds)),
    tap(action => {
      switch (action.type) {
        case UserCaseActions.deleteUserCasesSuccess.type:
          const caseCount = action.nodeIds.length;
          return this.snack(`Removed ${caseCount === 1 ? '1 case' : caseCount + ' cases'}!`);
        case UserCaseActions.deleteUserCasesFailure.type:
          return this.snack('Cases were not removed!');
      }
    })
  ));

  UpdateUserCase$ = createEffect(() => this.actions$.pipe(
    ofType(UserCaseActions.updateUserCase),
    // Unpack the action payload
    mergeMap(({ userCase }) => this.updateUserCase(userCase)),
  ));

  UpdateUserCases$ = createEffect(() => this.actions$.pipe(
    ofType(UserCaseActions.updateUserCases),
    // Unpack the action payload
    mergeMap(({ userCases }) => userCases.map(userCase => UserCaseActions.updateUserCase({ userCase })))
  ));

  AddUserCases$ = createEffect(() => this.actions$.pipe(
    ofType(UserCaseActions.createUserCases),
    // Unpack the action payload
    mergeMap(({ userCases }) => userCases.map(userCase => UserCaseActions.createUserCase({ userCase }))),
  ));

  AddUserCasesRefreshStatus$ = createEffect(() => this.actions$.pipe(
    ofType(UserCaseActions.addUserCasesBySerialNumbersSuccess),
    mergeMap(({ payload: nodes }) => {
      const userCases = nodes.map(n => n.userCase);
      const statuses = nodes.map(n => n.status).filter(s => !!s);
      const cases = nodes.map(n => n.case_);
      const caseIds = cases.map(c => c.id);
      const actions: any[] = [
        CaseActions.addCases({ cases }),
        UserCaseActions.createUserCases({ userCases }),
        StatusActions.refreshStatuses({ caseIds }),
      ];
      if (statuses.length > 0) {
        actions.push(StatusActions.addStatuses({ statuses }));
      }
      return actions;
    })
  ));

  /** After an user case has been added or loaded, poll for completion of analysis. */
  watchForAnalysisCompletion$ = createEffect(() => this.actions$.pipe(
    ofType(UserCaseActions.addUserCasesBySerialNumbersSuccess, UserCaseActions.loadUserCases),
    map(() => {
      const until = new Date();
      until.setMilliseconds(until.getMilliseconds() + POLLING_LIMIT);
      return CaseActions.pollUnanalyzed({ until });
    })
  ));

  /** Create a new case, user case, and version */
  CreateNewCase = createEffect(() => this.actions$.pipe(
    ofType(UserCaseActions.createNewCase),
    concatMap(() => {
      const newCase = { ...new Case(), id: uuidv4() };
      const userCase = { caseId: newCase.id, name: this.getNewAppCaseName() };
      return this.createNewUserCaseMutation.mutate({ case: newCase, userCase }).pipe(
        throwGraphqlErrors(),
        concatMap(({ data }) => [
          CaseActions.addCase({ case: data.createCase.case }),
          UserCaseActions.createUserCaseSuccess({ userCase: data.createUserCase.userCase }),
          UserCaseActions.createNewCaseSuccess({ caseId: newCase.id })
        ]),
        catchError(({ errorMessage }) => of(UserCaseActions.createNewCaseFailure({ errorMessage })))
      );
    }),
  ));

  /**
   * Clone a case and related user case.
   *
   * Expect version creation and navigation as a follow-up to cloneUserCaseSuccess.  See:
   *   `CloneCaseVersion$` in `version.effects.ts`
   */
  CloneUserCase = createEffect(() => this.actions$.pipe(
    ofType(UserCaseActions.cloneUserCase),
    concatMap(({ nodeId }) => this.store.pipe(select(getUserCaseByNodeId(), { nodeId }), first())),
    concatMap((originalUserCase) => {
      const newCase = { ...new Case(), id: uuidv4() };
      const newUserCase: NewUserCase = { caseId: newCase.id, name: this.getNewAppCaseName() };
      return this.createNewUserCaseMutation.mutate({ case: newCase, userCase: newUserCase }).pipe(
        throwGraphqlErrors(),
        concatMap(({ data }) => [
          CaseActions.addCase({ case: data.createCase.case }),
          UserCaseActions.addUserCase({ userCase: data.createUserCase.userCase }),
          UserCaseActions.cloneUserCaseSuccess({
            oldCaseId: originalUserCase.caseId,
            newCaseId: data.createUserCase.userCase.caseId
          })
        ])
      );
    }),
    catchError(({ errorMessage }) => of(UserCaseActions.cloneUserCaseFailure({ errorMessage })))
  ));

  AddUserCase$ = createEffect(() => this.actions$.pipe(
    ofType(UserCaseActions.createUserCase),
    mergeMap(({ userCase: userCase_ }) => this.createUserCase(userCase_).pipe(
      map(userCase => UserCaseActions.createUserCaseSuccess({ userCase })),
      catchError(({ errorMessage }) => of(UserCaseActions.createUserCaseFailure({ errorMessage })))
    ))
  ));

  constructor(
    private service: UserCaseService,
    private actions$: Actions,
    private snackbar: MatSnackBar,
    private http: HttpClient,
    private store: Store<AppState>,
    private createNewUserCaseMutation: CreateNewUserCaseMutation
  ) {}

  /**
   * Dispatch a simple snackbar message about the result of an effect
   * @param message Snackbar message
   */
  private snack(message: string) {
    this.snackbar.open(message, undefined, { duration: 2000 });
  }

  // **************************************************************************
  // User Cases
  // **************************************************************************

  /**
   * Return an action encapsulating the result of adding user cases to the profile
   *
   * This method is broken out of the effect stream as a concession
   * to ngrx4. As of ngrx4, exceptions must be handled immedately,
   * rather than being allowed to bubble into the effect stream.
   */
  addUserCasesBySerialNumbers(serialNumbers: number[]) {
    return this.service.requestAddUserCasesBySerialNumbers(serialNumbers).pipe(
      // If authentication succeeded, emit success
      map(payload => UserCaseActions.addUserCasesBySerialNumbersSuccess({ payload })),
      // If authentication failed, emit failure
      catchError(error => {
        const errorMessage = error.message;
        return of(UserCaseActions.addUserCasesBySerialNumbersFailure({ errorMessage }));
      })
    );
  }

  /**
   * Return an action encapsulating the result of removing user cases
   *
   * This method is broken out of the effect stream as a concession
   * to ngrx4. As of ngrx4, exceptions must be handled immedately,
   * rather than being allowed to bubble into the effect stream.
   */
  deleteUserCasesByCaseIds(caseIds: string[]) {
    return this.service.requestRemoveUserCases(caseIds).pipe(
      // Map cases to node ids
      map(userCases => userCases.map(uc => uc.nodeId) as string[]),
      // If authentication succeeded, emit success
      map(nodeIds => UserCaseActions.deleteUserCasesSuccess({ nodeIds })),
      // If authentication failed, emit failure
      catchError(error => {
        const errorMessage = error.message;
        return of(UserCaseActions.deleteUserCasesFailure({ errorMessage }));
      })
    );
  }

  /**
   * Return an action encapsulating the result of removing user cases
   *
   * This method is broken out of the effect stream as a concession
   * to ngrx4. As of ngrx4, exceptions must be handled immedately,
   * rather than being allowed to bubble into the effect stream.
   */
  updateUserCase(payload: Update<UserCase>) {
    return this.service.requestUpdateUserCase(payload).pipe(
      // If authentication succeeded, emit success
      map(() => UserCaseActions.updateUserCaseSuccess({ userCase: payload })),
      // If authentication failed, emit failure
      catchError(error => {
        const errorMessage = error.message;
        return of(UserCaseActions.updateUserCaseFailure({ errorMessage }));
      })
    );
  }

  /**
   * Return an action encapsulating the result of updating user cases
   *
   * This method is broken out of the effect stream as a concession
   * to ngrx4. As of ngrx4, exceptions must be handled immedately,
   * rather than being allowed to bubble into the effect stream.
   */
  updateUserCases(payload: Update<UserCase>[]) {
    const updates = payload.map(userCase => this.updateUserCase(userCase));
    return merge(...updates);
  }

  /**
   * Create a new User Case
   */
  createUserCase(userCase: Partial<UserCase>): Observable<UserCase> {
    return this.http
      .post<CreateUserCaseResponse>(GRAPHQL, new CreateUserCaseMutation({ userCase }))
      .pipe(unpackGraphqlResponse('data.createUserCase.userCase'));
  }

  /**
   * Create the Case Metadata string from the current date
   * String result is of form "New Application-<currentdate>"
   */
  getNewAppCaseName(): string {
    return 'New Application-' + new Date().getTime();
  }

  /**
   * Create the Case Metadata string from the current date
   * String result is of form "<oldname>-Copy-<currentdate>"
   */
  getCloneCaseName(oldName?: string): string {
    let newName = 'Copy-' + new Date().getTime();
    if (oldName) {
      newName = oldName + '-' + newName;
    }
    return newName;
  }
}
