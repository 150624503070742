import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';
import { SubmissionService } from '@markmachine/views/submission-page/services/submission.service';

@Component({
  selector: 'mm-allege-use-form',
  templateUrl: './allege-use-form.component.html',
  styleUrls: ['./allege-use-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AllegeUseFormComponent {
  @Input() root: FormGroup;
  constructor(private cd: ChangeDetectorRef, private submission: SubmissionService) {}

  get classes() {
    return this.root.get('classes') as FormArray;
  }

  get classGroups(): FormGroup[] {
    return this.classes.controls.filter(g => !!g.get('$eligible-for-allegation-of-use')?.value) as FormGroup[];
  }

  delete(index: number): void {
    this.submission.deleteClass(
      this.classes.at(index) as FormGroup,
      () => this.cd.markForCheck()
    );
  }
}
