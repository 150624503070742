import { NoticeDialogData } from '@markmachine/shared/components/notice-dialog/notice-dialog.component';
import { createAction, props } from '@ngrx/store';
import { AuthenticatedUserProfile, UserProfile } from '../models/user-profile.model';

export const init = createAction('[Account] Init');
export const register = createAction('[Account] Register device', props<{ email: string; password: string }>());
export const registerSuccess = createAction(
  '[Account] Register device success',
  props<{ accessToken: string; profile: AuthenticatedUserProfile }>()
);
export const registerFailure = createAction('[Account] Register device failure', props<{ errorMessage: string }>());
export const authorize = createAction('[Account] Authorize User', props<{ email: string; password: string }>());
export const authorizeSuccess = createAction(
  '[Account] Authorize device success',
  props<{ accessToken: string; profile: AuthenticatedUserProfile }>()
);
export const authorizeFailure = createAction('[Account] Authorize device failure', props<{ errorMessage: string }>());
export const deauthorize = createAction('[Account] Deauthorize device');
export const signOut = createAction('[Account] Sign out');
export const signIn = createAction('[Account] Sign in');
export const confirmChangePassword = createAction('[Account] Change Password Confirm', props<{ password: string }>());
export const changePassword = createAction('[Account] Change Password', props<{ password: string }>());
export const changePasswordSuccess = createAction('[Account] Change Password Success');
export const changePasswordFailure = createAction('[Account] Change Password Failure', props<{ errorMessage: string; }>());
export const loadProfileSuccess = createAction('[Account] Load profile success', props<{ profile: UserProfile }>());
export const updateProfile = createAction('[Account] Update profile', props<{ profile: Partial<UserProfile> }>());
export const updateProfileSuccess = createAction('[Account] Update profile success', props<{ profile: UserProfile }>());
export const updateProfileFailure = createAction('[Account] Update profile failure');
export const setReturnUrlAction = createAction('[Account] Set Return Url', props<{ returnUrl: string }>());
export const navigateToReturnUrl = createAction('[Account] Navigate To Return Url');
export const dismissTutorial = createAction('[Account] Dismiss Tutorial', props<{ tutorialName: string }>());
export const undismissTutorial = createAction('[Account] Undismiss Tutorial', props<{ tutorialName: string }>());
export const showNotice = createAction('[Account] Show Notice', props<NoticeDialogData>());
