import { ComponentFactoryResolver, Injectable, ViewContainerRef } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { ContextMenuPathDirective } from '../directives/context-menu-path.directive';
import { ContextMenuItem } from '../models/context-menu-item.model';
import { filter } from 'rxjs/operators';
import { AppState } from '@markmachine/interfaces';
import { Store, select } from '@ngrx/store';
import * as fromContextMenu from '../reducers/context-menu.reducer';

@Injectable({
  providedIn: 'root'
})
export class ContextMenuService {
  registery = new Map<string, ViewContainerRef>();
  reverseRegistery = new Map<ViewContainerRef, string>();
  channel = new Subject<ContextMenuItem>();
  registeryChanges = new Subject<void>();

  constructor(private store: Store<AppState>) {}

  /**
   * Register a view with a path for future navigation and editing.
   *
   * Once a view is registered to a path, the context menu system can
   * use that path to navigate to or edit a view. Edits can include
   * adding content children.
   */
  register(path: string, view: ViewContainerRef) {
    this.registery.set(path, view);
    this.reverseRegistery.set(view, path);
    this.registeryChanges.next();
  }

  /** Unregister a view once it's no longer usable. */
  unregister(view: ViewContainerRef) {
    const path = this.reverseRegistery.get(view) as string;
    this.reverseRegistery.delete(view);
    this.registery.delete(path);
    this.registeryChanges.next();
  }

  /** Obtain a menu item */
  contextMenuItems(path: string, override?: object): Observable<ContextMenuItem[]> {
    if (!path) {
      return of([]);
    }
    return this.store.pipe(select(fromContextMenu.getContextMenuItemsByPath(), { path, props: override }));
  }

  activate(item: ContextMenuItem) {
    this.channel.next(item);
  }

  activations(path: string) {
    return this.channel.pipe(filter(item => item.path === path));
  }
}
