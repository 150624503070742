import { FormArray } from '@angular/forms';
import { isArray, isEqual, isNil, isObject, mergeWith, transform } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


/**
 * Return if the date object is a valid date
 * @param d Date object
 */
export function isValidDate(d: Date) {
  return d instanceof Date && !isNaN(d.getTime());
}

/**
 * Make a date safe for Safari
 * Safari only understands an extremely narrow set of RFC 3339 / ISO 8601 date strings.
 */
type DateLike = string | Date | undefined | null;
export function safariSafeIsoDate(d: string): string;
export function safariSafeIsoDate(d: Date): Date;
export function safariSafeIsoDate(d: DateLike): DateLike {
  if (isNil(d) || d instanceof Date || !d.replace) {
    return d;
  }
  return d.replace(/(\d{4}-?\d{2}-?\d{2}) (\d{2}.+)/, '$1T$2');
}

/**
 * Extract USPTO trademark serial numbers from text
 * @param text Text containing USPTO trademark serial numbers
 */
export function extractSerialNumbers(text: string): number[] {
  const regex = /(\b\d{2}\D?\d{3}\D?\d{3}\b)/g;
  const matches = text.match(regex);
  if (!matches) {
    return [];
  }
  const serialNumbers = matches.map(s => parseInt(s.replace(/\D/g, ''), 10));
  return serialNumbers;
}

/**
 * Filtered serial numbers that cannot be analyzed at this time.
 *
 * See TMEP [401.02]
 *
 * [401.02]: https://tmep.uspto.gov/RDMS/TMEP/current#/current/TMEP-400d1e1.html
 */
export function unanalyzableSerialNumbers(serialNumbers: number[]): number[] {
  return serialNumbers.filter(n => {
    return !(
      (n >= 10e6 && n < 100e6) // Exclude non-8-digit numbers
      && (n >= 77e6) // Exclude all pre-TEAS ranges
      && (n < 89e6 || n >= 90e6) // Excluded the statutory 89 series
      && (n < 79e6 || n >= 80e6) // Exclude the madrid extension 79 series
    );
  });
}

/**
 * Deep clone an object
 *
 * https://stackoverflow.com/questions/34688517/how-can-i-use-angular-copy-in-angular-2/34694155
 *
 * @param obj
 * @returns copy of obj
 */
export function deepClone(obj) {
  // return value is input is not an Object or Array.
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  let clone;

  if (Array.isArray(obj)) {
    clone = obj.slice(); // unlink Array reference.
  } else {
    clone = Object.assign({}, obj); // Unlink Object reference.
  }

  const keys = Object.keys(clone);

  for (let i = 0; i < keys.length; i++) {
    clone[keys[i]] = deepClone(clone[keys[i]]); // recursively unlink reference to nested objects.
  }

  return clone; // return unlinked clone.
}

type SortOrder = -1 | 0 | 1;

export function ascending(...keys: string[]): (a: any, b: any) => SortOrder {
  return (a, b) =>
    keys.reduce(
      (order: SortOrder, key) =>
        order || a[key] > b[key] ? 1 : a[key] < b[key] ? -1 : 0,
      0
    );
}

export function descending(...keys: string[]): (a: any, b: any) => SortOrder {
  return (a, b) =>
    keys.reduce(
      (order: SortOrder, key) =>
        order || a[key] < b[key] ? 1 : a[key] > b[key] ? -1 : 0,
      0
    );
}

export function asBoolString(value: string | boolean): string {
  if ([true, 'Yes', 'Y', 'y', 'yes', 't', 'true'].includes(value)) {
    return 'Yes';
  } else if (value === undefined) {
    return '';
  } else {
    return 'No';
  }
}

export function asBoolean(value: string): boolean {
  return asBoolString(value) === 'Yes';
}

/**
 * uuidv4
 * https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
 */
export function uuidv4(): string {
  return (<any>[1e7] + -1e3 + -4e3 + -8e3 + -1e11)
    .replace( /[018]/g, c => ( c ^ (
      crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4))) ).toString(16) // tslint:disable-line:no-bitwise
  );
}

/**
 * Deep diff between two object, using lodash
 * @param  object Object compared
 * @param  base   Object to compare with
 * @return        Return a new object who represent the diff
 */
export function difference(object, base) {
  return transform(object, (result: object, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] =
        isObject(value) && isObject(base[key])
          ? difference(value, base[key])
          : value;
    }
  });
}

/**
 * Update a FormArray of FormGroups
 * @param array FormArray to update
 * @param values Values to update the array with
 * @param initializer Function to create a new FormGroup
 */
export function updateFormArray<T>(
  array: FormArray,
  values: T[],
  initializer
): void {
  // Remove any extra controls from the form array
  for (let i = array.length - 1; i > values.length - 1; i--) {
    array.removeAt(i);
  }
  // Add any controls needed in the form array
  for (let i = array.length; i < values.length; i++) {
    array.setControl(i, initializer(values, i));
  }
  // Patch values into the form array
  array.patchValue(values, { emitEvent: false });
}

/** Customizer for use with VersionContent and lodash's mergeWith */
export function mergeWithCustomizer(objValue, srcValue, key, object, source, stack) {
  if (isArray(objValue) || isArray(srcValue)) {
    if (isNil(objValue) || isNil(srcValue)) {
      return srcValue;
    } else if ((srcValue && srcValue[0]) instanceof Object) {
      return srcValue.map((s, i) => mergeWith({}, objValue[i], s, mergeWithCustomizer));
    } else {
      return srcValue;
    }
  }
}

/** Slugify (lower-kebab-a-z-0-9-_) some text */
export function slug(text: string): string {
  return text.toLocaleLowerCase().replace(/[\s–—/]+/g, '-').replace(/[^-_a-z0-9]/g, '');
}

/** Return whether the Observable of an array is an empty array. */
export function isEmpty$(xs$: Observable<any[]>): Observable<boolean> {
  return xs$.pipe(map(xs => xs.length === 0));
}
