import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AccountService } from '@markmachine/features/account/services/account.service';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SignInDialogComponent, SignInDialogData } from '../components/sign-in-dialog/sign-in-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class SignInGuard implements CanActivate {
  constructor(private account: AccountService, private dialog: MatDialog) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const { requireAdmin } = { requireAdmin: false, ...next.data };
    const isAuthorized$ = requireAdmin ? this.account.isAdmin$ : this.account.signedIn$;
    return isAuthorized$.pipe(switchMap(isAuthorized => {
      if (isAuthorized) {
        return of(isAuthorized);
      } else {
        return this.dialog.open<SignInDialogComponent, SignInDialogData>(SignInDialogComponent, { data: { requireAdmin } }).afterClosed();
      }
    }));
  }
}
