import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as UserNoteActions from '../actions/user-note.actions';
import { UserNote } from '../models/user-note.model';

export const storeKey = 'user-notes';

// **************************************************************************
// State
// **************************************************************************

export interface State extends EntityState<UserNote> {
  // additional entities state properties
}

export const adapter: EntityAdapter<UserNote> = createEntityAdapter<UserNote>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

// **************************************************************************
// Reducers
// **************************************************************************

export const reducer = createReducer(
  initialState,

  // **************************************************************************
  // @ngrx/entities
  // **************************************************************************
  on(UserNoteActions.addUserNote, (state, { userNote }) => adapter.addOne(userNote, state)),
  on(UserNoteActions.addUserNoteSuccess, (state, { userNote }) => adapter.updateOne(
    { id: userNote.id as string, changes: userNote },
    state)
  ),
  on(UserNoteActions.upsertUserNote, (state, { userNote }) => adapter.upsertOne(userNote, state)),
  on(UserNoteActions.addUserNotes, (state, { userNotes }) => adapter.addMany(userNotes, state)),
  on(UserNoteActions.upsertUserNotes, (state, { userNotes }) => adapter.upsertMany(userNotes, state)),
  on(UserNoteActions.updateUserNote, (state, { userNote }) => adapter.updateOne(userNote, state)),
  on(UserNoteActions.updateUserNotes, (state, { userNotes }) => adapter.updateMany(userNotes, state)),
  on(UserNoteActions.deleteUserNote, (state, { id }) => adapter.removeOne(id, state)),
  on(UserNoteActions.deleteUserNotes, (state, { ids }) => adapter.removeMany(ids, state)),
  on(UserNoteActions.loadUserNotes, (state, { userNotes }) => adapter.setAll(userNotes, state)),
  on(UserNoteActions.clearUserNotes, state => adapter.removeAll(state))
);


// **************************************************************************
// Selectors
// **************************************************************************

export const getUserNoteState = createFeatureSelector<State>(storeKey);

export const {
  selectIds: getUserNoteIds,
  selectEntities: getUserNoteEntities,
  selectAll: getAllUserNotes,
  selectTotal: getTotalUserNotes,
} = adapter.getSelectors(getUserNoteState);

export const getAllUserNotesByPath = createSelector(
  getAllUserNotes,
  (notes: UserNote[], { path, caseId }) => notes.filter(note => note.location === path && note.caseId === caseId)
);
