import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { UserNote } from '../models/user-note.model';

// **************************************************************************
// Local CRUD
// **************************************************************************

export const loadUserNotes = createAction('[UserNote] Load UserNotes', props<{ userNotes: UserNote[] }>());
export const addUserNote = createAction('[UserNote] Add UserNote', props<{ userNote: UserNote }>());
export const addUserNoteSuccess = createAction('[UserNote] Add UserNote Success', props<{ userNote: UserNote }>());
export const addUserNoteFailure = createAction('[UserNote] Add UserNote Failure', props<{ userNote: UserNote, error: any }>());
export const upsertUserNote = createAction('[UserNote] Upsert UserNote', props<{ userNote: UserNote }>());
export const addUserNotes = createAction('[UserNote] Add UserNotes', props<{ userNotes: UserNote[] }>());
export const upsertUserNotes = createAction('[UserNote] Upsert UserNotes', props<{ userNotes: UserNote[] }>());
export const updateUserNote = createAction('[UserNote] Update UserNote', props<{ userNote: Update<UserNote> }>());
export const updateUserNoteSuccess = createAction('[UserNote] Update UserNote Success', props<{ userNote: Update<UserNote> }>());
export const updateUserNoteFailure = createAction(
  '[UserNote] Update UserNote Failure',
  props<{ userNote: Update<UserNote>, error: any }>()
);
export const updateUserNotes = createAction('[UserNote] Update UserNotes', props<{ userNotes: Update<UserNote>[] }>());
export const deleteUserNote = createAction('[UserNote] Delete UserNote', props<{ id: string }>());
export const deleteUserNoteSuccess = createAction('[UserNote] Delete UserNote Success', props<{ userNote: UserNote }>());
export const deleteUserNoteFailure = createAction('[UserNote] Delete UserNote Failure', props<{ id: string, error: any }>());
export const deleteUserNotes = createAction('[UserNote] Delete UserNotes', props<{ ids: string[] }>());
export const clearUserNotes = createAction('[UserNote] Clear UserNotes');
