import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'mm-error-dialog-redirect',
  templateUrl: './error-dialog-redirect.component.html',
  styleUrls: ['./error-dialog-redirect.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorDialogRedirectComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: unknown,
    private router: Router,
    private dialog: MatDialog,
  ) {}

  redirect() {
    let navigateTo = null;
    
    if (this.router.url.startsWith('/fix')) {
      navigateTo = ['/docket'];
    } else if (this.router.url.startsWith('/search/result')) {
      navigateTo = ['/search'];
    } else if (this.router.url.startsWith('/search') || this.router.url.startsWith('/docket')) {
      navigateTo = ['/'];
    } 
    
    if (!!navigateTo) {
      this.router.navigate(navigateTo).then(() => {
        this.closeDialogs()
      });
    }
  }

  closeDialogs() {
    this.dialog.openDialogs.forEach(dialog => dialog.close());
  }
}
