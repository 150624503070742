import { GraphQLQuery, GraphQLResponse } from 'app/interfaces';
import { ContextMenuItem } from '../../models/context-menu-item.model';
import { contextMenuItemFragment } from './context-menu.fragment';

const QUERY = `

mutation CreateContextMenu($input: CreateContextMenuInput!) {
  createContextMenu(input: $input) {
    contextMenu {
      ...contextMenuFields
    }
  }
}
${contextMenuItemFragment}
`;

export class CreateContextMenuMutation implements GraphQLQuery {
  query = QUERY;
  constructor(public variables: { input: { contextMenu: ContextMenuItem } }) {}
}

export type CreateContextMenuResponse = GraphQLResponse<{
  createContextMenu: {
    contextMenu: ContextMenuItem;
  }
}>;
