import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'mm-expansion-panel-actions',
  templateUrl: './expansion-panel-actions.component.html',
  styleUrls: ['./expansion-panel-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpansionPanelActionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
