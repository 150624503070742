import { Version } from '@markmachine/features/version/models/version.model';
import { versionMetadataFragmentFields } from './version-metadata.fragment';
import { GraphQLQuery, GraphQLResponse } from '@markmachine/interfaces';

const query = `
mutation CreateVersion($caseVersion: CaseVersionInput!) {
  createCaseVersion(input: { caseVersion: $caseVersion }) {
    caseVersion {
      content
      ${versionMetadataFragmentFields}
    }
  }
}
`;

export class CreateVersionMutation implements GraphQLQuery {
  query = query;

  constructor(public variables: { caseVersion: Partial<Version> }) {}
}

export type CreateVersionResponse = GraphQLResponse<{
  createCaseVersion: {
    caseVersion: Version;
  }
}>;
