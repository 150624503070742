import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'input[formControlName]'
})
export class SaveOnEnterDirective {

  constructor(private control: NgControl, private el: ElementRef<HTMLTextAreaElement>) { }

  @HostListener('keydown.enter', ['$event'])
  onKeyDown() {
    this.control.control?.setValue(this.el.nativeElement.value, { emitEvent: true });
  }
}
