/**
 * Case Status Module
 *
 * Provides access to current USPTO TSDR Status via Mark Machine API.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaseStatusGuard } from './guards/case-status.guard';
import { StoreModule } from '@ngrx/store';
import * as fromStatus from '@markmachine/features/case-status/reducers/status.reducer';
import { EffectsModule } from '@ngrx/effects';
import { StatusEffects } from './effects/status.effects';

@NgModule({
  declarations: [],
  providers: [CaseStatusGuard],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromStatus.statusKey, fromStatus.reducer),
    EffectsModule.forFeature([StatusEffects])
  ]
})
export class CaseStatusModule { }
