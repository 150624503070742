import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { USA_VALUE } from '@markmachine/features/case/services/input-option.service';
import { Correspondent, Owner } from '@markmachine/features/version/models/version-content.model';
import { isMatch, merge } from 'lodash-es';
import { CaseFormValidationService } from './case-form-validation.service';

const TEMPLATE = new Correspondent();

@Injectable()
export class CaseFormCorrespondentService {
  constructor(private validationService: CaseFormValidationService) {}

  /** Update the FormGroup. */
  update(group: FormGroup, values: Correspondent): void {
    const newValues = this.sanitizeValues(values);
    this.initializeGroup(group, newValues);
    this.validationService.setFormControlsValidators(group);
    group.patchValue(newValues, { emitEvent: false });
    this.mutate(group, newValues);
  }

  /** Perform secondary mutations on Correspondent */
  mutate(group: FormGroup, patch: Readonly<Correspondent>): void {
    if (!patch['withdraw-as-attorney']) {
      patch = { ...patch, 'withdrawal-reason-text': '' };
      patch = { ...patch, 'withdrawal-statements': '' };
    }

    // Check owners are all domestic; if so, Domestic Representative is impossible
    const owners: Owner[] = group.root.get('owners')?.value;
    const hasDomesticOwner = owners.some(o => o['country-name'] === USA_VALUE);
    if (hasDomesticOwner) {
      patch = { ...patch, 'has-domestic-representative': false };
    }

    // Check if TEAS Plus and all-foreign owners; if so, Domestic Representative is required
    const isTeasPlus = group.root.value?.['case-file-header']?.['application-type'] === 'plus';
    if (!hasDomesticOwner && isTeasPlus) {
      patch = { ...patch, 'has-attorney': true };
    }

    const emitEvent = !isMatch(patch, group.value); // Inspect the patch for equivalent values in group.value
    if (emitEvent) {
      group.patchValue(patch, { emitEvent });
    }
  }

  /**
   * Populate Form for Correspondent with controls.
   *
   * @param group {FormGroup}

   * TODO: It would be safer to generalize this method to work directly off of
   * inspection of the model's structure.
   */
  initializeGroup(group: FormGroup, values: Correspondent = new Correspondent()): void {
    // Initialize the scalar value that live directly within statements
    const valueKeys = Object.keys(TEMPLATE);
    for (const key of valueKeys) {
      if (group.get(key)) {
        continue;
      }
      group.setControl(key, new FormControl(values[key]));
    }
  }

  /** Make sure fields have sane values. */
  private sanitizeValues(values: Correspondent): Correspondent {
    const newValues = merge(new Correspondent(), values);
    return newValues;
  }
}
