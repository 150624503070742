import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Trademark } from '@markmachine/features/clearance-search/models/knockout-response.model';

@Component({
  selector: 'mm-clearance-search-results-details',
  templateUrl: './clearance-search-results-details.component.html',
  styleUrls: ['./clearance-search-results-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClearanceSearchResultsDetailsComponent implements OnInit {
  @Input()
  set row(value: Trademark) {
    this.statusCode = parseInt(value.statusCode, 10);
    this.regDate = this.tmtkoDateToIsoDate(value.registrationDate as string);
    this.pubDate = this.tmtkoDateToIsoDate(value.publicationDate as string);
    this.filedDate = this.tmtkoDateToIsoDate(value.filingDate as string);
    this.filingBasis = value.currentFilingBases.map( fb => `${fb}`).join(', ');
  }

  statusCode?: number;
  regDate?: string;
  pubDate?: string;
  filedDate?: string;
  filingBasis?: string;

  tmtkoDateToIsoDate(d: string): string | undefined {
    if (d && d.length > 0) {
      return `${d.substring(0, 4)}-${d.substring(4, 6)}-${d.substring(6, 8)}`;
    }
  }

  constructor() { }

  ngOnInit() {
  }
}
