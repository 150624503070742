/**
 * Determine if the values in 'a' are different in 'b'.
 *
 * Returns true if the keys in 'a' yield identical values
 * in both 'a' and 'b':
 *
 * ```
 * differs({ a: 1, b: 2 }, { a: 1, b: 2, c: 3 }) === true
 * differs({ a: 1, b: 2, c: 3 }, { a: 1, b: 2 }) === false
 * ```
 */
export function differs<T>(a: Partial<T>, b: Partial<T>) {
  return Object.entries(a).some(([k, v]) => b[k] !== v);
}
