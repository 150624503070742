import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { SubmissionService } from '@markmachine/views/submission-page/services/submission.service';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
  selector: 'mm-request-extension-form',
  templateUrl: './request-extension-form.component.html',
  styleUrls: ['./request-extension-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestExtensionFormComponent {
  @Input() extensionCount: number;
  @Input() root: FormGroup;
  constructor(private submission: SubmissionService) {}

  get classes() {
    return this.root.get('classes') as FormArray;
  }

  get classGroups(): FormGroup[] {
    return this.classes.controls.filter(g => !!g.get('$eligible-for-extension')?.value) as FormGroup[];
  }

  get statements() {
    return this.root.get('statements') as FormGroup;
  }

  get isOngoingEffortsRequired(): boolean {
    return this.extensionCount >= 1;
  }

  delete(index: number): void {
    this.submission.deleteClass(this.classes.at(index) as FormGroup);
  }
}
