<mat-form-field>
  <mat-label *ngIf="label">{{label}}</mat-label>
  <mat-select [formControl]="ngControl.control"
    (selectionChange)="selectionChange($event)"
    [required]="required">
    <mat-select-trigger *ngIf="selected && selected !== service.UNKNOWN.code">{{selected}} — {{selectedTitle}}</mat-select-trigger>
    <mat-select-trigger *ngIf="selected && selected === service.UNKNOWN.code">{{selectedTitle}}</mat-select-trigger>
    <mat-option *ngIf="showUnknown$ | async" value="UNK">Unknown</mat-option>
    <mat-optgroup label="Goods">
      <mat-option *ngFor="let cls of service.goodsOptions"
        [value]="cls.code">{{cls.code}} — {{cls.title}}</mat-option>
    </mat-optgroup>
    <mat-optgroup label="Services">
      <mat-option *ngFor="let cls of service.servicesOptions"
        [value]="cls.code">{{cls.code}} — {{cls.title}}</mat-option>
    </mat-optgroup>
  </mat-select>

  <!-- Bug: Content projection is projecting into the mat-select -->
  <mat-hint *ngIf="mmHint">{{ mmHint }}</mat-hint>
  <!-- MAJOR BUG: mat-error is not working from this component -->
  <mat-error *ngIf="mmError">{{ mmError }}</mat-error>
</mat-form-field>
