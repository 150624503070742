import { Pipe, PipeTransform } from '@angular/core';
import { isNumber, isObject, isNil } from 'lodash-es';
import { InputOptionService } from '@markmachine/features/case/services/input-option.service';
import { deepClone } from '@markmachine/core/functions/utilities';

@Pipe({
  name: 'mapDisplayValues'
})
export class MapDisplayValuesPipe implements PipeTransform {
  readonly mapping = {
    'legal-entity-type-code': 'entityTypeDesc',
    'tsdr-status-code': 'statusShortDesc'
  };

  constructor(private inputOption: InputOptionService) {}

  /**
   * Map case field values to human-readable values.  The mapping key can be specified,
   * otherwise all keys will be checked.
   * @param mapKey string
   */
  transform(value: any, mapKey?: string): any {
    if (mapKey && !isObject(value)) {
      const translate = this.mapping[mapKey];
      return this[translate](value, value);
    }
    if (isNil(value)) {
      return value;
    }
    value = deepClone(value);
    for (const key in this.mapping) {
      if (key in value) {
        const translate = this.mapping[key];
        value[key] = this[translate](value[key], value);
      }
    }
    return value;
  }

  private entityTypeDesc(code: string, value: any): string {
    // We don't want InputOptionService.entityTypes to
    // include Other, so we manually handle that case here.
    // We also want to return the actual "other" next, not the
    // literal text "Other".
    if (code === '99') {
      return value['other-entity-type-text'];
    }
    return this.inputOption.entityTypeDisplay(code);
  }

  private statusShortDesc(code: number): string {
    if (!isNumber(code)) {
      return '';
    }
    for (const status of this.inputOption.status) {
      if (status.value === code.toString()) {
        return status.display;
      }
    }
    return code.toString();
  }
}
