import * as StatusActions from '@markmachine/features/case-status/actions/status.actions';
import { CaseStatus as Status } from '@markmachine/features/case-status/models/status.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { isEmpty } from 'lodash-es';

// **************************************************************************
// State
// **************************************************************************
export interface State extends EntityState<Status> {
  // additional entities state properties
  polling: number[];
  pollingExpectedTimestamp: string | undefined;
}

export const adapter: EntityAdapter<Status> = createEntityAdapter<Status>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  polling: [],
  pollingExpectedTimestamp: undefined
});

// **************************************************************************
// Reducers
// **************************************************************************
export const reducer = createReducer(
  initialState,
  on(StatusActions.addStatus, (state, { status }) => adapter.addOne(status, state)),
  on(StatusActions.upsertStatus, (state, { status }) => adapter.upsertOne(status, state)),
  on(StatusActions.addStatuses, (state, { statuses }) => adapter.addMany(statuses, state)),
  on(StatusActions.upsertStatuses, (state, { statuses }) => adapter.upsertMany(statuses, state)),
  on(StatusActions.updateStatus, (state, { status }) => adapter.updateOne(status, state)),
  on(StatusActions.updateStatuses, (state, { statuses }) => adapter.updateMany(statuses, state)),
  on(StatusActions.deleteStatus, (state, { id }) => adapter.removeOne(id, state)),
  on(StatusActions.deleteStatuses, (state, { ids }) => adapter.removeMany(ids, state)),
  on(StatusActions.loadStatuses, (state, { statuses }) => adapter.setAll(statuses, state)),
  on(StatusActions.clearStatuses, state => adapter.removeAll(state)),
  on(StatusActions.pollingStatusesInit, (state, { ids, until }) => ({
    ...state,
    polling: ids,
    pollingExpectedTimestamp: until
  }))
);


// **************************************************************************
// Selectors
// **************************************************************************
export const statusKey = 'statuses';
export const getStatusState = createFeatureSelector<State>(statusKey);

export const { selectEntities: getStatusEntities } = adapter.getSelectors(getStatusState);

export const getStatusPollingIds = createSelector(
  getStatusState,
  state => state.polling
);

export const getStatusPollingExpectedTimestamp = createSelector(
  getStatusState,
  state => state.pollingExpectedTimestamp
);

/** Select all statuses that need to be refreshed or polled. */
export const getStatusesOutdated = createSelector(
  getStatusEntities,
  getStatusPollingIds,
  getStatusPollingExpectedTimestamp,
  (statuses, watched, expected) => watched.filter(id => {
    const { updatedAt, error, content } = statuses[id] || { updatedAt: null, error: null, content: null };
    const outdated = !updatedAt || new Date(updatedAt) < new Date(expected as string);
    const permanentError = error && error.startsWith('Permanent error');
    return outdated || permanentError || isEmpty(content);
  })
);
