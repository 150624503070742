import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'mm-tip-button',
  templateUrl: './tip-button.component.html',
  styleUrls: ['./tip-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TipButtonComponent {
  @Input() anchor: string;
  @Input() color = 'primary';

  stop($event: MouseEvent) {
    $event.stopPropagation();
  }
}
