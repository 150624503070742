import { GraphQLQuery, GraphQLResponse } from '@markmachine/interfaces';
import { UserCase } from '@markmachine/features/user-case/models/user-case.model';
import { userCaseLargeFragment } from './user-case.fragment';

const graphqlQuery = `
query UserCases {
  allUserCases(orderBy: CREATED_AT_ASC) {
    nodes {
      ...userCaseLargeFragment
    }
  }
}
${userCaseLargeFragment}
`;

export class AllUserCasesQuery implements GraphQLQuery {
  query = graphqlQuery;
  constructor(public variables: {}) {}
}

export type AllUserCasesResponse = GraphQLResponse<{
  allUserCases: {
    nodes: UserCase[];
  };
}>;
