import { Injectable } from '@angular/core';

const TIMESTAMP = new Date().getTime();

function encodeQuery(params: object): string {
  return Object.keys(params)
    .map(key => [encodeURIComponent(key), encodeURIComponent(params[key])])
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
}

export function markImageUrl(serialNumber: number): string {
  return `https://tsdr.uspto.gov/img/${serialNumber}/large?${TIMESTAMP}`;
}

export function statusUrl(serialNumber: number | string): string {
  const options = {
    caseNumber: '' + serialNumber,
    caseSearchType: 'US_APPLICATION',
    caseType: 'DEFAULT',
    searchType: 'statusSearch'
  };
  return `https://tsdr.uspto.gov/#${encodeQuery(options)}`;
}

export function statusDocumentUrl(serialNumber: number): string {
  const options = {
    case: true,
    docs: '',
    assignments: '',
    prosecutionHistory: ''
  };
  // tslint:disable-next-line:max-line-length
  return `https://tsdrsec.uspto.gov/ts/cd/casedocs/sn${encodeURIComponent(`${serialNumber}`)}/mega-bundle-download?${encodeQuery(options)}`;
}

export function currentUsptoDocumentUrl(serialNumber: number): string {
  const options = {
    case: false,
    docs: '1',
    assignments: '',
    prosecutionHistory: ''
  };
  // tslint:disable-next-line:max-line-length
  return `https://tsdrsec.uspto.gov/ts/cd/casedocs/sn${encodeURIComponent(`${serialNumber}`)}/mega-bundle-download?${encodeQuery(options)}`;
}

export function documentListUrl(serialNumber: number): string {
  const options = {
    caseNumber: serialNumber,
    caseSearchType: 'US_APPLICATION',
    caseType: 'DEFAULT',
    searchType: 'documentSearch'
  };
  return `https://tsdr.uspto.gov/#${encodeQuery(options)}`;
}

@Injectable({
  providedIn: 'root'
})
export class TsdrService {
  markImageUrl = markImageUrl;
  statusUrl = statusUrl;
  statusDocumentUrl = statusDocumentUrl;
  currentUsptoDocumentUrl = currentUsptoDocumentUrl;
  documentListUrl = documentListUrl;
}
