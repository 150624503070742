<div [formGroup]="group"
  fxLayout="column"
  fxLayoutGap="16px">
  <div fxLayout="row">
    <mat-form-field appearance="outline">
      <mat-label>Foreign Registration Country Name</mat-label>
      <mat-select formControlName="foreign-registration-country-name"
        [required]="required$ | async">
        <mat-option>None</mat-option>
        <mat-option *ngFor="let opt of options.mailingCountries"
          [value]="opt.value">{{opt.display}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div fxLayout="row">
    <mat-form-field appearance="outline">
      <mat-label>Foreign Registration Date</mat-label>
      <input formControlName="foreign-registration-date"
        matInput
        mmUsptoIndefiniteDate
        [required]="required$ | async">
      <mat-hint>Enter date in MM/DD/YYYY format</mat-hint>
    </mat-form-field>
  </div>
  <div fxLayout="row">
    <mat-form-field appearance="outline">
      <mat-label>Foreign Registration Number</mat-label>
      <input formControlName="foreign-registration-number"
        matInput
        type="text">
    </mat-form-field>
  </div>
  <div fxLayout="row">
    <mat-form-field appearance="outline">
      <mat-label>Foreign Registration Renewal Date</mat-label>
      <input formControlName="foreign-renewal-date"
        matInput
        mmUsptoIndefiniteDate
        type="text">
      <mat-hint>Enter date in MM/DD/YYYY format</mat-hint>
    </mat-form-field>
  </div>
  <div fxLayout="row" fxLayoutAlign="flex-start baseline" fxLayoutGap="24px">
    <mat-form-field appearance="outline">
      <mat-label>Foreign Registration Expiration Date</mat-label>
      <input formControlName="foreign-expiration-date"
        matInput
        mmUsptoIndefiniteDate
        [required]="required$ | async">
      <mm-tip-button matSuffix anchor="1fed39ef-b512-4115-985b-b4eb24def1ae"></mm-tip-button>
      <mat-hint>Enter date in MM/DD/YYYY format</mat-hint>
    </mat-form-field>
  </div>
  <div *ngIf="hasSerialNumber$ | async" fxLayout="row">
    <mat-checkbox formControlName="foreign-registration-stdchar" name="foreign-registration-stdchar" value="Yes">
      The mark consists of standard characters or the country of origin's standard character equivalent.
    </mat-checkbox>
  </div>
  <div fxLayout="column" class="foreign-reg-attachment">
    <h4>Foreign Registration Certificate/Translation/Proof of Renewal</h4>
    <mm-case-file-control formControlName="foreign-registration-uploads"></mm-case-file-control>
  </div>
</div>
