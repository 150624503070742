import { Directive, ElementRef, EventEmitter, HostBinding, Output, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[mmResizeColumn]'
})
export class ResizeColumnDirective {
  /**
   * Handle on the explicitly set column width of the host table column header, if any
   */
  @HostBinding('style.width.px') width: number;

  /**
   * Set the position of the header to relative so that resize-handle positions correctly.
   *
   * The container of the resize handle needs a position besides `static` to create a "stacking context" for
   * absolute positioning. This class, defined in `supertable-styles.scss`, sets `position: relative`. We
   * rely on the class instead of setting position directly so that cells still can be given
   * `position: sticky` by other code without conflict.
   */
  @HostBinding('class.resize-handle-container') handleContainerClass = true;

  /**
   * Enable setting an initial width
   */
  @Input() set initialWidth(value: number) {
    this.width = value;
  }
  /**
   * Provide an output channel so that interested parties can know when the column width has been changed.
   */
  @Output() widthChanged = new EventEmitter<{ width: number }>();

  constructor(private el: ElementRef) {}

  /** Change the width of the column and let the world know. */
  resize(dx: number) {
    // getBoundingClientRect() is expensive, but we only do it exactly once on human timescales (i.e., on drag-end).
    const { width } = this.el.nativeElement.getBoundingClientRect();
    this.width = width + dx;
    this.widthChanged.emit({ width: width + dx });
  }
}
