import { caseStatusFragment } from '@markmachine/features/case-status/services/operations/case-status.fragment';
import { userNoteFragment } from '@markmachine/features/user-note/effects/user-note.fragment';
import { versionMetadataFragment } from '@markmachine/features/version/services/operations/version-metadata.fragment';

export const userCaseFragmentFields = `
nodeId
idx
userId
caseId
name
description
groupName
delegate
comments
alarm
alarmMessage
createdAt
updatedAt
other
`;

export const intervalFragmentFields = `
seconds
minutes
hours
days
months
years
`;

export const documentDescriptionFragmentFields = `
nodeId
idx
code
name
actor
deadline: deadline {
  ${intervalFragmentFields}
}
isMajor
`;

export const eventDescriptionFragmentFields = `
nodeId
idx
code
type
name
actor
deadline: deadline {
  ${intervalFragmentFields}
}
isMajor
`;


export const documentNextStatFragmentFields = `
  last
  curr
  next
  group
  countNext
  countCurr
  fraction
  min: min {
    ${intervalFragmentFields}
  }
  max: max {
    ${intervalFragmentFields}
  }
  avg: avg {
    ${intervalFragmentFields}
  }
  stddev: stddev {
    ${intervalFragmentFields}
  }
`;

export const documentNextStatWithNextFragmentFields = `
  last
  curr
  next
  group
  countNext
  countCurr
  fraction
  min: min {
    ${intervalFragmentFields}
  }
  max: max {
    ${intervalFragmentFields}
  }
  avg: avg {
    ${intervalFragmentFields}
  }
  stddev: stddev {
    ${intervalFragmentFields}
  }
  documentDescriptionByNext {
    ${documentDescriptionFragmentFields}
  }
`;

export const eventNextStatFragmentFields = `
  last
  curr
  next
  group
  countNext
  countCurr
  fraction
  min: min {
    ${intervalFragmentFields}
  }
  max: max {
    ${intervalFragmentFields}
  }
  avg: avg {
    ${intervalFragmentFields}
  }
  stddev: stddev {
    ${intervalFragmentFields}
  }
`;

export const eventNextStatWithNextFragmentFields = `
  last
  curr
  next
  group
  countNext
  countCurr
  fraction
  min: min {
    ${intervalFragmentFields}
  }
  max: max {
    ${intervalFragmentFields}
  }
  avg: avg {
    ${intervalFragmentFields}
  }
  stddev: stddev {
    ${intervalFragmentFields}
  }
  eventDescriptionByNext {
    ${eventDescriptionFragmentFields}
  }
`;

export const userCaseFragment = `
fragment userCaseFragment on UserCase {
  ${userCaseFragmentFields}
}
`;

export const userCaseDocumentsFragmentFields = `
  id
  nodeId
  serialNumber
  mailRoomDate
  scanDateTime
  pageMediaTypeList
  createdAt
  updatedAt
  documentDescription: documentDescriptionByDocumentTypeCode {
    ${documentDescriptionFragmentFields}
  }
`;

export const caseFileEventStatementsFragmentfields = `
  eventCode
  eventDescription
  eventDate
  eventType
  eventNumber
  eventDetails: eventDescriptionByEventCodeAndEventType {
    isMajor
    smeLongName
    customerLongName
    actor
    verb
  }
  eventCodeDocumentCodeByEventCodeAndEventDescription {
    documentDescriptionByDocumentCode {
      actor
      name
      code
      isMajor
    }
  }
`;

export const caseFileFragmentFields = `
  statusCode
  popularReaction {
    next
    eventDescriptionByNext {
      name
      actor
    }
    fraction
    deadlineRules {
      nodes {
        deadlineRuleByRuleId {
          id
          fromCodes
          repeatLimit
          unlessCodes
          beforeCodes
          deadlineText
          stage
          deadlineInterval {
            seconds
            minutes
            hours
            days
            months
            years
          }
          deadlineRuleElseByRuleId {
            ruleId
          }
          deadlineRuleReactionsByRuleId {
            nodes {
              reactionCode
            }
          }
        }
      }
    }
  }
  events: casefileEventStatementsBySerialNumber {
    nodes {
      ${caseFileEventStatementsFragmentfields}
    }
  }
`;


export const userCaseLargeFragment = `
fragment userCaseLargeFragment on UserCase {
  ${userCaseFragmentFields}
  caseByCaseId {
    createdAt
    createdBy
    id
    isAdded
    serialNumber
    updatedAt
    latestUsptoOrigin
    documents: documentsBySerialNumber (orderBy: MAIL_ROOM_DATE_DESC) {
      nodes {
        ${userCaseDocumentsFragmentFields}
      }
    }
    userNotesByCaseId { nodes { ...userNoteFragment } }
    userCasesByCaseId { nodes { ...userCaseFragment } }
    status: caseStatus {
      ...caseStatusFragment
      content
    }
    files: caseFilesByIdCase {
      totalCount
      nodes { id idCase createdAt filepath }
    }
    versions: caseVersionsByIdCase(orderBy:CREATED_AT_DESC) {
      totalCount
      nodes { ...versionMetadataFragment content }
    }
    casefile: casefileBySerialNumber {
      ${caseFileFragmentFields}
    }
  }
}
${versionMetadataFragment}
${caseStatusFragment}
${userCaseFragment}
${userNoteFragment}
`
