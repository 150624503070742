<button mat-icon-button
  (click)="backward()"
  [disabled]="isBackwardDisabled">
  <mat-icon>chevron_left</mat-icon>
</button>

<button mat-icon-button
  (click)="forward()"
  [disabled]="isForwardDisabled">
  <mat-icon>chevron_right</mat-icon>
</button>
