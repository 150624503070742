import { Model } from '@markmachine/core/model.class';
import { GuideState } from '@markmachine/features/guide/models/guide.model';

/**
 * Case Model
 */

export interface Changeset { [propName: string]: any; }
export type FormChoices = Changeset[];

export type ClaimBasis = 'use' | 'registration' | 'evidence';
export type ClaimType = 'TRADEMARK' | 'COLLECTIVE';
export type ClaimCollectiveType = 'TRADEMARK' | 'MEMBERSHIP' | 'CERTIFICATION';
export type ApplicationType = 'plus' | 'standard' | null;

export class AcquiredDistinctiveness extends Model<AcquiredDistinctiveness> {
  readonly 'whole-or-in-part'?: '2F_WHOLE' | '2F_PART' = null;

  readonly 'whole-based-on-use': boolean = false;
  readonly 'whole-based-on-use-trademark-or-collective'?: ClaimType = null;
  readonly 'whole-based-on-use-collective-type'?: ClaimCollectiveType = null;

  readonly 'whole-based-on-registration': boolean = false;
  readonly 'whole-based-on-registration-trademark-or-collective'?: ClaimType = null;
  readonly 'whole-based-on-registration-trademark-registrations': string = '';
  readonly 'whole-based-on-registration-collective-type'?: ClaimCollectiveType = null;
  readonly 'whole-based-on-registration-collective-membership-registrations': string = '';
  readonly 'whole-based-on-registration-collective-trademark-registrations': string = '';
  readonly 'whole-based-on-registration-certification-registrations': string = '';

  readonly 'whole-based-on-evidence': boolean = false;
  readonly 'whole-based-on-evidence-trademark-or-collective'?: ClaimType = null;
  readonly 'whole-based-on-evidence-collective-type'?: ClaimCollectiveType = null;
  readonly 'whole-based-on-evidence-files' = new Array<string>();

  readonly 'based-on-use': boolean = false;
  readonly 'based-on-use-trademark-or-collective'?: ClaimType = null;
  readonly 'based-on-use-trademark-distinctive': string = '';
  readonly 'based-on-use-collective-type'?: ClaimCollectiveType = null;
  readonly 'based-on-use-collective-membership-distinctive': string = '';
  readonly 'based-on-use-collective-trademark-distinctive': string = '';
  readonly 'based-on-use-certification-distinctive': string = '';

  readonly 'based-on-registration': boolean = false;
  readonly 'based-on-registration-trademark-or-collective'?: ClaimType = null;
  readonly 'based-on-registration-trademark-distinctive': string = '';
  readonly 'based-on-registration-trademark-registrations': string = '';
  readonly 'based-on-registration-collective-type'?: ClaimCollectiveType = null;
  readonly 'based-on-registration-collective-membership-registrations': string = '';
  readonly 'based-on-registration-collective-trademark-registrations': string = '';
  readonly 'based-on-registration-certification-registrations': string = '';
  readonly 'based-on-registration-collective-trademark-distinctive': string = '';
  readonly 'based-on-registration-collective-membership-distinctive': string = '';
  readonly 'based-on-registration-certification-distinctive': string = '';

  readonly 'based-on-evidence': boolean = false;
  readonly 'based-on-evidence-trademark-or-collective'?: ClaimType = null;
  readonly 'based-on-evidence-trademark-distinctive': string = '';
  readonly 'based-on-evidence-collective-type'?: ClaimCollectiveType = null;
  readonly 'based-on-evidence-collective-membership-distinctive': string = '';
  readonly 'based-on-evidence-collective-trademark-distinctive': string = '';
  readonly 'based-on-evidence-certification-distinctive': string = '';
  readonly 'based-on-evidence-files' = new Array<string>();
}

export class CaseFileHeader {
  readonly 'serial-number'?: number = null;
  readonly 'filing-date'?: Date = null;
  readonly 'law-office-location-code': string = '';
  readonly 'registration-number': string = '';
  readonly 'application-type': ApplicationType = null;
  readonly 'currentlyAsTeasPlusApp': boolean = false;
  readonly 'filedAsTeasPlusApp': boolean = false;
  readonly 'status': number = null;
}

export class Specimen {
  readonly 'color': string = '';
  readonly 'description-text': string = '';
  readonly 'description-statement': string = '';
  readonly 'statement-type': string = '';
  readonly 'declaration': string = '';
  readonly 'excusable-nonuse': string = '';
  readonly 'file-names' = [] as Filename[];
  readonly files = new Array<string>();
}

export class Standard {
  readonly 'description-text': string = '';
  readonly 'file-names' = [] as Filename[];
  readonly files = new Array<string>();
}

export enum ClassBasis {
  PriorUse = '1a',
  IntentToUse = '1b',
  ForeignApp = '44d',
  ForeignReg = '44e'
}

export type ClassDisposition = 'affirm-use' | 'delete-class' | 'remove-goods-services';

export class Class {
  /**
   * Indicates if a Class is newly-created
   * NOTE: If you have 2 classes and delete 1 then add 1, you might gain the ability to amend the 2nd class in impermissible ways.
   * Be cautious about basing behavior on the `_isNew` property.
   */
  readonly _isNew?: boolean = null;

  readonly '$extension-requested': boolean = false;
  readonly '$eligible-for-extension': boolean = false;
  readonly '$eligible-for-allegation-of-use': boolean = false;
  readonly '$pending-allegation-of-use': boolean = false;

  readonly 'renewal-89': ClassDisposition = 'affirm-use';
  readonly '$renewal-89-requested': boolean = false;
  readonly '$renewal-89-grace-period': boolean = false;

  readonly 'renewal-815': ClassDisposition = 'affirm-use';
  readonly '$renewal-815-requested': boolean = false;
  readonly '$renewal-815-grace-period': boolean = false;

  readonly '$eligible-to-amend-1a': boolean = false;
  readonly '$eligible-to-amend-1b': boolean = false;

  readonly 'sequence-number'?: number = null;
  readonly 'class-code': string = '';
  readonly 'filing-basis-1b-notice': string = '';
  readonly 'filing-basis-current-1a-in': boolean = false;
  readonly 'filing-basis-current-1b-in': boolean = false;
  readonly 'filing-basis-current-44d-in': boolean = false;
  readonly 'filing-basis-current-44e-in': boolean = false;
  readonly 'filing-basis-current-no-basis-in': boolean = false;
  readonly 'first-use-anywhere-date'?: Date = null;
  readonly 'first-use-in-commerce-date'?: Date = null;
  readonly 'foreign-application-country-name': string = '';
  readonly 'foreign-application-number': string = '';
  readonly 'foreign-application-priority-only': string = '';
  readonly 'foreign-expiration-date'?: Date = null;
  readonly 'foreign-filing-date'?: Date = null;
  readonly 'foreign-registration-country-name': string = '';
  readonly 'foreign-registration-date'?: Date = null;
  readonly 'foreign-registration-stdchar': boolean = false;
  readonly 'foreign-registration-uploads' = new Array<string>();
  readonly 'foreign-registration-files': Filename[] = [];
  readonly 'foreign-registration-number': string = '';
  readonly 'foreign-renewal-date'?: Date = null;
  readonly 'listing-markup': string = '';
  readonly listing: string = '';
  readonly specimen: Specimen = new Specimen();
  readonly standard: Standard = new Standard();
  readonly 'allegation-of-use': ClassDisposition = 'affirm-use';
  readonly 'allegation-of-use-ext-req': ClassDisposition = 'affirm-use';
  readonly '$listing-deleted'?: string = null;
}

/**
 * Allegations of Use are calculated from "currently 1b-basis" Classes
 */
export class AllegationOfUse {
  readonly 'allegation-of-use': ClassDisposition = 'affirm-use';
  readonly '$listing-deleted'?: string = null;
  readonly '$listing-remaining'?: string = null;
  readonly 'class-code': string = '';
  readonly 'first-use-anywhere-date'?: Date = null;
  readonly 'first-use-in-commerce-date'?: Date = null;
  readonly '$pending-allegation-of-use': boolean = false;
  readonly listing: string = '';
  readonly specimen: Specimen = new Specimen();
}

/**
 * Extension Requests are calculated from "currently 1b-basis" Classes
 */
export class ExtensionRequest {
  readonly 'allegation-of-use-ext-req': ClassDisposition = 'affirm-use';
  readonly '$extension-requested': boolean = false;
  readonly '$listing-deleted'?: string = null;
  readonly '$listing-remaining'?: string = null;
  readonly 'class-code': string = '';
  readonly listing: string = '';
}


/**
 * Renewal of class registration under Sections 8 & 9 (10, 20, 30, etc.)
 *
 * Like Allegation of Use and Extension of use, one of these is created for each elligible class
 * when applying for a Renewal to your case for the 10-, 20-, 30-year, etc. registration renewal.
 */
export class Renewal89 {
  readonly 'renewal-89': ClassDisposition = 'affirm-use';
  readonly '$listing-deleted'?: string = null;
  readonly '$listing-remaining'?: string = null;
  readonly 'class-code': string = '';
  readonly '$renewal-89-requested': boolean = false;
  readonly '$renewal-89-grace-period': boolean = false;
  readonly listing: string = '';
  readonly specimen: Specimen = new Specimen();
}


/**
 * "Renewal" of class registration under Sections 8 & 15 (6-year)
 *
 * Like Allegation of Use and Extension of use, one of these is created for each elligible class
 * when applying for a Renewal to your case for the 6-year registration renewal.
 */
export class Renewal815 {
  readonly 'renewal-815': ClassDisposition = 'affirm-use';
  readonly '$listing-deleted'?: string = null;
  readonly '$listing-remaining'?: string = null;
  readonly 'class-code': string = '';
  readonly '$renewal-815-requested': boolean = false;
  readonly '$renewal-815-grace-period': boolean = false;
  readonly listing: string = '';
  readonly specimen: Specimen = new Specimen();
}

export class MailingAddress {
  readonly city: string = '';
  readonly 'country-name': string = '';
  readonly 'email-address': string = '';
  readonly fax: string = '';
  readonly 'internal-address': string = '';
  readonly name: string = '';
  readonly 'other-appointed-attorney': string = '';
  readonly phone: string = '';
  readonly 'phone-country': string = '';
  readonly 'phone-extension': string = '';
  readonly 'postal-code': string = '';
  readonly state: string = '';
  readonly street: string = '';
}

export class Correspondent extends MailingAddress {
  readonly 'attorney-docket-number': string = '';
  readonly 'email-addresses-secondary': string = '';
  readonly 'firm-name': string = '';
  readonly 'attorney-bar-admission-year': string = '';
  readonly 'attorney-bar-state': string = '';
  readonly 'attorney-bar-number': string = '';
  readonly 'attorney-bar-active': boolean = false;
  readonly 'other-appointed-attorney': string = '';
  readonly 'type-code': string = '';
  readonly 'withdraw-as-attorney': string = '';
  readonly 'withdrawal-reason-text': string = '';
  readonly 'withdrawal-statements': string = '';
  readonly 'has-attorney': boolean = false;
  readonly 'has-domestic-representative': boolean = false;
  readonly 'recognized-canadian-attorney-agent': string = '';
  readonly 'replacement-reason': string = '';
  readonly 'replacement-attachments' = [] as string[];
  // This value is used exclusively for the "Change of Address or Representation (CAR) Form".
  readonly 'preparer-role': 'attorney' | 'owner' | 'domestic-representative' = 'attorney';
}

export class CorrespondentAttorney extends Correspondent {
  readonly 'has-attorney': boolean = true;
}

export class CorrespondentDomesticRepresentative extends Correspondent {
  // TODO: revisit extending from MailingAddress instead
  readonly 'has-domestic-representative': boolean = true;
}

export class Argument {
  readonly 'argument-plaintext': string = '';
  readonly 'evidence-plaintext': string = '';
  readonly 'file-names' = [] as Filename[];
  readonly files = new Array<string>();
  readonly 'sequence-number': number = 0;
}

/** USPTO Fee History */
export class UsptoFeeType {
  readonly 'fee-code'?: number = null;
  readonly 'number-of-classes'?: number = null;
  readonly 'number-of-classes-paid'?: number = null;
  readonly 'subtotal-amount'?: number = null;
}

/**
 * One row in the Pending USPTO Fees panel.
 * Descriptive information should be pulled from the USPTO Fees
 * module based on the `code`.
 */
export class PendingPaymentItem {
  'code': number = null;
  'count': number = null;
  'autoCount': number = null;
  /** Internal book-keeping to determine if count should be set to autoCount. */
  '_isManual' = false;
}

export class PendingPayment {
  /**
   * Array of pending payments.
   *
   * Each row of the Pending USPTO Fees table is presented in this array in the
   * expected initial order.
   *
   * The array is automatically constructed by a function that takes the rest of
   * the VersionContent.
   */
  payments = [] as PendingPaymentItem[];

  /** "I authorize Mark machien to pay the total fee(s) shown above on my behalf" */
  authorization = false;

  /**
   * Quantities that need to be bound on individual TEAS forms.
   *
   * Note that items like "chargebacks" can appear on multiple forms,
   * so that will need to be dealt with.
   */
  'chargebacks' = 0;
  'additional-classes' = 0;
  'teas-plus-failures' = 0;
  'requests-to-divide' = 0;
}

export class Filename {
  readonly '@acceptable': string = '';
  readonly '@height': string = '';
  readonly '@image-type': string = '';
  readonly '@ref': string = '';
  readonly '@width': string = '';
  readonly '#text': string = '';
}

export class Filing {
  readonly 'filing-identifier': string = '';
  readonly 'xml-create-date': string = ''; // naive datetime
  readonly 'submit-date': string = ''; // non-naive datetime
  readonly 'teas-stamp': string = '';
  readonly 'filing-description': string = '';
  readonly 'reply-email': string = '';
}

export class LivingConsent {
  readonly individuals: string = '';
  readonly 'file-names' = [] as Filename[];
  readonly files = new Array<string>();
}

export type MarkKind = 'design-mark' | 'non-visual-mark' | 'typed-mark';

export class Mark {
  readonly '@color': string = '';
  readonly '@standard-character': string = '';
  readonly kind: MarkKind = 'typed-mark';
  readonly 'mark-text': string = '';
  readonly 'mark-description': string = '';
  readonly 'color-claim': string = '';
  readonly 'material-alteration-agreement': boolean = false;
  readonly 'uploads' = new Array<string>();
  // Guide fields
  readonly '$component': string = '';
}

export class Membership {
  readonly 'custom-description-in': string = '';
  readonly 'method-of-control': string = '';
  readonly 'type-of-organization': string = '';
}

export class Owner extends MailingAddress {
  /** UI field for autocompletion of entity type name */
  readonly '$entity-type': string = '';
  /** UI field for autocompletion of country name */
  readonly '$entity-country': string = '';
  /** UI field for autocompletion of US State name */
  readonly '$entity-state': string = '';
  /** DRIVER: an entity type from the domestic entity pick list; output only */
  readonly '$entity-type-other-domestic': string = '';
  /** DRIVER: an entity type from the foreign entity pick list; output only */
  readonly '$entity-type-other-foreign': string = '';
  /** GUIDE: show foreign or domestic options */
  readonly '$legal-entity-domestic-or-foreign': string = '';
  readonly 'citizenship-country-name': string = '';
  readonly 'dba-aka-kind': string = '';
  readonly 'dba-aka-text': string = '';
  readonly 'entry-sequence-number': number = 0;
  readonly 'general-partner-name': string = '';
  readonly 'incorporated-in-state-code': string = '';
  readonly 'legal-entity-type-code': string = '';
  readonly 'other-entity-type-text': string = '';
  readonly 'role-code': string = '';
  readonly 'role-sequence-number': string = '';
  readonly 'state-organized': string = '';
  readonly 'website-address': string = '';
  readonly '$is-indian-tribe': string = '';
}

/** USPTO Payment History */
export class UsptoPayment {
  readonly 'payment-method': 'CC' | 'DA' | 'ET' = null;
  readonly 'ram-accounting-date'?: Date = null;
  readonly 'ram-sale-number'?: number = null;
}

export class Signature {
  readonly authorization: 'authCheck1' | 'authCheck2' | 'authCheck3';
  readonly 'esignature-type': 'DIRECT' /* LEGACY ONLY */ | 'DSIGN' | 'ESIGN' | 'HSIGN' = 'DSIGN';
  readonly 'signature-type': 'R' | 'D' = null;
  readonly 'signature-entry-number'?: number = null;
  readonly 'signature-name'?: string = null;
  readonly 'signatory-date'?: string = null;
  readonly 'signatory-name'?: string = null;
  readonly 'signatory-position'?: string = null;
  readonly phone?: string = null;
  readonly 'phone-country'?: string = null;
  readonly 'phone-extension'?: string = null;
  readonly 'file-name'?: Filename = null;
  readonly file?: string = null;
  readonly '$approval-signature-confirmed'?: boolean = false;
}

export class Significance {
  readonly '$numerals-y-n': string = ''; // UI field
  readonly 'no-foreign-meaning-text': string = '';
  readonly 'no-significance-text': string = ''; // TEAS field
  readonly '$no-significance-text': string = ''; // TEAS field
  readonly 'significant-meaning': string = '';
  readonly 'significant-text': string = '';
}

export class StatementWithFiles {
  readonly text: string = '';
  readonly 'file-names' = [] as Filename[];
  readonly files = new Array<string>();
}

export interface SingleStatementsMisc {
  $output: string;
  files: string[];
}

// TODO: Consider having StatementsMisc implement SingleStatementsMisc
export class StatementsMisc {
  readonly text: string = '';
  readonly 'file-names' = new Array<Filename>();
  readonly files = new Array<string>();
  readonly '$acquired-distinctiveness-delete'?: string = null;
  readonly $association?: string = null;
  readonly '$banking-institution-state-law'?: string = null;
  readonly '$banking-institution-US-state'?: string = null;
  readonly '$certification-statement-part-one'?: string = null;
  readonly '$certification-statement-part-two'?: string = null;
  readonly '$cert-app'?: string = null;
  readonly '$clarify-app-type-more'?: string = null;
  readonly '$collective-mem-app'?: string = null;
  readonly '$collective-tm-or-sm-app'?: string = null;
  readonly '$delete-color-claim'?: string = null;
  readonly '$delete-unnecessary-entity-details'?: string = null;
  readonly '$g-s-geo-connection-text'?: string = null;
  readonly '$g-s-geo-connection'?: string = null;
  readonly '$geo-significance-text'?: string = null;
  readonly '$geo-significance'?: string = null;
  readonly '$govt-univ-applicant'?: string = null;
  readonly '$joint-applicants'?: string = null;
  readonly '$membership-exercise-of-control-stmt-1'?: string = null;
  readonly '$membership-exercise-of-control-stmt-2'?: string = null;
  readonly '$membership-exercise-of-control-stmt-3'?: string = null;
  readonly '$membership-exercise-of-control-stmt-4'?: string = null;
  readonly '$minor-applicant'?: string = null;
  readonly '$minor-applicant-eligible'?: string = null;
  readonly '$minor-applicant-parent'?: string = null;
  readonly '$origin-country'?: string = null;
  readonly '$owner-federally-chartered-bank'?: string = null;
  readonly '$owner-is-banking-institution'?: string = null;
  readonly $output?: string = null;
  readonly '$requested-goods-services'?: string = null;
  readonly '$requested-info'?: string = null;
  readonly '$single-work-rebuttal'?: string = null;
  readonly '$specimen-translation'?: string = null;
  readonly '$suspension-pending-cancellation'?: string = null;
  readonly '$suspension-pending-cancellation-proceeding-no'?: string = null;
  readonly '$suspension-pending-cancellation-REG-numbers'?: string = null;
  readonly '$suspension-pending-foreign-reg'?: string = null;
  readonly '$tm-or-sm-app'?: string = null;
  readonly '$trade-name-rebuttal'?: string = null;
  readonly '$translation-remove'?: string = null;
  readonly $varietal?: string = null;
  readonly '$varietal-patent'?: string = null;
  readonly '$varietal-patent-no'?: string = null;
  readonly '$withdraw-concurrent-use'?: string = null;
  readonly '$withdraw-disclaimer'?: string = null;
}

export class Stippling {
  readonly 'shading-only': boolean = false;
  readonly 'feature-not-color': boolean = false;
}

export class Translation {
  readonly 'foreign-words': string = '';
  readonly 'no-foreign-meaning-text': string = '';
  readonly translation: string = '';
}

export class Transliteration {
  readonly translation: string = '';
  readonly 'characters-with-translation': string = '';
  readonly 'characters-without-translation': string = '';
}

export class UseInAnotherForm {
  readonly 'use-anywhere'?: Date = null;
  readonly 'use-in-commerce'?: Date = null;
}

export class OngoingEfforts {
  readonly checkboxes: string[] = [];
  readonly other: string = '';
}

export class Statements {
  readonly 'acquired-distinctiveness' = new AcquiredDistinctiveness();
  readonly 'active-prior-registrations-1'?: string = null;
  readonly 'active-prior-registrations-2'?: string = null;
  readonly 'active-prior-registrations-3'?: string = null;
  readonly 'active-prior-registrations-more'?: string = null;
  readonly 'concurrent-use': string = '';
  readonly disclaimer: string = '';
  readonly 'extension-request-insurance': boolean = false;
  readonly 'living-consent': LivingConsent = new LivingConsent();
  readonly 'living-consent-unnecessary': boolean = false;
  readonly 'ongoing-efforts': string = '';
  readonly '$ongoing-efforts' = new OngoingEfforts();
  readonly miscellaneous = new StatementsMisc();
  readonly significance = new Significance();
  readonly 'principal-register': string = '';
  readonly 'supplemental-register': string = '';
  readonly 'supplemental-register-SOU-filed': string = '';
  readonly stippling = new Stippling();
  readonly translation = new Translation();
  readonly transliteration = new Transliteration();
  readonly 'use-in-another-form' = new UseInAnotherForm();
}

export class GuideSelection {
  selectedGuideIds: string[] = [];
  selectedIssueIds: string[] = []; // Issue IDs
  guideStates: { [guideId: string]: GuideState } = {};
}

export const VERSION_CONTENT_ARRAYS = [
  'allegations-of-use',
  'classes',
  'declaration-signatures',
  'extension-requests',
  'fee-types', //
  'owners',
  'payments', //
  'renewals-815',
  'renewals-89',
  'response-signatures',
  'signatures', //
];

export class VersionContent {
  readonly 'allegations-of-use' = [] as AllegationOfUse[];
  readonly 'extension-requests' = [] as ExtensionRequest[];
  readonly argument = new Argument();
  readonly 'case-file-header' = new CaseFileHeader();
  readonly classes = [] as Class[];
  readonly correspondent = new Correspondent();
  readonly 'correspondent-attorney' = new CorrespondentAttorney();
  readonly 'correspondent-domestic-representative' = new CorrespondentDomesticRepresentative();
  readonly mark = new Mark();
  readonly owners = [new Owner()] as Owner[];
  readonly 'renewals-89' = [] as Renewal89[];
  readonly 'renewals-815' = [] as Renewal815[];
  readonly 'response-signatures' = [] as Signature[];
  readonly 'declaration-signatures' = [] as Signature[];
  readonly statements = new Statements();
  // Non-informational Mark Machine form choices
  readonly 'form-choice' = [] as FormChoices;
  readonly guides = new GuideSelection();
  readonly scripts?: { phone?: string; email: string; } = { phone: '', email: '' };
  readonly 'pending-payments' = new PendingPayment();
}

export class CurrentVersionContent extends VersionContent {
  readonly 'certification-statement' = '';
  readonly 'fee-types' = [] as UsptoFeeType[];
  readonly filing = new Filing();
  readonly membership = new Membership();
  readonly payments = [] as UsptoPayment[];
  readonly signatures = [] as Signature[];
}
