/**
 * Case File Module
 *
 * This module provides case-related FILE UPLOAD for customers.
 *
 * NOTE: This module does NOT demonstrate best practices.
 */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TipModule } from '@markmachine/features/tip/tip.module';
import { SharedMaterialModule } from '@markmachine/shared/materials/shared-materials.module';
import { CaseFileControlComponent } from './components/case-file-control/case-file-control.component';
import { FileListEditComponent } from './components/case-file-list-edit/case-file-list-edit.component';
import { CaseFileRequestService } from './services/case-file-request.service';
import { CaseFileService } from './services/case-file.service';
import { SharedModule } from '@markmachine/shared/shared.module';


@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    SharedMaterialModule,
    SharedModule,
    TipModule
  ],
  declarations: [CaseFileControlComponent, FileListEditComponent],
  exports: [CaseFileControlComponent],
  providers: [CaseFileService, CaseFileRequestService]
})
export class CaseFileModule {}
