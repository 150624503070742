import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Update } from '@ngrx/entity';
import { environment } from 'environments/environment';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as UserTableActions from '../actions/user-table.actions';
import { UserTable } from '../models/user-table.model';
import { UserTableService } from '../services/user-table.service';

const { GRAPHQL } = environment;

@Injectable()
export class UserTableEffects {

  UpdateUserTable$ = createEffect(() => this.actions$.pipe(
    ofType(UserTableActions.updateUserTable),
    switchMap(({ userTable }) => this.updateUserTable(userTable)),
  ));

  AddUserTable$ = createEffect(() => this.actions$.pipe(
    ofType(UserTableActions.addUserTable),
    mergeMap(({ userTable:
      userTable_ }) => this.service.requestCreateUserTable(userTable_).pipe(
      map(userTable => UserTableActions.addUserTableSuccess({ userTable})),
      catchError(({ errorMessage }) => of(UserTableActions.addUserTableFailure({ errorMessage })))
    ))
  ));

  constructor(
    private service: UserTableService,
    private actions$: Actions
  ) {}

  updateUserTable(payload: Update<UserTable>) {
    return this.service.requestUpdateUserTable(payload).pipe(
      map(() => UserTableActions.updateUserTableSuccess({ userTable: payload })),
      catchError(error => {
        const errorMessage = error.message;
        return of(UserTableActions.updateUserTableFailure({ errorMessage }));
      })
    );
  }
}
