
export const versionMetadataFragmentFields = `
createdAt createdBy format id idCase idParent isCurrent isDraft status title updatedAt usptoOrigin submissionMethod
`;

export const versionMetadataFragment = `
fragment versionMetadataFragment on CaseVersion {
  ${versionMetadataFragmentFields}
}
`;
