import { Injectable } from '@angular/core';
import { POLLING_LIMIT } from '@markmachine/core/core.config';
import { getUserCaseCaseIds } from '@markmachine/features/user-case/reducers/user-case.reducer';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { isEqual } from 'lodash-es';
import { of } from 'rxjs';
import { bufferTime, catchError, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import * as CaseActions from '../actions/case.actions';
import * as fromCase from '../reducers/case.reducer';
import { CaseService } from '../services/case.service';


@Injectable()
export class CaseEffects {

  // FIXME: The UserCase query should be sufficient; don't do this.
  getCaseFromUserCase$ = createEffect(() => this.store.pipe(
    select(getUserCaseCaseIds),
    withLatestFrom(this.store.pipe(select(fromCase.getCaseIds))),
    map(([desiredCaseIds, currentCaseIds]: [string[], string[]]) => {
      return desiredCaseIds.filter(id => !currentCaseIds.includes(id));
    }),
    mergeMap(caseIds => caseIds),
    // HTTP via GraphQL
    mergeMap(caseId => this.service.getCaseById(caseId).pipe(
      map(case_ => CaseActions.upsertCase({ case: case_ })),
      catchError(({ message: errorMessage }: Error) => of(CaseActions.getCaseFailure({ errorMessage })))
    )),
  ));

  /** Get the latest version of a case. */
  getCase$ = createEffect(() => this.actions$.pipe(
    ofType(CaseActions.getCase),
    // Use mergeMap because we want to get many cases simultaneously
    mergeMap(({ serialNumber }) => this.service.getCase(serialNumber)),
    bufferTime(POLLING_LIMIT),
    withLatestFrom(this.store.pipe(select(fromCase.getCaseEntities))),
    // Compare with existing record (if any) and don't upsert if it's unchanged
    map(([cases, entities]) => cases.filter(c => !isEqual(c, entities[c.id]))),
    filter(cases => cases.length > 0),
    map((cases) => CaseActions.upsertCases({ cases })),
    catchError(({ message: errorMessage }: Error) => of(CaseActions.getCaseFailure({ errorMessage }))),
  ));

  // /**
  //  * While unanalyzed polling is active, emit actions to fetch cases.
  //  *
  //  * DISABLED: #177913967
  //  */
  // pollUnanalyzed$ = createEffect(() => interval(POLLING_INTERVAL).pipe(
  //   switchMap(() => this.store.pipe(select(fromCase.getPollUnanalyzedUntil), first())),
  //   filter(until => until ? until > new Date() : false),
  //   switchMap(() => this.store.pipe(select(fromCase.getUnanalyzedCaseSerialNumbers), first())),
  //   concatMap((serialNumbers) => serialNumbers.slice(0, CONCURRENT_REQUEST_LIMIT)),
  //   map(serialNumber => CaseActions.getCase({ serialNumber }))
  // ));

  constructor(private actions$: Actions, private service: CaseService, private store: Store) {}

}
