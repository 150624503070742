import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserProfile } from '@markmachine/features/account/models/user-profile.model';
import { AccountService } from '@markmachine/features/account/services/account.service';
import { map, tap } from 'rxjs/operators';
import { AccountRequestService } from '@markmachine/features/account/services/account-request.service';

@Injectable()
export class UserProfileGuard implements CanActivate, Resolve<UserProfile> {
  constructor(
    private account: AccountService,
    private accountQuery: AccountRequestService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<UserProfile> {
    return this.accountQuery
      .requestProfile()
      .pipe(tap(profile => this.account.setUserProfile(profile)));
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.resolve(route, state).pipe(map(() => true));
  }
}
