<ng-container [ngSwitch]="header.type">

  <div *ngSwitchCase="'checkbox'">
    <mat-checkbox
      [checked]="getChecked()"
      [indeterminate]="getIndeterminate()"
      (change)="header.onHeaderChange($event.checked)">
    </mat-checkbox>
  </div>

  <div *ngSwitchCase="'readonly-text'" class="header-title">
    {{ header.name }}
  </div>

  <div *ngSwitchCase="'context-menu-location'" class="header-title">
    {{ header.name }}
  </div>

  <div *ngSwitchCase="'input'" class="header-title">
    {{ header.name }}
  </div>

  <div *ngSwitchCase="'user-case'" class="header-title">
    {{ header.name }}
  </div>

  <div *ngSwitchCase="'docket-event'" class="header-title">
    {{ header.name }}
  </div>

  <div *ngSwitchCase="'clearance-search-mark'" class="header-title">
    {{ header.name }}
  </div>

  <div *ngSwitchCase="'clearance-search-goods-services'" class="header-title">
    {{ header.name }}
  </div>

  <div *ngSwitchCase="'clearance-search-details'" class="header-title">
    {{ header.name }}
  </div>

  <div *ngSwitchCase="'clearance-search-owner'" class="header-title">
    {{ header.name }}
  </div>

  <ng-container *ngSwitchCase="'row-options'"></ng-container>

  <ng-container *ngSwitchCase="'icon-button'"></ng-container>

  <div *ngSwitchDefault class="header-title">
    ERROR
  </div>

</ng-container>
