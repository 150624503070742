/**
 * Dispatch a "Show Tip" Action to the store when the host element is clicked.
 *
 * @param mmShowTip {string} Anchor of the tip to show.
 */
import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { DialogOpenByAnchors } from '@markmachine/features/tip/actions/tip.actions';
import { Store } from '@ngrx/store';

/**
 * Handle click events on this element by showing the specified tip
 */
@Directive({ selector: '[mmShowTip]' })
export class ShowTipDirective {
  private alive = true;
  private path: string;

  @HostBinding('attr.role') role = 'button';

  @Input('mmShowTip') anchor: string; // tslint:disable-line:no-input-rename

  @HostListener('click', ['$event'])
  onClick($event) {
    const { anchor } = this;
    this.store.dispatch(DialogOpenByAnchors({ anchors: [anchor] }));
  }

  constructor(private store: Store) {}
}
