import { Component, Input, QueryList, ViewChildren } from '@angular/core';
import { TreeControllerComponent } from '@markmachine/features/tip/components/tree-controller/tree-controller.component';

/**
 * Provides a list of tips as though they were siblings.
 *
 * This component is used to present tips from several issues at the same time.
 */
@Component({
  selector: 'mm-tip-array',
  templateUrl: './tip-array.component.html',
  styleUrls: ['./tip-array.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class TipArrayComponent {
  @Input() tipIds: string[];
  // We used to accept a [hideToggle] input, but it can cause contradictions with with autoexpansion.
  // Instead, showing/hiding toggle is the responsibility of this component.
  @ViewChildren(TreeControllerComponent) children: QueryList<TreeControllerComponent>;

  collapseOtherChildren(tipId: string) {
    if (!this.children) {
      return;
    }
    this.children
      // Children eligible to collapse
      .filter((child) => child.expanded && tipId !== child.tipId)
      // Collapse them
      .forEach((child) => child.collapse());
  }

  get autoExpand() {
    return this.tipIds.length === 1;
  }
}
