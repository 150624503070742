import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Class, Specimen } from '@markmachine/features/version/models/version-content.model';
import { updateFormArray } from '@markmachine/core/functions/utilities';
import { isMatch, isNil } from 'lodash-es';
import { isArray } from 'lodash-es';
import { CaseFormValidationService } from './case-form-validation.service';


@Injectable()
export class CaseFormClassesService {
  constructor(private fb: FormBuilder, private validationService: CaseFormValidationService) {}

  /**
   * Update length and contents of FormArray for classes.
   * @param array FormArray for classes
   * @param values New values for classes
   */
  update(array: FormArray, values: Partial<Class>[], current: Class[]): void {
    updateFormArray(array, values, (values_, i) => this.create(values_[i]));
    array.controls.forEach(formGroup => this.validationService.setFormControlsValidators(formGroup));
    this.mutateArray(array, values, current);
  }

  /**
   * Create a new class
   * @param newClass Fields to set in class
   */
  create(newClass: Partial<Class> = {}): FormGroup {
    const ON_CHANGE_KEYS = [
      'filing-basis-current-1a-in',
      'filing-basis-current-1b-in',
      'filing-basis-current-44d-in',
      'filing-basis-current-44e-in'
    ];

    const group = this.fb.group({ ...new Class(), ...newClass });
    for (const key of ON_CHANGE_KEYS) {
      group.setControl(key, new FormControl(newClass[key], { updateOn: 'change' }));
    }

    // NOTE: `specimen.files` is an array; this confuses FormControl because it accepts an array where
    //       the first element is a value and the second element is a validator.
    const specimen = this.fb.group({});
    for (const [key, value] of Object.entries({ ...new Specimen(), ...newClass['specimen']})) {
      specimen.addControl(key, new FormControl(value, { updateOn: key === 'files' ? 'change' : 'blur' }));
    }
    group.setControl('specimen', specimen);
    return group;
  }

  /**
   * Append a newly-created class to a FormArray of classes.
   *
   * @param classes FormArray of classes to append to
   * @param newClass Any properties to set on the new class
   */
  appendNew(classes: FormArray, newClass: Partial<Class> = {}): void {
    classes.push(this.create({ ...new Class(), ...newClass, _isNew: true }));
  }

  /**
   * Idempotently patch any nil sequence numbers.
   *
   * This method is responsible for incrementing the sequence number of any new class.
   *
   * @param values New values for classes
   * @param current Current (non-draft) values for classes
   */
  patchSequenceNumbers(values: Partial<Class>[], current: Class[]): Partial<Class>[] {
    // Check for an undefined or null sequence-number
    const done = values.every(cls => !isNil(cls['sequence-number']));
    if (done) {
      // Nothing to do? Return original object
      return values;
    }
    // Find the biggest sequence-number, past or present
    const last = Math.max(
      ...values.map(cls => cls['sequence-number'] || 0),
      ...current.map(cls => cls['sequence-number'] || 0));
    // Replace the object with the nil sequence-number
    const idx = values.findIndex(cls => isNil(cls['sequence-number']));
    const value = { ...values[idx], 'sequence-number': last + 1 };
    values = [...values.slice(0, idx), value, ...values.slice(idx + 1)];
    // Recurse for any additional sequence numbers
    return this.patchSequenceNumbers(values, current);
  }

  /**
   * Perform secondary mutations on FormClasses.
   * @param array FormArray for classes
   * @param values New values for classes
   */
  mutateArray(array: FormArray, values: Partial<Class>[], current: Class[]): void {
    values = this.patchSequenceNumbers(values, current);
    for (let i = 0; i < array.length; i++) {
      this.mutate(array.at(i) as FormGroup, values[i], current[i]);
    }
  }

  /**
   * Perform secondary mutation on a FormClass, such as clearing old country fields.
   * @param group FormGroup for an individual class
   * @param value New value for FormGroup
   */
  mutate(group: FormGroup, value: Partial<Class>, current: Class): void {
    /*
    TODO: Instead of setting these fields from internal state, strongly consider calculating from Status in-situ.
    TODO: Alternatively, dispatch a mutation effect from updated status effects.
    */
    const isEligibleForItuForms = this.isEligibleForItuForms(group, value, current);
    const patch: Partial<Class> = {
      '$eligible-for-extension': isEligibleForItuForms,
      '$eligible-for-allegation-of-use': isEligibleForItuForms,
      '$eligible-to-amend-1a': this.isEligibleToAmend1a(group, value, current),
      '$eligible-to-amend-1b': this.isEligibleToAmend1b(group, value, current),
    };

    // Determine if group.value will be altered by patch
    const emitEvent = !isMatch(group.value, patch);
    if (emitEvent) {
      group.patchValue(patch, { emitEvent });
    }

    // Updated disabled states (doesn't emit unless state actually changes)
    this.fromEligibilitySetEnabled(group, '$eligible-to-amend-1a', 'filing-basis-current-1a-in');
    this.fromEligibilitySetEnabled(group, '$eligible-to-amend-1b', 'filing-basis-current-1b-in');
  }

  /**
   * Set the disabled state of a field according to the truthiness of another field.
   *
   * Checks to avoids generating unnecessrary statusChanged events.
   * @param group Form group to alter
   * @param eligibilityKey Key for the control value to check
   * @param controlKey Control to set according to the eligibilityKey.
   */
  fromEligibilitySetEnabled(group: FormGroup, eligibilityKey: string, controlKey: string): void {
    const eligible = !!group.get(eligibilityKey).value;
    const enabled = !!group.get(controlKey).enabled;
    if (eligible !== enabled) {
      if (enabled) {
        group.get(controlKey).disable();
      } else {
        group.get(controlKey).enable();
      }
    }
  }

  /**
   * Determine if a class is eligible for a Request for Extension of Use
   *
   * To determine if a clas is eligible for an Extension Request, we need to know if
   * it's ALREADY part of the case and, if so, if its basis is ALREADY 1b/ITU.
   * NOTE: You can't file an extension request on a class you are PRESENTLY ADDING,
   * even if you're adding it with an ITU basis.  You have a wait a couple of days for
   * the class to show up in TSDR & TEAS.
   */
  isEligibleForItuForms(group: FormGroup, value: Partial<Class>, current: Partial<Class>): boolean {
    return !!(current && current['filing-basis-current-1b-in']);
  }

  /** Determine if the FormGroup belongs to a new application (no serial-number yet). */
  isNewApp(group: FormGroup): boolean {
    return !group.root.get(['case-file-header', 'serial-number']).value;
  }

  /** Determine if the FormGroup belongs to a new class */
  isNewClass(group: FormGroup): boolean {
    return !!group.get('_isNew').value;
  }

  /** Determine if the FormGroup belongs to an app with a pending Specimen of Use Office Action. */
  hasSpecimenOfUseOfficeAction(group: FormGroup): boolean {
    return group.root.get(['case-file-header', 'status']).value === 807;
  }

  /** Determine if the corresponding class currently has a 1a basis according to USPTO. */
  isCurrentlyPriorUse(current: Partial<Class>): boolean {
    return !!current?.['filing-basis-current-1a-in'];
  }

  /** Determine if this class can be amnded to 1a without an AOU. */
  isEligibleToAmend1a(group: FormGroup, value: Partial<Class>, current: Partial<Class>): boolean {
    // TODO: handle post-registration (false)
    // Can always revert changes back to 1a basis while current basis is 1a
    return this.isNewApp(group) || this.isNewClass(group) || this.isCurrentlyPriorUse(current) || this.hasSpecimenOfUseOfficeAction(group);
  }

  /** Determine if this class can be amended to 1b. */
  isEligibleToAmend1b(group: FormGroup, value: Partial<Class>, current: Partial<Class>): boolean {
    // TODO: handle post-registration (false)
    return this.isNewApp(group) || this.isNewClass(group) || this.isCurrentlyPriorUse(current) || this.hasSpecimenOfUseOfficeAction(group);
  }
}
