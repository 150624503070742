import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { deepClone, uuidv4 } from '@markmachine/core/functions/utilities';
import { unpackGraphqlResponse } from '@markmachine/core/graphql-operators';
import { Argument, CaseFileHeader, Signature, Specimen, Statements, VersionContent } from '@markmachine/features/version/models/version-content.model';
import { CurrentVersion, StatusEnum, SubmissionMethod, Version, VersionId } from '@markmachine/features/version/models/version.model';
import { RenameVersionMutation, RenameVersionResponse } from '@markmachine/features/version/services/operations/rename-version.mutation';
import { UnlockVersionMutation, UnlockVersionResponse } from '@markmachine/features/version/services/operations/unlock-version.mutation';
// tslint:disable-next-line: max-line-length
import { ChangeSubmissionMethodMutation, ChangeSubmissionMethodResponse } from '@markmachine/views/submission-page/services/operations/choose-submission-method.mutation';
// tslint:disable-next-line: max-line-length
import { UpdateVersionMutation, UpdateVersionResponse } from '@markmachine/views/submission-page/services/operations/update-version.mutation';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { CreateVersionMutation, CreateVersionResponse } from './operations/create-version.mutation';
import { DeleteVersionMutation, DeleteVersionResponse } from './operations/delete-version.mutation';
import { VersionByIdQuery, VersionByIdResponse } from './operations/version-by-id.query';
import { VersionsByCaseIdQuery, VersionsByCaseIdResponse } from './operations/versions-by-case-id.query';

const { GRAPHQL } = environment;

@Injectable({
  providedIn: 'root'
})
export class VersionRequestService {
  constructor(private http: HttpClient) {}

  /** Create an initial new version for a new case. */
  static generateInitialCurrentVersion(idCase: string): CurrentVersion {
    const id = uuidv4();
    const [createdAt, updatedAt] = [new Date().toISOString(), new Date().toISOString()];
    const [status, isCurrent, isDraft] = ['LOCKED' as 'LOCKED', true, false];
    return { ...new CurrentVersion(), id, idCase, createdAt, updatedAt, status, isCurrent, isDraft };
  }

  static generateResponseSignature(): Signature {
    const today = new Date().toISOString().slice(0, 10);
    return {
      ...new Signature(),
      'signature-type': 'R',
      'esignature-type': 'DSIGN',
      'authorization': 'authCheck1',
      'signatory-date': today
    };
  }

  static generateDeclarationSignature(): Signature {
    return { ...VersionRequestService.generateResponseSignature(), 'signature-type': 'D' };
  }

  /**
   * Generate a new draft version from an existing version.
   *
   * The new Version is a child of the given Version and belongs to the same Case.
   */
  static generateDraft(current: Version = new Version(), { isNewApp } = { isNewApp: false }): Partial<Version> {
    return {
      format: current.format,
      idCase: current.idCase,
      id: uuidv4(),
      idParent: current.id,
      isCurrent: false,
      isDraft: true,
      title: 'Draft',
      usptoOrigin: null,
      status: 'UNLOCKED',
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      content: VersionRequestService.prepareDraftContent(current.content, isNewApp),
      submissionMethod: 'TEAS'
    };
  }

  /**
   * Generate the content of a new draft from a reference.
   *
   * Clears out transient fields that should start empty in a new draft, such as statements and signatures.
   */
  static prepareDraftContent(content: VersionContent, isNewApp = false): VersionContent {
    // TODO: Determine which fields to omit based on planned transient property of VersionField records
    return {
      ...deepClone(content),
      'allegations-of-use': [],
      argument: new Argument(),
      'declaration-signatures': isNewApp ? [VersionRequestService.generateDeclarationSignature()] : [],
      'extension-requests': [],
      'response-signatures': isNewApp ? [] : [VersionRequestService.generateResponseSignature()],
      statements: new Statements(),
      correspondent: { ...content.correspondent, 'has-attorney': !!(content['correspondent-attorney'].name) },
      classes: content.classes.map((currentClass) => ({
          ...deepClone(currentClass),
          specimen: new Specimen()
      }))
    };
  }

  static cloneDraftVersion(old: Version): Version {
    return {
      ...new Version(),
      content: {
        ...VersionRequestService.prepareDraftContent(old.content),
        'case-file-header': new CaseFileHeader()
      }
    };
  }

  static generateSnapshot(draft: Version): Version {
    return {
      ...deepClone(draft),
      id: uuidv4(),
      idParent: draft.idParent,
      isCurrent: false,
      isDraft: false,
      title: `Archive from ${new Date().toLocaleDateString()}`,
      usptoOrigin: null,
      status: 'LOCKED',
      createdAt: new Date().toISOString(),
      updatedAt: draft.updatedAt
    };
  }

  static generateUpdate(version: Version): Version {
    return {
      ...version,
      updatedAt: new Date().toISOString()
    };
  }

  static generateSubmitted(version: Version): Version {
    return {
      ...version,
      status: 'SUBMITTED',
      updatedAt: version.updatedAt
    };
  }

  static generateCancelledSubmission(version: Version): Version {
    return {
      ...version,
      status: 'UNLOCKED',
      title: 'Draft',
      updatedAt: version.updatedAt
    };
  }

  /** Fetch versions for a case */
  getVersions(caseId: string): Observable<Version[]> {
    return this.http
      .post<VersionsByCaseIdResponse>(GRAPHQL, new VersionsByCaseIdQuery({ caseId }))
      .pipe(unpackGraphqlResponse('data.caseById.caseVersionsByIdCase.nodes'));
  }

  /** Fetch a specific version. */
  getVersion(versionId: string): Observable<Version> {
    return this.http
      .post<VersionByIdResponse>(GRAPHQL, new VersionByIdQuery({ versionId }))
      .pipe(unpackGraphqlResponse('data.caseVersionById'));
  }

  /** Create a new version that is the child of the given version. */
  createVersion(caseVersion: Partial<Version>): Observable<Version> {
    return this.http
      .post<CreateVersionResponse>(GRAPHQL, new CreateVersionMutation({ caseVersion }))
      .pipe(unpackGraphqlResponse('data.createCaseVersion.caseVersion'));
  }

  /**
   * Save changes to the version.
   *
   * NOTE: The GraphQL query can take patches, but the values for nested keys will
   * be replaced (dropping omitted values) and this is rarely what you want when
   * updating versions.  So, always replace the entire version.
   */
  updateVersion(caseVersionPatch: Version): Observable<Version> {
    const { id: caseVersionId } = caseVersionPatch;
    return this.http
      .post<UpdateVersionResponse>(GRAPHQL, new UpdateVersionMutation({ caseVersionId, caseVersionPatch }))
      .pipe(unpackGraphqlResponse('data.updateCaseVersionById.caseVersion'));
  }

  /** Delete the given version. */
  deleteVersion(caseVersionId: string): Observable<Version> {
    return this.http
      .post<DeleteVersionResponse>(GRAPHQL, new DeleteVersionMutation({ caseVersionId }))
      .pipe(unpackGraphqlResponse('data.deleteCaseVersionById.caseVersion'));
  }

  /**
   * Request the renaming of a case version.
   * @param caseVersionId Case version primary key (UUID)
   * @param title New title for case version
   */
  renameVersion(caseVersionId: string, title: string): Observable<{ id: string; title: string }> {
    return this.http
      .post<RenameVersionResponse>(GRAPHQL, new RenameVersionMutation({ caseVersionId, title }))
      .pipe(unpackGraphqlResponse('data.updateCaseVersionById.caseVersion'));
  }

  /** INTERNAL USE: Unlock a draft for editing after submission. */
  unlockVersion(versionId: VersionId): Observable<{ id: VersionId; status: StatusEnum }> {
    return this.http
      .post<UnlockVersionResponse>(GRAPHQL, new UnlockVersionMutation({ versionId }))
      .pipe(unpackGraphqlResponse('data.unlockVersion.caseVersion'));
  }

  /** Change submission type between TEAS, Email, Phone, etc. */
  changeSubmissionMethod(caseVersionId: string, method: SubmissionMethod): Observable<SubmissionMethod> {
    return this.http
      .post<ChangeSubmissionMethodResponse>(GRAPHQL, new ChangeSubmissionMethodMutation({ caseVersionId, method }))
      .pipe(unpackGraphqlResponse('data.updateCaseVersionById.caseVersion.submissionMethod'));
  }
}
