import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'commaList'
})
export class CommaListPipe implements PipeTransform {
  transform(value: any[], oxford: boolean = false) {
    // TODO: use commaList.function.ts
    let terminalGlue = ' and ';
    if (oxford && value.filter(v => !!v).length >= 3) {
      terminalGlue = ',' + terminalGlue;
    }
    return [value.slice(0, -1).join(', '), value.slice(-1)].filter(v => !!v).join(terminalGlue);
  }
}
