import { Params } from '@angular/router';
import * as fromRouterStore from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
  data: Params;
}

export type State = fromRouterStore.RouterReducerState<RouterStateUrl>;

//
// Router Selectors
//
export const storeKey = 'routerReducer';
export const getRouterState = createFeatureSelector<State>(storeKey);
export const getRouterUrl = createSelector(getRouterState, state => state?.state?.url ?? '');
export const getRouterTitle = createSelector(getRouterState, state => state?.state?.data?.title);
export const getPaidDocket = createSelector(getRouterState, state => state?.state?.data?.paidDocket ?? false);
export const getRouterParams = createSelector(getRouterState, state => state?.state?.params ?? {});
export const getRouterQueryParams = createSelector(getRouterState, state => state?.state?.queryParams ?? {});
