import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import * as StatusActions from '@markmachine/features/case-status/actions/status.actions';
import { AppState } from '@markmachine/interfaces';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StatusRequestService } from '../services/status-request.service';


@Injectable()
export class CaseStatusGuard implements CanActivate {
  constructor(
    private service: StatusRequestService,
    private store: Store<AppState>
  ) {}

  hasStatusInApi(caseId: string): Observable<boolean> {
    return this.service
      .refreshStatuses([caseId])
      .pipe(
        map(([status]) => {
          if (status) {
            this.store.dispatch(StatusActions.upsertStatus({status}));
          }
          return true;
        })
      );
  }

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    return this.hasStatusInApi(next.paramMap.get('caseId') as string);
  }
}
