import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Trademark } from '@markmachine/features/clearance-search/models/knockout-response.model';

export interface ClearanceSearchResultGoodService {
  class: string;
  description: string;
}

@Component({
  selector: 'mm-clearance-search-results-goods-services',
  templateUrl: './clearance-search-results-goods-services.component.html',
  styleUrls: ['./clearance-search-results-goods-services.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClearanceSearchResultsGoodsServicesComponent implements OnInit {
  @Input()
  set row(value: Trademark) {
    if (value.goodsAndServices) {
      const goodsServices = value.goodsAndServices.reduce(
        (acc, item) => {
          if (!acc[item.internationalClass]) {
            // Create a spot for the class if it doesn't exist
            acc[item.internationalClass] = {
              class: item.internationalClass,
              description: item.value,
            };
          } else {
            // Append the item's value to the accumulator's description if it has one
            acc[item.internationalClass].description = `${acc[item.internationalClass].description}; ${item.value}`;
          }
          return acc;
      }, {});
      this.goodsServices = Object.keys(goodsServices).map((k) => goodsServices[k]);
    }
  }

  goodsServices: ClearanceSearchResultGoodService[] = [];
  constructor() { }

  ngOnInit() {
  }

}
