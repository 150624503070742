import { AfterViewChecked, ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MatRadioGroup } from '@angular/material/radio';

@Component({
  selector: 'mm-clear-value-button',
  templateUrl: './clear-value-button.component.html',
  styleUrls: ['./clear-value-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClearValueButtonComponent implements AfterViewChecked {
  control: AbstractControl;
  radioGroup: MatRadioGroup;

  ngAfterViewChecked() {
    this.radioGroup._emitChangeEvent();
  }

  clearValue() {
    this.control.setValue(null);
  }
}
