import { Version } from '@markmachine/features/version/models/version.model';
import { versionMetadataFragmentFields } from './version-metadata.fragment';
import { GraphQLQuery, GraphQLResponse } from '@markmachine/interfaces';


const query = `
mutation DeleteVersion($caseVersionId: UUID!) {
  deleteCaseVersionById(input: { id: $caseVersionId }) {
    caseVersion {
      content ${versionMetadataFragmentFields}
    }
  }
}
`;

export class DeleteVersionMutation implements GraphQLQuery {
  query = query;

  constructor(public variables: { caseVersionId: string }) {}
}

export type DeleteVersionResponse = GraphQLResponse<{
  deleteCaseVersionById: {
    caseVersion: Version;
  };
}>;
