<h2 mat-dialog-title>{{title}}</h2>

<div mat-dialog-content
  class="mat-typography">

  <p>{{message}}</p>

  <section *ngIf="details && details.stack"
    class="error-stack">
    <h3>Error Stack</h3>
    <pre>{{details.stack}}</pre>
  </section>

</div>

<mat-dialog-actions>

  <button *ngIf="multipleDialogs"
    mat-button
    color="warn"
    (click)="closeErrors()">CLOSE ALL</button>

  <button mat-button
    mat-dialog-close>CLOSE</button>

</mat-dialog-actions>