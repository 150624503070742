import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticatedUserProfile, UserProfile } from '@markmachine/features/account/models/user-profile.model';
import { JwtToken } from '@markmachine/interfaces';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthenticationError, UnauthorizedError } from '../exceptions/authorization-error';
import { EmailTakenError, RegisterationError } from '../exceptions/registeration-error';
import { AuthorizeUserQuery, AuthorizeUserResponse } from './operations/authorize.query';
import { RegisterUserQuery, RegisterUserResponse } from './operations/register.mutation';
import { UpdateUserProfileMutation, UpdateUserProfileResponse } from './operations/update-user-profile.mutation';
import { UserProfileQuery, UserProfileResponse } from './operations/user-profile.query';

const URL = environment.GRAPHQL;

@Injectable()
export class AccountRequestService {
  constructor(private http: HttpClient) {}

  /**
   * Register a new user account with the Mark Machine API
   * @param email Email address of new account
   * @param password Password for new account
   */
  requestRegistration(email: string, password: string): Observable<{ jwtToken: JwtToken, profile: AuthenticatedUserProfile }> {
    return this.http
      .post<RegisterUserResponse>(
        URL,
        new RegisterUserQuery({ email, password })
      )
      .pipe(
        tap(({ data, errors }) => {
          const duplicateEmailMessage =
            'duplicate key value violates unique constraint "user_account_email_key"';
          if (errors) {
            const message = errors[0].message;
            if (message.includes(duplicateEmailMessage)) {
              throw new EmailTakenError();
            }
            throw new RegisterationError();
          } else if (data.registerUser === null) {
            throw new RegisterationError();
          }
        }),
        map(({ data }) => data.registerUser),
        map(({ jwtToken, currentProfile: profile }) => ({ jwtToken, profile }))
      );
  }

  /**
   * Obtain an access token from the Mark Machine API
   * @param email Email address of existing account
   * @param password Password of existing account
   */
  requestAuthorization(email: string, password: string): Observable<{ accessToken: JwtToken, profile: AuthenticatedUserProfile }> {
    return this.http
      .post<AuthorizeUserResponse>(URL, new AuthorizeUserQuery({ email, password }))
      .pipe(
        tap(({ data }) => {
          if (data.authenticate.jwtToken === null) {
            throw new AuthenticationError();
          }
        }),
        map(({ data: { authenticate } }) => {
          const { jwtToken: accessToken, query: { currentProfile } } = authenticate;
          return { accessToken, profile: currentProfile };
        }),
      );
  }

  /**
   * Fetch the current user's profile from the Mark Machine API
   */
  requestProfile(): Observable<UserProfile> {
    return this.http
      .post<UserProfileResponse>(URL, new UserProfileQuery({}))
      .pipe(
        tap(({ data, errors }) => {
          if (errors) {
            const message = errors[0].message;
            throw new UnauthorizedError();
          } else if (data.currentProfile === null) {
            throw new UnauthorizedError();
          }
        }),
        map(({ data }) => data.currentProfile)
      );
  }

  /**
   * Modify a stored user profile via the Mark Machine API
   * @param userId User ID obtained from user profile
   * @param userProfilePatch Changes to make to user profile
   */
  requestUserProfileUpdate(
    userId: string,
    userProfilePatch: Partial<UserProfile>
  ): Observable<UserProfile> {
    return this.http
      .post<UpdateUserProfileResponse>(
        URL,
        new UpdateUserProfileMutation({ userId, userProfilePatch })
      )
      .pipe(
        tap(({ data, errors }) => {
          if (data.updateUserProfileByUserId === null) {
            throw new UnauthorizedError();
          } else if (errors) {
            const message = errors[0].message;
            throw new UnauthorizedError();
          }
        }),
        map(({ data }) => data.updateUserProfileByUserId.userProfile)
      );
  }


}
