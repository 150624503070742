import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface LoadFailureDialogOpeningData {
  error: any;
}

export interface LoadFailureDialogClosingData {
  error: any;
}

@Component({
  selector: 'mm-load-failure-dialog',
  templateUrl: './load-failure-dialog.component.html',
  styleUrls: ['./load-failure-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadFailureDialogComponent implements OnInit {
  error: any;

  constructor(@Inject(MAT_DIALOG_DATA) private data: LoadFailureDialogOpeningData) { }

  ngOnInit() {
    this.error = this.data.error;
    console.log(this.error);
  }

  get exitData(): LoadFailureDialogClosingData {
    return { error: this.error };
  }
}
