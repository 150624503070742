import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { isArray, isObject } from 'lodash-es';


/** Recursively construct a FormGroup, FormArray, or FormControl according to types. */
export function buildForm(value): FormArray | FormGroup | FormControl {
  if (isArray(value)) {
    const contents = value.map(v => buildForm(v));
    return new FormArray(contents);
  } else if (isObject(value)) {
    const contents = {};
    for (const [k, v] of Object.entries(value)) {
      contents[k] = buildForm(v);
    }
    return new FormGroup(contents);
  }
  return new FormControl({ value, disabled: false });
}


/** Recursively update the structure of a form to match the structure of the given value. */
export function reviseControls(container: AbstractControl, value: any): AbstractControl {
  // The interfaces for FormGroup and Object are needlessly different from
  // the interfaces for FormArray and Array. So, we kinda need to ourselves
  // or write very non-idiomatic JS.

  if (container instanceof FormArray && isArray(value)) {
    // Add/update missing controls
    for (const [k, v] of value.entries()) {
      if (container.controls[k] === undefined) {
        // Create the control for this key
        container.setControl(k, buildForm(v));
      } else {
        // Revise the control for this key
        reviseControls(container.at(k), v);
      }
    }
    // Remove deleted controls
    while (container.controls.length > value.length) {
      container.removeAt(container.controls.length - 1);
    }
  } else if (container instanceof FormGroup && isObject(value)) {
    // Add/update missing controls
    for (const [k, v] of Object.entries(value)) {
      if (container.controls[k] === undefined) {
        // Create the control for this key
        container.setControl(k, buildForm(v));
      } else {
        // Revise the control for this key
        reviseControls(container.get(k), v);
      }
    }
    // Remove delete controls
    for (const key of Object.keys(container.controls)) {
      if (value[key] === undefined) {
        container.removeControl(key);
      }
    }
  } else {
    if (container instanceof FormControl) {
      if (container.value !== value) {
        container.setValue(value);
      }
    } else {
      // Warning: We're replacing the control. This might orphan anything that subscribed to valueChanges or statusChanges.
      return new FormControl({ value, disabled: container.disabled });
    }
  }
  return container;
}
