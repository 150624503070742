import { Injectable } from '@angular/core';
import { go } from '@markmachine/core/actions/router.actions';
import { NETWORK_DEBOUNCE_TIME } from '@markmachine/core/core.config';
import { getUserId } from '@markmachine/features/account/reducers/account.reducer';
import * as CaseActions from '@markmachine/features/case/actions/case.actions';
import * as LogActions from '@markmachine/features/log/actions/log.actions';
import * as UserCaseActions from '@markmachine/features/user-case/actions/user-case.actions';
import * as VersionActions from '@markmachine/features/version/actions/version.actions';
import {
  addVersion,
  cloneCaseVersionFailure,
  createBlankCaseVersionFailure,
  createVersionFailure,
  createVersionSuccess,
  remoteDeleteFailure,
  remoteDeleteSuccess,
  remoteUpdate,
  remoteUpdateFailure,
  remoteUpdateSuccess,
  renameVersionFailure,
  renameVersionSuccess,
  snapshotVersionFailure,
  snapshotVersionSuccess,
} from '@markmachine/features/version/actions/version.actions';
import { Version } from '@markmachine/features/version/models/version.model';
import { VersionRequestService } from '@markmachine/features/version/services/version-request.service';
import { AppState } from '@markmachine/interfaces';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';
import { mapTo, catchError, concatMap, debounceTime, endWith, first, map, switchMap, withLatestFrom, filter, tap } from 'rxjs/operators';
import { getVersionEntities } from '../reducers/version.reducer';

const SUBMISSION_STARTED_MESSAGE = `
Mark Machine will begin transmitting your Submission to the USPTO as soon as possible during normal business hours.
In the event of a substantial transmission error, transmission could be delayed for up to one business day.
In any event, Mark Machine will notify you via email when the transmission has been successfully completed.
This case is currently uneditable and will become editable once the recently-submitted changes have been successfully transmitted to the USPTO.
`;

const REMOTE_UPDATE_FAILURE_NOT_UNLOCKED_MESSAGE = `
Your changes can't be accepted right now because you have already
submitted a change to the USPTO, but the USPTO has not yet accepted the
submitted modification. They typically accept modifications within 1–2 business
days. Try again after the USPTO has accepted your prior submission.
`;

const REMOTE_UPDATE_FAILURE_UNLOCKED_MESSAGE = `
Your changes couldn't be saved at this time. This might happen if you're
offline or if you're having trouble reaching our servers. Try again in a minute or
check your connection by trying other sites.
`;

/**
 * Perform side-effects in response to actions, such as network persistence.
 */
@Injectable()
export class VersionEffects {
  SnapshotVersion$ = createEffect(() => this.actions$.pipe(
    ofType(VersionActions.snapshotVersion),
    map(({ version }) => VersionRequestService.generateSnapshot(version)),
    switchMap(snapshot_ =>
      this.caseRequest.createVersion(snapshot_).pipe(
        map(snapshot => snapshotVersionSuccess({ version: snapshot })),
        catchError(err => of(snapshotVersionFailure()))
      )
    )
  ));

  RenameVersion$ = createEffect(() => this.actions$.pipe(
    ofType(VersionActions.renameVersion),
    switchMap(({ id: id_, title: title_ }) =>
      this.caseRequest.renameVersion(id_, title_).pipe(
        map(({ id, title }) => renameVersionSuccess({ id, title })),
        catchError(err => of(renameVersionFailure()))
      )
    )
  ), { dispatch: false });

  CreateVersion$ = createEffect(() => this.actions$.pipe(
    ofType(VersionActions.createVersion),
    switchMap(({ version: version_ }) =>
      this.caseRequest.createVersion(version_).pipe(
        map(version => createVersionSuccess({ version })),
        catchError(err => of(createVersionFailure()))
      )
    )
  ));

  DeleteVersion$ = createEffect(() => this.actions$.pipe(
    ofType(VersionActions.deleteVersion),
    switchMap(({ id }) =>
      this.caseRequest.deleteVersion(id).pipe(
        map(version => remoteDeleteSuccess({ id: version.id })),
        catchError(err => of(remoteDeleteFailure()))
      )
    )
  ));

  UpdateVersion$ = createEffect(() => this.actions$.pipe(
    ofType(VersionActions.updateVersion),
    map(({ version }) => version),
    map(({ id }) => id as string),
    map(id => remoteUpdate({ id }))
  ));

  RemoteUpdate$ = createEffect(() => this.actions$.pipe(
    ofType(VersionActions.remoteUpdate),
    debounceTime(NETWORK_DEBOUNCE_TIME),
    switchMap(({ id }) =>
      this.store.pipe(
        select(getVersionEntities),
        map(entities => entities[id]),
        first()
      )
    ),
    map(VersionRequestService.generateUpdate),
    switchMap(version_ =>
      this.caseRequest.updateVersion(version_).pipe(
        map(version => remoteUpdateSuccess({ version })),
        catchError(() => {
          // We need status to determine what error message to emit.
          const { status } = version_;
          return of(remoteUpdateFailure({ version: version_ }));
        })
      ))
  ));

  UpdateVersionSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(VersionActions.remoteUpdateSuccess),
    filter(({ version }) => version.status === 'SUBMITTED'),
    mapTo(
      LogActions.userAlert({
        title: 'Editing Locked',
        message: SUBMISSION_STARTED_MESSAGE
      }))
    ));

  UpdateVersionFailure$ = createEffect(() => this.actions$.pipe(
    ofType(VersionActions.remoteUpdateFailure),
    map(({ version: { status } }) => {
      // let the user know what went wrong
      // To discuss:  Triggering updateVersion to revert the version status creates an infite loop
      // because the remoteUpdate required for the updateVersion call will trigger a remoteUpdateFailure
      // again.

      // TO DISCUSS: The error message when status !== UNLOCKED seems to be a catch all
      // for any type of remote update failure.  Failure might occur because of some database
      // error as well but the message assumes that failure means the submission was successful.
      // How do we reconcile that with the need to toggle the Stores version status back to UNLOCKED
      // in the case that there was a genuine error?
      if (status !== 'UNLOCKED') {
        return LogActions.userAlert({
          title: `Can't modify submission`,
          message: REMOTE_UPDATE_FAILURE_NOT_UNLOCKED_MESSAGE
        });
      }

      return LogActions.userAlert({
        title: `Can't save right now`,
        message: REMOTE_UPDATE_FAILURE_UNLOCKED_MESSAGE
      });
    })
  ));

  /** Create a new case and related user case and version then redirect the user to that case */
  CreateNewCaseVersion = createEffect(() => this.actions$.pipe(
    ofType(UserCaseActions.createNewCaseSuccess),
    withLatestFrom(this.store.pipe(map(getUserId))),
    switchMap(([{ caseId }, createdBy]) => {
      const currentVersion: Version = { ...VersionRequestService.generateInitialCurrentVersion(caseId), createdBy };
      const draftVersion = VersionRequestService.generateDraft(currentVersion, { isNewApp: true });
      // Since the Draft refers to the Current as its parent, it has to be successfully created first.
      return of(currentVersion, draftVersion).pipe(
        concatMap(v => this.caseRequest.createVersion(v)),
        map(version => addVersion({ version })),
        endWith(go({ path: ['apply', caseId] })),
        catchError(error => of(createBlankCaseVersionFailure({ error })))
      );
    }),
  ));

  /**
   * Clone a case version then redirect the user to that case
   */
  CloneCaseVersion$ = createEffect(() => this.actions$.pipe(
    ofType(UserCaseActions.cloneUserCaseSuccess),
    withLatestFrom(this.store.pipe(map(getUserId))),
    switchMap(([{ newCaseId, oldCaseId }, createdBy]) => {
      return this.caseRequest.getVersions(oldCaseId).pipe(
        switchMap(versions => {
          let base;
          const oldVersionList = Object.values(versions);
          const oldDraft = oldVersionList.find(v => v.isDraft);
          const oldCurrent = oldVersionList.find(v => v.isCurrent);
          if (oldDraft) {
            base = VersionRequestService.cloneDraftVersion(oldDraft);
          } else if (oldCurrent) {
            base = VersionRequestService.cloneDraftVersion(oldCurrent);
          } else {
            base = new Version();
          }
          return forkJoin([
            this.caseRequest.createVersion({
              ...new Version(), idCase: newCaseId, createdBy, format: 3, status: 'LOCKED', isCurrent: true
            }).pipe(
              map(newVersion => addVersion({ version: newVersion })),
              catchError(({ errorMessage }) => of(cloneCaseVersionFailure({ errorMessage })))
            ),
            this.caseRequest.createVersion({
              ...base, idCase: newCaseId, createdBy, format: 3, status: 'UNLOCKED', isDraft: true
            }).pipe(
              map(newVersion => addVersion({ version: newVersion })),
              catchError(({ errorMessage }) => of(cloneCaseVersionFailure({ errorMessage })))
            ),
          ]).pipe(
            map(() => go({ path: ['apply', newCaseId] }))
          );
        })
      );
    })
  ));

  constructor(
    private actions$: Actions,
    private caseRequest: VersionRequestService,
    private store: Store<AppState>
  ) { }
}
