import { Directive, ElementRef, forwardRef, HostListener, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';
import { DATE_REGEX, US_DATE } from '@markmachine/features/uspto-indefinite-date/constants';
import { isEqual } from 'lodash-es';

type Year = string;
type Month = string;
type Day = string;
type Other = string;
type DateArray = [Year, Month, Day, Other];
type DisplayValue = string | null; // US Format
type StorageValue = string | null; // ISO Format

@Directive({
  selector: '[mmUsptoIndefiniteDate]',
  providers: [
    // Provide bindings for form controls
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UsptoIndefiniteDateDirective),
      multi: true
    },
    // Provide value for matInput
    { provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: UsptoIndefiniteDateDirective }
  ],
})
export class UsptoIndefiniteDateDirective implements ControlValueAccessor {
  @Input()
  get value(): StorageValue { return this._value; }
  set value(value: StorageValue) {
    this._value = value;
    this._formatValue(value); // Update view when we change value
  }
  private _value: string | null = null;

  @Input()
  get disabled(): boolean { return !!this._disabled; }
  set disabled(value: boolean) {
    // Forward disable to native element.
    const newValue = !!value;
    const element = this._elementRef.nativeElement;
    if (this._disabled !== newValue) {
      this._disabled = newValue;
    }
    // Blur is supposed to emit when disabled, but sometimes doesn't if disabled
    // gets toggled too quickly. Explicit emission makes behavior more regular.
    if (newValue && element.blur) {
      element.blur();
    }
  }
  private _disabled: boolean;

  constructor(private _elementRef: ElementRef<HTMLInputElement>) {}

  /* Listen to keystrokes from native element (display value) */
  @HostListener('input', ['$event.target.value']) _input(value: DisplayValue) {
    // Compare dates in comparable identical structure
    const newDateArray = this._fromDisplayValue(value);
    const oldDateArray = this._fromStorageValue(this.value);
    if (!isEqual(newDateArray, oldDateArray)) {
      // If the date was parsable, set internal value with storage format
      // Otherwise, fall through to literal value
      this._value = newDateArray ? this._toStorageValue(newDateArray) : value;
      // Emit to form model
      this._cvaOnChange(this._value);
    }
  }

  @HostListener('blur', ['$event.target.value']) _blur(value: DisplayValue) {
    this._formatValue(value);
    this._onTouched();
  }

  /*
   * Variables and methods used by ControlValueAccessor
   */
  _cvaOnChange: (value: StorageValue) => void = () => {};
  _onTouched = () => {};

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: (value: StorageValue) => void): void {
    this._cvaOnChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /** Parse storage date for component logic. */
  private _fromStorageValue(value: StorageValue): DateArray | null {
    if (DATE_REGEX.test(value as string)) {
      const [year, month, day, other] = (DATE_REGEX.exec(value as string) as RegExpExecArray).slice(1);
      return [year, month, day, other];
    }
    return null;
  }

  /** Parse presentation date for component logic. */
  private _fromDisplayValue(value: DisplayValue): DateArray | null {
    if (US_DATE.test(value as string)) {
      const [month, day, year, other] = (US_DATE.exec(value as string) as RegExpExecArray).slice(1);
      return [year, month, day, other];
    }
    return null;
  }

  /** Construct US date for presentation. */
  private _toDisplayValue([year, month, day, other]: DateArray): DisplayValue {
    // year/month/day and other are mutually exclusive.
    return other || `${month}/${day}/${year}`;
  }

  /** Construct ISO date for storage. */
  private _toStorageValue([year, month, day, other]: DateArray): StorageValue {
    // year/month/day and other are mutually exclusive.
    return other || `${year}${month}${day}`;
  }

  /** Update native element. */
  private _formatValue(value: StorageValue) {
    const arr = this._fromStorageValue(value);
    if (value && arr) {
      this._elementRef.nativeElement.value = this._toDisplayValue(arr) as string;
    } else {
      this._elementRef.nativeElement.value = value as string;
    }
  }

}
