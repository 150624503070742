import { Injectable } from '@angular/core';
import { removeFile, uploadFile, uploadFileSuccess } from '@markmachine/features/case/actions/file.actions';
import { CaseFile } from '@markmachine/features/case/models/file.model';
import { AppState } from '@markmachine/interfaces';
import { getCaseFiles, getCaseId } from '@markmachine/views/submission-page/reducers';
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';


/**
 * Upload or download files attached to cases.
 */
@Injectable()
export class CaseFileService {
  private _caseFiles: Observable<CaseFile[]>;
  private _caseId: Observable<string>;
  private _pendingFiles: File[] = [];

  constructor(private store: Store<AppState>, private actions$: Actions) {
    this._caseId = this.store.pipe(select(getCaseId)) as Observable<string>;
    this._caseFiles = this.store.pipe(select(getCaseFiles));
  }

  /* **************** */
  /* Public Interface */
  /* **************** */

  public casefiles() {
    return this._caseFiles;
  }

  public casefilesByIds(ids: Array<string>): Observable<CaseFile[]> {
    return this._caseFiles.pipe(
      map(cfs => {
        return cfs.filter(cf => (ids || []).includes(cf.id));
      })
    );
  }

  public completedUploadActions() {
    return this.actions$.pipe(ofType(uploadFileSuccess));
  }

  public deleteFile(idCaseFile: string): void {
    this._caseId
      .pipe(take(1))
      .subscribe(idCase => this._dispatchDelete(idCase, idCaseFile));
  }

  public uploadFiles(
    newFiles: FileList,
    path?: string,
    attachment?: string
  ): void {
    this._caseId.pipe(take(1)).subscribe(idCase => {
      for (let index = 0; index < newFiles.length; index++) {
        const file = newFiles.item(index) as File;
        this._pendingFiles = [...this._pendingFiles, file];
        this._dispatchUpload(idCase, file, path, attachment);
      }
    });
  }

  /* ***************** */
  /* Private Interface */
  /* ***************** */

  private _dispatchDelete(idCase: string, idCaseFile: string) {
    this.store.dispatch(removeFile({ idCase, idCaseFile }));
  }

  private _dispatchUpload(
    idCase: string,
    file: File,
    path?: string,
    attachment?: string
  ) {
    this.store.dispatch(uploadFile({ idCase, path, file, attachment }));
  }
}
