import { reviseControls } from '@angular-prelude/forms';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { CurrentVersionContent, PendingPayment, PendingPaymentItem, VersionContent } from '@markmachine/features/version/models/version-content.model';
import { isEmpty, isEqual } from 'lodash-es';

const TEMPLATE = new PendingPayment();
const TEMPLATE_ITEM = new PendingPaymentItem();

interface IntermediatePendingPaymentItems {
  [code: number]: Omit<PendingPaymentItem, 'code'>;
}

@Injectable({
  providedIn: 'root',
})
export class CaseFormPendingPaymentsService {
  constructor(private fb: FormBuilder) {}

  /**
   * Update FromGroup from change to values
   * @param array FormArray for classes
   * @param values New values for classes
   */
  update(group: FormGroup, values: VersionContent, current: CurrentVersionContent): void {
    const initialValues = { ...TEMPLATE, ...values['pending-payments'] };
    const payments = this.pendingPayments(values, current);
    const rest = this.drivableValues(payments);
    const patchValues = { ...initialValues, ...rest, payments };
    // TODO: Preserve payment counts from group.value
    const shouldRevise = !isEqual(patchValues, group.value);
    if (shouldRevise) {
      reviseControls(group, patchValues);
    }
    this.mutate(group, patchValues);
  }

  drivableValues(payments: PendingPaymentItem[]): Partial<PendingPayment> {
    const KEYS: { [key: number]: keyof PendingPayment } = {
      7006: 'requests-to-divide',
      7007: 'additional-classes',
      7008: 'teas-plus-failures',
      7009: 'additional-classes',
      9101: 'chargebacks',
    };
    const paymentEntries = payments
      .filter(({ code }) => code)
      .map(({ code, count }) => [KEYS[code], count]);
    return Object.fromEntries(paymentEntries);
  }

  classFeesPaid(values: CurrentVersionContent, feeCode: number): number {
    return values['fee-types']?.find((t) => t['fee-code'] === feeCode)?.['number-of-classes-paid'] ?? 0;
  }

  /** Calculate the payments and natural quantities for those payments. */
  pendingPayments(values: VersionContent, current: CurrentVersionContent): PendingPaymentItem[] {
    const { payments } = values['pending-payments'] ?? new PendingPayment();
    // Translate from array form to map form for fast access/update; we'll reverse this at the end.
    // This translation isn't strictly necessary, but it avoids a lot of linear searches and nullchecks.
    const result: IntermediatePendingPaymentItems = Object.fromEntries(
      payments.map(({ code, ...rest }) => [code, rest])
    );

    const isNewApp = !values['case-file-header']['serial-number'];
    const { currentlyAsTeasPlusApp, filedAsTeasPlusApp } = values['case-file-header'];
    const lostPlusStatus = !currentlyAsTeasPlusApp && filedAsTeasPlusApp;
    const isPlus = values['case-file-header']['application-type'] === 'plus' || currentlyAsTeasPlusApp;

    const classCount = values['classes']?.length ?? 0;
    const paidPlus = this.classFeesPaid(current, 7007);
    const paidStandard = this.classFeesPaid(current, 7009);
    const paidRegular = this.classFeesPaid(current, 7001);
    const paidDeficiency = this.classFeesPaid(current, 7008);
    // TEAS Plus
    if (isPlus) {
      // Not TEAS Standard, so remove and TEAS Standard-related fees:
      this.deleteFee(result, 7008);
      this.deleteFee(result, 7009);
      // Still PLUS, but allowably added a class
      if (classCount > paidPlus) {
        const unpaidPlusClasses = classCount - paidPlus;
        this.modifyFee(result, 7007, unpaidPlusClasses);
      } else {
        this.deleteFee(result, 7007);
      }
    } else {
      // Not TEAS PLUS, so remove any TEAS PLUS-related fees:
      this.deleteFee(result, 7007);
      // Must have been PLUS, but no longer
      if (lostPlusStatus) {
        const unpaidPlusClasses = paidPlus - paidDeficiency;
        // TEAS Plus Deficiencies per class
        this.modifyFee(result, 7008, unpaidPlusClasses);
      } else {
        this.deleteFee(result, 7008);
      }
      // More classes now than originally paid for?
      const paidStandardClasses = paidStandard + paidRegular + paidPlus;
      if (classCount > paidStandardClasses) {
        this.modifyFee(result, 7009, classCount - paidStandardClasses);
      } else {
        this.deleteFee(result, 7009);
      }
    }

    // Add chargebacks if not a new app
    if (!isNewApp) {
      this.modifyFee(result, 9101, 0);
    } else {
      this.deleteFee(result, 9101);
    }

    const allegations = values['allegations-of-use']?.filter((aou) => aou['allegation-of-use'] !== 'delete-class');
    if (allegations && allegations.length > 0) {
      this.modifyFee(result, 7003, allegations.length);
    } else {
      this.deleteFee(result, 7003);
    }

    const extensions = values['extension-requests']?.filter((ext) => ext['allegation-of-use-ext-req'] !== 'delete-class');
    if (extensions && extensions.length > 0) {
      this.modifyFee(result, 7004, extensions.length);
    } else {
      this.deleteFee(result, 7004);
    }

    const renewals89 = values['renewals-89']?.filter((renew) => renew['renewal-89'] !== 'delete-class');
    if (renewals89 && renewals89.length > 0) {
      this.modifyFee(result, 7205, renewals89.length);
      this.modifyFee(result, 7201, renewals89.length);
      const gracePeriod = !!values['renewals-89'].find(cls => cls['$renewal-89-grace-period']);
      if (gracePeriod) {
        this.modifyFee(result, 7203, renewals89.length);
      } else {
        this.deleteFee(result, 7203);
      }
    } else {
      this.deleteFee(result, 7205, 7201, 7203);
    }

    const renewals815 = values['renewals-815']?.filter((renew) => renew['renewal-815'] !== 'delete-class');
    if (renewals815 && renewals815.length > 0) {
      this.modifyFee(result, 7205, renewals815.length);
      this.modifyFee(result, 7208, renewals815.length);
      const gracePeriod = !!values['renewals-815'].find(cls => cls['$renewal-815-grace-period']);
      if (gracePeriod) {
        this.modifyFee(result, 7206, renewals815.length);
      } else {
        this.deleteFee(result, 7206);
      }
    } else {
      this.deleteFee(result, 7205, 7208, 7206);
    }

    return Object
      .entries(result) // Get control values as key-value pairs
      .sort(([a, _], [b, __]) => a < b ? -1 : +(a > b)) // Provide a stable ordering for controls
      // Translate back to array form
      .map(([code, rest]) => ({ ...rest, code: parseInt(code, 10) } as PendingPaymentItem));
  }

  /** Provide an autoCount value and a fallback for initial count. */
  modifyFee(result: IntermediatePendingPaymentItems, code: number, autoCount: number): void {
    if (result[code]?.autoCount !== autoCount) {
      // Upsert the autoCount value, supplying initial values if the fee code is new:
      const { code: _, ...init } = { ...TEMPLATE_ITEM, count: autoCount };
      result[code] = { ...init, ...result[code], autoCount };
    }
  }

  deleteFee(result: IntermediatePendingPaymentItems, ...codes: number[]): void {
    for (const code of codes) {
      delete result[code];
    }
  }

  mutate(group: FormGroup, values: PendingPayment): void {
    this.updatePristinePayments(group);
  }

  /** For each payment group that's pristine, update its count. */
  updatePristinePayments(group): void {
    const paymentGroups = group.get('payments').controls as FormGroup[];
    for (const paymentGroup of paymentGroups as FormGroup[]) {
      const {
        count: { value: count, pristine },
        autoCount: { value: autoCount },
        _isManual: { value: isManual }
      } = paymentGroup.controls;

      if (pristine && !isManual && count !== autoCount) {
        // If the count control is pristine, not manual, and different from autoCount, update it.
        paymentGroup.patchValue({ count: autoCount });
      } else if (!pristine && !isManual) {
        // If the control isn't pristine, then change this fee's controls to manual mode.
        paymentGroup.patchValue({ _isManual: true });
      }
    }
  }
}
