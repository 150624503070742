/**
 * Register icons with the Material Icon Registery
 *
 * This service isn't used directly in any component. It's instanced once by CoreModule to
 * register new SVG icons so they can be used with <mat-icon> in templates, like so:
 *
 *     <mat-icon class="title" svgIcon="markmachine-title"></mat-icon>
 */
import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

const icons = [
  {
    name: 'markmachine-gear',
    path: '/assets/icons/markmachine-gear.svg'
  },
  {
    name: 'markmachine-gearset',
    path: '/assets/icons/markmachine-gearset.svg'
  },
  {
    name: 'markmachine-title',
    path: '/assets/icons/markmachine-title.svg'
  },
  {
    name: 'markmachine-search',
    path: '/assets/icons/markmachine-search.svg'
  },
  {
    name: 'markmachine-apply',
    path: '/assets/icons/markmachine-apply.svg'
  },
  {
    name: 'markmachine-docket',
    path: '/assets/icons/markmachine-docket.svg'
  },
  {
    name: 'markmachine-fix',
    path: '/assets/icons/markmachine-fix.svg'
  },
  {
    name: 'markmachine-manage',
    path: '/assets/icons/markmachine-manage.svg'
  },
  {
    name: 'markmachine-guide',
    path: '/assets/icons/markmachine-guide.svg'
  },
  {
    name: 'markmachine-notes',
    path: '/assets/icons/markmachine-notes.svg'
  },
  {
    name: 'outlined_check_box',
    path: '/assets/icons/check_box-black-18dp.svg'
  },
];

@Injectable()
export class IconService {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    icons.forEach(({ name, path }) => {
      iconRegistry.addSvgIcon(
        name,
        sanitizer.bypassSecurityTrustResourceUrl(path)
      );
    });
  }
}
