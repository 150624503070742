import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';

/* Helper function to get a control given it's full path starting from an arbitrary control in the tree */
function getControlFromFullPath(control: AbstractControl, fullPath: string): AbstractControl {
  let root = control;
  let breaker = 0;
  while (root.parent) {
    root = root.parent;
    breaker += 1;
    // just in case
    if (breaker > 100) {
      break;
    }
  }
  if (breaker > 100) {
    console.warn('There was a problem getting the root control to access the control at path ' + fullPath);
    console.warn(control);
    // might be better to return some kind of error here
    return null;
  }
  return root.get(fullPath);
}

/********************************************************
 * Generic validators testing path value for truthiness *
 ********************************************************/

/* Used to check a control value based on the full path starting from the root form group */
function requiredIfPathValue(fullPath: string): ValidatorFn {
  return (control) => {
    const fullPathControl = getControlFromFullPath(control, fullPath);
    if (fullPathControl?.value && !control.value) {
      return { required: true };
    }
    return null;
  };
}

/* Used to check a control value based on a path starting at the parent */
function requiredIfParentPathValue(path: string): ValidatorFn {
  return (control) => {
    if (control.parent?.get(path)?.value && !control.value) {
      return { required: true };
    }
    return null;
  };
}

/********************************************
 * Custom validators testing special values *
 ********************************************/

/* Only required if the minor emancipated control value is 'No' */
function requiredIfMinorIsNotEmancipated(): ValidatorFn {
  return (control) => {
    const minorEmancipatedControl = getControlFromFullPath(control, 'statements.miscellaneous.$minor-applicant-eligible');
    if (minorEmancipatedControl?.value === 'No' && !control.value) {
      return { required: true };
    }
    return null;
  };
}

/* Only required if the mark type is 'design-mark' */
function requiredIfMarkTypeSpecialForm(): ValidatorFn {
  return (control) => {
    if (control.parent?.value?.kind === 'design-mark' && !control.value) {
      return { required: true };
    }
    return null;
  };
}

/* Only required if the mark type is 'typed-mark' */
function requiredIfMarkTypeStandardCharacters(): ValidatorFn {
  return (control) => {
    if (getControlFromFullPath(control, 'mark.kind')?.value === 'typed-mark' && !control.value) {
      return { required: true };
    }
    return null;
  };
}

/* Only required if the owner entity type is 'Individual' */
function requiredIfOwnerEntityTypeIndividual(): ValidatorFn {
  return (control) => {
    if (control.parent?.get('$entity-type')?.value === 'Individual' && !control.value) {
      return { required: true };
    }
    return null;
  };
}

/* Only required if the owner entity type is not 'Individual' */
function requiredIfOwnerEntityNotIndividual(): ValidatorFn {
  return (control) => {
    if (control.parent?.get('$entity-type')?.value !== 'Individual' && !control.value) {
      return { required: true };
    }
    return null;
  };
}

function requiredIfOwnerPartnership(): ValidatorFn {
  return (control) => {
    if (
      (control.parent?.get('$entity-type')?.value === 'Partnership' ||
        control.parent?.get('$entity-type')?.value === 'Limited Partnership' ||
        control.parent?.get('$entity-type')?.value === 'Joint Venture' ||
        control.parent?.get('$entity-type')?.value === 'Sole Proprietorship' ||
        control.parent?.get('$entity-type')?.value === 'Trust' ||
        control.parent?.get('$entity-type')?.value === 'Estate') &&
      !control.value
    ) {
      return { required: true };
    }
    return null;
  };
}

/* Only required if the owner entity type is not 'Individual' and country is 'US' */
function requiredIfOwnerEntityUSOrg(): ValidatorFn {
  return (control) => {
    // US organization that isn't a corporation or an individual
    if (
      control.parent?.get('$entity-type')?.value !== 'Individual' &&
      control.parent?.get('$entity-type')?.value !== 'Corporation' &&
      control.parent?.get('$entity-country')?.value === 'United States (USX)' &&
      !control.value
    ) {
      return { required: true };
    }
    return null;
  };
}

function requiredIfOwnerEntityUSInc(): ValidatorFn {
  return (control) => {
    // US Corporation
    if (
      control.parent?.get('$entity-type')?.value === 'Corporation' &&
      control.parent?.get('$entity-country')?.value === 'United States (USX)' &&
      !control.value
    ) {
      return { required: true };
    }
    return null;
  };
}

/* Postal code must be 5 numerals if US,  otherwise up to 9 numerals */
function postalCodeFormat(countryNamePath: string): ValidatorFn {
  // NNNNN or NNNNN-NNNN
  return (control) => {
    let countryNameControl: AbstractControl;
    if (countryNamePath === 'owners.country-name') {
      // owners are part of an array so defining the full path would
      // require knowing which array item the input control comes from
      // and navigating through parent is simpler
      countryNameControl = control.parent?.get('country-name');
    } else {
      countryNameControl = getControlFromFullPath(control, countryNamePath);
    }

    if (countryNameControl?.value === 'United States (USX)') {
      // must be 5 numerals
      return Validators.pattern('[0-9]{5}')(control);
    } else {
      return Validators.pattern('[0-9]{5}-[0-9]{4}')(control);
    }
  };
}

function requiredIfSpecimenFor1a(): ValidatorFn {
  return (control) => {
    if (control.value) {
      return null;
    }
    // Expect control is `classes.specimen.description-text` or a sibling.
    // So, walk up to class instance (member of FormArray) and grab 1a basis control.
    const classFormGroup = control.parent?.parent;
    const is1aBasis = !!classFormGroup?.get?.('filing-basis-current-1a-in')?.value;
    return is1aBasis ? { required: true } : null;
  };
}

/**************************************************
 * Custom validators for Acquired Distinctiveness *
 **************************************************/

function wholeBasedOnRequired(): ValidatorFn {
  return (control) => {
    console.log('wholeBasedOnRequired');
    // if 2F_WHOLE is selected, one or more of the "whole-based-on-*" controls
    // must be selected
    // if 2F_PART is selected, one or more of the "based-on-*" controls
    // must be selected
    const acquiredDistinctivenessType = getControlFromFullPath(control, 'statements.acquired-distinctiveness.whole-or-in-part').value;
    console.log('ad type: ' + acquiredDistinctivenessType);

    // controls for type 2F_WHOLE
    const wholeBasedOnUseControl = getControlFromFullPath(control, 'statements.acquired-distinctiveness.whole-based-on-use');
    const wholeBasedOnRegistrationControl = getControlFromFullPath(
      control,
      'statements.acquired-distinctiveness.whole-based-on-registration'
    );
    const wholeBasedOnEvidenceCollectiveControl = getControlFromFullPath(
      control,
      'statements.acquired-distinctiveness.whole-based-on-evidence'
    );

    // part based on controls
    const basedOnUseControl = getControlFromFullPath(control, 'statements.acquired-distinctiveness.based-on-use');
    const basedOnRegistrationControl = getControlFromFullPath(control, 'statements.acquired-distinctiveness.based-on-registration');
    const basedOnEvidenceCollectiveControl = getControlFromFullPath(control, 'statements.acquired-distinctiveness.based-on-evidence');

    if (acquiredDistinctivenessType === '2F_WHOLE') {
      if (!wholeBasedOnUseControl.value && !wholeBasedOnRegistrationControl.value && !wholeBasedOnEvidenceCollectiveControl.value) {
        return { required: null };
      }
    } else if (acquiredDistinctivenessType === '2F_PART') {
      if (!basedOnUseControl.value && !basedOnRegistrationControl.value && !basedOnEvidenceCollectiveControl.value) {
        return { required: null };
      }
    }
  };
}

function basedOnUseMarkTypeRequired(basedOnWhole): ValidatorFn {
  return (control) => {
    let basedOnUse: any;
    let trademarkOrCollectiveControl: AbstractControl;
    if (basedOnWhole) {
      basedOnUse = getControlFromFullPath(control, 'statements.acquired-distinctiveness.whole-based-on-use').value;
      trademarkOrCollectiveControl = getControlFromFullPath(
        control,
        'statements.acquired-distinctiveness.whole-based-on-use-trademark-or-collective'
      );
    } else {
      basedOnUse = getControlFromFullPath(control, 'statements.acquired-distinctiveness.based-on-use').value;
      trademarkOrCollectiveControl = getControlFromFullPath(
        control,
        'statements.acquired-distinctiveness.based-on-use-trademark-or-collective'
      );
    }
    if (basedOnUse && !trademarkOrCollectiveControl?.value) {
      return { required: null };
    }
    return null;
  };
}

/************
 * Registry *
 ************/
export const validatorRegistry = {
  /* Owners */
  // tslint:disable: quotemark
  'owners.name': {
    type: 'sync',
    validators: [Validators.required],
    fieldName: 'Owner Name',
    errorMessage: "Please enter the owner's name.",
  },
  'owners.dba-aka-text': {
    type: 'sync',
    validators: [requiredIfParentPathValue('dba-aka-kind')],
    fieldName: 'Alias Name',
    errorMessage: 'Please enter the alias.',
  },
  'owners.$entity-type': {
    type: 'sync',
    validators: [Validators.required],
    fieldName: 'Owner Entity Type',
    errorMessage: "Please enter the owner's entity type.",
  },
  'owners.legal-entity-type-code': {
    type: 'sync',
    validators: [Validators.required],
    fieldName: 'Owner Entity Type',
    errorMessage: "Please enter the owner's entity type.",
  },
  'owners.$entity-country': {
    type: 'sync',
    validators: [requiredIfOwnerEntityNotIndividual()],
    fieldName: 'Owner Country of Organization',
    errorMessage: "Please enter the owner's country of organization.",
  },
  'owners.citizenship-country-name': {
    type: 'sync',
    validators: [requiredIfOwnerEntityTypeIndividual()],
    fieldName: 'Owner Country of Citizenship',
    errorMessage: "Please enter the owner's country of citizenship.",
  },
  'owners.street': {
    type: 'sync',
    validators: [Validators.required, Validators.maxLength(40)],
    fieldName: 'Owner Mailing Address Street',
    errorMessage: "Please enter the owner's mailing address street.",
  },
  'owners.city': {
    type: 'sync',
    validators: [Validators.required, Validators.maxLength(22)],
    fieldName: 'Owner Mailing Address City',
    errorMessage: "Please enter the owner's mailing address city.",
  },
  'owners.state': {
    type: 'sync',
    validators: [Validators.required],
    fieldName: 'Owner Mailing Address State',
    errorMessage: "Please enter the owner's mailing address state.",
  },
  'owners.state-organized': {
    type: 'sync',
    validators: [requiredIfOwnerEntityUSOrg()],
    fieldName: 'Owner State of Organization',
    errorMessage: "Please enter the owner's state of organization.",
  },
  'owners.incorporated-in-state-code': {
    type: 'sync',
    validators: [requiredIfOwnerEntityUSInc()],
    fieldName: 'Owner State of Incorporation',
    errorMessage: "Please enter the owner's state of incorporation.",
  },
  'owners.country-name': {
    type: 'sync',
    validators: [Validators.required],
    fieldName: 'Owner Mailing Address Country',
    errorMessage: "Please enter the owner's mailing address country.",
  },
  'owners.postal-code': {
    type: 'sync',
    validators: [Validators.required, postalCodeFormat('owners.country-name')],
    fieldName: 'Owner Mailing Address Zip Code',
    errorMessage: "Please enter the owner's mailing address postal/zip code.",
  },
  'owners.email-address': {
    type: 'sync',
    validators: [Validators.required, Validators.email],
    fieldName: 'Owner Email Address',
    errorMessage: "Please enter the owner's email address.",
  },
  // Phone Country needs to be addressed: this field is currently only accessible in new apps.  Instead, we'd like to provide it all the time, but we'll need to adjust the how phone numbers are stored.
  // "owners.phone-country": {
  //   "type": "sync",
  //   "validators": [Validators.required],
  //   "fieldName": "Owner Phone Country",
  //   "errorMessage": "Please enter the country for the owner's phone number."
  // },
  'owners.general-partner-name': {
    type: 'sync',
    validators: [requiredIfOwnerPartnership()],
    fieldName: 'Owner General Partner Name',
    errorMessage: 'Please enter the name and citizenship of all general partners, active members, individuals, trustees, or executors.',
  },

  /* Mark */
  'mark.kind': {
    type: 'sync',
    validators: [Validators.required],
    fieldName: 'Mark Type',
    errorMessage: 'Please select a mark type.',
  },
  'mark.mark-text': {
    type: 'sync',
    validators: [requiredIfMarkTypeStandardCharacters()],
    fieldName: 'Mark Text',
    errorMessage: "Please enter the mark's literal elements.",
  },
  'mark.color-claim': {
    type: 'sync',
    validators: [Validators.maxLength(1000)],
    fieldName: 'Mark Color Claim',
    errorMessage: 'Maximum length is 1,000 characters.',
  },
  'mark.mark-description': {
    type: 'sync',
    validators: [requiredIfMarkTypeSpecialForm()],
    fieldName: 'Mark Description',
    errorMessage: 'Please enter a description of the mark.',
  },

  /* Classes */
  'classes.class-code': {
    type: 'sync',
    validators: [Validators.required],
    fieldName: 'Classes',
    errorMessage: 'Please select at least one class.',
  },
  'classes.listing': {
    type: 'sync',
    validators: [Validators.required, Validators.minLength(3), Validators.maxLength(1000)],
    fieldName: 'Good/Service',
    errorMessage: 'Please select at least one good/service and the description must be at least 3 and no more than 1,000 characters.',
  },

  /* Classes 1a */
  'classes.first-use-anywhere-date': {
    type: 'sync',
    validators: [requiredIfParentPathValue('filing-basis-current-1a-in'), Validators.pattern('[0-9]{8}')],
    fieldName: 'Date of First Use (1a)',
    errorMessage: 'Please enter the Date of First Use (MM/DD/YYYY).',
  },
  'classes.first-use-in-commerce-date': {
    type: 'sync',
    validators: [requiredIfParentPathValue('filing-basis-current-1a-in'), Validators.pattern('[0-9]{8}')],
    fieldName: 'Date of First Use in Commerce (1a)',
    errorMessage: 'Please enter the Date of First Use in Commerce (MM/DD/YYYY).',
  },
  'classes.specimen-files': {
    type: 'sync',
    validators: [requiredIfParentPathValue('filing-basis-current-1a-in')],
    fieldName: 'Specimens (1a)',
    errorMessage: 'Please attach specimen(s).',
  },
  'classes.specimen.description-text': {
    type: 'sync',
    validators: [requiredIfSpecimenFor1a()],
    fieldName: 'Specimen Description (1a)',
    errorMessage: 'Please enter a description of the specimen(s).',
  },

  /* Classes 44d */
  'classes.foreign-application-country-name': {
    type: 'sync',
    validators: [requiredIfParentPathValue('filing-basis-current-44d-in')],
    fieldName: 'Foreign Application Country (44d)',
    errorMessage: 'Please enter the foreign application country.',
  },
  'classes.foreign-application-number': {
    type: 'sync',
    validators: [requiredIfParentPathValue('filing-basis-current-44d-in')],
    fieldName: 'Foreign Application Number (44d)',
    errorMessage: 'Please enter the foreign application number.',
  },
  'classes.foreign-filing-date': {
    type: 'sync',
    validators: [requiredIfParentPathValue('filing-basis-current-44d-in'), Validators.pattern('[0-9]{8}')],
    fieldName: 'Foreign Application Filing Date  (44d)',
    errorMessage: 'Please enter the foreign application filing date (MM/DD/YYYY).',
  },
  'classes.foreign-application-priority-only': {
    type: 'sync',
    validators: [requiredIfParentPathValue('filing-basis-current-44d-in')],
    fieldName: 'Intention of Priority Claim (44d)',
    errorMessage: 'Please select a statement to describe the intention of the priority claim for the 44(d) basis.',
  },

  /* Classes 44e */
  'classes.foreign-registration-country-name': {
    type: 'sync',
    validators: [requiredIfParentPathValue('filing-basis-current-44e-in')],
    fieldName: 'Foreign Registration Country (44e)',
    errorMessage: 'Please enter the foreign registration country.',
  },
  'classes.foreign-registration-date': {
    type: 'sync',
    validators: [requiredIfParentPathValue('filing-basis-current-44e-in'), Validators.pattern('[0-9]{8}')],
    fieldName: 'Foreign Registration Date (44e)',
    errorMessage: 'Please enter the foreign registration date (MM/DD/YYYY).',
  },
  'classes.foreign-registration-number': {
    type: 'sync',
    validators: [requiredIfParentPathValue('filing-basis-current-44e-in'), Validators.maxLength(19)],
    fieldName: 'Foreign Registration Number (44e)',
    errorMessage: 'Please enter the foreign registration number.',
  },
  'classes.foreign-renewal-date': {
    type: 'sync',
    validators: [requiredIfParentPathValue('filing-basis-current-44e-in'), Validators.pattern('[0-9]{8}')],
    fieldName: 'Foreign Renewal Date (44e)',
    errorMessage: 'Please enter the foreign renewal date (MM/DD/YYYY).',
  },
  'classes.foreign-expiration-date': {
    type: 'sync',
    validators: [requiredIfParentPathValue('filing-basis-current-44e-in'), Validators.pattern('[0-9]{8}')],
    fieldName: 'Foreign Expiration Date (44e)',
    errorMessage: 'Please enter the foreign expiration date (MM/DD/YYYY).',
  },
  'classes.foreign-registration-files': {
    type: 'sync',
    validators: [requiredIfParentPathValue('filing-basis-current-44e-in')],
    fieldName: 'Foreign Registration Certificate (44e)',
    errorMessage: 'Please provide a foreign registration certificate.',
  },

  /* Arguments */
  'argument.argument-plaintext': {
    type: 'sync',
    validators: [Validators.maxLength(10000)],
    fieldName: 'Argument',
    errorMessage: 'Maximum length is 10,000 characters.',
  },

  /* Correspondent - Attorney */
  'correspondent-attorney.name': {
    type: 'sync',
    validators: [requiredIfPathValue('correspondent.has-attorney')],
    fieldName: 'Attorney Name',
    errorMessage: "Please enter the attorney's name.",
  },
  'correspondent-attorney.attorney-docket-number': {
    type: 'sync',
    validators: [Validators.maxLength(12)],
    fieldName: 'Attorney Docket Number',
    errorMessage: 'Docket number maximum length is 12.',
  },
  'correspondent-attorney.attorney-bar-admission-year': {
    type: 'sync',
    validators: [requiredIfPathValue('correspondent.has-attorney')],
    fieldName: 'Correspondent/Attorney Bar Admission Year',
    errorMessage: 'Correspondent/Attorney Bar Admission Year is required and must be four digits.',
  },
  'correspondent-attorney.attorney-bar-state': {
    type: 'sync',
    validators: [requiredIfPathValue('correspondent.has-attorney')],
    fieldName: 'Correspondent/Attorney Bar State',
    errorMessage: "Please enter the attorney's bar membership state.",
  },
  'correspondent-attorney.attorney-bar-number': {
    type: 'sync',
    validators: [requiredIfPathValue('correspondent.has-attorney'), Validators.maxLength(40)],
    fieldName: 'Correspondent/Attorney Bar Member Number',
    errorMessage: 'Please enter the attorney’s bar member number/ID (enter "N/A" if state does not issue bar membership numbers).',
  },
  'correspondent-attorney.attorney-bar-active': {
    type: 'sync',
    validators: [requiredIfPathValue('correspondent.has-attorney')],
    fieldName: 'Correspondent/Attorney Bar Active',
    errorMessage: 'Please provide the statement indicating that the attorney is in good standing.',
  },
  'correspondent-attorney.internal-address': {
    type: 'sync',
    validators: [Validators.maxLength(40)],
    fieldName: 'Correspondent/Attorney Internal Address',
    errorMessage: 'Correspondent/Attorney Internal Address maximum length is 40.',
  },
  'correspondent-attorney.street': {
    type: 'sync',
    validators: [requiredIfPathValue('correspondent.has-attorney'), Validators.maxLength(40)],
    fieldName: 'Correspondent/Attorney Street',
    errorMessage: "Please enter the attorney's street address.",
  },
  'correspondent-attorney.city': {
    type: 'sync',
    validators: [requiredIfPathValue('correspondent.has-attorney'), Validators.maxLength(22)],
    fieldName: 'Correspondent/Attorney City',
    errorMessage: "Please enter the attorney's mailing address city.",
  },
  'correspondent-attorney.state': {
    type: 'sync',
    validators: [requiredIfPathValue('correspondent.has-attorney')],
    fieldName: 'Correspondent/Attorney State',
    errorMessage: "Please enter the attorney's mailing address state.",
  },
  'correspondent-attorney.country-name': {
    type: 'sync',
    validators: [requiredIfPathValue('correspondent.has-attorney')],
    fieldName: 'Correspondent/Attorney Country',
    errorMessage: "Please enter the attorney's mailing address country.",
  },
  'correspondent-attorney.postal-code': {
    type: 'sync',
    validators: [requiredIfPathValue('correspondent.has-attorney'), postalCodeFormat('correspondent-attorney.country-name')],
    fieldName: 'Correspondent/Attorney Postal Code',
    errorMessage: "Please enter the attorney's mailing address postal/zip code.",
  },
  'correspondent-attorney.email-address': {
    type: 'sync',
    validators: [requiredIfPathValue('correspondent.has-attorney'), Validators.email],
    fieldName: 'Correspondent/Attorney Email Address',
    errorMessage: "Please enter the attorney's email address.",
  },

  /* Correspondent */
  'correspondent.email-addresses-secondary': {
    type: 'sync',
    validators: [Validators.email],
    fieldName: 'Secondary Email Address',
    errorMessage: 'Please make sure the email address is in the correct format.',
  },
  'correspondent.attorney-docket-number': {
    type: 'sync',
    validators: [Validators.maxLength(12)],
    fieldName: 'Correspondent Attorney Docket Number',
    errorMessage: 'Docket Number maximum length is 12.',
  },

  /* Domestic Representative */
  'correspondent-domestic-representative.name': {
    type: 'sync',
    validators: [requiredIfPathValue('correspondent.has-domestic-representative')],
    fieldName: 'Domestic Representative Name',
    errorMessage: "Please enter the domestic representative's name.",
  },
  'correspondent-domestic-representative.email-address': {
    type: 'sync',
    validators: [requiredIfPathValue('correspondent.has-domestic-representative')],
    fieldName: 'Domestic Representative Email Address',
    errorMessage: "Please enter the domestic representative's email address.",
  },
  'correspondent-domestic-representative.street': {
    type: 'sync',
    validators: [requiredIfPathValue('correspondent.has-domestic-representative')],
    fieldName: 'Domestic Representative Street Address',
    errorMessage: "Please enter the domestic representative's street address.",
  },
  'correspondent-domestic-representative.city': {
    type: 'sync',
    validators: [requiredIfPathValue('correspondent.has-domestic-representative')],
    fieldName: 'Domestic Representative Mailing Address City',
    errorMessage: "Please enter the domestic representative's mailing address city.",
  },
  'correspondent-domestic-representative.state': {
    type: 'sync',
    validators: [requiredIfPathValue('correspondent.has-domestic-representative')],
    fieldName: 'Domestic Representative Mailing Address State',
    errorMessage: "Please enter the domestic representative's mailing address state.",
  },
  'correspondent-domestic-representative.postal-code': {
    type: 'sync',
    validators: [requiredIfPathValue('correspondent.has-domestic-representative')],
    fieldName: 'Domestic Representative Email Address',
    errorMessage: "Please enter the domestic representative's email address.",
  },
  'correspondent-domestic-representative.attorney-docket-number': {
    type: 'sync',
    validators: [Validators.maxLength(12)],
    fieldName: 'Correspondent Domestic Representative Docket Number',
    errorMessage: 'Docket Number maximum length is 12.',
  },

  /* Statements */
  'statements.living-consent.individuals': {
    type: 'sync',
    validators: [requiredIfPathValue('statements.living-consent-unnecessary')],
    fieldName: 'Living Individual',
    errorMessage: 'Please enter the name of the living individual(s).',
  },
  'statements.significance.significant-meaning': {
    type: 'sync',
    validators: [requiredIfPathValue('statements.significance.significant-text')],
    fieldName: 'Significant Meaning',
    errorMessage: 'Please enter the meaning/significance.',
  },
  'statements.translation.translation': {
    type: 'sync',
    validators: [requiredIfParentPathValue('foreign-words')],
    fieldName: 'Translation',
    errorMessage: 'Please enter the translation.',
  },
  'statements.transliteration.translation': {
    type: 'sync',
    validators: [requiredIfPathValue('statements.transliteration.characters-with-translation')],
    fieldName: 'Transliteration',
    errorMessage: 'Please enter the translation.',
  },
  'statements.active-prior-registrations-1': {
    type: 'sync',
    validators: [Validators.pattern('[0-9]{7}')],
    fieldName: 'Active Prior Registrations',
    errorMessage: 'Active Prior Registration number(s) must be 7 numerals long.',
  },
  'statements.active-prior-registrations-2': {
    type: 'sync',
    validators: [Validators.pattern('[0-9]{7}')],
    fieldName: 'Active Prior Registrations',
    errorMessage: 'Active Prior Registration number(s) must be 7 numerals long.',
  },
  'statements.active-prior-registrations-3': {
    type: 'sync',
    validators: [Validators.pattern('[0-9]{7}')],
    fieldName: 'Active Prior Registrations',
    errorMessage: 'Active Prior Registration number(s) must be 7 numerals long.',
  },
  /* Miscellaneous */
  'statements.miscellaneous.$banking-institution-state-law': {
    type: 'sync',
    validators: [requiredIfPathValue('statements.miscellaneous.$owner-is-banking-institution')],
    fieldName: 'Banking Institution State Law',
    errorMessage: 'Please enter whether the bank is organized under state law.',
  },
  'statements.miscellaneous.$owner-federally-chartered-bank': {
    type: 'sync',
    validators: [requiredIfPathValue('statements.miscellaneous.$owner-is-banking-institution')],
    fieldName: 'Banking Institution Federally Chartered',
    errorMessage: 'Please enter whether the bank is a federally chartered bank.',
  },
  'statements.miscellaneous.$banking-institution-US-state': {
    type: 'sync',
    validators: [requiredIfPathValue('statements.miscellaneous.$banking-institution-state-law')],
    fieldName: 'Banking Institution State',
    errorMessage: 'Please enter which state the bank is organized under.',
  },
  'statements.miscellaneous.$g-s-geo-connection-text': {
    type: 'sync',
    validators: [requiredIfPathValue('statements.miscellaneous.$geo-significance')],
    fieldName: 'Geographical Significance',
    errorMessage: 'Please enter the portion of the mark with no geographical significance.',
  },
  'statements.miscellaneous.$minor-applicant-eligible': {
    type: 'sync',
    validators: [requiredIfPathValue('statements.miscellaneous.$minor-applicant')],
    fieldName: 'Minor Applicant Status',
    errorMessage: 'Please provide the emancipation status of the minor applicant.',
  },
  'statements.miscellaneous.$minor-applicant-parent': {
    type: 'sync',
    validators: [requiredIfMinorIsNotEmancipated()],
    fieldName: 'Minor Applicant Parent',
    errorMessage: 'Please provide the name of parent or legal guardian.',
  },
  'statements.miscellaneous.$certification-statement-part-two': {
    type: 'sync',
    validators: [requiredIfPathValue('statements.miscellaneous.$certification-statement-part-one')],
    fieldName: 'Certification Statement',
    errorMessage: 'Please provide the Certification Statement.',
  },
  'statements.miscellaneous.$varietal-patent': {
    type: 'sync',
    validators: [requiredIfPathValue('statements.miscellaneous.$varietal')],
    fieldName: 'Varietal Patent Information',
    errorMessage: 'Please enter the patent information for the varietal name.',
  },
  'statements.miscellaneous.$varietal-patent-no': {
    type: 'sync',
    validators: [requiredIfPathValue('statements.miscellaneous.$varietal')],
    fieldName: 'Varietal Serial Number(s)',
    errorMessage: 'Please enter the serial numbers(s) for the varietal name.',
  },
  'statements.miscellaneous.$suspension-pending-cancellation-REG-numbers': {
    type: 'sync',
    validators: [requiredIfPathValue('statements.miscellaneous.$suspension-pending-cancellation')],
    fieldName: 'Pending Cancellation Registration Number(s)',
    errorMessage: 'Please enter the challenged registration number(s).',
  },
  'statements.miscellaneous.text': {
    type: 'sync',
    validators: [Validators.maxLength(10000)],
    fieldName: 'Miscellaneous Text Statement',
    errorMessage: 'Maximum length is 10,000 characters.',
  },

  // /* Statements - Acquired Distinctiveness*/
  // "statements.acquired-distinctiveness.whole-or-in-part": {
  //   "type": "sync",
  //   "validators": [wholeBasedOnRequired()],
  //   "fieldName": "Acquired Distinctiveness Based On",
  //   "errorMessage": "Please select what the Acquired Distinctiveness Claim is based on."
  // },
  // "statements.acquired-distinctiveness.whole-based-on-use": {
  //   "type": "sync",
  //   "validators": [basedOnUseMarkTypeRequired(true)],
  //   "fieldName": "Acquired Distinctiveness Mark Type",
  //   "errorMessage": "Please select the type of mark the Acquired Distinctiveness Claim is based on."
  // },
  // "statements.acquired-distinctiveness.based-on-use": {
  //   "type": "sync",
  //   "validators": [basedOnUseMarkTypeRequired(false)],
  //   "fieldName": "Acquired Distinctiveness Mark Type",
  //   "errorMessage": "Please select the type of mark the Acquired Distinctiveness Claim is based on."
  // },
};
