import { asBoolString } from '@markmachine/core/functions/utilities';
import { StatementsMisc } from '@markmachine/features/version/models/version-content.model';

type Values = { [key in keyof StatementsMisc]: any };

type StatementMap = {
  [key in keyof StatementsMisc]: (value: any, values?: Values) => string | null
};

export const MISC_STATEMENTS: StatementMap = {
  '$acquired-distinctiveness-delete': value =>
    asBoolString(value) === 'Yes'
      ? `Applicant requests that the claim of acquired distinctiveness under Trademark Act Section 2(f) be deleted.`
      : null,
  $association: value =>
    value
      ? `Applicant is providing the following association information: ${value}.`
      : null,
  '$banking-institution-state-law': value =>
    asBoolString(value) === 'Yes'
      ? null
      : `The Banking Institution is not organized under state law.`,
  '$banking-institution-US-state': value =>
    value
      ? `The Banking Institution is organized under ${value} state law.`
      : null,
  '$certification-statement-part-one': value =>
    asBoolString(value) === 'Yes'
      ? `The certification mark, as used or intended to be used by those authorized by the certifier, certifies or is intended to ` +
        `certify certain characteristic(s), standard(s), or other feature(s) of the goods/services.`
      : null,
  '$certification-statement-part-two': value =>
    value
      ? `The mark certifies that: ${value}.`
      : null,
  '$cert-app': value =>
    asBoolString(value) === 'Yes'
      ? 'Applicant is seeking registration of a certification mark'
      : null,
  '$clarify-app-type-more': value => value ? `${value}` : null,
  '$collective-mem-app': value =>
    asBoolString(value) === 'Yes'
      ? 'Applicant is seeking registration of a collective membership mark.'
      : null,
  '$collective-tm-or-sm-app': value =>
    asBoolString(value) === 'Yes'
      ? 'Applicant is seeking registration of a collective trademark or service mark.'
      : null,
  '$delete-color-claim': value =>
    asBoolString(value) === 'Yes'
      ? `Please delete the color claim.`
      : null,
  '$delete-unnecessary-entity-details': value =>
    asBoolString(value) === 'Yes'
      ? `Applicant requests deletion of the unnecessary information in the application.`
      : null,
  '$g-s-geo-connection': (value) =>
    asBoolString(value) === 'Yes'
      ? `The goods/services originate from, are manufactured in, packaged in, shipped from, sold in, or have some other ` +
      `connection with the geographical location named/referenced in the mark.`
      : value && asBoolString(value) === 'No'
      ? `The goods/services do not originate from, are not manufactured in, packaged in, shipped from, sold in, or have any other ` +
        `connection with the geographical location.`
      : null,
  '$g-s-geo-connection-text': value =>
    value
      ? `The geographical meaning of the mark is: ${value}`
      : null,
  '$geo-significance': value =>
    asBoolString(value) === 'Yes'
      ? `The mark contains the name, nickname, or graphical representation of a geographic place.`
      : value && asBoolString(value) === 'No'
      ? `The mark does not contain the name, nickname, or graphical representation of a geographic place.`
      : null,
  '$geo-significance-text': (value, values: any) =>
    values['$geo-significance'] && asBoolString(values['$geo-significance']) === 'No'
      ? `The specific portion of the mark that has no geographical significance is: ${value}`
      : null,
  '$govt-univ-applicant': value =>
    value
      ? `Applicant provides the following information requested by the USPTO: ${value}`
      : null,
  '$joint-applicants': value =>
    asBoolString(value) === 'Yes'
      ? `The nature of the relationship of the applicants is that of Joint Applicants.`
      : null,
  '$membership-exercise-of-control-stmt-1': value =>
    value === 'Use'
      ? 'The Applicant has adopted and is exercising legitimate control over the use of the mark in commerce.'
      : value === 'Intent-to-Use'
      ? 'The Applicant has a bona fide intention to exercise legitimate control over the use of the mark in commerce.'
      : null,
  '$membership-exercise-of-control-stmt-2': value =>
    value
      ? `The following is entitled to use the mark:  ${value}`
      : null,
  '$membership-exercise-of-control-stmt-3': value =>
    value
      ? `The relationship between the applicant and those entitled to use the mark is:  ${value}`
      : null,
  '$membership-exercise-of-control-stmt-4': value =>
    value
      ? `The manner in which the applicant controls the members’ use of the mark is:  ${value}`
      : null,
  '$minor-applicant': value =>
    asBoolString(value) === 'Yes'
      ? `The applicant is a minor.`
      : value && asBoolString(value) === 'No'
      ? `The applicant is not a minor.`
      : null,
  '$minor-applicant-eligible': value =>
    asBoolString(value) === 'Yes'
      ? `The applicant is eligible to enter into binding legal obligations and sue or be sued in the state in which the ` +
        `applicant lives.`
      : value && asBoolString(value) === 'No'
      ? `The applicant is not eligible to enter into binding legal obligations and to sue or be sued in the state in which the ` +
        `applicant lives.`
      : null,
  '$minor-applicant-parent': value =>
    value
      ? `The Name of Parent or Legal Guardian is: ${value}`
      : null,
  '$origin-country': value =>
    asBoolString(value) === 'Yes'
      ? (`As of the date of the issuance of the foreign registration, the country that issued the foreign `
        + `registration was also the Applicant’s country of origin.`)
      : value && asBoolString(value) === 'No'
      ? (`As of the date of the issuance of the foreign registration, the country that issued the foreign `
        + `registration was not the Applicant’s country of origin.  Accordingly, the foreign registration `
        + `filing basis has been amended.`)
      : null,
  '$owner-federally-chartered-bank': value =>
    asBoolString(value) === 'Yes'
      ? `The Banking Institution is Federally Chartered.`
      : value && asBoolString(value) === 'No'
      ? `The Banking Institution is not Federally Chartered.`
      : null,
  '$owner-is-banking-institution': value =>
    asBoolString(value) === 'Yes'
      ? `The Applicant is a banking institution.`
      : value && asBoolString(value) === 'No'
      ? `The Applicant is not a banking institution.`
      : null,
  '$requested-goods-services': value =>
    value
      ? `Applicant provides the following statement(s) clarifying the goods/services: ${value}`
      : null,
  '$requested-info': value =>
    value
      ? `Applicant is providing the following statement(s) to fulfill the request for information made by the USPTO: ${value}`
      : null,
  '$single-work-rebuttal': value =>
    asBoolString(value) === 'Yes'
      ? `Applicant hereby provides evidence that the mark identifies a series, has acquired an identity separate from its publication, ` +
        `or is a syndicated column.`
      : null,
  '$specimen-translation': value =>
    value
      ? `Applicant provides the following translation of the wording on the specimen(s): ${value}`
      : null,
  '$suspension-pending-cancellation': value =>
    asBoolString(value) === 'Yes'
      ? `A cancellation proceeding has already been submitted or is being submitted concurrently with this Response.  Applicant requests ` +
        `suspension of the application pending cancellation proceeding(s).`
      : null,
  '$suspension-pending-cancellation-REG-numbers': value =>
    value
      ? `The registration number(s) being challenged is/are:  ${value}`
      : null,
  '$suspension-pending-cancellation-proceeding-no': value =>
    value
      ? `Cancellation Proceeding No. ${value}`
      : null,
  '$suspension-pending-foreign-reg': value =>
    asBoolString(value) === 'Yes'
      ? `The foreign application upon which this application is based is still pending.  Applicant requests suspension of this ` +
        `application until the foreign registration issues and a certified copy of it is available.`
      : null,
  '$tm-or-sm-app': value =>
    asBoolString(value) === 'Yes'
      ? 'Applicant is seeking registration of a trademark or service mark.'
      : null,
  '$trade-name-rebuttal': value =>
    asBoolString(value) === 'Yes'
      ? `Applicant hereby provides evidence that the mark is not used as a trade name only.`
      : null,
  '$translation-remove': value =>
    asBoolString(value) === 'Yes'
      ? `Applicant requests deletion of the translation/transliteration statement(s) currently of record.`
      : null,
  $varietal: value =>
    asBoolString(value) === 'Yes'
      ? `The mark has been used as a varietal or cultivar name.`
      : value && asBoolString(value) === 'No'
      ? `The mark has not been used as a varietal or cultivar name.`
      : null,
  '$varietal-patent': value =>
    asBoolString(value) === 'Yes'
      ? `A plant patent, utility patent, or certificate for plant variety protection exists under the name.`
      : value && asBoolString(value) === 'No'
      ? `A plant patent, utility patent, or certificate for plant variety protection does not exist under the name.`
      : null,
  '$varietal-patent-no': value =>
    value
      ? `The related patent serial number(s) is/are: ${value}.`
      : null,
  '$withdraw-concurrent-use': value =>
    asBoolString(value) === 'Yes'
      ? ` The Applicant did not intend to assert concurrent use, and hereby withdraws the claim of concurrent use.`
      : null,
  '$withdraw-disclaimer': value =>
    asBoolString(value) === 'Yes'
      ? `Applicant requests withdrawal of the previously-submitted disclaimer.`
      : null,
  text: value => value,
  // Do nothing with these:
  'file-names': () => null,
  files: () => null,
  $output: () => null
};
