import { CaseStatus } from '@markmachine/features/case-status/models/status.model';
import { VersionId } from '@markmachine/features/version/models/version.model';
import { Model } from '@markmachine/core/model.class';
import { DocumentNextStat } from './document-next-stat.model';
import { EventNextStat } from './event-next-stat.model';
import { CaseDocument } from './case-document.model';
import { DeadlineRule } from '@markmachine/features/events/models/deadline-rule.model';
import { EventStatement } from './event-statement.model';

export type CaseId = string;

export type UsptoCaseFile = {
  statusCode: number;
  events: {
    nodes: EventStatement[];
  };
  popularReaction: {
    eventDescriptionByNext: {
      name: string;
      actor: string;
    }
    fraction: number;
    deadlineRules: {
      nodes: DeadlineRule[];
    }
  }
}

export class Case extends Model<Case> {
  readonly createdBy: string;
  readonly createdAt: string;
  readonly draftKey: VersionId;
  readonly currentKey: VersionId;
  readonly id: CaseId;
  readonly isAdded: boolean;
  readonly latestUsptoOrigin: string;
  readonly serialNumber: number;
  readonly updatedAt: string;

  readonly documentNextStats?: { nodes: DocumentNextStat[] };
  readonly eventNextStats?: { nodes: EventNextStat[] };
  readonly documents?: { nodes: CaseDocument[] };
  readonly status?: CaseStatus;
  readonly casefile?: UsptoCaseFile;
}
