<form [formGroup]="formGroup">

  <h2 mat-dialog-title>Authorization Required</h2>

  <mat-dialog-content>

    <p class="admin"
      *ngIf="this.data && this.data.requireAdmin">This page is for admins only, return to
      <a mat-dialog-close
        [routerLink]="['/home']">Home</a> if you're not an admin.
    </p>

    <mat-form-field>
      <input matInput
        autofocus
        type="email"
        placeholder="Email"
        formControlName="email"
        #email>
      <mat-error *ngIf="getEmailErrorMessage(); let message">{{ message }}</mat-error>
    </mat-form-field>

    <mat-form-field>
      <input matInput
        type="password"
        placeholder="Password"
        formControlName="password">
    </mat-form-field>

    <p class="error"
      *ngIf="account.authorizationError$ | async; let authError">Failed to sign in because {{authError}}.</p>

    <p class="error"
      *ngIf="account.registrationError$ | async; let regError">Registration failed because {{regError}}.</p>

  </mat-dialog-content>

  <mat-dialog-actions>

    <button mat-button
      [mat-dialog-close]="false">CANCEL</button>

    <button mat-button
      type="submit"
      color="primary"
      (click)="authorize()">SIGN IN</button>

  </mat-dialog-actions>

</form>
