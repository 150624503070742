import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { GuideWidget } from '../models/guide-widget.model';

export const loadGuideWidgets = createAction(
  '[GuideWidget] LoadGuideWidgets',
  props<{ guideWidgets: GuideWidget[] }>()
);

export const addGuideWidget = createAction(
  '[GuideWidget] AddGuideWidget',
  props<{ guideWidget: GuideWidget }>()
);

export const addGuideWidgetSuccess = createAction(
  '[GuideWidget] AddGuideWidgetSuccess',
  props<{ guideWidget: GuideWidget }>()
);

export const addGuideWidgetFailure = createAction(
  '[GuideWidget] AddGuideWidgetFailure',
  props<{ guideWidget: GuideWidget, error: any }>()
);

export const upsertGuideWidget = createAction(
  '[GuideWidget] UpsertGuideWidget',
  props<{ guideWidget: GuideWidget }>()
);

export const addGuideWidgets = createAction(
  '[GuideWidget] AddGuideWidgets',
  props<{ guideWidgets: GuideWidget[] }>()
);

export const upsertGuideWidgets = createAction(
  '[GuideWidget] UpsertGuideWidgets',
  props<{ guideWidgets: GuideWidget[] }>()
);

export const updateGuideWidget = createAction(
  '[GuideWidget] UpdateGuideWidget',
  props<{ guideWidget: Update<GuideWidget> }>()
);

export const updateGuideWidgets = createAction(
  '[GuideWidget] UpdateGuideWidgets',
  props<{ guideWidgets: Update<GuideWidget>[] }>()
);

export const deleteGuideWidget = createAction(
  '[GuideWidget] DeleteGuideWidget',
  props<{ id: string }>()
);

export const deleteGuideWidgets = createAction(
  '[GuideWidget] DeleteGuideWidgets',
  props<{ ids: string[] }>()
);

export const clearGuideWidgets = createAction(
  '[GuideWidget] ClearGuideWidgets',
);

export type NewGuideWidget = Partial<GuideWidget> & Required<Pick<GuideWidget, 'idReply'>>;

export const createNewGuideWidget = createAction(
  '[GuideWidget] CreateNewGuideWidget',
  props<{ guideWidget: GuideWidget}>()
);
