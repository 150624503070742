import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { get } from 'lodash-es';
import { merge, Observable, of } from 'rxjs';
import { mapTo, mergeMap, reduce, tap } from 'rxjs/operators';
import { loadUserTables } from '../actions/user-table.actions';
import { UserTable } from '../models/user-table.model';
import { UserTableService } from '../services/user-table.service';

@Injectable()
export class UserTablesGuard implements CanActivate {
  constructor(
    private service: UserTableService,
    private store: Store<any>
  ) {}

  /**
   * Return required user tables, creating new ones as needed.
   *
   * NOTE: Returns all user tables, not just required user tables.
   */
  loadUserTables(requireUserTables: string[]): Observable<UserTable[]> {
    return this.service.requestAllUserTables().pipe(
      // Having fetched the current user tables…
      mergeMap(userTables => {
        // Figure out which required tables don't yet exist
        const unmadeKeys = requireUserTables.filter(key => !userTables.find(t => t.key === key));
        // For each unmade table, create a new table
        const newTables = of(...unmadeKeys).pipe(
          mergeMap(key => this.service.requestCreateUserTableByKey(key))
        );
        // Merge old and new tables into a single stream
        return merge(of(...userTables), newTables);
      }),
      // Accumulate tables into an array
      reduce((acc, value: UserTable) => [...acc, value], []),
      // Load into store before returning tables
      tap(userTables => this.store.dispatch(loadUserTables({ userTables }))),
    );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const requireUserTables: string[] = get(route, 'data.requireUserTables', []);
    return this.loadUserTables(requireUserTables).pipe(mapTo(true));
  }
}
