import {
  ChangeDetectorRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  OnDestroy,
  OnInit,
  SimpleChange,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';
import { ContextMenuPathDirective } from '@markmachine/features/context-menu/directives/context-menu-path.directive';
import { ContextMenuItem } from '@markmachine/features/context-menu/models/context-menu-item.model';
import { ContextMenuService } from '@markmachine/features/context-menu/services/context-menu.service';
import { ExpansionPanelComponent } from '@markmachine/features/expansion-panel/components/expansion-panel/expansion-panel.component';
import { Observable, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { ExpansionPanelHelperComponent } from '../components/expansion-panel-helper/expansion-panel-helper.component';

/**
 * Creates an anchor point where expansion panel helpers will be created.
 */
@Directive({
  selector: '[mmExpansionPanelHelper]'
})
export class ExpansionPanelHelperDirective implements OnDestroy, OnInit {
  private destroy$ = new Subject();
  private helpers = new Map<string, ComponentRef<ExpansionPanelHelperComponent>>();
  private _initialDisabledState: boolean;
  changes$: Observable<{ path: string; props: any; }>;
  activations$: Observable<ContextMenuItem>;

  constructor(
    private directive: ContextMenuPathDirective,
    private service: ContextMenuService,
    private container: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
    private panel: ExpansionPanelComponent,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this._initialDisabledState = this.panel.disabled;
    this.changes$ = this.directive.valueChanges.pipe(takeUntil(this.destroy$));
    this.activations$ = this.changes$.pipe(switchMap(() => this.service.activations(this.directive.fullPath)));
    this.activations$.subscribe(item => {
      // Create a helper panel
      this.createPanel(item);
      // Make sure the parent expansion panel is open.
      this.panel.disabled = false;
      this.panel.open();
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  /** Create a helper panel and instantiate a helper inside it. */
  createPanel(menuItem: ContextMenuItem) {
    // Only one helper for a given action is permitted at a time
    if (this.helpers.has(menuItem.action)) {
      // For lifecycle convenience, we destroy and recreate when the asked to create a second instance
      this.destroyHelper(menuItem);
    }

    const factory = this.resolver.resolveComponentFactory(ExpansionPanelHelperComponent);
    const componentRef: ComponentRef<ExpansionPanelHelperComponent> = this.container.createComponent(factory);

    componentRef.instance.menuItem = menuItem;
    componentRef.instance.hide.pipe(takeUntil(this.destroy$)).subscribe(() => this.destroyHelper(menuItem));
    componentRef.changeDetectorRef.detectChanges();
    componentRef.instance.componentRef.changeDetectorRef.detectChanges();

    // Set up input change tracking
    this.changes$.pipe(takeUntil(componentRef.instance.destroy$)).subscribe(({ path, props }) => {
      const previousValue = componentRef.instance.menuItem;
      // Retain the props the panel was originally instantiated with (e.g., tip ids) unless specifically overridden
      // by the directive.
      props = { ...menuItem.props, ...props };
      const changes = this.constructSimpleChanges(componentRef, { menuItem: { ...previousValue, props, path } });
      this.updateHelperInputs(componentRef, changes);
    });

    this.helpers.set(menuItem.action, componentRef);
    this.cd.markForCheck();
  }

  destroyHelper({ action }: ContextMenuItem) {
    // Destroy helper component from host
    // The destruction will cascade down to children, invoking ngOnDestroy per normal.
    this.helpers.get(action)?.destroy();
    this.helpers.delete(action);
    if (this.helpers.size === 0) {
      this.panel.disabled = this._initialDisabledState;
      if (this._initialDisabledState) {
        this.panel.close();
      }
    }
  }

  /** Construct an Angular SimpleChanges from a component and new inputs. */
  constructSimpleChanges(componentRef: ComponentRef<ExpansionPanelHelperComponent>, inputs: object): SimpleChanges {
    const changes: SimpleChanges = {};
    for (const key of Object.keys(inputs)) {
      changes[key] = new SimpleChange(componentRef.instance[key], inputs[key], false);
    }
    return changes;
  }

  /** Apply change detection to an ExpansionPanelHelper. */
  updateHelperInputs(componentRef: ComponentRef<ExpansionPanelHelperComponent>, changes: SimpleChanges) {
    // Update inputs
    Object.entries(changes).forEach(([key, { currentValue }]) => componentRef.instance[key] = currentValue);
    // Call ngOnChanges
    componentRef.instance.ngOnChanges(changes);
    // Mark dirty
    componentRef.changeDetectorRef.markForCheck();
    componentRef.instance.componentRef.changeDetectorRef.markForCheck();
  }
}
