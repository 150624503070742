import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { GraphqlErrors } from 'app/core/exceptions/graphql.exception';
import { MissingDataError } from 'app/core/exceptions/missing-data.exception';
import { Tip } from '@markmachine/core/models/tip';
import {
  GetTipQuery,
  GetTipResponse
} from '@markmachine/features/tip/services/operations/get-tip';
import { tap, map } from 'rxjs/operators';
import { GraphQLResponse } from '@markmachine/interfaces';
import {
  GetTipChildrenQuery,
  GetTipChildrenResponse
} from '@markmachine/features/tip/services/operations/get-tip-children';
import { GetTipByAnchorResponse, GetTipByAnchorQuery } from '@markmachine/features/tip/services/operations/get-tip-by-anchor';

const URL = environment.GRAPHQL;

function handleErrors<T, S>(unpack: (response: T) => S) {
  return ({ data, errors }: GraphQLResponse<T>) => {
    if (errors) {
      throw new GraphqlErrors(errors);
    }
    const unpackedData = data && unpack(data);
    if (!unpackedData) {
      throw new MissingDataError('' + unpack);
    }
    return unpackedData;
  };
}

@Injectable({
  providedIn: 'root'
})
export class TipRequestService {
  constructor(private http: HttpClient) {}

  /**
   * Request a tip from the GraphQL API
   * @param id primary key (uuid)
   */
  getTip(id: string): Observable<Tip> {
    return this.http
      .post<GetTipResponse>(URL, new GetTipQuery({ id }))
      .pipe(tap(handleErrors(data => data.tip)), map(({ data }) => data.tip as Tip));
  }

  /**
   * Request a tip by anchor from the GraphQL API
   * @param anchor anchor (string)
   */
  getTipByAnchor(anchor: string) {
    return this.http
      .post<GetTipByAnchorResponse>(URL, new GetTipByAnchorQuery({ anchor }))
      .pipe(tap(handleErrors(data => data.tip)), map(({ data }) => data.tip));
  }

  /**
   * Request children of a tip from the GraphQL API
   * @param id parent primary key (uuid)
   */
  getTipChildren(id: string): Observable<Tip[]> {
    return this.http
      .post<GetTipChildrenResponse>(URL, new GetTipChildrenQuery({ id }))
      .pipe(
        tap(handleErrors(data => data.tip as Tip)),
        map(({ data }) => data.tip?.children.nodes as Tip[])
      );
  }
}
