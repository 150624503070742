<h2 mat-dialog-title
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayoutGap="24px">
  <div fxFlex="auto">{{title}}</div>
  <mm-tip-button anchor="72707f37-663a-4441-812c-98f108c1e451"></mm-tip-button>
</h2>

<mat-dialog-content class="mat-typography"
  [formGroup]="group"
  fxLayout="column"
  fxLayoutGap="24px">

  <div class="mm-dialog-header"
    style="position: relative"
    fxFlex="none">
    <div>
      <mm-class-selector formControlName="class-code"
        label="Class code"></mm-class-selector>
    </div>
    <div fxLayout="row"
      fxLayoutAlign="start start"
      fxLayoutGap="24px">
      <mat-form-field appearance="outline"
        class="mm-formfield-textarea">
        <mat-label>Identification of Goods/Services</mat-label>
        <textarea matInput
          #textarea
          formControlName="listing"
          rows="3"></textarea>
        <mm-tip-button matSuffix
          anchor="c3be7d25-d3f4-47ca-a51e-44408d7dbcfe"></mm-tip-button>
          <mat-hint>For suggestions, search the <a href="https://tmidm.uspto.gov/id-master-list-public.html" target="_blank">Trademark ID Manual</a>.</mat-hint>
      </mat-form-field>
    </div>
  </div>


  <mat-tab-group [selectedIndex]="selectedIndex">

    <mat-tab label="Prior Use" *ngIf="isPriorUseAllowed()">
      <ng-template mat-tab-label>
        <mat-icon class="basis-checkmark"
          *ngIf="hasBasis(BASIS.PriorUse)">check</mat-icon>
        {{priorUseTitle()}}
      </ng-template>
      <ng-template matTabContent>
        <div class="mat-tab-group__mm-body">
          <div [ngSwitch]="hasBasis(BASIS.PriorUse)"
            class="button-group">
            <button mat-raised-button
              *ngSwitchCase="true"
              (click)="deleteBasis(BASIS.PriorUse)"
              color="warn">DELETE PRIOR USE BASIS</button>
            <button mat-raised-button
              *ngSwitchCase="false"
              (click)="addBasis(BASIS.PriorUse)"
              color="primary">ADD PRIOR USE BASIS</button>
          </div>
          <mm-basis-1a-edit [group]="group"
            *ngIf="hasBasis(BASIS.PriorUse)"
            #basis1a></mm-basis-1a-edit>
        </div>
        <div class="spacer"></div>
      </ng-template>
    </mat-tab>

    <mat-tab label="Intent to Use" *ngIf="isItuAllowed()">
      <ng-template mat-tab-label>
        <mat-icon class="basis-checkmark"
          *ngIf="hasBasis(BASIS.IntentToUse)">check</mat-icon>
        Intent to Use
      </ng-template>
      <ng-template matTabContent>
        <div class="mat-tab-group__mm-body">
          <div [ngSwitch]="hasBasis(BASIS.IntentToUse)"
            class="button-group">
            <button mat-raised-button
              *ngSwitchCase="true"
              (click)="deleteBasis(BASIS.IntentToUse)"
              color="warn">DELETE INTENT TO USE BASIS</button>
            <button mat-raised-button
              *ngSwitchCase="false"
              (click)="addBasis(BASIS.IntentToUse)"
              color="primary">ADD INTENT TO USE BASIS</button>
          </div>
          <mm-basis-1b-edit [group]="group"
            [extensionCount]="extensionCount"
            *ngIf="hasBasis(BASIS.IntentToUse)"
            #basis1b></mm-basis-1b-edit>
        </div>
        <div class="spacer"></div>
      </ng-template>
    </mat-tab>

    <mat-tab label="Foreign Application">
      <ng-template mat-tab-label>
        <mat-icon class="basis-checkmark"
          *ngIf="hasBasis(BASIS.ForeignApp)">check</mat-icon>
        Foreign Application
      </ng-template>
      <ng-template matTabContent>
        <div class="mat-tab-group__mm-body">
          <div [ngSwitch]="hasBasis(BASIS.ForeignApp)"
            class="button-group">
            <button mat-raised-button
              *ngSwitchCase="true"
              (click)="deleteBasis(BASIS.ForeignApp)"
              color="warn">DELETE FOREIGN APPLICATION BASIS</button>
            <button mat-raised-button
              *ngSwitchCase="false"
              (click)="addBasis(BASIS.ForeignApp)"
              color="primary">ADD FOREIGN APPLICATION BASIS</button>
          </div>
          <mm-basis-44d-edit [group]="group"
            *ngIf="hasBasis(BASIS.ForeignApp)"
            #basis44d></mm-basis-44d-edit>
        </div>
        <div class="spacer"></div>
      </ng-template>
    </mat-tab>

    <mat-tab label="Foreign Registration">
      <ng-template mat-tab-label>
        <mat-icon class="basis-checkmark"
          *ngIf="hasBasis(BASIS.ForeignReg)">check</mat-icon>
        Foreign Registration
      </ng-template>
      <ng-template matTabContent>
        <div class="mat-tab-group__mm-body">
          <div [ngSwitch]="hasBasis(BASIS.ForeignReg)"
            class="button-group">
            <button mat-raised-button
              *ngSwitchCase="true"
              (click)="deleteBasis(BASIS.ForeignReg)"
              color="warn">DELETE FOREIGN REGISTRATION BASIS</button>
            <button mat-raised-button
              *ngSwitchCase="false"
              (click)="addBasis(BASIS.ForeignReg)"
              color="primary">ADD FOREIGN REGISTRATION BASIS</button>
          </div>
          <mm-basis-44e-edit [group]="group"
            *ngIf="hasBasis(BASIS.ForeignReg)"
            #basis44e></mm-basis-44e-edit>
        </div>
        <div class="spacer"></div>
      </ng-template>
    </mat-tab>

    <mat-tab class="help-tab">
      <ng-template mat-tab-label>
        <mat-icon color="primary">help</mat-icon>
      </ng-template>
      <ng-template matTabContent>

        <div class="mat-tab-group__mm-body">
          <mm-tip-array mmOpenLinksInDialog
            [tipIds]="[
              'f71661b5-c067-4708-bfdd-b7a6af55156e',
              'b545df3a-492c-47f3-9d1a-9e5c479d6b8a',
              '33aafaab-00a7-4f2a-a27d-ac86b640c436',
              '320c8394-d040-400e-b9fa-817c3227c3c6',
              '3ee6faef-98aa-4a1d-ac44-a845bdab19ec'
            ]"></mm-tip-array>
        </div>
      </ng-template>
    </mat-tab>

  </mat-tab-group>

</mat-dialog-content>

<mat-dialog-actions fxLayoutAlign="flex-end"
  fxFlex="96px">
  <button mat-button
    mat-dialog-close
    (click)="deleteClass()"
    color="warn">DELETE CLASS</button>
  <button mat-button
    mat-dialog-close>DONE</button>
</mat-dialog-actions>
