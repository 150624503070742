<h2 mat-dialog-title>Unexpected Error</h2>

<div mat-dialog-content
  class="mat-typography">

  <p>Our apologies. An error has occurred, we've noted that error, and we plan to fix it very soon.</p>

</div>

<mat-dialog-actions>

  <button mat-button (click)="redirect()">CLOSE</button>

</mat-dialog-actions>