import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { updateFormArray } from '@markmachine/core/functions/utilities';
import { Class, Renewal815, Specimen } from '@markmachine/features/version/models/version-content.model';
import { pick } from 'lodash-es';

const TEMPLATE = new Renewal815();

@Injectable()
export class CaseFormRenewal815Service {
  constructor(private fb: FormBuilder) {}

  /**
   * Update length and contents of FormArray for classes.
   * @param array FormArray for classes
   * @param values New values for classes
   */
  update(array: FormArray, values: Class[], current: Class[]): void {
    const renewals = this._renewals(values, current);
    updateFormArray(array, renewals, (current_, i) => this.create(current_[i]));
  }

  /**
   * Create a new renewal
   * @param newClass Fields to set in renewal
   */
  create(newClass: Class): FormGroup {
    const specimen = this.fb.group({});
    for (const [key, value] of Object.entries({ ...new Specimen(), ...newClass['specimen'] })) {
      specimen.addControl(key, this.fb.control({ value }));
    }
    const group = this.fb.group({ ...pick(newClass, Object.keys(TEMPLATE)), specimen });
    return group;
  }

  private _renewals(values: Class[], current: Class[]): Renewal815[] {
    // return current?.map(cls => this._renewal(values, cls)) ?? [];
    return current
      ?.map(cls => this._renewal(values, cls))
      .filter(cls => cls['$renewal-815-requested'])
      ?? [];

  }

  /**
   * Calculate an Renewal815, if any
   * @param values draft class
   * @param current current (USPTO) class
   */
  private _renewal(values: Class[], current: Class): Renewal815 {
    const value = values.find(v => v['class-code'] === current['class-code']);
    return this._createRenewalFromClass(value);
  }

  /**
   * Create an Renewal815 from a Class.
   * @param value Form Value of draft Class
   */
  private _createRenewalFromClass(value: Class | null): Renewal815 {
    let renewal = new Renewal815();
    if (!value) {
      return { ...renewal, 'renewal-815': 'delete-class' };
    }
    for (const key in renewal) {
      if (renewal.hasOwnProperty(key)) {
        renewal[key] = value[key];
      }
    }
    if (renewal['renewal-815'] === 'remove-goods-services') {
      renewal = { ...renewal, '$listing-remaining': value['listing'] };
    }
    return renewal;
  }
}
