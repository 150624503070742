import { differs } from '@markmachine/core/functions/differs';
import { ReplyGroupStatistic } from '@markmachine/core/models/office-action-statistics';
import { documentListUrl, statusUrl } from '@markmachine/core/services/tsdr.service';
import { CaseStatus } from '@markmachine/features/case-status/models/status.model';
import * as fromStatus from '@markmachine/features/case-status/reducers/status.reducer';
import { Case } from '@markmachine/features/case/models/case.model';
import * as fromCase from '@markmachine/features/case/reducers/case.reducer';
import * as fromFiles from '@markmachine/features/case/reducers/file.reducer';
import * as fromDeclarations from '@markmachine/features/declaration/reducers/declaration.reducer';
import { Guide } from '@markmachine/features/guide/models/guide.model';
import * as fromGuide from '@markmachine/features/guide/reducers/guide.reducer';
import { Issue } from '@markmachine/features/issue/models/issue.model';
import { getSelectableIssues } from '@markmachine/features/issue/reducers/issue.reducer';
import { UserCase } from '@markmachine/features/user-case/models/user-case.model';
import * as fromUserCase from '@markmachine/features/user-case/reducers/user-case.reducer';
import { GuideSelection } from '@markmachine/features/version/models/version-content.model';
import { CurrentVersion, SubmittedStatuses, Version } from '@markmachine/features/version/models/version.model';
import * as fromVersion from '@markmachine/features/version/reducers/version.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { get, isNil, max, omitBy, pickBy, some, sortBy } from 'lodash-es';
import { DateTime } from 'luxon';
import { ReplyGroupTableItem } from '../models/reply-group-table-item.model';
import { manualDeclarations } from './manual-declarations.functions'; // FIXME
import { allowedRenewalPeriod, NO_RENEWAL } from './renewal.functions';
import * as fromSubmission from './submission-page.reducer';

export const submissionPageKey = 'submissionPage';

export interface State extends fromCase.State {
  [submissionPageKey]: fromSubmission.State;
}

/**
 * The createFeatureSelector function selects a piece of state from the root of the state object.
 * This is used for selecting feature states that are loaded eagerly or lazily.
 */

export const getSubmissionPageState = createFeatureSelector<fromSubmission.State>(submissionPageKey);

/**
 * Every reducer module exports selector functions, however child reducers
 * have no knowledge of the overall state tree. To make them usable, we
 * need to make new selectors that wrap them.
 *
 * The createSelector function creates very efficient selectors that are memoized and
 * only recompute when arguments change. The created selectors can also be composed
 * together to select different pieces of state.
 */

export const getIsLoaded = createSelector(getSubmissionPageState, fromSubmission.getIsLoaded);

export const getIsLoading = createSelector(getSubmissionPageState, fromSubmission.getIsLoading);

export const getCaseId = createSelector(getSubmissionPageState, state => state && fromSubmission.getCaseId(state));

export const getUserCase = createSelector(
  getCaseId,
  fromUserCase.getAllUserCases,
  (caseId, userCases) => userCases.find(c => c.caseId === caseId) as UserCase);
export const getUserCaseNodeId = createSelector(getUserCase, userCase => userCase.nodeId);

export const getDraftId = createSelector(getSubmissionPageState, state => state && fromSubmission.getDraftId(state));

export const getCurrentId = createSelector(getSubmissionPageState, fromSubmission.getCurrentId);

export const getExpandedGuides = createSelector(getSubmissionPageState, fromSubmission.getExpandedGuides);

export const getIsStatisticsOutdated = createSelector(getSubmissionPageState, fromSubmission.getPollingIndicator);

/** Version Selectors */

export const getDraftVersion = createSelector(
  getDraftId,
  fromVersion.getVersionEntities,
  (draftId, versions) => versions[draftId] as Version
);

export const getCurrentVersion = createSelector(
  getCurrentId,
  fromVersion.getVersionEntities,
  (currentId, versions) => versions[currentId] as CurrentVersion
);

/**
 * Determine if the 'preparer-role' field is required for the draft version.
 *
 * TODO: Determine based on TEAS forms (CAR-required) instead.
 */
export const isRoleRequired = createSelector(getDraftVersion, getCurrentVersion, ({ content: draft }, { content: current }) => {
  // Check the correspondence objects
  const CORR_KEYS = ['correspondent', 'correspondent-attorney', 'correspondent-domestic-representative'];
  const correspondenceDiffers = CORR_KEYS.some(key => differs(current[key], draft[key]));
  // Lengths differ? Totally different! Same length—do the values differ?
  const ownersDiffer
    = current.owners.length !== draft.owners.length
    || current.owners.some((owner, i) => differs(omitBy(owner, (value) => value === null || value === ''), draft.owners[i]));
  return correspondenceDiffers || ownersDiffer;
});

/** Determine if an attorney is already appointed. */
const isAttorneyAlreadyAppointed = createSelector(getCurrentVersion, ({ content }) => {
  // Check if any one of the required fields for attorney are present.
  return !!content?.['correspondent-attorney']?.['name'];
});

/** Determine if the attorney is modified relative to `current`. */
const isAttorneyModified = createSelector(getDraftVersion, getCurrentVersion, ({ content: draft }, { content: current }) => {
  // Check if any USPTO-provided values are altered.
  // We expect that all possible USPTO values have a key in `current`, even if the value is `null`.
  return differs(current['correspondent-attorney'], draft['correspondent-attorney']);
});

/** Determine if the "Role of the Preparer" is set to "Attorney". */
const isRoleAttorney = createSelector(getDraftVersion, ({ content }) => {
  return content?.['correspondent']?.['preparer-role'] === 'attorney';
});

/** Determine if the "Reason for Change" field in the Attorney section is required. */
export const isReasonForChangeRequired = createSelector(
  isRoleRequired,
  isRoleAttorney,
  isAttorneyAlreadyAppointed,
  isAttorneyModified,
  (...args) => args.every(Boolean)
);

export const getCurrentCase = createSelector(getCurrentVersion, version => version.content);

export const getDraftCase = createSelector(getDraftVersion, version => version?.content);

export const getVersionList = createSelector(
  fromVersion.getAllVersions,
  getCaseId,
  (versions, caseId) => versions.filter(v => v.idCase === caseId)
);

export const getVersions = createSelector(
  getCaseId,
  fromVersion.getVersionEntities,
  (caseId, entities) => pickBy(entities, v => v?.idCase === caseId)
);

export const getCase = createSelector(
  getCaseId,
  fromCase.getCaseEntities,
  (caseId, entities) => entities[caseId as string]
);

export const getCaseDocuments = createSelector(
  getCase,
  (case_) => case_.documents.nodes
);

export const getCaseEventStatements = createSelector(
  getCase,
  (case_) => case_.casefile?.events?.nodes
);

export const getIsAdded = createSelector(getUserCase, userCase => !!userCase); // TODO: Get from Account State instead

export const getMarkText = createSelector(getDraftCase, content => content.mark['mark-text']);

export const getDescription = createSelector(getDraftCase, content => {
  return [content.mark['mark-text'], content.mark['mark-description']].filter(v => v).join(' ');
});

export const getSerialNumber = createSelector(getCase, (c: Case) => c.serialNumber);
export const getDocumentListUrl = createSelector(getSerialNumber, sn => documentListUrl(sn));
export const getStatusUrl = createSelector(getSerialNumber, sn => statusUrl(sn));

export const getOwners = createSelector(getCurrentCase, c => c.owners);

export const isUnfiled = createSelector(getCase, c => !c?.serialNumber);

export const getApplicationType = createSelector(getDraftCase, c => c?.['case-file-header']?.['application-type']);

export const getFilingDate = createSelector(getCurrentCase, content => content['case-file-header']['filing-date']);

export const getClasses = createSelector(getCurrentCase, content => {
  return content.classes;
});

export const getClassCodes = createSelector(getClasses, classes => {
  const classCodes = classes.map((c) => c['class-code']);
  return classCodes.join(', ');
});

export const getCaseGuideSelection = createSelector(getDraftCase, content => {
  // Sloppy automatic guide selection migration
  return { ...new GuideSelection(), ...content.guides };
});

export const getCorrespondent = createSelector(
  getCurrentCase,
  content => content.correspondent
);

export const getCorrespondentAttorney = createSelector(
  getCurrentCase,
  content => content['correspondent-attorney']
);

export const getCorrespondentDomesticRepresentative = createSelector(
  getCurrentCase,
  content => content['correspondent-domestic-representative']
);

export const getDocketNumber = createSelector(
  getCurrentCase,
  content => get(content, 'correspondent-attorney.attorney-docket-number', null)
    || get(content, 'correspondent.attorney-docket-number', null)
);

export const hasAOU = createSelector(getDraftCase,
  getCurrentCase,
  content => some(content.classes.map(cls => cls['$eligible-for-allegation-of-use'] && cls['filing-basis-current-1a-in']))
);

export const isSubmitted = createSelector(getDraftVersion, state => SubmittedStatuses.includes(state.status));

export const getDraftStatus = createSelector(getDraftVersion, state => state.status);

export const getManualDeclarations = createSelector(
  getDraftVersion,
  version => manualDeclarations(version?.content?.classes ?? [])
);

export const requiresManualDeclarations = createSelector(getManualDeclarations, decs => decs.length > 0);

export const selectDraftDeclarations = createSelector(
  fromDeclarations.selectDeclarationState,
  getDraftId,
  (decState, draftId) => fromDeclarations.selectDeclarationsByVersionId(decState, draftId)
);

/** Get Draft Declaration status */
export const selectDraftDeclarationsStatus = createSelector(
  fromDeclarations.selectDeclarationState,
  getDraftId,
  (decState, draftId) => fromDeclarations.selectDeclarationStateByVersionId(decState, draftId)
);

export const getDeclarationSignatures = createSelector(
  getDraftVersion,
  (draft) => draft?.content?.['declaration-signatures'] ?? []
);

export const modifyDeclarationSignatures = createSelector(
  getDeclarationSignatures,
  selectDraftDeclarationsStatus,
  (signatures, decStatus) => {
    const status = decStatus?.loadState;
    const length = signatures.length ?? -1;
    const ready = status === 'loaded';
    const required = ready;
    const action = !ready ? null
      : (required && length === 0) ? 'initialize'
      : (!required && length > 0) ? 'clear'
      : null;
    return action;
  }
);


/** Case Status Selectors */

export const getNewApplicationStatus = createSelector(isSubmitted, state => (state ? 'Pending USPTO processing' : 'Not submitted'));

export const getStatus = createSelector(
  getCase,
  fromStatus.getStatusEntities,
  (case_: Case, statuses) => statuses[case_.serialNumber] as CaseStatus
);

const getMilestoneDate = createSelector(getStatus, status => {
  const keys = [
    'abandonmentDate',
    'unresponsiveDate',
    // 'officeActionDate',
    'latestRenewalDate',
    'registeredDate',
    'noticeOfAllowanceDate',
    'publishedDate',
    // 'responseDate',
  ];
  if (status) {
    return max(keys.map(k => status[k]).filter(date => !!date)) as string | null;
  }
  return null;
});

export const getStatusDescription = createSelector(getStatus, status => status ? status.statusDesc : null);

export const getStatusContent = createSelector(getStatus, status => status && status.content ? status.content : null);

export const getStatusIsLoaded = createSelector(getStatus, status => !!(status && status.content));

export const getStatusContentStatus = createSelector(getStatusContent, content => content ? content.status : null);

export const getStatusDate = createSelector(getStatusContentStatus, status => status ? status.statusDate : null);

export const getTeasPlus = createSelector(getStatusContentStatus, status => status ? status.currentlyAsTeasPlusApp : null);

export const getRegistrationNumber = createSelector(getStatusContentStatus, status => status ? status.usRegistrationNumber : null);

export const getDisclaimer = createSelector(getStatusContentStatus, status => status ? status.disclaimer : null);

export const getSupplementalRegister = createSelector(getStatusContentStatus, status => status ? status.supplementalRegister : null);

export const getStatusGoodsServices = createSelector(getStatusContent, content => content?.gsList ?? []);

export const getPublicationDate = createSelector(getStatus, status => status ? status.publishedDate : null);

export const getAllowanceDate = createSelector(getStatus, status => status ? status.noticeOfAllowanceDate : null);

export const getCurrentlyItu = createSelector(getStatus, status => !!status?.content?.status?.ituCurr);

export const getRegistrationDate = createSelector(getStatus, status => status ? status.registeredDate : null);

export const getRenewalDeadline = createSelector(getStatus, status => status ? status.renewalDeadlineDate : null);

export const getIsAbandoned = createSelector(getStatus, status => status?.abandonmentDate
  ? DateTime.fromISO(status.abandonmentDate) < DateTime.local()
  : false);

/**
 * Determine if application is eligible for an AAU/AOU/SOU.
 *
 * Any time a pre-Reg specimen is submitted it is paired with an "Allegation of Use" (AOU).
 * If submitted prior to the Notice of Publication, it's called an "Amendment to Allege Use" (AAU).
 * If submitted after allowance, it's called a "Statement of Use" (SOU).
 * In between the Notice of Publication and the Notice of Allowance is the "blackout period" or "blackout window",
 * during which time an AOU should not be submitted because it won't be "processed".
 */
export const getAllegationOfUseType = createSelector(
  getPublicationDate,
  getAllowanceDate,
  getCurrentlyItu,
  (allowDate, pubDate, isCurrentlyItu) => {
    if (!isCurrentlyItu) {
      // Not eligible for Amendment to Allege Use if lacking an ITU basis.
      // This also excludes post-registration, when an ITU basis is impossible.
      return null;
    }
    // Exclude the blackout region between publication and allowance.
    if (isNil(pubDate) !== isNil(allowDate)) {
      return null;
    }
    return isNil(pubDate) && isNil(allowDate)
      // Allow pre-publication Specimen Of Use.
      ? 'Amendment to Allege Use'
      // Allow post-allowance Allegation to Amend Use.
      : 'Statement of Use';
  }
);

/** Return the eligible renewal period. */
export const getRenewalPeriod = createSelector(
  getRegistrationDate,
  getIsAbandoned,
  (registrationDate, isAbandoned) => !registrationDate || isAbandoned ? NO_RENEWAL : allowedRenewalPeriod(
    DateTime.fromISO(registrationDate),
    DateTime.local()
  )
);

export const getIntlClasses = createSelector(getStatusContent, ({ gsList }) => {
  return gsList.map(cls => ({ listing: cls.description, code: cls.internationalClasses[0].code }));
});

export const getExtensionCount = createSelector(getStatus, status => {
  if (status) {
    return status.extensionCount;
  } else {
    return 0;
  }
});

export const getMarkDrawingDescription = createSelector(getStatus, status => {
  if (status) {
    return status?.content?.status?.markDrawDesc ?? '';
  } else {
    return '';
  }
});

export const getStage = createSelector(getStatus, status => status ? status.stage : undefined);

export const getPublishedDate = createSelector(getStatus, status => {
  if (status && status.stage?.toLowerCase() === 'pub' && status.publishedDate) {
    return status.publishedDate;
  } else {
    return '';
  }
});

/** Case File Selectors */

export const getCaseFiles = createSelector(
  getCaseId,
  fromFiles.getAllFiles,
  (caseId, files) => files.filter(f => f.idCase === caseId)
);


/**
 * Guide Selectors
 */

/** Get all selected guides. */
export const getSelectedGuides = createSelector(
  fromGuide.getGuideEntities,
  getCaseGuideSelection,
  (guides, { selectedGuideIds }) => sortBy((selectedGuideIds ?? []).map(id => guides[id] as Guide), ['displayName'])
);

export const getSelectedIssues = createSelector(
  getSelectableIssues,
  getCaseGuideSelection,
  (issues, { selectedIssueIds }) => sortBy(selectedIssueIds.map(id => issues.find(i => i.id === id) as Issue), ['displayName'])
);

export const getSelectedGuideIds = createSelector(
  getCaseGuideSelection,
  ({ selectedGuideIds }) => selectedGuideIds
);

/** Issue Statistics that depend on Status Date */

const getPostMilestoneStatistics = createSelector(
  getSubmissionPageState,
  getMilestoneDate,
  (state, milestoneDate) => {
    let latestOfficeAction = sortBy(state.statistics ?? [], ({ documentId }) => documentId.slice(3, 3 + 8))
      .slice(-1);
    // If we didn't find any milestones date, well, this is a pretty new app.
    if (!milestoneDate) {
      // … so, show all the stats! (might be an empty array if there is no office action)
      return fromSubmission.aggregateOfficeActionStatistics(latestOfficeAction);
    }
    // Through the magic of timezone naivety, milestoneDate and documentId both contain EST timestamps.
    const milestoneDateString = milestoneDate.replace(/[^0-9]/g, '').slice(0, 'yyyyMMDD'.length);
    // NOTE: Inconsistently see `Object(...)(...) is null` errors here?
    latestOfficeAction = latestOfficeAction.filter(stat => {
      // A documentId looks like "OOA20190120151718", so the 8 chararacters after "OOA" are the
      // date of the `scanDateTime` timestamp in EST.
      const statDt = stat.documentId.slice(3, 3 + 8);
      // … so string comparison of 8-digit dates works right here.
      return statDt > milestoneDateString;
    });
    return fromSubmission.aggregateOfficeActionStatistics(latestOfficeAction);
  }
);

export const getResponseIsFiled = createSelector(
  getStatus,
  (status) => {
    if (status) {
      return !!(status.officeActionDate &&
                status.responseDate &&
                status.officeActionPending &&
                (status.responseDate >= status.officeActionDate));
    } else {
      return false;
    }
  });

/* @deprecated */
export const getOfficeActionPending = createSelector(
  getStatus,
  (status) => {
    if (status) {
      return status.officeActionPending;
    } else {
      return false;
    }
  });

export const getDetectedIssues = createSelector(getPostMilestoneStatistics, statistics => statistics?.issues?.nodes ?? []);

export const getIssueStatistics = createSelector(
  getPostMilestoneStatistics,
  aggregatedStats => {
    const issues = aggregatedStats?.issues?.nodes ?? [];
    const { numerator, denominator } = aggregatedStats?.issueGroupStatistics || { numerator: 0, denominator: 0 };
    return [{ success: numerator / denominator, displayNames: issues.map(i => i.displayName) }];
  }
);

export const getDetectedRequirementIssues = createSelector(
  getDetectedIssues,
  issues => issues.filter(i => i.severity === 'Requirement')
);

export const getAdvisoryIssues = createSelector(
  getDetectedIssues,
  issues => issues.filter(i => !['Requirement', 'Nonissue'].includes(i.severity)));

export const getTmepCoverage = createSelector(getPostMilestoneStatistics, state => get(state, 'tmepCoverage', 0));

export const getReplyGroupStatistics = createSelector(
  getPostMilestoneStatistics,
  state => get(state, 'statistics.nodes', []) as ReplyGroupStatistic[]
);

export const getReplyGroupTableItems = createSelector(
  getReplyGroupStatistics,
  (replyStats): ReplyGroupTableItem[] => {
    const total = replyStats.reduce((t, row) => t + row.denominator, 0);
    const replyRows: ReplyGroupTableItem[] = replyStats.map(stat => {
      // Filter out duplicate description of the mark guides
      const hasAmendDescriptionOfMark = !!stat.guides.nodes.find(i => i.displayName === 'Amend Description of Mark');
      const nodes = stat.guides.nodes.filter(i => i.displayName !== 'Add Description of Mark' || !hasAmendDescriptionOfMark);
      const guideIds = stat.guides.nodes.map(guide => guide.id);
      return {
        guideNames: nodes.map(g => g.displayName),
        popularity: stat.denominator / total,
        successNovices: Math.max((0.8 * stat.numerator) / stat.denominator, 0),
        successAverage: (1.0 * stat.numerator) / stat.denominator,
        successExperts: Math.min((1.2 * stat.numerator) / stat.denominator, 1),
        groupId: stat.groupId,
        guideIds
      };
    });
    return replyRows;
  }
);

export const getHasExaminerSuggestions = createSelector(
  getPostMilestoneStatistics,
  state => get(state, 'suggestions.hasSuggestions', false)
);

export const getOfficeActionPendingWithIssues = createSelector(
  getOfficeActionPending,
  getDetectedIssues,
  (pending, issues) => pending && issues.length > 0
);

export const getOfficeActionPendingNoIssues = createSelector(
  getOfficeActionPending,
  getDetectedIssues,
  (pending, issues) => pending && issues.length === 0
);

