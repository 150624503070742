import { GraphQLQuery, GraphQLResponse } from 'app/interfaces';
import { SubmissionMethod } from '@markmachine/features/version/models/version.model';

const query = `
mutation ChooseSubmissionMethod(
  $caseVersionId: UUID!,
  $method: VersionSubmissionMethod
) {
  updateCaseVersionById(input: {
    id: $caseVersionId,
    caseVersionPatch: {
      submissionMethod: $method
    }
  }) {
    caseVersion {
      submissionMethod
    }
  }
}
`;

export class ChangeSubmissionMethodMutation implements GraphQLQuery {
  query = query;

  constructor(public variables: { caseVersionId: string; method: SubmissionMethod }) {}
}

export type ChangeSubmissionMethodResponse = GraphQLResponse<{
  updateCaseVersionById: {
    caseVersion: { submissionMethod: SubmissionMethod };
  };
}>;
