/**
 * Version Form Module
 *
 * Provides the CaseFormService. This service generates a FormGroup from a CaseVersion,
 * providing means of editing a case version that can be shared across different frontend
 * interfaces.
 *
 * TODO: Rename CaseFormService et all to VersionFormService
 * TODO: Change how #connect() works so that it generalizes.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaseFormService } from './services/case-form.service';
import { CaseFormClassesService } from './services/case-form-classes.service';
import { CaseFormHeaderService } from './services/case-form-header.service';
import { CaseFormStatementsService } from './services/case-form-statements.service';
import { CaseFormOwnersService } from './services/case-form-owners.service';
import { CaseFormSignaturesService } from './services/case-form-signatures.service';
import { CaseFormAllegationsService } from './services/case-form-allegations.service';
import { CaseFormExtensionRequestService } from './services/case-form-extension.service';
import { CaseFormMarkService } from './services/case-form-mark.service';
import { CaseFormArgumentService } from './services/case-form-argument.service';
import { CaseFormCorrespondentService } from './services/case-form-correspondent.service';
import { CaseFormRenewal89Service } from './services/case-form-renewal-89.service';
import { CaseFormRenewal815Service } from './services/case-form-renewal-815.service';
import { CaseFormGuideService } from './services/case-form-guide.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    CaseFormService,
    CaseFormClassesService,
    CaseFormHeaderService,
    CaseFormStatementsService,
    CaseFormOwnersService,
    CaseFormSignaturesService,
    CaseFormAllegationsService,
    CaseFormExtensionRequestService,
    CaseFormRenewal89Service,
    CaseFormRenewal815Service,
    CaseFormMarkService,
    CaseFormArgumentService,
    CaseFormCorrespondentService,
    CaseFormGuideService,
  ]
})
export class VersionFormModule { }
