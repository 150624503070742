import { Class } from '@markmachine/features/version/models/version-content.model';
import { DisplayedDeclaration } from '../models/displayed-declaration.model';

const MANUAL_DECLARATIONS: DisplayedDeclaration[] = [
  {
    key: '$extension-requested',
    title: 'Request for Extension of Time to File Statement of Use',
    description: '',
    filingDescription: '',
    type: 'ESU',
    texts: [
      'STATEMENTS: The signatory believes that: the applicant has a continued bona fide intention, and is entitled, to use the mark in commerce on or in connection with all the goods/services under Section 1(b) in the notice of allowance or as subsequently modified, or, if applicable, the applicant has a continued bona fide intention, and is entitled, to exercise legitimate control over the use of the mark in commerce on or in connection with all the goods/services/collective membership organization under Section 1(b) in the notice of allowance or as subsequently modified; and that to the best of the signatory\'s knowledge and belief, no other persons, except, if applicable, members and concurrent users, have the right to use the mark in commerce, either in the identical form or in such near resemblance as to be likely, when used on or in connection with the goods/services/collective membership organization of such other persons, to cause confusion or mistake, or to deceive.',
      'DECLARATION: The signatory being warned that willful false statements and the like are punishable by fine or imprisonment, or both, under 18 U.S.C. §1001, and that such willful false statements and the like may jeopardize the validity of the application or submission or any resulting registration, declares that all statements made of his/her own knowledge are true and that all statements made on information and belief are believed to be true.'
    ],
  },
  {
    key: '$pending-allegation-of-use',
    title: 'Statement of Use',
    description: '',
    filingDescription: '',
    type: 'AOU',
    texts: [
      '1) The signatory believes that the applicant is the owner of the mark sought to be registered.',
      'For a trademark or service mark application, the mark is in use in commerce on or in connection with all the goods/services in the application or notice of allowance, or as subsequently modified.',
      'For a collective trademark, collective service mark, collective membership mark application, the applicant is exercising legitimate control over the use of the mark in commerce by members on or in connection with all the goods/services/collective membership organization in the application or notice of allowance, or as subsequently modified.',
      'For a certification mark application, the applicant is exercising legitimate control over the use of the mark in commerce by authorized users on or in connection with the all goods/services in the application or notice of allowance, or as subsequently modified, and the applicant is not engaged in the production or marketing of the goods/services to which the mark is applied, except to advertise or promote recognition of the certification program or of the goods/services that meet the certification standards of the applicant.',
      '2) The specimen(s) shows the mark as used on or in connection with the goods/services/collective membership organization in commerce.',
      '3) To the best of the signatory\'s knowledge and belief, no other persons, except, if applicable, authorized users, members, and/or concurrent users, have the right to use the mark in commerce, either in the identical form or in such near resemblance as to be likely, when used on or in connection with the goods/services/collective membership organization of such other persons, to cause confusion or mistake, or to deceive.',
      '4) To the best of the signatory\'s knowledge, information, and belief, formed after an inquiry reasonable under the circumstances, the allegations and other factual contentions made above have evidentiary support.',
      '5) The signatory being warned that willful false statements and the like are punishable by fine or imprisonment, or both, under 18 U.S.C. § 1001, and that such willful false statements and the like may jeopardize the validity of the application or submission or any registration resulting therefrom, declares that all statements made of his/her own knowledge are true and all statements made on information and belief are believed to be true.'
    ],
  },
  {
    key: '$renewal-89-requested',
    title: 'Declaration of Use & Application for Renewal',
    description: '',
    filingDescription: '',
    type: 'S89',
    texts: [
      '1) Unless the owner has specifically claimed excusable nonuse, the mark is in use in commerce on or in connection with the goods/services or to indicate membership in the collective membership organization identified above, as evidenced by the attached specimen(s).',
      '2) Unless the owner has specifically claimed excusable nonuse, the specimen(s) shows the mark as currently used in commerce on or in connection with the goods/services/collective membership organization.',
      '3) The registrant requests that the registration be renewed for the goods/services/collective organization identified above.',
      '4) To the best of the signatory\'s knowledge, information, and belief, formed after an inquiry reasonable under the circumstances, the allegations and other factual contentions made above have evidentiary support.',
      '5) The signatory being warned that willful false statements and the like are punishable by fine or imprisonment, or both, under 18 U.S.C. § 1001, and that such willful false statements and the like may jeopardize the validity of this submission and the registration, declares that all statements made of his/her own knowledge are true and all statements made on information and belief are believed to be true.'
    ],
  },
  {
    key: '$renewal-815-requested',
    title: 'Declaration of Use & Incontestability',
    description: '',
    filingDescription: '',
    type: '815',
    texts: [
      '1) Unless the owner has specifically claimed excusable nonuse, the mark is in use in commerce on or in connection with the goods/services or to indicate membership in the collective membership organization identified above, as evidenced by the attached specimen(s).',
      '2) Unless the owner has specifically claimed excusable nonuse, the specimen(s) shows the mark as currently used in commerce on or in connection with the goods/services/collective membership organization.',
      '3) The mark has been in continuous use in commerce for five consecutive years after the date of registration, or the date of publication under 15 U.S.C. § 1062(c), and is still in use in commerce on or in connection with all goods/services, or to indicate membership in the collective membership organization, listed in the existing registration.',
      '4) There has been no final decision adverse to the owner\'s claim of ownership of such mark for such goods/services, or to indicate membership in the collective membership organization, or to the owner\'s right to register the same or to keep the same on the register.',
      '5) There is no proceeding involving said rights pending and not finally disposed of either in the United States Patent and Trademark Office or in a court.',
      '6) To the best of the signatory\'s knowledge, information, and belief, formed after an inquiry reasonable under the circumstances, the allegations and other factual contentions made above have evidentiary support.',
      '7) The signatory being warned that willful false statements and the like are punishable by fine or imprisonment, or both, under 18 U.S.C. § 1001, and that such willful false statements and the like may jeopardize the validity of this submission and the registration, declares that all statements made of his/her own knowledge are true and all statements made on information and belief are believed to be true.'
    ],
  },
];

/** Return any declarations that should be included despite any automatic discovery of declarations. */
export function manualDeclarations(classes: Class[]): DisplayedDeclaration[] {
  // Return manual declarations where the specified key is true in at least one class
  return MANUAL_DECLARATIONS.filter(({ key }) => classes.some((cls) => cls[key]));
}
