import { DateTime, Interval } from 'luxon';
import { last } from 'lodash-es';

/**
 * Construct the interval for a 6-year anniversary renewal of registration.
 *
 * There is only one 6-year anniversary.
 *
 * Renewals are permitted within 1 calendar year of the 6-year deadline, plus a 6 month grace period.
 */
const renewal815Interval = (registrationDate: DateTime): Interval => {
  const deadline = registrationDate.plus({ years: 6 });
  return deadline.minus({ years: 1 }).until(deadline.plus({ months: 6 }));
};

/**
 * Construct the interval for a 10-year anniversay renewal of registration.
 *
 * As long as the registration is not abandoned, it must be renewed every 10 years.
 *
 * Renewals are permitted within 1 calendar year of the 10-year deadline, plus a 6 month grace period.
 *
 * |******---------------------************|
 * ^   ^- Grace Period (6 mo)       ^      ^- Deadline (year 10)
 *  \ Reg/Renewal                    \ Renewal window (years 9 through 10)
 *
 */
const renewal89Interval = (registrationDate: DateTime, at: DateTime): Interval => {
  // Take the remainder interval after 10-calendar-year intervals since registration
  const renewalPeriod = registrationDate.until(at).splitBy({ years: 10 }).pop();
  // A this point, the start of the renewal period tell us *a* deadline.
  // However, we could be in the first 6 months of the next renewal period (grace period)
  // or the last 1 year of the current renewal period (normal renewal)
  // or the middle 8.5 years (no renewal permitted).
  const isGracePeriod = renewalPeriod.length('months') < 6;
  // If we're in the grace period, `start` is our active deadline; otherwise it's 10 years later.
  const deadline = isGracePeriod ? renewalPeriod.start : renewalPeriod.start.plus({ years: 10 });
  // Return the interval surrounding the active deadline
  return deadline.minus({ years: 1 }).until(deadline.plus({ months: 6 }));
};

/**
 * INTERNAL: Description of a renewal interval.
 *
 * This renewal period description is an intermediate value for calculations.
 */
interface RenewalInterval {
  renewalPeriod: '815' | '89';
  interval: Interval;
}

/** A nullish return value when there is no active renewal period. */
interface NoRenewal {
  renewalPeriod: null;
  gracePeriod: false;
}

/** Allowed return values for renewal period calculations. */
export type PermittedRenewal
  = { renewalPeriod: '815' | '89'; gracePeriod: boolean; }
  | NoRenewal;

/** A concrete nullish return value when there is no active renewal period. */
export const NO_RENEWAL: NoRenewal = { renewalPeriod: null, gracePeriod: false };

/**
 * Determine which, if any, renewal is permitted at the given time for the given registration date.
 *
 * The returned object names the renewalPeriod and whether the give time falls into the post-deadline
 * grace-period.
 */
export const allowedRenewalPeriod = (registrationDate: DateTime | null, at: DateTime): PermittedRenewal => {
  if (!registrationDate) {
    return NO_RENEWAL;
  }
  // Calculate the renewal intervals from the registration date.
  // n.b., these will never overlap.
  const renewalPhases: RenewalInterval[] = [
    { renewalPeriod: '815', interval: renewal815Interval(registrationDate) },
    { renewalPeriod: '89', interval: renewal89Interval(registrationDate, at) },
  ];
  // Identify the renewal phase
  const eligibleRenewal = renewalPhases.find(({ interval: x }) => x.contains(at));
  if (!eligibleRenewal) {
    return NO_RENEWAL;
  }
  // Unpack the renewal phase and determine if the date is within the grace period.
  const { interval, renewalPeriod } = eligibleRenewal;
  const gracePeriod = interval?.end.minus({ months: 6 }).until(interval.end).contains(at) ?? false;

  return { renewalPeriod, gracePeriod };
};

