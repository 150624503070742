export const caseStatusFragmentFields = `
id: serialNumber
abandonmentDate
createdAt
error
examinerEmail
examinerName
examinerPhone
extensionCount
firstSection8DeadlineDate
latestRenewalDate
noticeOfAllowanceDate
officeActionDate
officeActionPending
oppositionDeadlineDate
publishedDate
registeredDate
renewalCount
renewalDeadlineDate
responseDate
responseDeadlineDate
revivalDeadlineDate
serialNumber
souDeadlineDate
stage
statusCode
statusDesc
unresponsiveDate
updatedAt
`;

export const caseStatusFragment = `fragment caseStatusFragment on CaseStatus {
  ${caseStatusFragmentFields}
}`;
