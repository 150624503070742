import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedMaterialModule } from '@markmachine/shared/materials/shared-materials.module';
import { SharedModule } from '@markmachine/shared/shared.module';
import { ContextMenuModule } from '../context-menu/context-menu.module';
import { ExpansionPanelModule } from '../expansion-panel/expansion-panel.module';
import { TipModule } from '../tip/tip.module';
import { UserNoteModule } from '../user-note/user-note.module';
import { CaseNoteHelperItemComponent } from './components/case-note-helper-item/case-note-helper-item.component';
import { CaseNoteHelperComponent } from './components/case-note-helper/case-note-helper.component';
import { CommonExpansionPanelComponent } from './components/common-expansion-panel/common-expansion-panel.component';
import { ExpansionPanelHelperComponent } from './components/expansion-panel-helper/expansion-panel-helper.component';
import { TipHelperComponent } from './components/tip-helper/tip-helper.component';
import { ExpansionPanelHelperDirective } from './directives/expansion-panel-helper.directive';
import { ExpansionPanelOpenerDirective } from './directives/expansion-panel-opener.directive';

@NgModule({
  declarations: [
    ExpansionPanelHelperDirective,
    ExpansionPanelHelperComponent,
    TipHelperComponent,
    CommonExpansionPanelComponent,
    CaseNoteHelperComponent,
    ExpansionPanelOpenerDirective,
    CaseNoteHelperItemComponent
  ],
  exports: [
    ExpansionPanelHelperDirective,
    CommonExpansionPanelComponent,
    CaseNoteHelperComponent,
    ExpansionPanelOpenerDirective
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedMaterialModule,
    SharedModule,
    ExpansionPanelModule,
    ContextMenuModule,
    TipModule,
    UserNoteModule
  ]
})
export class ExpansionPanelHelperModule {}
