import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as fromUserNote from '../actions/user-note.actions';
import { UserNote } from '../models/user-note.model';
import { unpackGraphqlResponse } from '@markmachine/core/graphql-operators';
import { CreateUserNoteResponse, CreateUserNoteMutation } from './create-user-note.mutation';
import { UpdateUserNoteResponse, UpdateUserNoteMutation } from './update-user-note.mutation';
import { DeleteUserNoteResponse, DeleteUserNoteMutation } from './delete-user-note.mutation';

const { GRAPHQL } = environment;


@Injectable()
export class UserNoteEffects {

  /** Create new UserNote on server */
  createUserNote$ = createEffect(() => this.actions$.pipe(
    ofType(fromUserNote.addUserNote),
    mergeMap(action => this.createUserNote(action.userNote).pipe(
      map(userNote => fromUserNote.addUserNoteSuccess({ userNote })),
      catchError(error => of(fromUserNote.addUserNoteFailure({ userNote: action.userNote, error })))
    )),
  ));

  /** Update a UserNote on server */
  updateUserNote$ = createEffect(() => this.actions$.pipe(
    ofType(fromUserNote.updateUserNote),
    mergeMap((action) => this.updateUserNote(action.userNote.id as string, action.userNote.changes).pipe(
      map(userNote => fromUserNote.updateUserNoteSuccess({ userNote: { id: userNote.id as string, changes: userNote } })),
      catchError((error: any) => of(fromUserNote.updateUserNoteFailure({ userNote: action.userNote, error })))
    )),
  ));

  /** Delete a UserNote on server */
  deleteUserNote$ = createEffect(() => this.actions$.pipe(
    ofType(fromUserNote.deleteUserNote),
    mergeMap(action => this.deleteUserNote(action.id as string).pipe(
      map(userNote => fromUserNote.deleteUserNoteSuccess({ userNote })),
      catchError((error: any) => of(fromUserNote.deleteUserNoteFailure({ id: action.id, error })))
    ))
  ));

  constructor(private actions$: Actions, private http: HttpClient) {}

  createUserNote(userNote: UserNote): Observable<UserNote> {
    return this.http
      .post<CreateUserNoteResponse>(GRAPHQL, new CreateUserNoteMutation({ userNote }))
      .pipe(unpackGraphqlResponse('data.createUserNote.userNote'));
  }

  updateUserNote(id: string, userNotePatch: Partial<UserNote>): Observable<UserNote> {
    return this.http
      .post<UpdateUserNoteResponse>(GRAPHQL, new UpdateUserNoteMutation({ id, userNotePatch }))
      .pipe(unpackGraphqlResponse('data.updateUserNoteById.userNote'));
  }

  deleteUserNote(id: string): Observable<UserNote> {
    return this.http
      .post<DeleteUserNoteResponse>(GRAPHQL, new DeleteUserNoteMutation({ id }))
      .pipe(unpackGraphqlResponse('data.deleteUserNoteById.userNote'));
  }
}
