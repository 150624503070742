import { UserNote } from '../models/user-note.model';
import { GraphQLQuery, GraphQLResponse } from '@markmachine/interfaces';

const query = `
mutation UpdateUserNote($id: UUID!, $userNotePatch: UserNotePatch!) {
  updateUserNoteById(input: {id: $id, userNotePatch: $userNotePatch}) {
    userNote {
      id
      userId
      caseId
      createdAt
      updatedAt
      location
      note
    }
  }
}

`;

export class UpdateUserNoteMutation implements GraphQLQuery {
  query = query;

  constructor(public variables: { id: string, userNotePatch: Partial<UserNote> }) {}
}


export type UpdateUserNoteResponse = GraphQLResponse<{
  updateUserNoteById: {
    userNote: UserNote;
  }
}>;
