import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AccountService } from '../../services/account.service';

export interface SignInDialogData {
  requireAdmin: boolean;
}

@Component({
  selector: 'mm-sign-in-dialog',
  templateUrl: './sign-in-dialog.component.html',
  styleUrls: ['./sign-in-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignInDialogComponent implements OnDestroy, OnInit {
  destroy$ = new Subject();
  formGroup: FormGroup;
  DEFAULT_DATA: SignInDialogData = { requireAdmin: false };

  constructor(
    public account: AccountService,
    private dialogRef: MatDialogRef<SignInDialogComponent, boolean>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: SignInDialogData
  ) {}

  ngOnInit() {
    this.buildForm();
    // Combine defaults with any provided data
    const { requireAdmin } = { ...this.DEFAULT_DATA, ...this.data };
    // Get the specified authorization state
    const isAuthorized$ = requireAdmin ? this.account.isAdmin$ : this.account.signedIn$;
    // Emit a closing event once the specified authorization state is reached
    const shouldClose$ = isAuthorized$.pipe(
      filter(isAuthorized => isAuthorized),
      takeUntil(this.destroy$) // cleanup
    );
    // As soon as the close event is emitted, close the dialog.
    shouldClose$.subscribe(() => this.dialogRef.close(true));
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  buildForm() {
    this.formGroup = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.required]
    });
  }

  authorize() {
    const { email, password } = this.formGroup.value;
    if (this.formGroup.valid) {
      this.account.authorize(email, password);
    }
  }

  register() {
    const { email, password } = this.formGroup.value;
    if (this.formGroup.valid) {
      this.account.register(email, password);
    }
  }

  getEmailErrorMessage() {
    const email = this.formGroup.get('email') as FormControl;
    return email.hasError('required') //
      ? 'You must enter an email'
      : email.hasError('email')
      ? 'Not a valid email'
      : null;
  }
}
