import { Update } from '@ngrx/entity';
import { CaseStatus as Status } from '@markmachine/features/case-status/models/status.model';
import { createAction, props } from '@ngrx/store';

export const loadStatuses = createAction('[Status] Load Statuses', props<{ statuses: Status[] }>());
export const addStatus = createAction('[Status] Add Status', props<{ status: Status }>());
export const upsertStatus = createAction('[Status] Upsert Status', props<{ status: Status }>());
export const addStatuses = createAction('[Status] Add Statuses', props<{ statuses: Status[] }>());
export const updateStatus = createAction('[Status] Update Status', props<{ status: Update<Status> }>());
export const refreshStatuses = createAction('[Status] Refresh Statuses', props<{ caseIds: string[] }>());
export const upsertStatuses = createAction('[Status] Upsert Statuses', props<{ statuses: Status[] }>());
export const updateStatuses = createAction('[Status] Update Statuses', props<{ statuses: Update<Status>[] }>());
export const deleteStatus = createAction('[Status] Delete Status', props<{ id: number }>());
export const deleteStatuses = createAction('[Status] Delete Statuses', props<{ ids: number[] }>());
export const clearStatuses = createAction('[Status] Clear Statuses', props<{ caseIds: string[] }>());
/** Notice that a refresh attempt failed. */
export const refreshStatusesFailure = createAction('[Status] Refresh Status Failure', props<{ errorMessage: string }>());
/** Activate case-status polling until those statuses are as recent as `until`. */
export const pollingStatusesInit = createAction('[Status] Polling Statuses Init', props<{ ids: number[], until: string }>());
/** Notice that a polling attempt failed. */
export const pollingStatusesFailure = createAction('[Status] Polling Failure', props<{ errorMessage: string }>());
