import { ChangeDetectorRef, Directive, ElementRef, OnDestroy, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ExpansionPanelOpenerDirective } from '@markmachine/features/expansion-panel-helper/directives/expansion-panel-opener.directive';
import { CaseFormValidationService } from '@markmachine/features/version-form/services/case-form-validation.service';
import { FormControlElementRefService } from '@markmachine/features/version/services/form-control-element-ref.service';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[formControl], [formControlName]'
})
export class FormControlElementRefDirective implements OnDestroy {

  constructor(
    private service: FormControlElementRefService,
    private caseFormValidation: CaseFormValidationService,
    public elementRef: ElementRef,
    public control: NgControl,
    public changeDetectorRef: ChangeDetectorRef,
    @Optional() public opener: ExpansionPanelOpenerDirective,
  ) { }

  ngAfterViewInit() {
    const fullPath = this.caseFormValidation.getFullControlPath(this.control.control, true);
    this.service.unregister(fullPath);
    this.service.register(fullPath, this);
  }

  /** When our host deconstructs, unregister. */
  ngOnDestroy() {
    const fullPath = this.caseFormValidation.getFullControlPath(this.control.control, true);
    this.service.unregister(fullPath);
  }
}
