import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { build } from 'environments/build';

Sentry.init({
  dsn: environment.SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['localhost', 'vercel.app', 'https://www.markmachine.com'],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new CaptureConsoleIntegration({
      levels: ['warn', 'error', 'assert'],
    }),
  ],
  release: build.VERSION,
  environment: build.VERCEL_ENV
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
