<button class="empty-button" *ngIf="isEmpty()"
  mat-icon-button
  disabled>
  <mat-icon>more_vert</mat-icon>
</button>

<button class="vellipsis-button" *ngIf="!isEmpty()"
  mat-icon-button
  [matMenuTriggerFor]="appMenu">
  <mat-icon>more_vert</mat-icon>
</button>

<mat-menu #appMenu="matMenu">
  <ng-template matMenuContent>
    <button mat-menu-item *ngFor="let option of vellipsisMenuOptions; trackBy: trackByFn"
      (click)="onMenuOptionClick(option)"
      [disabled]="option.disabled">
      {{ option.name }}
    </button>
  </ng-template>
</mat-menu>
