import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Version, VersionId, StatusEnum } from '@markmachine/features/version/models/version.model';
import { VersionContent } from '@markmachine/features/version/models/version-content.model';
import { GoodService } from '@markmachine/features/goods-services-selector/models/good-service.model';

// **************************************************************************
// Local CRUD
// **************************************************************************

export const loadVersions = createAction('[Version] Load Versions', props<{ versions: Version[] }>());
export const addVersion = createAction('[Version] Add Version', props<{ version: Version }>());
export const upsertVersion = createAction('[Version] Upsert Version', props<{ version: Version }>());
export const addVersions = createAction('[Version] Add Versions', props<{ versions: Version[] }>());
export const upsertVersions = createAction('[Version] Upsert Versions', props<{ versions: Version[] }>());
export const updateVersion = createAction('[Version] Update Version', props<{ version: Update<Version> }>());
export const updateVersions = createAction('[Version] Update Versions', props<{ versions: Update<Version>[] }>());
export const deleteVersion = createAction('[Version] Delete Version', props<{ id: string }>());
export const deleteVersions = createAction('[Version] Delete Versions', props<{ ids: string[] }>());
export const clearVersions = createAction('[Version] Clear Versions');

// **************************************************************************
// Network Requests
// **************************************************************************

export const renameVersion = createAction('[Version] Rename Version', props<{ id: string; title: string }>());
export const renameVersionSuccess = createAction('[Version] Rename Version Success', props<{ id: string; title: string }>());
export const renameVersionFailure = createAction('[Version] Rename Version Failure');
export const createVersion = createAction('[Version] Create Version', props<{ version: Version }>());
export const createVersionSuccess = createAction('[Version] Create Version Success', props<{ version: Version }>());
export const createVersionFailure = createAction('[Version] Create Version Failure');
export const snapshotVersion = createAction('[Version] Snapshot Version', props<{ version: Version }>());
export const snapshotVersionSuccess = createAction('[Version] Snapshot Version Success', props<{ version: Version }>());
export const snapshotVersionFailure = createAction('[Version] Snapshot Version Failure');
export const remoteUpdate = createAction('[Version] Remote Update', props<{ id: VersionId }>());
export const remoteUpdateSuccess = createAction('[Version] Remote Update Success', props<{ version: Version }>());
export const remoteUpdateFailure = createAction('[Version] Remote Update Failure', props<{ version: Partial<Version> }>());
export const remoteDeleteSuccess = createAction('[Version] Remote Delete Success', props<{ id: VersionId }>());
export const remoteDeleteFailure = createAction('[Version] Remote Delete Failure');
export const unlockVersion = createAction('[Version] Unlock Version', props<{ id: VersionId }>());
export const remoteUnlockSuccess = createAction('[Version] Unlock Version Success', props<{ id: VersionId; status: StatusEnum }>());
export const remoteUnlockFailure = createAction('[Version] Unlock Version Failure', props<{ id: VersionId }>());

// **************************************************************************
// Load Version List
// **************************************************************************

export const loadVersionList = createAction('[Version] Load Version List');
export const LoadVersionListSuccess = createAction('[Version] Load Version List Success', props<{ versions: Version[] }>());
export const loadVersionListFailure = createAction('[Version] Load Version List Failure');

// **************************************************************************
// Load Draft Version
// **************************************************************************

export const loadDraft = createAction('[Version] Load Draft', props<{ versionId: string }>());
export const loadVersionSuccess = createAction('[Version] Load Version Success', props<{ version: Version }>());
export const loadVersionFailure = createAction('[Version] Load Version Failure');

// **************************************************************************
// Update Version
// **************************************************************************

export const patchDraft = createAction('[Version] Patch Draft', props<{ content: VersionContent }>());

// **************************************************************************
// Delete Version
// **************************************************************************

export const deleteVersionSuccess = createAction('[Version] Delete Success', props<{ id: string }>());
export const deleteVersionFailure = createAction('[Version] Delete Failure');

// **************************************************************************
// Cancel Finalization/Submission of Version
// **************************************************************************

export const cancelSubmitVersion = createAction('[Version] Cancel Submit', props<{ version: Version }>());

// **************************************************************************
// Modify draft content
// **************************************************************************

export const revertVersion = createAction('[Version] Revert');

// **************************************************************************
// Create Blank Case Version
// **************************************************************************

export const createBlankCaseVersionFailure = createAction(
  '[Version] Create Blank Case Version Failure',
  props<{ error: any }>()
);

// **************************************************************************
// Goods and Services
// **************************************************************************

export const upsertGoodsAndServices = createAction(
  '[Version] Upsert Goods And Services',
  props<{ id: string; goodsServices: GoodService[] }>()
);

// **************************************************************************
// Filing Status (new apps & convenience properties)
// **************************************************************************

export const updateStatusFields = createAction(
  '[Version] Update Plus Status',
  props<{ id: string; currentlyAsTeasPlusApp: boolean, filedAsTeasPlusApp: boolean; status: number; }>()
);

// **************************************************************************
// Guides
// **************************************************************************

export const selectGuides = createAction(
  '[Version] Select Guides',
  props<{ id: string; guideIds: string[] }>()
);

export const deselectGuides = createAction(
  '[Version] Deselect Guides',
  props<{ id: string; guideIds: string[] }>()
);

export const cloneCaseVersionFailure = createAction('[Version] Clone Case Version Failure', props<{ errorMessage: string }>());
