import { ComponentFactoryResolver, ComponentRef, Directive, Input, OnInit, ViewContainerRef } from '@angular/core';
import { NgControl, AbstractControl } from '@angular/forms';
import { MatRadioGroup } from '@angular/material/radio';
import { ClearValueButtonComponent } from '@markmachine/shared/components/clear-value-button/clear-value-button.component';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'mat-radio-group[formControlName], mat-radio-group[formControl], mat-radio-group[ngModel]'
})
export class RadioClearValueDirective implements OnInit {
  @Input() noClearButton = false;
  button: ComponentRef<ClearValueButtonComponent>;

  constructor(
    private control: NgControl,
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef,
    private radioGroup: MatRadioGroup) {
  }

  ngOnInit() {
    if (!this.noClearButton) {
      const factory = this.resolver.resolveComponentFactory(ClearValueButtonComponent);
      this.button = this.container.createComponent(factory);
      this.button.instance.radioGroup = this.radioGroup;
      this.button.instance.control = this.control.control as AbstractControl;
    }
  }
}
