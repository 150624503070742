import { Component, Input, OnInit } from '@angular/core';
import { UserCase } from '@markmachine/features/user-case/models/user-case.model';
import { SupertableHeaderTypes } from '../../models/header-models';
import { RowModel } from '../../models/row-model';

@Component({
  selector: 'mm-data-cell',
  templateUrl: './data-cell.component.html',
  styleUrls: ['./data-cell.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataCellComponent implements OnInit {

  @Input() header: any;
  @Input() row: RowModel;

  constructor() { }

  ngOnInit() {}

  isCurrentEventValid(row: UserCase): boolean {
    return !!(row.caseByCaseId.serialNumber);
  }

  determineRouterLink(row: any): 'response' | 'new-app' | 'pending' | 'permanent-failure' {
    if (!!row.caseByCaseId?.latestUsptoOrigin) {
      return 'response';
    } else if (!!row.caseByCaseId?.serialNumber) {
      if (row.status && row.status.error && row.status.error.startsWith('permanent failure')) {
        return 'permanent-failure';
      } else {
        return 'pending';
      }
    } else {
      return 'new-app';
    }
  }

  getCaseUrl(row: UserCase): string[] {
    return ['/', (row.caseByCaseId?.serialNumber ? 'fix' : 'apply'), row.caseId];
  }

}
