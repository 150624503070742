import { UserNote } from '../models/user-note.model';
import { GraphQLQuery, GraphQLResponse } from '@markmachine/interfaces';

const query = `
mutation DeleteUserNote($id: UUID!) {
  deleteUserNoteById(input: {id: $id}) {
    userNote {
      id
      userId
      caseId
      createdAt
      updatedAt
      location
      note
    }
  }
}

`;

export class DeleteUserNoteMutation implements GraphQLQuery {
  query = query;

  constructor(public variables: { id: string }) {}
}


export type DeleteUserNoteResponse = GraphQLResponse<{
  deleteUserNoteById: {
    userNote: UserNote;
  }
}>;
