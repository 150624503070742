import { deepClone } from '@markmachine/core/functions/utilities';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { at, defaultTo, get, isEmpty, keys, sortBy, update } from 'lodash-es';
import * as IssueActions from '../actions/issue.actions';
import { Issue } from '../models/issue.model';


// **************************************************************************
// State
// **************************************************************************

export const issuesFeatureKey = 'issues';

interface Category {
  [category: string]: Category;
}

interface IssuesByCategory {
  [menuPath: string]: string[];
}

export interface State extends EntityState<Issue> {
  // additional entities state properties
  categories: Category;
  issuesByCategory: IssuesByCategory;
}

export const adapter: EntityAdapter<Issue> = createEntityAdapter<Issue>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
  categories: {},
  issuesByCategory: {}
});


// **************************************************************************
// Reducers
// **************************************************************************

export const reducer = createReducer(
  initialState,

  // **************************************************************************
  // @ngrx/entities
  // **************************************************************************
  on(IssueActions.loadIssues, (state, { issues }) => {
    const entityState = adapter.setAll(issues, state);
    const issuesByCategory = menuReducer(state, issues);
    const categories = categoryReducer(state, issues);
    return { ...entityState, categories, issuesByCategory };
  }),
  // on(IssueActions.addIssue, (state, { issue }) => adapter.addOne(issue, state)),
  // on(IssueActions.upsertIssue, (state, { issue }) => adapter.upsertOne(issue, state)),
  // on(IssueActions.addIssues, (state, { issues }) => adapter.addMany(issues, state)),
  // on(IssueActions.upsertIssues, (state, { issues }) => adapter.upsertMany(issues, state)),
  // on(IssueActions.updateIssue, (state, { issue }) => adapter.updateOne(issue, state)),
  // on(IssueActions.updateIssues, (state, { issues }) => adapter.updateMany(issues, state)),
  // on(IssueActions.deleteIssue, (state, { id }) => adapter.removeOne(id, state)),
  // on(IssueActions.deleteIssues, (state, { ids }) => adapter.removeMany(ids, state)),
  // on(IssueActions.clearIssues, state => adapter.removeAll(state))
);

export function categoryReducer(state: State, issues: Issue[]): Category {
  const result = deepClone(state.categories);
  issues
    .filter(({ menuPath }) => menuPath && menuPath.length > 0)
    .forEach(({ menuPath }) => update(result, menuPath as string[], value => value || {}));
  return result;
}

const MENU_PATH_DELIMITER = ' » ';
export function menuReducer(state: State, issues: Issue[]): IssuesByCategory {
  const result = deepClone(state.issuesByCategory);
  issues
    .filter(({ menuPath }) => menuPath && menuPath.length > 0)
    .forEach(({ menuPath, id }) =>
      update(result, menuPath?.join(MENU_PATH_DELIMITER) as string, (arr: string[] | undefined) => [...(arr || []), id])
    );
  return result;
}


// **************************************************************************
// Selectors
// **************************************************************************

export const getIssueState = createFeatureSelector<State>(issuesFeatureKey);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(getIssueState);

//
// Issue Selection Menu (Hierarchical)
//

export const getSelectableIssues = createSelector(
  selectAll,
  issues => sortBy(defaultTo(issues, []).filter(i => i.severity === 'Requirement'), ['menuName'])
);

/** Get the names of child menus */
export const getSelectableIssueMenu = () => createSelector(
  getIssueState,
  ({ categories }: State, { prefix }) => sortBy(keys(isEmpty(prefix) ? categories : get(categories, prefix)))
);

export const getSelectableIssueMenuItems = () =>
  createSelector(getIssueState, ({ entities, issuesByCategory }: State, { prefix }) => {
    const issueIds = issuesByCategory[prefix.join(MENU_PATH_DELIMITER)];
    return issueIds ? sortBy(at(entities, issueIds) as Issue[], ['menuName']) : [];
  });
