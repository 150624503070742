<button mat-button
  [matMenuTriggerFor]="visibilityMenu">
  Toggle Visibility
</button>

<mat-menu #visibilityMenu="matMenu">
  <ng-template matMenuContent>
    <mat-checkbox *ngFor="let header of headers"
      class="supertable-menu-item mat-menu-item"
      [checked]="isVisible(header)"
      (change)="toggleVisible(header)">{{ header.name }}</mat-checkbox>
  </ng-template>
</mat-menu>