<mm-expansion-panel [mmContextMenuPath]="menuPath()"
  [expanded]="expanded"
  [disabled]="disabled">
  <mm-expansion-panel-title>
    {{ expansionPanelTitle }}
    <ng-content select=".title-decoration"></ng-content>
  </mm-expansion-panel-title>
  <mm-expansion-panel-actions>
    <ng-content select=".mm-expansion-panel-action"></ng-content>
    <mm-context-menu-icon-button>
      <ng-content select="[mat-menu-item]"></ng-content>
    </mm-context-menu-icon-button>
  </mm-expansion-panel-actions>
  <ng-template mmExpansionPanelHelper></ng-template>
  <ng-content></ng-content>
</mm-expansion-panel>
