<mat-list>

  <mat-list-item *ngFor="let casefile of casefiles; let count=count" class="mat-body-1">

    <mat-icon mat-list-icon>folder</mat-icon>

    <h4 mat-line class="mat-body-1">
      <span *ngIf="casefile.status === 'uploading'"
        class="uploading-tag">Uploading…</span>
      {{casefile.filepath || casefile.file?.name}}
    </h4>

    <p mat-line>{{ casefile.createdAt | safeDate:'short' }}</p>

    <mat-progress-bar mat-line
      *ngIf="progressBarMode(casefile.status, casefile.progress); let mode"
      [mode]="mode"
      [value]="casefile.progress"></mat-progress-bar>

    <ng-container [ngSwitch]="casefile.status">
      <button *ngSwitchCase="'error'"
        mat-icon-button
        class="secondary-action"
        color="warn"
        [matTooltip]="casefile.statusMessage">
        <mat-icon>error</mat-icon>
      </button>
      <button mat-icon-button
        *ngIf="!casefile.status && !disabled"
        class="secondary-action"
        color="warn"
        (click)="removeFile(casefile.id)"
        matTooltip="Remove">
        <mat-icon>remove_circle</mat-icon>
      </button>
    </ng-container>
  </mat-list-item>

  <mat-list-item *ngIf="!disabled">
    <h4 fxFlex="auto" class="mat-subheading-1">
      <span *ngIf="casefiles.length === 0">No Files Attached</span>
      <span *ngIf="casefiles.length > 0">Attach More Files</span>
    </h4>

    <!-- invisible file input... -->
    <input type="file"
      style="display: none"
      [multiple]="multiple"
      (change)="uploadFiles(upload.files)"
      #upload>

    <!-- ... controlled by this button -->
    <button mat-raised-button
      fxFlex="none"
      (click)="upload.click()"
      color="primary">
      ATTACH
    </button>

    <button *ngIf="tipAnchor"
      fxFlex="none"
      mat-icon-button
      color="primary"
      [mmShowTip]="tipAnchor">
      <mat-icon>help</mat-icon>
    </button>
  </mat-list-item>
</mat-list>