/**
 * Core Module
 *
 * Core is critical functionality that must be loaded into AppModule.
 *
 * Services that must be instantiated once and ONLY once (singletons)
 * live here.
 *
 * No Components can be used from Core Module.
 */
import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { LayoutEffects } from '@markmachine/core/effects/layout.effects';
import { AccountModule } from '@markmachine/features/account/account.module';
import { UserProfileGuard } from '@markmachine/features/account/guards/user-profile.guard';
import { AccountMenuService } from '@markmachine/features/account/services/account-menu.service';
import { AccountRequestService } from '@markmachine/features/account/services/account-request.service';
import { LogModule } from '@markmachine/features/log/log.module';
import { UserCasesGuard } from '@markmachine/features/user-case/guards/user-cases.guard';
import { UserTablesGuard } from '@markmachine/features/user-table/guards/user-tables.guard';
import { EffectsModule } from '@ngrx/effects';
import { RouterEffects } from './effects/router.effects';
import { IconService } from './services/icon.service';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([
      LayoutEffects,
      RouterEffects,
    ]),
    LogModule,
    AccountModule,
  ],
  providers: [
    AccountMenuService,
    AccountRequestService,
    IconService,
    UserCasesGuard,
    UserTablesGuard,
    UserProfileGuard,
  ]
})
export class CoreModule {
  /** Prevent reimport of the CoreModule */
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
