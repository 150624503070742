import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GraphQLResponse } from '@markmachine/interfaces';
import { environment } from 'environments/environment';

const { GRAPHQL } = environment;

@Injectable({
  providedIn: 'root'
})
export abstract class Mutation<T, V = unknown> {
  protected document: string;

  constructor(protected http: HttpClient) { }

  mutate(variables?: V) {
    return this.http.post<GraphQLResponse<T>>(GRAPHQL, { query: this.document, variables });
  }
}


