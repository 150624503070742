import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface NoticeDialogData {
  dismissLabel?: string;
  markdown?: boolean;
  message: string;
  title?: string;
}


@Component({
  selector: 'mm-notice-dialog',
  templateUrl: './notice-dialog.component.html',
  styleUrls: ['./notice-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoticeDialogComponent implements OnInit {
  @Input() title: string | null = null;
  @Input() message = '';
  @Input() markdown = true;
  @Input() dismissLabel = 'DISMISS';

  constructor(@Inject(MAT_DIALOG_DATA) private data: NoticeDialogData) { }

  ngOnInit() {
    const { title, message, markdown, dismissLabel } = this.data;
    this.title = title ?? this.title;
    this.message = message ?? this.message;
    this.markdown = markdown ?? this.markdown;
    this.dismissLabel = dismissLabel ?? this.dismissLabel;
  }

}
