import { createAction, props } from '@ngrx/store';
import { FormDeclaration } from '../models/form-declaration.model';

/** Request declarations for the identified Version. */
export const fetchDeclarationsByVersionId = createAction(
  '[Declaration] Fetch Decs by VersionId',
  props<{ versionId: string }>()
);

/** Successful response for fetchDeclarationsByVersionId */
export const fetchDeclarationsByVersionIdSuccess = createAction(
  '[Declaration] Fetch Declarations Success',
  props<{ versionId: string; data: FormDeclaration[] }>()
);

/** Failure response for fetchDeclarationsByVersionId */
export const fetchDeclarationsByVersionIdFailure = createAction(
  '[Declaration] Fetch Declarations Failure',
  props<{ versionId: string; error: any }>()
);
