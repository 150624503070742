import { GraphQLQuery, GraphQLResponse } from 'app/interfaces';
import { UserProfile, UserProfileFlags, AuthenticatedUserProfile } from '@markmachine/features/account/models/user-profile.model';

export class RegisterUserQuery implements GraphQLQuery {
  query = `
  mutation RegisterUser($email: Email!, $password: String!) {
    registerUser(input: {email: $email, password: $password}) {
      jwtToken
      currentProfile {
        userId
        flags
      }
    }
  }
  `;
  constructor(public variables: {email: string, password: string}) { }
}

export type RegisterUserResponse = GraphQLResponse<{
  registerUser: {
    jwtToken: string;
    currentProfile: AuthenticatedUserProfile
  }
}>;
