import { CurrentVersionContent, VersionContent } from '@markmachine/features/version/models/version-content.model';

export type StatusEnum =
  | 'FROZEN'
  | 'UNLOCKED'
  | 'LOCKED'
  | 'SUBMITTED'
  | 'RESERVED'
  | 'COMPLETED'
  | 'FAILED'
  | 'ABORTED'
  | 'NOTIFIED';

export const SubmittedStatuses = [
  'SUBMITTED',
  'RESERVED',
  'COMPLETED',
  'FAILED',
  'ABORTED',
  'NOTIFIED'
];

export type SubmissionMethod = 'TEAS' | 'PHONE' | 'EMAIL';
export type VersionId = string;

export class Version {
  readonly content: VersionContent = new VersionContent();
  readonly createdAt: string;
  readonly createdBy: string;
  readonly format: number = 3;
  readonly id: VersionId;
  readonly idCase: string;
  readonly idParent: string;
  readonly isCurrent: boolean = false;
  readonly isDraft: boolean = false;
  readonly status: StatusEnum = 'LOCKED';
  readonly title: string;
  readonly updatedAt: string;
  readonly usptoOrigin: string | null;
  readonly submissionMethod: SubmissionMethod;
}

export class CurrentVersion extends Version {
  readonly content: CurrentVersionContent = new CurrentVersionContent();
}
