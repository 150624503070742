export const userNoteFragmentFields = `
id
caseId
userId
createdAt
updatedAt
location
note
userProfileByUserId {
  nodeId
  userId
  fullName
}
`;

export const userNoteFragment = `fragment userNoteFragment on UserNote {
  ${userNoteFragmentFields}
}`;
