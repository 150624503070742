import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Class, ExtensionRequest } from '@markmachine/features/version/models/version-content.model';
import { updateFormArray } from '@markmachine/core/functions/utilities';
import * as jsdiff from 'diff';

@Injectable()
export class CaseFormExtensionRequestService {
  constructor(private fb: FormBuilder) {}

  /**
   * Update length and contents of FormArray for classes.
   * @param array FormArray for classes
   * @param values New values for classes
   */
  update(array: FormArray, values: Class[], current: Class[]): void {
    const extensions = this._extensions(values, current);
    updateFormArray(array, extensions, (current_, i) => this.create(current_[i]));
  }

  /**
   * Create a new class
   * @param newClass Fields to set in class
   */
  create(newClass: Class): FormGroup {
    const group = this.fb.group(new ExtensionRequest());
    return group;
  }

  private _extensions(values: Class[], current: Class[]): ExtensionRequest[] {
    if (!current) {
      return [];
    }
    const extensions = current
      // Only classes that are currently 1b can request an extension
      .filter(cls => cls['filing-basis-current-1b-in'])
      .map(cls => this._extension(values, cls));
    // Only extend if an eligible class is requesting an extension
    const isRequestingExtension = !!extensions.find(a => a['$extension-requested']);
    // Otherwise, return an empty list
    return isRequestingExtension ? extensions : [];
  }

  /**
   * Calculate an ExtensionRequest, if any
   * @param values draft class
   * @param current current (USPTO) class
   */
  private _extension(values: Class[], current: Class): ExtensionRequest | null {
    // Find out what happened to the original class by class-code
    const value = values.find(v => v['class-code'] === current['class-code']);
    // Was the class 1b (ITU) and is now requesting an extension?
    return this._createExtensionFromClass(value, current);
  }

  /**
   * Create an ExtensionRequest from a Class.
   * @param value Form Value of draft Class
   * @param current Current (USPTO) value of Class
   * @param init initialization values for ExtensionRequest
   */
  private _createExtensionFromClass(
    value: Partial<Class> | undefined,
    current: Partial<Class>
  ): ExtensionRequest {
    let extension = new ExtensionRequest();
    // Was the class deleted from the draft?
    if (!value) {
      return { ...extension, 'allegation-of-use-ext-req': 'delete-class' };
    }
    for (const key in extension) {
      if (extension.hasOwnProperty(key)) {
        extension[key] = value[key];
      }
    }
    if (extension['allegation-of-use-ext-req'] === 'remove-goods-services') {
      extension = { ...extension, '$listing-remaining': value['listing'] };
    }
    return extension;
  }

  /**
   * Calculate goods/services deleted from draft
   * @param draft proposed listing of goods/services
   * @param current current (USPTO) listing of goods/services
   */
  private _deletedListing(draft: string, current: string) {
    // Find narrowings (deletions) of good/services
    // NOTE: grammar is handled naively at the moment
    const changes: string[] = jsdiff
      .diffWords(current, draft, { ignoreCase: true })
      .filter(change => change.removed)
      .map(change => change.value)
      .map(value => value.replace(/[^\w()-]/, '').trim());
    // Fall through to null if the result is the empty string
    return changes.length > 0 ? changes.join('; ') : undefined;
  }
}
