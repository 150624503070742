import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ConfirmDialogData {
  title?: string;
  message?: string;
  confirm?: string;
  cancel?: string;
}

@Component({
  selector: 'mm-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmDialogComponent implements OnInit {
  @Input() title = 'Confirmation';
  @Input() message = 'Are you sure?';
  @Input() confirm = 'CONFIRM';
  @Input() cancel = 'CANCEL';

  constructor(@Inject(MAT_DIALOG_DATA) private data: ConfirmDialogData) {}

  ngOnInit() {
    this.title = this.data.title || this.title;
    this.message = this.data.message || this.message;
    this.confirm = this.data.confirm || this.confirm;
    this.cancel = this.data.cancel || this.cancel;
  }
}
