import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

/**
 * Marks HTML as safe for injection into a template.
 * From http://stackoverflow.com/questions/38303065/inject-input-in-innerhtml-angular-2
 */
@Pipe({
    name: 'sanitizeHtml'
})
export class SanitizeHtmlPipe implements PipeTransform  {

   constructor (private _sanitizer: DomSanitizer) {}

   transform(v: string): SafeHtml {
      return this._sanitizer.bypassSecurityTrustHtml(v);
   }
}
