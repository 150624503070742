import { Pipe, PipeTransform } from '@angular/core';

import * as mustache from 'mustache';
import { CoreService } from '@markmachine/core/services/core.service';

export class MustachePipeError extends Error {
  public stack;
  constructor(public template, public args) {
    super();
  }
}

@Pipe({
  name: 'mustache'
})
export class MustachePipe implements PipeTransform {
  constructor(private core: CoreService) {}
  /**
   * RenderPipe.transform
   * Render mustache template using passed parameters.
   * Usage: `mustachetemplate | merge : params2 : params3 : params4`
   *
   * @param  {any} value Text to merge
   * @param  {any} args  One or more objects to merge into text
   * @return {string}    Merged text
   */
  transform(value: any, ...args: any[]): any {
    if (!value || !args || args.length === 0) {
      return value;
    }
    try {
      return mustache.render(value, Object.assign({}, ...args));
    } catch (e) {
      this.core.developerWarning(new MustachePipeError(value, args));
      return value;
    }
  }
}
