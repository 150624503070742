/**
 * Argument Builder
 *
 * Provides the Arugment Builder interface.
 */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TipModule } from '@markmachine/features/tip/tip.module';
import { SharedMaterialModule } from '@markmachine/shared/materials/shared-materials.module';
import { ArgumentDialogComponent } from './components/argument-dialog/argument-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedMaterialModule,
    TipModule
  ],
  declarations: [ArgumentDialogComponent],
  exports: [ArgumentDialogComponent]
})
export class ArgumentBuilderModule {}
