import { SelectionModel } from '@angular/cdk/collections';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Platform } from '@angular/cdk/platform';
import { StickyStyler } from '@angular/cdk/table';
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import { get } from 'lodash-es';
import {
  HeaderResizeEvent,
  HeaderResizeFinishEvent,
  HeaderVellipsisMenuClick,
  ReorderHeadersEvent,
  TableScrollEvent,
  VellipsisMenuClick
} from '../../models/event-models';
import { FeaturesModel } from '../../models/feature-models';
import { SupertableHeader } from '../../models/header-models';
import { RowModel } from '../../models/row-model';
import { VellipsisMenuOption } from '../vellipsis-button/vellipsis-button.component';

@Component({
  selector: 'mm-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent implements AfterViewChecked, OnInit, OnChanges {

  @Input() actions: SupertableHeader[];
  @Input() headers: SupertableHeader[];
  @Input() filteredRows: RowModel[];
  @Input() pagedRows: RowModel[];
  @Input() features: FeaturesModel;

  @Output() reorderHeaders = new EventEmitter<ReorderHeadersEvent>();
  @Output() headerResize = new EventEmitter<HeaderResizeEvent>();
  @Output() headerResizeFinish = new EventEmitter<HeaderResizeFinishEvent>();
  @Output() tableScroll = new EventEmitter<TableScrollEvent>();
  @Output() headerVellipsisMenuClick = new EventEmitter<HeaderVellipsisMenuClick>();

  selection = new SelectionModel<string>(true, []);

  @ViewChildren('headerRow') headerRows: QueryList<ElementRef>;
  @ViewChildren('dataRow') dataRows: QueryList<ElementRef>;

  private _stickyStyler: StickyStyler;
  protected stickyCssClass = 'cdk-table-sticky';
  private _isNativeHtmlTable = true;

  constructor(private _platform: Platform)  {}

  ngOnInit() {
    this._setupStickyStyler();
  }

  ngAfterViewChecked() {
    const { enabled, colIndex = -1, rowIndex = -1 } = this.features.frozen;
    if (enabled) {
      this.updateStickyColumnStyles(colIndex, rowIndex);
    }
  }

  /** Creates the sticky styler that will be used for sticky rows and columns. */
  private _setupStickyStyler() {
    this._stickyStyler = new StickyStyler(this._isNativeHtmlTable, this.stickyCssClass, 'ltr', null, this._platform. isBrowser);
  }

  ngOnChanges(changes: SimpleChanges) {}

  isSelectionEnabled() {
    return this.features?.selection?.enabled;
  }

  hasActions() {
    return this.actions && this.actions.length > 0;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.selection.select(...this.pagedRows.map(row => row.id));
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.pagedRows.length;
    return numSelected === numRows;
  }

  initialWidth(headerId: string) {
    const DEFAULT_WIDTH = headerId === 'author' ? 216 : 240;
    return get(this, ['features', 'resizeCols', 'widths', headerId], DEFAULT_WIDTH);
  }

  widthChanged({ width }, headerId: string) {
    this.headerResize.emit({ headerId, width });
  }

  getHeaderClass(headerIndex: number) {
    const frozenRightBorder = this.features.frozen.enabled && this.features.frozen.colIndex === headerIndex;
    const frozenBottomBorder = this.features.frozen.enabled && this.features.frozen.rowIndex === 0;
    return {
      'frozen-right-border': frozenRightBorder,
      'frozen-bottom-border': frozenBottomBorder,
      'frozen-bottom-right-border': frozenRightBorder && frozenBottomBorder,
      'single-elevate-frozen': this.features.frozen.enabled && (this.isHeaderRowFrozen() || this.isColumnFrozen(headerIndex)),
      'double-elevate-frozen': this.features.frozen.enabled && this.isHeaderRowFrozen() && this.isColumnFrozen(headerIndex),
    };
  }

  checkboxColumnWidth() {
    return (this.showRowNumber() ? 70 : 0)
    + (this.isSelectionEnabled() ? 50 : 0)
    + (this.actions?.length ? this.actions.length * 48 : 0);
  }

  getCellClass(headerIndex: number, rowIndex: number) {
    const header = this.headers[headerIndex];
    const frozenRightBorder = this.features.frozen.enabled && this.features.frozen.colIndex === headerIndex;
    const frozenBottomBorder = this.features.frozen.enabled && this.features.frozen.rowIndex === rowIndex + 1;
    const inputStyle = header.type === 'input' ? (header as any).inputStyle : null;
    return {
      'frozen-right-border': frozenRightBorder,
      'frozen-bottom-border': frozenBottomBorder,
      'frozen-bottom-right-border': frozenRightBorder && frozenBottomBorder,
      'single-elevate-frozen': this.features.frozen.enabled && (this.isRowFrozen(rowIndex) || this.isColumnFrozen(headerIndex)),
      'double-elevate-frozen': this.features.frozen.enabled && this.isRowFrozen(rowIndex) && this.isColumnFrozen(headerIndex),
      'readonly-text-cell': this.headers[headerIndex].type === 'readonly-text',
      'align-top': inputStyle && inputStyle.align === 'top',
      'align-middle': inputStyle && inputStyle.align === 'middle',
      'align-bottom': inputStyle && inputStyle.align === 'bottom',
    };
  }

  getTableHeaderId(header: SupertableHeader) {
    return header.id + '-header';
  }

  isHeaderRowFrozen(): boolean {
    // ? Always return true because headers should always be frozen
    return true;
  }

  isRowFrozen(rowIndex: number): boolean {
    const { rowIndex: frozenIndex = -1 } = this.features?.frozen;
    return rowIndex + 1 <= frozenIndex;
  }

  isColumnFrozen(headerIndex: number): boolean {
    const { colIndex: frozenIndex = -1 } = this.features?.frozen;
    return headerIndex <= frozenIndex;
  }

  isColReorderDisabled() {
    return !this.features.reorderCols.enabled;
  }

  isColResizeDisabled() {
    return !this.features.resizeCols.enabled;
  }

  isFrozenDisabled() {
    return !this.features.frozen.enabled;
  }

  isSorted(headerId?: string): boolean {
    const { headerId: sortedHeaderId } = this.features.sort;
    if (headerId) {
      return headerId === sortedHeaderId;
    }
    return !!sortedHeaderId;
  }

  getHeaderVellipsisMenuOptions(header: SupertableHeader): VellipsisMenuOption[] {
    const options: [string, boolean][] = [
      ['Hide', !!this.features.visibility?.enabled && !!(header as any).name],
      ['Un-hide All', !!this.features.visibility?.enabled && header.type === 'row-options'],
      ['Sort Ascending', !!this.features.sort?.enabled],
      ['Sort Descending', !!this.features.sort?.enabled],
      ['Unsort', !!this.features.sort?.enabled],
      ['Freeze Column', !!this.features.frozen?.enabled],
      ['Freeze Row', !!this.features.frozen?.enabled && header.type === 'row-options']
    ];
    return options.filter(([_, include]) => include).map(([name, _]) => ({ name }));
  }

  getVellipsisMenuOptions(headerIdx: number) {
    // TODO: dummy data cell vellipsis buttons
    return [];
  }

  onHeaderVellipsisMenuClick(headerIndex: number, event: VellipsisMenuClick) {
    headerIndex = headerIndex + (this.isSelectionEnabled() ? 1 : 0);
    const header = this.headers[headerIndex];
    if (event.menuOption === 'Freeze Column') {
      const { rowIndex = -1 } = this.features.frozen;
      this.updateStickyColumnStyles(headerIndex, rowIndex);
    }
    const [{ features }, { menuOption }, { id: headerId }] = [this, event, header];
    this.headerVellipsisMenuClick.emit({ features, headerId, headerIndex, menuOption });
  }

  /** Freeze columns up through columnIndex. */
  updateStickyColumnStyles(columnIndex: number, rowIndex: number) {
    const headerRows = this.headerRows.map(item => item.nativeElement);
    const dataRows = this.dataRows.map(item => item.nativeElement);
    const rows = [...headerRows, ...dataRows];
    const stickyStates = Array.from(headerRows[0].children).map((_, i) => i <= columnIndex);
    const stickyEndStates = stickyStates.map((_, i) => i < 0);
    this._stickyStyler.clearStickyPositioning(rows, ['left', 'right']);
    this._stickyStyler.updateStickyColumns(rows, stickyStates, stickyEndStates);
    this._stickyStyler.stickRows(headerRows, headerRows.map((_, i) => i <= rowIndex), 'top');
  }

  onVellipsisMenuClick(headerIdx: number, event: any) {
    // TODO: dummy data cell vellipsis buttons
    console.log(event);
  }

  onDropHeader(event: CdkDragDrop<any>) {
    const order = this.headers.map(({ id }) => id);
    moveItemInArray(order, event.previousIndex, event.currentIndex);
    this.reorderHeaders.emit({
      features: this.features,
      headerIds: order,
      headerId: this.headers[event.currentIndex].id,
      newIndex: event.currentIndex,
      oldIndex: event.previousIndex
    });
  }

  onHeaderResize({ width }: { width: number }, headerId) {
    this.headerResize.emit({ width, headerId });
  }

  onHeaderResizeFinish(event: HeaderResizeFinishEvent) {
    this.headerResizeFinish.emit(event);
  }

  onScroll(event) {
    this.tableScroll.emit({
      features: this.features,
      scrollX: event.target.scrollLeft,
      scrollY: event.target.scrollTop
    });
  }

  trackBy(item: RowModel) {
    return JSON.stringify(item);
  }

  showRowNumber(): boolean {
    return this.features?.showRowNumber?.enabled ?? false;
  }

  rowNumber(offset: number): number {
    const { pageIndex, pageSize } = this.features.paginator;
    return pageIndex * pageSize + offset + 1;
  }
}
