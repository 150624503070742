import { Directive, Host, Optional, SkipSelf, EventEmitter, Output } from '@angular/core';
import { ExpansionPanelComponent } from '@markmachine/features/expansion-panel/components/expansion-panel/expansion-panel.component';
import { CommonExpansionPanelComponent } from '../components/common-expansion-panel/common-expansion-panel.component';

/**
 * Black Magic
 *
 * This directive gets attached to all expansion panels (per the selector). If you can get
 * a reference to it through an injector, you can call #open() on it to open it and each
 * of its parent panels.
 */
@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'mm-expansion-panel, mm-common-expansion-panel'
})
export class ExpansionPanelOpenerDirective {
  constructor(
    // Include each kind of expansion panel that can be opened
    @Host() @Optional() private panel: ExpansionPanelComponent,
    @Host() @Optional() private common: CommonExpansionPanelComponent,
    // Get a reference to the parent opener, if any
    @SkipSelf() @Optional() private parent: ExpansionPanelOpenerDirective
  ) { }

  /** Resolves the panel to use from among the injected posibilities. */
  somePanel() {
    return this.panel || this.common;
  }

  /** Open the associated expansion panel and its parents. */
  open() {
    const panel = this.somePanel();
    // Find the parent opener, if any, and call its open method.
    if (this.parent) {
      this.parent.open();
    }
    // if the idea is to just open everything this will work 
    // (even though it's redundant)
    // just using `this.panel || this.common` like what's in 
    // somePanel() fails because if a common expansion panel is
    // inside a regular EP which then somePanel() will always
    // resolve to the containing EP and the common EP will never open
    if (this.panel) {
      this.panel.open();
    }
    if (this.common) {
      this.common.open();
    }
  }
}
