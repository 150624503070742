import { OfficeActionStatistics, ReplyGroupStatistic } from '@markmachine/core/models/office-action-statistics';
import { CaseId } from '@markmachine/features/case/models/case.model';
import { Issue } from '@markmachine/features/issue/models/issue.model';
import * as fromVersion from '@markmachine/features/version/actions/version.actions';
import { VersionId } from '@markmachine/features/version/models/version.model';
import { createReducer, on } from '@ngrx/store';
import { isEqual, reverse, some, sortBy, sum, uniq, uniqBy } from 'lodash-es';
import * as SubmissionPageActions from '../actions/submission-page.actions';

export interface State {
  caseId: CaseId | null;
  draftId: VersionId | null;
  currentId: VersionId | null;
  isLoading: boolean;
  isLoaded: boolean;
  pollingExpectedTimestamp: string;
  updatedDocumentsAt: string | null;
  showPollingIndicator: boolean;

  stripeToken: string | null;
  statistics: OfficeActionStatistics[];
  expandedGuides: string[];
}

export const initialState: State = {
  caseId: null,
  draftId: null,
  currentId: null,
  isLoading: false,
  isLoaded: false,
  pollingExpectedTimestamp: new Date().toISOString(),
  updatedDocumentsAt: null,
  showPollingIndicator: true,

  stripeToken: null,
  statistics: [],
  expandedGuides: [],
};

export const reducer = createReducer(
  initialState,
  on(SubmissionPageActions.deactivateSubmission, (state, action) => {
    return { ...state, isLoaded: false, isLoading: false };
  }),

  on(SubmissionPageActions.activateSubmission, (state, action) => {
    return { ...initialState, isLoading: true };
  }),

  on(SubmissionPageActions.activateSubmissionCompleted, (state, { caseId, draftId, currentId }) => {
    return { ...state, isLoading: false, isLoaded: true, caseId, draftId, currentId };
  }),

  on(SubmissionPageActions.payUsptoFeesAction, (state, { stripeToken }) => {
    return { ...state, stripeToken };
  }),

  on(SubmissionPageActions.setLatest, (state, { statistics, updatedDocumentsAt }) => {
    const showPollingIndicator = isStatisticsOutdated(updatedDocumentsAt, state.pollingExpectedTimestamp);
    const newState = { ...state, statistics, updatedDocumentsAt, showPollingIndicator };
    return isEqual(state, newState) ? state : newState;
  }),

  on(SubmissionPageActions.expandGuide, (state, { guideId }) => {
    if (state.expandedGuides.includes(guideId)) {
      return state;
    }
    const expandedGuides = [...state.expandedGuides, guideId];
    return { ...state, expandedGuides };
  }),

  on(SubmissionPageActions.collapseGuide, (state, { guideId }) => {
    if (!state.expandedGuides.includes(guideId)) {
      return state;
    }
    const expandedGuides = [...state.expandedGuides.filter(v => v !== guideId)];
    return { ...state, expandedGuides };
  }),

  on(SubmissionPageActions.pollingStatisticsInit, (state, action) => {
    const { until: pollingExpectedTimestamp } = action;
    return {
      ...state,
      pollingExpectedTimestamp,
      showPollingIndicator: isStatisticsOutdated(state.updatedDocumentsAt as string, pollingExpectedTimestamp)
    };
  }),

  on(fromVersion.addVersion, (state, action) => {
    const { version } = action as any;
    const { id, idCase, isDraft } = version;
    if (isDraft && idCase === state.caseId) {
      return { ...state, draftId: id };
    }
    return state;
  }),
);

/**
 * Because the data structure is defined within the reducer it is optimal to
 * locate our selector functions at this level. If store is to be thought of
 * as a database, and reducers the tables, selectors can be considered the
 * queries into said database. Remember to keep your selectors small and
 * focused so they can be combined and composed to fit each particular
 * use-case.
 */

function isStatisticsOutdated(updatedDocumentsAt: string, pollingExpectedTimestamp: string) {
  return !!updatedDocumentsAt && (new Date(updatedDocumentsAt) < new Date(pollingExpectedTimestamp));
}

export const getCaseId = (state: State) => state.caseId;
export const getDraftId = (state: State) => state.draftId;
export const getCurrentId = (state: State) => state.currentId;
export const getIsLoaded = (state: State) => state.isLoaded;
export const getIsLoading = (state: State) => state.isLoading;
export const getExpandedGuides = (state: State) => state.expandedGuides;

/**
 * Display an indicator that statistics are being refreshed.
 */
export const getPollingIndicator = (state: State) => {
  // This selector might be used before the reducer has been initialized, so it
  // should return true if the state hasn't loaded yet.
  const isOutdated = state.updatedDocumentsAt
    && state.pollingExpectedTimestamp
    && isStatisticsOutdated(state.updatedDocumentsAt, state.pollingExpectedTimestamp);
  return isOutdated || !state.updatedDocumentsAt;
};

/**
 * Summarize Office Action statistics
 */
export function aggregateOfficeActionStatistics(stats: OfficeActionStatistics[]): OfficeActionStatistics {
  if (!stats || stats.length < 1) {
    return null as any;
  }
  // Get data from latest document
  const [{ serialNumber, documentId, stage }] = reverse(sortBy(stats, s => s.documentId.slice(3)));
  // Filter out analyses from prior prosecution stages
  const currentStageStats = stats.filter(s => s.stage === stage);
  // Collect distinct issues ("carry forward")
  const flattenedIssues = ([] as Issue[]).concat(...currentStageStats.map(s => s.issues.nodes)); // flatten issues
  const issues = { nodes: uniqBy(flattenedIssues, i => i.id) as Issue[] }; // then uniqify by issue id
  // Collect all unique tmep citations
  const tmep = uniq(([] as string[]).concat(...currentStageStats.map(s => s.tmep))) as string[];
  // Collect all Reply Groups
  // flatten reply groups
  const rgStats: ReplyGroupStatistic[] = ([] as ReplyGroupStatistic[]).concat(...currentStageStats.map(s => s.statistics.nodes));
  const statistics = { pageInfo: null, nodes: uniqBy(rgStats, r => r.groupId) }; // then uniqify by group id
  // NOTE: Suggestions is actually an object, but we're merely testing for existence right now.
  const suggestions = some(currentStageStats.map(s => s.suggestions));
  // Calculate combined TMEP coverage
  const tmepCovered = sum(currentStageStats.map(s => s.tmepCoverage * s.tmep.length));
  const tmepCount = sum(currentStageStats.map(s => s.tmep.length));
  const tmepCoverage = tmepCount ? tmepCovered / tmepCount : 1.0; // If no TMEP, 100% coverage
  // Combining Issue Group Statistics:
  // Boundary conditions:
  // - If the same issues keep arising, the average doesn't change.
  // - If no issues are recognized on the second+ action, the average doesn't change.
  const meanFn = key => sum(stats.filter(s => s.issueGroupStatistics).map(s => s.issueGroupStatistics[key]));
  const issueGroupStatistics = {
    numerator: meanFn('numerator'),
    denominator: meanFn('denominator')
  };
  return { serialNumber, documentId, tmep, issues, stage, suggestions, tmepCoverage, issueGroupStatistics, statistics };
}

