import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { safariSafeIsoDate } from '@markmachine/core/functions/utilities';

/**
 * Wrapper around Angular's DatePipe that simplify RFC 3339 date
 * so that Safari doesn't explode.
 */
@Pipe({
  name: 'safeDate'
})
export class SafeDatePipe extends DatePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    return super.transform(safariSafeIsoDate(value), ...args);
  }

}
