import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ChangeDetectionStrategy
} from '@angular/core';
import { uuidv4 } from '@markmachine/core/functions/utilities';
import { MyNotesContextMenuItem } from '@markmachine/features/context-menu/models/context-menu-item.model';
import { ExpansionPanelHelper } from '@markmachine/features/expansion-panel-helper/models/expansion-panel-helper.model';
import * as UserNoteAction from '@markmachine/features/user-note/actions/user-note.actions';
import { UserNote } from '@markmachine/features/user-note/models/user-note.model';
import * as fromUserNote from '@markmachine/features/user-note/reducers/user-note.reducer';
import { AppState } from '@markmachine/interfaces';
import { select, Store } from '@ngrx/store';
import { reverse, sortBy } from 'lodash-es';
import { ReplaySubject, Subscription } from 'rxjs';

@Component({
  selector: 'mm-case-note-helper',
  templateUrl: './case-note-helper.component.html',
  styleUrls: ['./case-note-helper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CaseNoteHelperComponent implements OnDestroy, OnInit, OnChanges, ExpansionPanelHelper {
  @Input() menuItem: MyNotesContextMenuItem;
  @Output() hide = new EventEmitter<void>();

  expandedNoteIds = new Set();

  notes$ = new ReplaySubject(1);
  sub: Subscription;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.afterChangedMenuItem(this.menuItem);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.menuItem.currentValue) {
      this.afterChangedMenuItem(changes.menuItem.currentValue);
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  afterChangedMenuItem({ props, path }: MyNotesContextMenuItem) {
    this.expandNotes(...props.expandedNoteIds || []);
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.sub = this.store
      .pipe(select(fromUserNote.getAllUserNotesByPath, { path, caseId: props?.caseId ?? null }))
      .subscribe(notes => this.notes$.next(reverse(sortBy(notes, ['createdAt']))));
  }

  addNote() {
    const userNote = {
      caseId: this.menuItem.props?.caseId ?? null,
      location: this.menuItem.path,
      note: '',
      id: uuidv4(),
      createdAt: new Date().toISOString()
    };
    this.expandNotes(userNote.id);
    this.store.dispatch(UserNoteAction.addUserNote({ userNote }));
  }

  isNoteExpanded({ id }: UserNote): boolean {
    return this.expandedNoteIds.has(id);
  }

  expandNotes(...ids: string[]): void {
    ids.forEach(id => this.expandedNoteIds.add(id));
  }

  /** Prevents note panel from being destroyed and re-created after changes. */
  trackByFn(index: number, { id }: UserNote): string {
    return id as string;
  }
}
