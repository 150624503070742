import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Mark } from '@markmachine/features/version/models/version-content.model';
import { merge } from 'lodash-es';
import { CaseFormValidationService } from './case-form-validation.service';

const TEMPLATE = new Mark();

@Injectable()
export class CaseFormMarkService {

  constructor(
    private validationService: CaseFormValidationService
  ) {}

  /** Update the FormGroup. */
  update(group: FormGroup, values: Mark): void {
    const newValues = this.sanitizeValues(values);
    this.initializeGroup(group, newValues);
    this.validationService.setFormControlsValidators(group);
    group.patchValue(newValues, { emitEvent: false });
    // this.mutate(group, newValues);
  }

  /** Perform secondary mutations on Mark */
  mutate(group: FormGroup, values: Mark): void {}

  /**
   * Populate Form for Mark with controls.
   *
   * @param group {FormGroup}

   * TODO: It would be safer to generalize this method to work directly off of
   * inspection of the model's structure.
   */
  initializeGroup(group: FormGroup, values: Mark = new Mark()): void {
    // Initialize the scalar value that live directly within statements
    const valueKeys = Object.keys(TEMPLATE);
    for (const key of valueKeys) {
      if (group.get(key)) {
        continue;
      }
      group.setControl(key, new FormControl(values[key]));
    }
  }

  /** Make sure fields have sane values. */
  private sanitizeValues(values: Mark): Mark {
    const newValues = merge({}, new Mark(), values);
    return newValues;
  }
}
