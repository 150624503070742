/*
 * RxJS operators that perform common operations on GraphQL responses.
 *
 * RxJS operators are higher-order functions.  They are functions that take
 * zero or more parameters and return another function.  The returned function
 * takes a source observable and returns a new observable.
 *
 * The outer function's parameters are your API for using the operator.
 *
 * The inner function may use other rxjs operators (easy mode) with the pipe method.
 *
 * Alternatively, the inner function may subscribe to the source observable and return
 * the subscriber (the result of calling .subscribe), which is itself an observable.
 */
import { GraphQLResponse } from '@markmachine/interfaces';
import { get, some } from 'lodash-es';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { GraphqlErrors } from './exceptions/graphql.exception';
import { MissingDataError } from './exceptions/missing-data.exception';
import { NoChangeError } from './exceptions/no-change.exception';

/**
 * Throw error if GraphQL response contains errors
 */
export const throwGraphqlErrors = () => <T>(source: Observable<GraphQLResponse<T>>) =>
  source.pipe(
    tap(({ errors }: GraphQLResponse<T>) => {
      if (errors) {
        const noUpdates = some(errors, err => err.message.includes('No values were updated in collection'));
        if (noUpdates) {
          throw new NoChangeError(errors);
        }
        throw new GraphqlErrors(errors);
      }
    })
  );

/**
 * Throw error if GraphQL response has null values along any required paths.
 * @param required dotted paths to require non-null values
 */
export const requirePaths = (...required: string[]) => <T>(source: Observable<GraphQLResponse<T>>) =>
  source.pipe(
    tap((response: GraphQLResponse<T>) => {
      for (const path of required) {
        const expectedValue = path.split('.').reduce((a, p) => (a ? a && a[p] : null), response);
        if (expectedValue === null) {
          throw new MissingDataError(path);
        }
      }
    })
  );

export const unpackGraphqlResponse = <R>(path: string) => <T>(source: Observable<GraphQLResponse<T>>): Observable<R> =>
  source.pipe(
    throwGraphqlErrors(),
    requirePaths(path),
    map(response => get(response, path))
  );
