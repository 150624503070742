import { caseFragmentFields } from '@markmachine/features/case/services/operations/case.fragment';
import { Case } from '@markmachine/features/case/models/case.model';
import { GraphQLQuery, GraphQLResponse } from '@markmachine/interfaces';

const graphqlQuery = `
query CaseBySerialNumber($serialNumber: Int!) {
  caseBySerialNumber(serialNumber: $serialNumber) {
    ${caseFragmentFields}
  }
}
`;

export class CaseBySerialNumberQuery implements GraphQLQuery {
  query = graphqlQuery;
  constructor(public variables: { serialNumber: number }) {}
}

export type CaseBySerialNumberResponse = GraphQLResponse<{
  caseBySerialNumber: Case;
}>;
