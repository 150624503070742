/**
 * Case Module
 *
 * Provides access to and editing tools for CaseVersion editor.
 *
 * Requires: CaseFormService
 * Imported by: SubmissionPageModule
 *
 * NOTE: Badly organized module. This module and submission-page.module are spaghetti.
 */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ArgumentBuilderModule } from '@markmachine/features/argument-builder/argument-builder.module';
import { CaseFileModule } from '@markmachine/features/case-file/case-file.module';
import { ClassSelectorModule } from '@markmachine/features/class-selector/class-selector.module';
import { ExpansionPanelModule } from '@markmachine/features/expansion-panel/expansion-panel.module';
import { FooterModule } from '@markmachine/features/footer/footer.module';
import { TipModule } from '@markmachine/features/tip/tip.module';
import { UsptoIndefiniteDateModule } from '@markmachine/features/uspto-indefinite-date/uspto-indefinite-date.module';
import { VersionFormModule } from '@markmachine/features/version-form/version-form.module';
import { SharedMaterialModule } from '@markmachine/shared/materials/shared-materials.module';
import { SharedModule } from '@markmachine/shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CaseHeaderModule } from '../case-header/case-header.module';
import { ContextMenuModule } from '../context-menu/context-menu.module';
import { ExpansionPanelHelperModule } from '../expansion-panel-helper/expansion-panel-helper.module';
import { VersionModule } from '../version/version.module';
import { CaseReviewModule } from './case-review/case-review.module';
import { AllegeUseDialogComponent } from './components/allege-use-dialog/allege-use-dialog.component';
import { AllegeUseFormComponent } from './components/allege-use-form/allege-use-form.component';
import { ArgumentsEditComponent } from './components/arguments-edit/arguments-edit.component';
import { Basis1aEditComponent } from './components/basis-1a-edit/basis-1a-edit.component';
import { Basis1bEditComponent } from './components/basis-1b-edit/basis-1b-edit.component';
import { Basis44dEditComponent } from './components/basis-44d-edit/basis-44d-edit.component';
import { Basis44eEditComponent } from './components/basis-44e-edit/basis-44e-edit.component';
import { CaseCardComponent } from './components/case-card/case-card.component';
import { CaseNotFoundComponent } from './components/case-not-found/case-not-found.component';
import { CaseOverviewInformationComponent } from './components/case-overview-information/case-overview-information.component';
import { CaseOverviewPanelComponent } from './components/case-overview-panel/case-overview-panel.component';
import { ClassBasisChipListComponent } from './components/class-basis-chip-list/class-basis-chip-list.component';
import { ClassBasisChipComponent } from './components/class-basis-chip/class-basis-chip.component';
import { ClassEditDialogComponent } from './components/class-edit-dialog/class-edit-dialog.component';
import { ClassListContainerComponent } from './components/class-list-container/class-list-container.component';
import { ClassListComponent } from './components/class-list/class-list.component';
import { ClassTablePopoverComponent } from './components/class-table-popover/class-table-popover.component';
import { ClassTableComponent } from './components/class-table/class-table.component';
import { MarkFormComponent } from './components/mark-form/mark-form.component';
import { OngoingEffortsComponent } from './components/ongoing-efforts/ongoing-efforts.component';
import { OwnerCardsComponent } from './components/owner-cards/owner-cards.component';
import { OwnerEditDialogComponent } from './components/owner-edit-dialog/owner-edit-dialog.component';
import { OwnerTextComponent } from './components/owner-text/owner-text.component';
import { RecordComponent } from './components/record/record.component';
import { RequestExtensionDialogComponent } from './components/request-extension-dialog/request-extension-dialog.component';
import { RequestExtensionFormComponent } from './components/request-extension-form/request-extension-form.component';
import { SignatureTextComponent } from './components/signature-text/signature-text.component';
import { StatementsEditComponent } from './components/statements-edit/statements-edit.component';
import { VersionListComponent } from './components/version-list/version-list.component';
import { VersionMenuComponent } from './components/version-menu/version-menu.component';
import { VersionPageComponent } from './components/version-page/version-page.component';
import { CaseEffects } from './effects/case.effects';
import { FileEffects } from './effects/file.effects';
import { CaseVersionGuard } from './guards/case-version.guard';
import { ClassCodePipe } from './pipes/class-code.pipe';
import { StatusStagePipe } from './pipes/status-stage.pipe';
import * as fromCases from './reducers/case.reducer';
import * as fromFiles from './reducers/file.reducer';
import { CaseDialogService } from './services/case-dialog.service';
import { CaseService } from './services/case.service';
import { NextEventsFormComponent } from './components/next-events-form/next-events-form.component';
import { NextEventsPanelComponent } from './components/next-events-panel/next-events-panel.component';
import { UserCaseModule } from '../user-case/user-case.module';
import { CustomEventPanelComponent } from '../user-case/components/custom-event-panel/custom-event-panel.component';
import { HowItWorksPanelComponent } from './components/how-it-works-panel/how-it-works-panel.component';
import { CaseStatusPanelComponent } from './components/case-status-panel/case-status-panel.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    SharedMaterialModule,
    SharedModule,
    TipModule,
    FooterModule,
    ContextMenuModule,

    CaseReviewModule,
    CaseFileModule,
    CaseHeaderModule,
    ClassSelectorModule,
    ArgumentBuilderModule,
    UsptoIndefiniteDateModule,
    ExpansionPanelModule,
    ExpansionPanelHelperModule,
    VersionFormModule,
    VersionModule,
    UserCaseModule,

    StoreModule.forFeature('cases', fromCases.reducer),
    StoreModule.forFeature('files', fromFiles.reducer),
    // StoreModule.forFeature('versions', fromVersions.reducer),
    EffectsModule.forFeature([
      CaseEffects,
      FileEffects
      // VersionEffects,
    ])
  ],
  exports: [
    CaseCardComponent,
    VersionPageComponent,
    VersionMenuComponent,
    CaseReviewModule,
    ClassListComponent,
    ClassListContainerComponent,
    ClassEditDialogComponent,
    CaseOverviewPanelComponent,
    OngoingEffortsComponent,
    HowItWorksPanelComponent,
    CaseStatusPanelComponent
  ],
  declarations: [
    CaseCardComponent,
    VersionPageComponent,
    RecordComponent,
    StatementsEditComponent,
    ArgumentsEditComponent,
    MarkFormComponent,
    VersionMenuComponent,
    VersionListComponent,
    ClassTableComponent,
    Basis1aEditComponent,
    Basis1bEditComponent,
    Basis44dEditComponent,
    Basis44eEditComponent,
    ClassBasisChipListComponent,
    ClassBasisChipComponent,
    ClassTablePopoverComponent,
    ClassListComponent,
    ClassEditDialogComponent,
    ClassListContainerComponent,
    OwnerTextComponent,
    OwnerCardsComponent,
    OwnerEditDialogComponent,
    SignatureTextComponent,
    AllegeUseDialogComponent,
    RequestExtensionDialogComponent,
    AllegeUseFormComponent,
    RequestExtensionFormComponent,
    OngoingEffortsComponent,
    ClassCodePipe,
    CaseNotFoundComponent,
    CaseOverviewPanelComponent,
    CaseOverviewInformationComponent,
    StatusStagePipe,
    NextEventsFormComponent,
    NextEventsPanelComponent,
    HowItWorksPanelComponent,
    CaseStatusPanelComponent
  ],
  providers: [
    CaseService,
    CaseVersionGuard,
    CaseDialogService
  ]
})
export class CaseModule {}
