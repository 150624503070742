import { safariSafeIsoDate } from '@markmachine/core/functions/utilities';
import * as fromStatus from '@markmachine/features/case-status/reducers/status.reducer';
import * as fromCase from '@markmachine/features/case/reducers/case.reducer';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as UserCaseActions from '../actions/user-case.actions';
import { UserCase } from '../models/user-case.model';

export interface State extends EntityState<UserCase> {
  // additional entities state properties
}

export const adapter: EntityAdapter<UserCase> = createEntityAdapter<UserCase>({
  selectId: uc => uc.nodeId // Primary key selector for User Case entities
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export const reducer = createReducer(
  initialState,

  on(UserCaseActions.createUserCaseSuccess, (state, { userCase }) => adapter.addOne(userCase, state)),

  on(UserCaseActions.addUserCase, (state, { userCase }) => adapter.addOne(userCase, state)),

  on(UserCaseActions.upsertUserCase, (state, { userCase }) => adapter.upsertOne(userCase, state)),

  on(UserCaseActions.createUserCases, (state, { userCases }) => adapter.addMany(userCases as any, state)),

  on(UserCaseActions.upsertUserCases, (state, { userCases }) => adapter.upsertMany(userCases, state)),

  on(UserCaseActions.updateUserCase, (state, { userCase }) => adapter.updateOne(userCase, state)),

  on(UserCaseActions.updateUserCaseSuccess, (state, { userCase }) => adapter.updateOne(userCase, state)),

  on(UserCaseActions.updateUserCases, (state, { userCases }) => adapter.updateMany(userCases, state)),

  on(UserCaseActions.deleteUserCasesSuccess, (state, action) => adapter.removeMany(action.nodeIds, state)),

  on(UserCaseActions.loadUserCases, (state, { userCases }) => adapter.setAll(userCases, state)),

  on(UserCaseActions.clearUserCases, (state) => adapter.removeAll(state)),
);

// **************************************************************************
// Selectors
// **************************************************************************

export const storeKey = 'user-cases';
export const getUserCaseState = createFeatureSelector<State>(storeKey);

export const {
  selectIds: getUserCaseIds,
  selectEntities: getUserCaseEntities,
  selectAll: getAllUserCases,
  selectTotal: getTotalUserCases,
} = adapter.getSelectors(getUserCaseState);

/** Extend the UserCase object with its related Status and Case */
const extendUserCase = (userCase, statuses, cases) => {
  const caseByCaseId = cases[userCase.caseId] || {} as any; // we'll load the case asynchronously elsewhere
  const serialNumber = caseByCaseId.serialNumber;
  let status = statuses[serialNumber] || {} as any; // we'll load status asynchronously elsewhere
  if (status) {
    for (const k of ['officeActionDate', 'responseDeadlineDate', 'updatedAt']) {
      status = { ...status, [k]: safariSafeIsoDate(status[k]) };
    }
  }
  return { ...userCase, caseByCaseId, status };
};

/** List all user cases extended with status and case information */
export const getUserCasesWithStatus = createSelector(
  getAllUserCases,
  fromStatus.getStatusEntities,
  fromCase.getCaseEntities,
  (userCases, statuses, cases) => userCases.map(uc => extendUserCase(uc, statuses, cases))
);

/** List all case ids required by user cases. */
export const getUserCaseCaseIds = createSelector(
  getAllUserCases,
  userCases => userCases.map(usercase => usercase.caseId)
);

/** Find a specific user case extended with status and case information */
export const getDocketRowsByUserCaseNodeId = () => createSelector(
  getUserCaseEntities,
  fromStatus.getStatusEntities,
  fromCase.getCaseEntities,
  (entities, statuses, cases, { nodeId }) => extendUserCase(entities[nodeId], statuses, cases)
);

/** Find a specific user case by caseId. */
export const getUserCaseByNodeId = () => createSelector(
  getUserCaseEntities,
  (entities, { nodeId }: { nodeId: UserCase['nodeId'] }) => entities[nodeId]
);
