<h2 mat-dialog-title>Revert Draft?</h2>

<p class="mat-typography">You will lose all unsubmitted changes you've made to the application.</p>

<mat-dialog-actions>
  <span style="flex: auto"></span>
  <button mat-button
    color="warn"
    [mat-dialog-close]="true">CONFIRM</button>
  <button mat-button
    [mat-dialog-close]="false">CANCEL</button>
</mat-dialog-actions>