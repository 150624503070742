<div class="clearance-search-result clearance-search-mark">
  <div [class]="'clearance-search-scroll-target ' + 'sn' + '-' + serialNumber"></div>
  <p *ngIf="mark" class="clearance-search-mark--mark">
    {{ mark }}
  </p>
  <p *ngIf="disclaimer" class="clearance-search-mark--disclaimer">
    <span class="label">Disclaims: </span>
    {{ disclaimer }}
  </p>
  <p *ngIf="registrationNumber" class="clearance-search-mark--registration">
    <span class="label">Reg: </span>
    {{ registrationNumber }}
  </p>
  <p *ngIf="serialNumber" class="clearance-search-mark--serial">
    <span class="label">Serial: </span>
      <a [href]="tsdr.statusUrl(serialNumber)" target="_blank">{{serialNumber}}</a>
  </p>
  <p *ngIf="twoFClaim" class="clearance-search-mark--twoFClaim">
    2(f) claim
  </p>
  <p *ngIf="incomingCitationCount || outgoingCitationCount > 0" class="clearance-search-mark--citation">
      <span class="label">Citations: </span>
      <span class="citation citation--incoming" matTooltip="Incoming Citations">{{incomingCitationCount}}</span>
      <span class="citation citation--outgoing" matTooltip="Outgoing Citations">{{outgoingCitationCount}}</span>
  </p>

  <div *ngIf="showMarkImage" class="clearance-search-mark--image">
    <div class="mark-container">
      <mm-mark-image [serialNumber]="serialNumber"></mm-mark-image>
    </div>
  </div>
</div>