import { createAction, props } from '@ngrx/store';
import { ContextMenuItem } from '../models/context-menu-item.model';
import { Update } from '@ngrx/entity';

export const init = createAction(
  '[ContextMenu] Init'
);

export const fetchContextMenus = createAction(
  '[ContextMenu] Fetch Menus',
  (prefix: string = '') => ({ prefix })
);

export const loadContextMenus = createAction(
  '[ContextMenu] Load Menus',
  props<{ contextMenuItems: ContextMenuItem[] }>()
);

export const addContextMenus = createAction(
  '[ContextMenu] Add Menus',
  props<{ contextMenuItems: ContextMenuItem[] }>()
);

export const updateContextMenu = createAction(
  '[ContextMenu] Update Menu',
  props<{ contextMenuItemUpdate: Update<ContextMenuItem> }>()
);

export const updateContextMenuSuccess = createAction(
  '[ContextMenu] Update Menu Success',
  props<{ contextMenuItemUpdate: Update<ContextMenuItem> }>()
);

export const createContextMenu = createAction(
  '[ContextMenu] Create Menu',
  props<{ contextMenuItem: ContextMenuItem }>()
);

export const createContextMenuSuccess = createAction(
  '[ContextMenu] Create Menu Success',
  props<{ contextMenuItem: ContextMenuItem }>()
);

export const deleteContextMenus = createAction(
  '[ContextMenu] Delete Menus',
  props<{ contextMenuItems: ContextMenuItem[] }>()
);

export const deleteContextMenu = createAction(
  '[ContextMenu] Delete Menu',
  props<{ contextMenuItem: ContextMenuItem }>()
);

export const deleteContextMenuSuccess = createAction(
  '[ContextMenu] Delete Menu Success',
  props<{ contextMenuItem: ContextMenuItem }>()
);
