import { GraphQLQuery, GraphQLResponse } from 'app/interfaces';
import { UserProfile } from '@markmachine/features/account/models/user-profile.model';

const graphqlQuery = `
mutation UpdateUserProfile($userId: UUID!, $userProfilePatch: UserProfilePatch!) {
  updateUserProfileByUserId(input: {userId: $userId, userProfilePatch: $userProfilePatch}) {
    userProfile {
      userId
      fullName
      addressLine1
      addressLine2
      city
      state
      zip
      country
      phoneNumber
      faxNumber
      email
      position
      lawFirm
      isAttorney
      attorneyLicenseState
      signature
      createdAt
      updatedAt
      flags
    }
  }
}
`;

export class UpdateUserProfileMutation implements GraphQLQuery {
  query = graphqlQuery;
  constructor(
    public variables: { userId: string; userProfilePatch: Partial<UserProfile> }
  ) {}
}

export type UpdateUserProfileResponse = GraphQLResponse<{
  updateUserProfileByUserId: { userProfile: UserProfile };
}>;
