<mm-advanced-filter *ngIf="enableFilter$ | async"
  [filterableHeaders]="filterHeaders$ | async"
  [features]="features$ | async"
  (filterChange)="onFilterChange($event)">
</mm-advanced-filter>

<mm-visibility-menu *ngIf="enableVisibility$ | async"
  [headers]="namedHeaders$ | async"
  [features]="features$ | async"
  (change)="onVisibilityChange($event)">
</mm-visibility-menu>

<button *ngIf="enableFrozen$ | async"
  mat-button
  (click)="onResetFrozen()">
  Reset Frozen
</button>


<mm-paginator *ngIf="enablePaginator$ | async"
  [features]="features$ | async"
  [length]="numVisibleRows$ | async"
  (page)="onPaginatorChange($event)">
</mm-paginator>

<mm-table
  [actions]="actions"
  [headers]="orderedHeaders$ | async"
  [filteredRows]="filteredRows$ | async"
  [pagedRows]="pagedRows$ | async"
  (headerResize)="onHeaderResize($event)"
  [features]="features$ | async"
  (reorderHeaders)="onReorderHeaders($event)"
  (tableScroll)="onTableScroll($event)"
  (headerVellipsisMenuClick)="onHeaderVellipsisMenuClick($event)">
</mm-table>

<mm-paginator *ngIf="enablePaginator$ | async"
  [features]="features$ | async"
  [length]="numVisibleRows$ | async"
  (page)="onPaginatorChange($event)">
</mm-paginator>
