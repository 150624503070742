import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
// tslint:disable-next-line: max-line-length
import * as GoodServiceActionTypes from '@markmachine/features/goods-services-selector/actions/good-service-selector.actions';
import { AppState } from '@markmachine/interfaces';
import { isUnfiled } from '@markmachine/views/submission-page/reducers';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, mapTo } from 'rxjs/operators';
import { AllegeUseDialogComponent } from '../components/allege-use-dialog/allege-use-dialog.component';
import { ClassEditDialogComponent } from '../components/class-edit-dialog/class-edit-dialog.component';
import { RequestExtensionDialogComponent } from '../components/request-extension-dialog/request-extension-dialog.component';
import { CaseFormClassesService } from '@markmachine/features/version-form/services/case-form-classes.service';

const DIALOG_OPTIONS = {
  maxHeight: '90vh',
  height: '90vh',
  panelClass: 'mm-dialog-flex'
};

@Injectable()
export class CaseDialogService {
  constructor(
    public dialog: MatDialog,
    private classHelper: CaseFormClassesService,
    private actions$: Actions,
    private store: Store<AppState>
  ) {}

  /** Open the Class Edit dialog with the appropriate title. */
  private _openClassEditDialog(group: FormGroup, openBasisIndex: number, title: string) {
    const selectedIndex = openBasisIndex;
    const dialogRef = this.dialog.open(ClassEditDialogComponent, {
      ...DIALOG_OPTIONS,
      data: { group, selectedIndex, title },
    });
    return this._closeOnBackdropClick(dialogRef);
  }

  /** Close dialog if its backdrop is clicked. */
  private _closeOnBackdropClick(dialogRef: MatDialogRef<any>) {
    dialogRef.backdropClick().subscribe(() => dialogRef.close());
    return dialogRef;
  }

  /** Open a dialog to create a new class. */
  createClass(): Observable<null> {
    this.store
      .pipe(select(isUnfiled), first())
      .subscribe((fileStatus) => this.store.dispatch(GoodServiceActionTypes.openGoodServiceDialog({ payload: { isUnfiled: fileStatus } })));

    // Return an observable that emits once and completes after the dialog closes.
    return this.actions$.pipe(
      ofType(GoodServiceActionTypes.closeGoodServiceDialog, GoodServiceActionTypes.goodServiceDialogSuccess),
      mapTo(null),
      first() // Complete after emitting once
    );
  }

  /** Open a dialog to edit an existing class. */
  editClass(classGroup: FormGroup, openBasisIndex?: number) {
    if (openBasisIndex === undefined) {
      openBasisIndex = ['1a', '1b', '44d', '44e']
        .map((x) => `filing-basis-current-${x}-in`)
        .findIndex((key) => !!classGroup.get(key)?.value);
    }
    return this._openClassEditDialog(classGroup, openBasisIndex, 'Edit Class');
  }

  /** Open a dialog to complete an Extension Request. */
  requestExtension(classArray: FormArray, extensionCount: number) {
    const root = classArray.parent as FormGroup;
    return this.dialog.open(RequestExtensionDialogComponent, { ...DIALOG_OPTIONS, data: { root, extensionCount } });
  }

  /** Open a dialog to complete an Allegation of Use. */
  allegeUse(classArray: FormArray) {
    const root = classArray.parent as FormGroup;
    return this.dialog.open(AllegeUseDialogComponent, { ...DIALOG_OPTIONS, data: { root } });
  }
}
