<mm-expansion-panel [expanded]="true">
  <mm-expansion-panel-title>My Notes</mm-expansion-panel-title>
  <mm-expansion-panel-actions>
    <button mat-icon-button
      (click)="hide.next()">
      <mat-icon>visibility_off</mat-icon>
    </button>
    <button mat-icon-button
      (click)="addNote()">
      <mat-icon>add_circle_outline</mat-icon>
    </button>
  </mm-expansion-panel-actions>

  <mm-case-note-helper-item *ngFor="let note of notes$ | async; trackBy: trackByFn"
    [note]="note"
    [expanded]="isNoteExpanded(note)"
    (expansionChange)="expandNotes(note.id)"></mm-case-note-helper-item>

</mm-expansion-panel>
