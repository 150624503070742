import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Trademark } from '@markmachine/features/clearance-search/models/knockout-response.model';
import { TsdrService } from '@markmachine/core/services/tsdr.service';

@Component({
  selector: 'mm-clearance-search-results-mark',
  templateUrl: './clearance-search-results-mark.component.html',
  styleUrls: ['./clearance-search-results-mark.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClearanceSearchResultsMarkComponent implements OnInit {
  @Input()
  set row(value: Trademark) {
    this.mark = value.markIdentification || '';
    this.registrationNumber = value.registrationNumber || '';
    this.serialNumber = value.serialNumber || '';
    this.disclaimer = value.disclaimer || '';
    value.markDrawingType !== 'STANDARD_CHARACTERS' ? this.showMarkImage = true : this.showMarkImage = false;
    value.register.includes('2f') ? this.twoFClaim = true : this.twoFClaim = false;
    this.incomingCitationCount = value.incomingCitationCount;
    this.outgoingCitationCount = value.outgoingCitationCount;
  }

  mark = '';
  registrationNumber = '';
  serialNumber = '';
  disclaimer = '';
  twoFClaim = false;
  showMarkImage = false;
  incomingCitationCount = 0;
  outgoingCitationCount = 0;

  constructor(public tsdr: TsdrService) { }

  ngOnInit() {
  }

}
