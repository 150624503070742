import { createEntityAdapter, Dictionary, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { entries, filter, groupBy, map, mapValues, sortBy } from 'lodash-es';
import * as Action from '../actions/guide.actions';
import { Guide } from '../models/guide.model';
import * as fromWidgets from './guide-widget.reducer';


export interface State extends EntityState<Guide> {
}

export function sortByDisplayName(a: Guide, b: Guide): number {
  return a.displayName.localeCompare(b.displayName);
}

const adapter = createEntityAdapter<Guide>({
  selectId: guide => guide.id,
  sortComparer: sortByDisplayName
});

type GuideId = ReturnType<typeof adapter['selectId']>;

export const initialState: State = adapter.getInitialState({});

export const reducer = createReducer(
  initialState,

  on(Action.fetchListSuccess, (state, { guides }) => {
    // NOTE: Stripping out widgets to avoid errors -- use guide-widgets
    guides = guides.map(({ widgets, ...guide }) => guide);
    return adapter.addMany(guides, state);
  }),

  on(Action.loadGuides, (state, { guides }) => {
    return adapter.setAll(guides, state);
  }),

  on(Action.fetchGuideSuccess, (state, { guide: { widgets, ...guide } }) => {
    // NOTE: Stripping out widgets to avoid errors -- use guide-widgets
    return adapter.upsertOne(guide, state);
  }),

  on(Action.updateGuide, (state, { guide }) => {
    return adapter.updateOne(guide, state);
  }),

  on(Action.addGuideSuccess, (state, { guide }) => {
    return adapter.addOne(guide, state);
  }),

  on(Action.deleteGuides, (state, { guideIds }) => {
    return adapter.removeMany(guideIds, state);
  }),
);

/****************************************************************************
 * Selectors
 ***************************************************************************/

export const storeKey = 'guide';
export const getGuideState = createFeatureSelector<State>(storeKey);

export const {
  selectIds: getGuideIds,
  selectEntities,
  selectAll: getAllGuides,
  selectTotal: getTotalGuides,
} = adapter.getSelectors(getGuideState);

export type GuideWithWidgets = Guide & Required<Pick<Guide, 'widgets'>>;

/** Export Guide structure with widgets embedded */
export const getGuideEntities = createSelector(
  selectEntities,
  fromWidgets.getGuideWidgetsGroup,
  (guides, widgets) => mapValues(guides, (g: Guide) => ({ ...g, widgets: { nodes: widgets[g.id] }})) as Dictionary<GuideWithWidgets>
);

type GetGuideByIdProps = { guideId: GuideId };

export const getGuideById = () => createSelector(
  getGuideEntities,
  (guides: Dictionary<GuideWithWidgets>, { guideId }: GetGuideByIdProps) => guides[guideId]
);

/** Get guide by id (use props param of select()) */
export const getGuideAndWidgetsByNodeId = getGuideById;

/** Select guides in menu's total sort order. */
export const _getGuideMenuItems = createSelector(
  getAllGuides,
  guides => sortBy(
    filter(guides, g => !!g.responsePart && !g.isDeleted),
    ['responsePart', 'displayName'])
);

/** Build up a menu-maker by grouping up filtered values. */
export const _collateMenu = <T>(groupKey: (a: T) => string) =>
  (arrayLike: T[]) => map(
    entries(groupBy(arrayLike, groupKey)),
    ([ displayName, children ]) => ({ displayName, children }));

/** Select a Menu of Guides. */
export const getMenu = createSelector(
  _getGuideMenuItems,
  _collateMenu<Guide>(g => g.responsePart)
);
