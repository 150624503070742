import { CaseStatus } from '@markmachine/features/case-status/models/status.model';
import { Case } from '@markmachine/features/case/models/case.model';
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { UserCase } from '../models/user-case.model';

export const loadUserCases = createAction(
  '[UserCase] LoadUserCases',
  props<{ userCases: UserCase[] }>()
);

export const addUserCase = createAction(
  '[UserCase] AddUserCase',
  props<{ userCase: UserCase }>()
);

export const createUserCases = createAction(
  '[UserCase] CreateUserCases',
  props<{ userCases: (Partial<UserCase> & Required<Pick<UserCase, 'caseId'>>)[] }>()
);

export const createUserCase = createAction(
  '[UserCase] CreateUserCase',
  props<{ userCase: Partial<UserCase> & Required<Pick<UserCase, 'caseId'>> }>()
);

export const createUserCaseSuccess = createAction(
  '[UserCase] CreateUserCaseSuccess',
  props<{ userCase: UserCase }>()
);

export const createUserCaseFailure = createAction(
  '[UserCase] CreateUserCaseFailure',
  props<{ errorMessage: string }>()
);

export const upsertUserCase = createAction(
  '[UserCase] UpsertUserCase',
  props<{ userCase: UserCase }>()
);

export const upsertUserCases = createAction(
  '[UserCase] UpsertUserCases',
  props<{ userCases: UserCase[] }>()
);

export const addUserCasesBySerialNumbers = createAction(
  '[UserCase] AddUserCasesBySerialNumers',
  props<{ serialNumbers: number[] }>()
);

export const addUserCasesBySerialNumbersSuccess = createAction(
  '[UserCase] AddUserCasesBySerialNumbersSuccess',
  props<{ payload: { case_: Case, userCase: UserCase, status: CaseStatus }[] }>()
);

export const addUserCasesBySerialNumbersFailure = createAction(
  '[UserCase] AddUserCasesBySerialumbersFailure',
  props<{ errorMessage: string }>()
);

export const updateUserCase = createAction(
  '[UserCase] UpdateUserCase',
  props<{ userCase: Update<UserCase> }>()
);

export const updateUserCaseSuccess = createAction(
  '[UserCase] UpdateUserCaseSuccess',
  props<{ userCase: Update<UserCase> }>()
);

export const updateUserCaseFailure = createAction(
  '[UserCase] UpdateUserCaseFailure',
  props<{ errorMessage: string }>()
);

export const updateUserCases = createAction(
  '[UserCase] UpdateUserCases',
  props<{ userCases: Update<UserCase>[] }>()
);

export const deleteUserCase = createAction(
  '[UserCase] DeleteUserCase',
  props<{ nodeId: string }>()
);

export const deleteUserCases = createAction(
  '[UserCase] DeleteUserCases',
  props<{ nodeIds: string[] }>()
);

export const deleteUserCasesSuccess = createAction(
  '[UserCase] DeleteUserCasesSuccess',
  props<{ nodeIds: string[] }>()
);

export const deleteUserCasesFailure = createAction(
  '[UserCase] DeleteUserCasesFailure',
  props<{ errorMessage: string }>()
);

export const clearUserCases = createAction(
  '[UserCase] ClearUserCases',
);

export const createNewUserCaseFailure = createAction(
  '[UserCase] CreateNewUserCaseFailure',
  props<{ errorMessage: string }>()
);

export const cloneUserCase = createAction(
  '[UserCase] Clone UserCases',
  props<{ nodeId: string }>()
);

export const cloneUserCaseSuccess = createAction(
  '[UserCase] CloneUserCasesSuccess',
  props<{ newCaseId: string, oldCaseId: string }>()
);

export const cloneUserCaseFailure = createAction(
  '[UserCase] CloneUserCasesFailure',
  props<{ errorMessage: string }>()
);

export const createNewCase = createAction(
  '[UserCase] Create New Case'
);

export const createNewCaseSuccess = createAction(
  '[UserCase] Create New Case Success',
  props<{ caseId: string }>()
);

export const createNewCaseFailure = createAction(
  '[UserCase] Create New Case Failure',
  props<{ errorMessage: string }>()
);
