import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StatusRequestService } from '../services/status-request.service';

@Injectable()
export class StatusEffects {

  // /**
  //  * Asynchronously request updated statuses.
  //  *
  //  * DISABLED: #177913967
  //  */
  // refreshCaseStatus = createEffect(() => this.actions$.pipe(
  //   ofType(StatusActions.refreshStatuses),
  //   switchMap(({ caseIds }) => this.service.refreshStatuses(caseIds).pipe(
  //     map(statuses => StatusActions.upsertStatuses({ statuses })),
  //     catchError(({ message: errorMessage }) => of(StatusActions.refreshStatusesFailure({ errorMessage })))
  //   )),
  // ));

  // /**
  //  * Every POLLING_INTERVAL, poll server for more recent statuses.
  //  *
  //  * NOTE: We are watching for status to reach a previously set minimum age,
  //  * rather than making sure they are always recent. If you want to periodically update
  //  * statuses, periodically update pollingExpectedTimestamp and this effect will handle it.
  //  *
  //  * DISABLED: #177913967
  //  */
  // pollingSerialNumbers = createEffect(() => this.actions$.pipe(
  //   ofType(StatusActions.pollingStatusesInit),
  //   // Delegate to an observable that will attempt to poll until POLLING_LIMIT is reached
  //   switchMap(() => interval(POLLING_INTERVAL).pipe(
  //     takeUntil(timer(POLLING_LIMIT)),
  //     // Get statuses that haven't been updated yet
  //     switchMap(() => this.store.pipe(select(getStatusesOutdated), first())),
  //     filter(ids => ids.length > 0),
  //     // Limit concurrent polling to a sane number of database requests
  //     map(ids => ids.slice(0, CONCURRENT_REQUEST_LIMIT)),
  //     // Go fetch!
  //     switchMap(ids => this.service.getStatusesBySerialNumbers(ids).pipe(
  //       // If any of the statuses we fetched were actually updated, they won't be requested next time around.
  //       // filter(statuses => statuses.length > 1),
  //       map(statuses => StatusActions.upsertStatuses({ statuses })),
  //       catchError(({ message: errorMessage }) => of(StatusActions.pollingStatusesFailure({ errorMessage })))
  //     )),
  //   ))
  // ));

  constructor(
    private actions$: Actions,
    private service: StatusRequestService,
    private store: Store
  ) {}

}
