import { Version } from '@markmachine/features/version/models/version.model';
import { versionMetadataFragment } from './version-metadata.fragment';
import { GraphQLQuery, GraphQLResponse } from '@markmachine/interfaces';

const query = `
query CaseVersionByCaseId($caseId: UUID!) {
  caseById(id: $caseId) {
    caseVersionsByIdCase {
      nodes {
        content ...versionMetadataFragment
      }
    }
  }
}
${versionMetadataFragment}
`;

export class VersionsByCaseIdQuery implements GraphQLQuery {
  query = query;

  constructor(public variables: { caseId: string }) {}
}

export type VersionsByCaseIdResponse = GraphQLResponse<{
  caseById: { caseVersionsByIdCase: { nodes: Version[] } };
}>;
