import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CaseFile } from '@markmachine/features/case/models/file.model';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

const URL = environment.GRAPHQL;

@Injectable()
export class CaseFileRequestService {
  constructor(private http: HttpClient) {}

  deleteFile(idCase: string, idCaseFile: string): Observable<CaseFile> {
    const { REST_API } = environment;
    const url = `${REST_API}/uploads/${idCase}/${idCaseFile}`;
    return this.http.delete<CaseFile>(url);
  }

  uploadFileIncremental(
    idCase: string,
    file: File,
    path?: string
  ): Observable<HttpEvent<CaseFile>> {
    const { REST_API } = environment;
    const filepath = path ? [path, file.name].join('/') : file.name;
    const url = `${REST_API}/uploads/${idCase}/${filepath}`;
    const form = new FormData();
    form.append('file', file);
    const req = new HttpRequest('POST', url, form, {
      reportProgress: true
    });
    return this.http.request(req);
  }
}
