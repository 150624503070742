<p>Allege Use for each of the following classes with an Intent-to-Use basis.</p>

<div *ngFor="let cls of classGroups; let index=index"
  [formGroup]="cls"
  fxLayout="column"
  fxLayoutAlign="stretch">

  <h2 fxLayout="row">
    <span fxFlex="auto">Class {{cls.get('class-code').value | number}}</span>
    <button mat-stroked-button
      fxFlex="none"
      (click)="delete(index)"
      color="warn">DELETE CLASS</button>
  </h2>

  <mm-basis-1a-edit [group]="cls"></mm-basis-1a-edit>

</div>
