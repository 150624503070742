import { UserNote } from '../models/user-note.model';
import { GraphQLQuery, GraphQLResponse } from '@markmachine/interfaces';

const query = `
mutation CreateUserNote($userNote: UserNoteInput!) {
  createUserNote(input:{ userNote: $userNote }) {
    userNote {
      id
      userId
      caseId
      createdAt
      updatedAt
      location
      note
    }
  }
}

`;

export class CreateUserNoteMutation implements GraphQLQuery {
  query = query;

  constructor(public variables: { userNote: UserNote }) {}
}

export type CreateUserNoteResponse = GraphQLResponse<{
  createUserNote: {
    userNote: UserNote;
  }
}>;
