import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'mm-tip-not-found-dialog',
  templateUrl: './tip-not-found-dialog.component.html',
  styleUrls: ['./tip-not-found-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TipNotFoundDialogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
