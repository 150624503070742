import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Issue } from '../models/issue.model';

export const loadIssues = createAction('[Issue] loadIssues', props<{ issues: Issue[] }>());
// export const addIssue = createAction('[Issue] addIssue', props<{ issue: Issue }>());
// export const upsertIssue = createAction('[Issue] upsertIssue', props<{ issue: Issue }>());
// export const addIssues = createAction('[Issue] addIssues', props<{ issues: Issue[] }>());
// export const upsertIssues = createAction('[Issue] upsertIssues', props<{ issues: Issue[] }>());
// export const updateIssue = createAction('[Issue] updateIssue', props<{ issue: Update<Issue> }>());
// export const updateIssues = createAction('[Issue] updateIssues', props<{ issues: Update<Issue>[] }>());
// export const deleteIssue = createAction('[Issue] deleteIssue', props<{ id: string }>());
// export const deleteIssues = createAction('[Issue] deleteIssues', props<{ ids: string[] }>());
// export const clearIssues = createAction('[Issue] clearIssues');
