import { GraphQLQuery, GraphQLResponse } from 'app/interfaces';
import { UserProfile } from '@markmachine/features/account/models/user-profile.model';

const graphqlQuery = `
  query UserProfile {
    currentProfile {
      userId
      fullName
      addressLine1
      addressLine2
      city
      state
      zip
      country
      phoneNumber
      faxNumber
      email
      position
      lawFirm
      isAttorney
      attorneyLicenseState
      signature
      createdAt
      updatedAt
      flags
    }
  }
`;

export class UserProfileQuery implements GraphQLQuery {
  query = graphqlQuery;
  constructor(public variables: {}) {}
}

export type UserProfileResponse = GraphQLResponse<{
  currentProfile: UserProfile;
}>;
