import { Tip } from '@markmachine/core/models/tip';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as TipAction from '../actions/tip.actions';

/**
 * History stack item
 */
export interface DialogHistoryItem {
  anchor: string;
  tipIds: string[];
}

export interface State extends EntityState<Tip> {
  /** Secondary index for finding tips by anchor text */
  byAnchor: { [anchor: string]: string };
  /** Secondary index for finding children of a given tip */
  byParentId: { [parentId: string]: string[] };
  /** History stack for backwards navigation after following inter-tip links */
  dialogHistory: DialogHistoryItem[];
  /** History stack position, for "forward" history navigation */
  dialogHistoryPosition: number;
}

export const adapter: EntityAdapter<Tip> = createEntityAdapter<Tip>();
export const initialState: State = adapter.getInitialState({
  byAnchor: {},
  byParentId: {},
  dialogHistory: [],
  dialogHistoryPosition: 0
});

export const reducer = createReducer(
  initialState,
  on(TipAction.TipDocument, (state, { tip: { children, ...tip } }) => {
    const tips = [tip, ...children?.nodes ?? []];
    const byAnchor = Object.fromEntries(tips.map(t => [t.anchor, t.id]));
    const byParentId = { [tip.id]: children?.nodes?.map?.(t => t.id) };
    return {
      ...adapter.addMany(tips, state),
      byAnchor: { ...state.byAnchor, ...byAnchor },
      byParentId: { ...state.byParentId, ...byParentId }
    };
  }),

  on(TipAction.TipChildrenDocuments, (state, { tips }) => {
    if (tips.length === 0) {
      return state;
    }
    const byParentId = { [tips[0].idParent]: tips.map(t => t.id) };
    const byAnchor = Object.fromEntries(tips.map(t => [t.anchor, t.id]));
    return {
      ...adapter.addMany(tips, state),
      byAnchor: { ...state.byAnchor, ...byAnchor },
      byParentId: { ...state.byParentId, ...byParentId }
    };
  }),

  on(TipAction.DialogOpen, (state, { tipIds }) => ({
    ...state,
    dialogHistory: [{ anchor: '', tipIds }],
    dialogHistoryPosition: 0
  })),

  on(TipAction.DialogClose, (state) => ({
    ...state,
    dialogHistory: [],
    dialogHistoryPosition: 0
  })),

  on(TipAction.DialogOpenByAnchorsReady, (state, { tips }) => {
    const actions = [tips.map(tip => TipAction.TipDocument({ tip }))];
    const newState = actions.reduce(reducer, state);
    const dialogHistoryPosition = newState.dialogHistoryPosition + 1;
    const dialogHistory = [...newState.dialogHistory.slice(0, dialogHistoryPosition), { anchor: '', tipIds: tips.map(t => t.id) }];
    return { ...state, dialogHistory, dialogHistoryPosition };
  }),

  on(TipAction.DialogHistoryBack, (state) => ({
    ...state,
    dialogHistoryPosition: Math.max(state.dialogHistoryPosition - 1, 0)
  })),

  on(TipAction.DialogHistoryForward, (state) => ({
    ...state,
    dialogHistoryPosition: Math.min(state.dialogHistoryPosition + 1, state.dialogHistory.length - 1)
  }))
);

export const storeKey = 'tip';
export const getTipState = createFeatureSelector<State>(storeKey);
export const {
  selectAll: getAllTips,
  selectEntities: getTipEntities
} = adapter.getSelectors(getTipState);

// ************** Public API ***********************
/** Select a tip with the given id */
export const getTipById = createSelector(
  getTipEntities,
  (tips, { id }) => tips[id]
);

/** Select all tips with the given parent id */
export const getTipsByParentId = createSelector(
  getTipState,
  getTipEntities,
  ({ byParentId }, tips, { id }) => byParentId[id]?.map?.(childId => tips[childId] ?? [])
);

/** Return present navigation history consistent with history stack navigation */
export const getDialogHistory = createSelector(
  getTipState,
  ({ dialogHistory, dialogHistoryPosition }) => dialogHistory.slice(0, dialogHistoryPosition + 1)
);

/** Return whether there is forward and backward history to that can be navigated. */
export const getDialogHistoryStatus = createSelector(
  getTipState,
  ({ dialogHistory, dialogHistoryPosition }) => {
    const forward = dialogHistoryPosition < dialogHistory.length - 1;
    const backward = dialogHistoryPosition > 0;
    return { forward, backward };
  }
);

const getByAnchor = createSelector(getTipState, (state) => state.byAnchor);

/** Return id of a tip by anchor. */
export const getTipIdByAnchor = () => createSelector(
  getByAnchor,
  (byAnchor, { anchor }) => byAnchor[anchor]
);

/** Given an array of tip ids, get all tips. */
export const getTipsByAnchors = () => createSelector(
  getByAnchor,
  getTipEntities,
  (byAnchor, entities, { anchors }) => anchors.map(anchor => entities[byAnchor[anchor]])
);
