import { CreateGuide, Guide } from '@markmachine/features/guide/models/guide.model';
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';

export const fetchGuides = createAction(
  '[Guide] FetchGuides',
  props<{ guideIds: Guide['id'][] }>()
);

export const fetchGuideFailure = createAction(
  '[Guide] FetchGuidesFailure',
  props<{ error: string }>()
);

export const fetchGuideSuccess = createAction(
  '[Guide] FetchGuidesSuccess',
  props<{ guide: Guide }>()
);

export const fetchList = createAction(
  '[Guide] FetchList',
);

export const fetchListFailure = createAction(
  '[Guide] FetchListFailure',
  props<{ errorMessage: string }>()
);

export const fetchListSuccess = createAction(
  '[Guide] FetchListSuccess',
  props<{ guides: Guide[] }>()
);

export const loadGuides = createAction(
  '[Guide] Load Guides',
  props<{ guides: Guide[] }>()
);

export const updateGuide = createAction(
  '[Guide] UpdateGuide',
  props<{ guide: Update<Guide> }>()
);

export const addGuide = createAction(
  '[Guide] AddGuide',
  props<{ guide: CreateGuide }>()
);

export const addGuideSuccess = createAction(
  '[Guide] AddGuideSuccess',
  props<{ guide: Guide }>()
);

export const addGuideFailure = createAction(
  '[Guide] AddGuideFailure',
  props<{ guide: Partial<Guide>, error: any }>()
);

export const deleteGuides = createAction(
  '[Guide] DeleteGuides',
  props<{ guideIds: Guide['id'][] }>()
);

export const deleteGuidesSuccess = createAction(
  '[Guide] DeleteGuidesSuccess',
  props<{ guides: Guide[] }>()
);

export const deleteGuidesFailure = createAction(
  '[Guide] DeleteGuidesFailure',
  props<{ guideIds: Guide['id'][], error: any }>()
);

export const duplicateGuides = createAction(
  '[Guide] DuplicateGuides',
  props<{ guideIds: Guide['id'][] }>()
);

export const duplicateGuidesSuccess = createAction(
  '[Guide] DuplicateGuidesSuccess',
  props<{ guides: Guide[] }>()
);

export const duplicateGuidesFailure = createAction(
  '[Guide] DuplicateGuidesFailure',
  props<{ guideIds: Guide['id'][], error: any }>()
);
