/**
 * Profile Model
 * Mimics the User Profile in the API
 */

export type TutorialNames = 'newAppTutorial';

export class UserProfileFlags {
  debugMode = false;
  developerWarnings = false;
  tutorialInteractions = {
    newAppTutorial: { permanentlyDismissed: '' }
  };
}

export interface SharedUserProfile {
  readonly userId: string;
  fullName: string;
}

export interface AuthenticatedUserProfile {
  readonly userId: string;
  flags: UserProfileFlags;
}

export interface PrivateUserProfile extends AuthenticatedUserProfile {
  readonly userId: string;
  fullName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  phoneNumber: string;
  faxNumber: string;
  email: string;
  position: string;
  lawFirm: string;
  isAttorney: boolean;
  attorneyLicenseState: string;
  signature: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  flags: UserProfileFlags;
}

export class UserProfile implements SharedUserProfile, PrivateUserProfile {
  readonly userId: string;
  fullName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  phoneNumber: string;
  faxNumber: string;
  email: string;
  position: string;
  lawFirm: string;
  isAttorney = false;
  attorneyLicenseState: string;
  signature: string;
  readonly createdAt: Date;
  readonly updatedAt: Date;
  flags: UserProfileFlags = new UserProfileFlags();
}

