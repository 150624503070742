import { Injectable } from '@angular/core';
import { TipRequestService } from '@markmachine/features/tip/services/tip-request.service';
import { TipService } from '@markmachine/features/tip/services/tip.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, forkJoin, of } from 'rxjs';
import { catchError, concatMap, map, mapTo, mergeMap, switchMap } from 'rxjs/operators';
import * as TipAction from '../actions/tip.actions';

@Injectable()
export class TipsEffects {
  LoadTip$ = createEffect(() => this.actions$.pipe(
    ofType(TipAction.LoadTip),
    mergeMap(({ id }) =>
      this.request.getTip(id).pipe(
        map(tip => TipAction.TipDocument({ tip })),
        catchError(error => of(TipAction.LoadTipError({ error })))
      )
    )
  ));

  LoadTipByAnchor$ = createEffect(() => this.actions$.pipe(
    ofType(TipAction.LoadTipByAnchor),
    mergeMap(({ anchor }) => this.request.getTipByAnchor(anchor).pipe(
      map(tip => TipAction.TipDocument({ tip })),
      catchError(error => of(TipAction.LoadTipError({ error })))
    ))
  ));

  LoadTipChildren$ = createEffect(() => this.actions$.pipe(
    ofType(TipAction.LoadTipChildren),
    mergeMap(({ id }) =>
      this.request.getTipChildren(id).pipe(
        map(tips => TipAction.TipChildrenDocuments({ tips })),
        catchError(error => of(TipAction.LoadTipChildrenError({ error })))
      )
    )
  ));

  DialogOpen$ = createEffect(() => this.actions$.pipe(
    ofType(TipAction.DialogOpen),
    switchMap(({ tipIds }) => this.service.openDialog({ data: { tipIds } }).afterClosed()),
    mapTo(TipAction.DialogClose())
  ));

  DialogFollowAnchor$ = createEffect(() => this.actions$.pipe(
    ofType(TipAction.DialogFollowAnchor),
    switchMap(({ anchor }) => this.request.getTipByAnchor(anchor).pipe(map(tip => ({ anchor, tip })))),
    map(({ anchor, tip: tipWithChildren }) => {
      const { children: children_, ...tip } = tipWithChildren;
      const children = children_?.nodes ?? [];

      return TipAction.DialogFollowAnchorReady({ anchor, tip, children });
    })
  ));

  FollowAnchor$ = createEffect(() => this.actions$.pipe(
    ofType(TipAction.FollowAnchor),
    switchMap(({ anchor }) => this.request.getTipByAnchor(anchor)),
    map((tip) => TipAction.TipDocument({ tip })),
  ));

  DialogOpenByAnchors$ = createEffect(() => this.actions$.pipe(
    ofType(TipAction.DialogOpenByAnchors),
    // Fetch a tip by its anchor
    switchMap(({ anchors }) =>
      // Fetch each of the tips by anchor
      // TODO: Use a single query
      forkJoin(anchors.map(anchor => this.request.getTipByAnchor(anchor))).pipe(
        // Catch and report any request errors
        catchError((error) => {
          console.error(error);
          this.service.openNotFoundDialog();
          return EMPTY;
        })
      )
    ),
    // Split this action into a Load and Open action
    concatMap(tips => [
      // Load the tip into the store
      TipAction.DialogOpenByAnchorsReady({ tips }),
      // Open the dialog to present the tip
      TipAction.DialogOpen({ tipIds: tips.map(t => t.id) })
    ]),
  ));

  constructor(
    private actions$: Actions,
    private request: TipRequestService,
    private service: TipService
  ) {}
}
