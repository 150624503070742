/**
 * Case Version Reducer, initial state, and selectors
 *
 * Case Versions capture the case field values for a given case at a given point in time.
 * Drafts are also case versions.
 *
 * For the most part, case versions are not modified directly here. Instead, case versions
 * are updated in their entirety. Specific changes are usually made through the CaseFormService,
 * which is then subscribed to in order to dispatch `updateVersion` actions.
 */
import { GoodService } from '@markmachine/features/goods-services-selector/models/good-service.model';
import type { Guide } from '@markmachine/features/guide/models/guide.model';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createReducer, on } from '@ngrx/store';
import { isMatch, isNil } from 'lodash-es';
import * as VersionActions from '../actions/version.actions';
import { ApplicationType, Class, VersionContent } from '../models/version-content.model';
import { Version } from '../models/version.model';

// **************************************************************************
// State
// **************************************************************************

export interface State extends EntityState<Version> {
  // additional entities state properties
}

export const adapter: EntityAdapter<Version> = createEntityAdapter<Version>();

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

// **************************************************************************
// Migrations
// **************************************************************************

/** Migrate selections based on Guide['nodeId'] instead of Guide['id']. */
function migrateSelectedGuideIds(version: Version): Version {
  // Contains old base64-encoded Guide['nodeId'] instead of Guide['id']:
  let selectedGuideIds = version.content.guides?.selectedGuideIds ?? [];
  const needsMigration = selectedGuideIds.find(id => id.length > 36);
  if (!needsMigration) {
    return version;
  }

  // Migrate nodeId->id
  selectedGuideIds = selectedGuideIds.map(guideId => {
    if (guideId.length <= 36) {
      return guideId;
    }
    const [_, pkey] = JSON.parse(atob(guideId)) as [table: string, pkey: Guide['id']];
    return pkey;
  });
  // Return updated Version
  const guides = { ...version.content.guides, selectedGuideIds };
  return { ...version, content: { ...version.content, guides } };
}

/** Order in which to apply migrations. */
const MIGRATIONS = [migrateSelectedGuideIds];

/** Apply all migrations to a version. */
const migrate = (version: Version) => MIGRATIONS.reduce((val, f) => f(val), version);


// **************************************************************************
// Reducers
// **************************************************************************

export const reducer = createReducer(
  initialState,

  // **************************************************************************
  // @ngrx/entities
  // **************************************************************************
  on(VersionActions.addVersion, (state, { version }) => adapter.addOne(migrate(version), state)),
  on(VersionActions.upsertVersion, (state, { version }) => adapter.upsertOne(version, state)),
  on(VersionActions.addVersions, (state, { versions }) => adapter.addMany(versions.map(migrate), state)),
  on(VersionActions.upsertVersions, (state, { versions }) => adapter.upsertMany(versions, state)),
  on(VersionActions.updateVersion, (state, { version }) => adapter.updateOne(version, state)),
  on(VersionActions.updateVersions, (state, { versions }) => adapter.updateMany(versions, state)),
  on(VersionActions.deleteVersion, (state, { id }) => adapter.removeOne(id, state)),
  on(VersionActions.deleteVersions, (state, { ids }) => adapter.removeMany(ids, state)),
  on(VersionActions.loadVersions, (state, { versions }) => adapter.setAll(versions, state)),
  on(VersionActions.clearVersions, (state) => adapter.removeAll(state)),

  on(VersionActions.renameVersion, (state, { id, title }) => {
    const changes = { ...state.entities[id], title };
    return adapter.updateOne({ id, changes }, state);
  }),

  on(VersionActions.upsertGoodsAndServices, (state, { id, goodsServices }) => {
    const version = state.entities[id];
    const classes = upsertClassesReducer(version?.content.classes, goodsServices );
    const content = { ...version?.content, classes } as VersionContent;
    return adapter.updateOne({ id, changes: { ...version, content } }, state);
  }),

  on(VersionActions.remoteUnlockSuccess, (state, { id, status }) => {
    const changes = { ...state.entities[id], status };
    return adapter.updateOne({ id, changes }, state);
  }),

  on(VersionActions.updateStatusFields, (state, { id, type, ...rest }) => {
    const version = state.entities[id];
    let header = version?.content?.['case-file-header'];
    if (!header || isMatch(header, rest)) {
      return state;
    }
    header = { ...header, ...rest };
    const content = { ...version?.content, 'case-file-header': header };
    return adapter.updateOne({ id, changes: { content } }, state);
  }),

  on(VersionActions.selectGuides, (state, { id, guideIds }) => {
    const version = state.entities[id];
    const selectedGuideIds = version.content.guides?.selectedGuideIds ?? [];
    const toAdd = guideIds.filter(guideId => !selectedGuideIds.includes(guideId));
    if (toAdd.length === 0) {
      return state;
    }
    const content = {
      ...version.content,
      guides: {
        ...version.content.guides,
        selectedGuideIds: [...selectedGuideIds, ...toAdd]
      }
    };
    return adapter.updateOne({ id, changes: { content } }, state);
  }),

  on(VersionActions.deselectGuides, (state, { id, guideIds }) => {
    const version = state.entities[id];
    const selectedGuideIds = version.content.guides?.selectedGuideIds ?? [];
    const content = {
      ...version.content,
      guides: {
        ...version.content.guides,
        selectedGuideIds: selectedGuideIds.filter(guideId => !guideIds.includes(guideId))
      }
    };
    return adapter.updateOne({ id, changes: { content } }, state);
  }),
);


export function upsertClassesReducer(
  classes: Class[] = [],
  goodsServices: GoodService[]
): Class[] {
  goodsServices.forEach(s => {
    const classIndex = classes.findIndex(f => f['class-code'] === s.class);
    // Create a new Class Listing if the class doesn't exist
    if (classes.length === -1 || classIndex < 0) {
      classes = [...classes, createClassListing(s)];
    } else {
      // Update the class' listing if it exist
      classes[classIndex] = updateClassListing(
        classes[classIndex],
        s.description
      );
    }
  });

  return classes;
}

export function updateClassListing(cls: Class, listing: string): Class {
  const listings = cls.listing.split(';').map(c => c.trim());
  if (listings.includes(listing)) {
    return { ...cls, listing: listings.join('; ') };
  }
  return {
    ...cls,
    listing: listings.concat(listing).join('; ')
  };
}

export function createClassListing(goodService: GoodService): Class {
  return {
    ...new Class(),
    'class-code': goodService.class,
    listing: goodService.description.trim()
  };
}

// **************************************************************************
// Selectors
// **************************************************************************

export const storeKey = 'versions';
export const getVersionState = createFeatureSelector<State>(storeKey);

export const {
  selectIds: getVersionIds,
  selectEntities: getVersionEntities,
  selectAll: getAllVersions,
  selectTotal: getTotalVersions,
} = adapter.getSelectors(getVersionState);

export function applicationType(content: VersionContent): ApplicationType {
  // application-type is a customer form-field, initialized by CaseFormHeaderService
  // If initialization hasn't happened, we need to fallback to readonly USPTO fields.
  if (!isNil(applicationType)) {
    return content?.['case-file-header']?.['application-type'];
  }
  const isCurrentlyTeasPlus = content['case-file-header']['currentlyAsTeasPlusApp'];
  return isCurrentlyTeasPlus ? 'plus' : 'standard';
}
