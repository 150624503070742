import { CdkDragEnd } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ResizeColumnDirective } from '../../directives/resize-column.directive';

@Component({
  selector: 'mm-resize-handle',
  templateUrl: './resize-handle.component.html',
  styleUrls: ['./resize-handle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResizeHandleComponent {
  constructor(private column: ResizeColumnDirective) { }

  /** When the handle is released, reset the handle and let the resize directive know. */
  dragEnded(event: CdkDragEnd) {
    const { x: dx } = event.distance;
    this.column.resize(dx);
    event.source.reset();
  }
}
