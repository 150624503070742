import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnDestroy
} from '@angular/core';
import { SupertableComponent } from '../supertable/supertable.component';
import { SupertableHeader } from '../../models/header-models';
import { FilterChangeEvent } from '../../models/event-models';
import { FeaturesModel } from '../../models/feature-models';
import { FormControl } from '@angular/forms';
import { debounceTime, map } from 'rxjs/operators';
import { get, isNil } from 'lodash-es';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mm-advanced-filter',
  templateUrl: './advanced-filter.component.html',
  styleUrls: ['./advanced-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdvancedFilterComponent implements OnChanges, OnDestroy, OnInit {
  formControl = new FormControl('');

  @Input() filterableHeaders: SupertableHeader[];

  @Input() features: FeaturesModel;

  @Output() filterChange = new EventEmitter<FilterChangeEvent>();
  private sub: Subscription;

  ngOnInit() {
    this.sub = this.formControl.valueChanges.pipe(
      debounceTime(200),
      map(text => this.filterTextChange(text))
    ).subscribe(this.filterChange);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.features.currentValue.filter.text) {
      this.formControl.setValue(this.getValue(), { emitEvent: false });
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getFilterText(): string {
    return (document.getElementById('filter-input') as any).value;
  }

  getDisabledHeaderIds(): string[] {
    return this.filterableHeaders.filter(header => this.features.filter.disabledIds?.includes(header.id)).map(({ id }) => id);
  }

  getValue() {
    return this.features.filter.text;
  }

  isEnabled(header: SupertableHeader) {
    return !this.features.filter.disabledIds?.includes(header.id);
  }

  toggleHeader(header: SupertableHeader) {
    const disabledIds = this.isEnabled(header)
      ? [...this.features.filter.disabledIds ?? [], header.id]
      : this.features.filter.disabledIds?.filter(id => header.id !== id) ?? [];
    this.filterChange.emit({ features: this.features, text: this.getFilterText(), disabledIds });
  }

  filterTextChange(text) {
    return { features: this.features, text, disabledIds: this.getDisabledHeaderIds() };
  }
}
