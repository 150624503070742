export default `
fragment tipFields on Tip {
	id
  anchor
  content: topic
  hasChildren
  identifier
  idParent
  order: index
  parentIdentifier
}
`;
