import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AppState } from '@markmachine/interfaces';
import { getSerialNumber } from '@markmachine/views/submission-page/reducers';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'mm-basis-1b-edit',
  templateUrl: './basis-1b-edit.component.html',
  styleUrls: ['./basis-1b-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Basis1bEditComponent implements OnInit {
  @Input() group: FormGroup;
  @Input() extensionCount: number;

  serialNumber$: Observable<number>;

  constructor(
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    this.serialNumber$ = this.store.pipe(select(getSerialNumber));
  }

  get eligibleForExtension() {
    return !!this.group && !!this.group.get('$eligible-for-extension')?.value;
  }
}
