import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getUserId } from '@markmachine/features/account/reducers/account.reducer';
import { unpackGraphqlResponse } from '@markmachine/core/graphql-operators';
import { AppState } from '@markmachine/interfaces';
import { UserTable } from '@markmachine/features/user-table/models/user-table.model';
import { Update } from '@ngrx/entity';
import { select, Store } from '@ngrx/store';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { AllUserTablesQuery, AllUserTablesResponse } from './operations/all-user-tables.query';
import { CreateUserTableMutation, CreateUserTableResponse } from './operations/create-user-table.mutation';
import { UpdateUserTableMutation, UpdateUserTableResponse } from './operations/update-user-table.mutation';
import { getAllUserTables, getUserTableByKey } from '../reducers/user-table.reducer';
import * as UserTableAction from '@markmachine/features/user-table/actions/user-table.actions';

const URL = environment.GRAPHQL;

@Injectable({
  providedIn: 'root'
})
export class UserTableService {
  constructor(private http: HttpClient, private store: Store<AppState>) {}

  get userId$(): Observable<string> {
    return this.store.pipe(select(getUserId));
  }

  // **************************************************************************
  // User Tables
  // **************************************************************************

  get userTables$(): Observable<UserTable[]> {
    return this.store.pipe(select(getAllUserTables));
  }

  addUserTable(key: string) {
    this.store.dispatch(UserTableAction.addUserTable({ userTable: { key } }));
  }

  deleteUserTable(nodeId: string) {
    this.store.dispatch(UserTableAction.deleteUserTable({ nodeId }));
  }

  updateUserTable(id: string, changes: Partial<UserTable>) {
    this.store.dispatch(UserTableAction.updateUserTable({ userTable: { id, changes } }));
  }

  getUserTableByKey$(key: string) {
    return this.store.pipe(select(getUserTableByKey, key));
  }

  requestAllUserTables(): Observable<UserTable[]> {
    return this.http
      .post<AllUserTablesResponse>(URL, new AllUserTablesQuery({}))
      .pipe(unpackGraphqlResponse<UserTable[]>('data.allUserTables.nodes'));
  }

  requestUpdateUserTable(patch: Update<UserTable>): Observable<UserTable> {
    return this.http
      .post<UpdateUserTableResponse>(URL, new UpdateUserTableMutation(patch))
      .pipe(unpackGraphqlResponse<UserTable>('data.updateUserTable.userTable'));
  }

  requestCreateUserTable(userTable: Partial<UserTable>): Observable<UserTable> {
    return this.userId$.pipe(
      first(),
      switchMap(userId =>
        this.http
          .post<CreateUserTableResponse>(URL, new CreateUserTableMutation({ userTable: { ...userTable, userId } }))
          .pipe(unpackGraphqlResponse<UserTable>('data.createUserTable.userTable'))
      )
    );
  }

  /** Create a default user table matching the given key. */
  requestCreateUserTableByKey(key: string): Observable<UserTable> {
    // TODO: Look up default columns for special keys
    const [columns, options] = [[], {}];
    return this.requestCreateUserTable({ key, columns, options });
  }
}
