import { GraphQLQuery, GraphQLResponse } from 'app/interfaces';
import { ContextMenuItem } from '../../models/context-menu-item.model';
import { contextMenuItemFragment } from './context-menu.fragment';

const QUERY = `
query GetContextMenuByPrefix($prefix: String!) {
  contextMenuByPrefix(prefix: $prefix) {
    totalCount
    nodes {
      ...contextMenuFields
    }
  }
}
${contextMenuItemFragment}
`;

export class GetContextMenuByPrefixQuery implements GraphQLQuery {
  query = QUERY;
  constructor(public variables: { prefix: string }) {}
}

export type GetContextMenuByPrefixResponse = GraphQLResponse<{
  contextMenuByPrefix: {
    totalCount: number;
    nodes: ContextMenuItem[];
  }
}>;
