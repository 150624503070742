import { caseStatusFragmentFields } from '@markmachine/features/case-status/services/operations/case-status.fragment';
import { CaseStatusesResponse } from '@markmachine/features/case-status/services/operations/case-statuses.query';
import { GraphQLQuery } from '@markmachine/interfaces';

const query = `
query CaseStatusesByCaseIds($caseIds: [UUID]!, $withContent: Boolean!) {
	caseStatusesByCaseIds(caseIds: $caseIds) {
    nodes {
      ${caseStatusFragmentFields}
      content @include(if: $withContent)
    }
  }
}`;

export class CaseStatusesByCaseIdsQuery implements GraphQLQuery {
  query = query;
  constructor(public variables: {
    caseIds: string[],
    withContent?: boolean
  }) {
    this.variables.withContent = !!variables.withContent;
  }
}

export type CaseStatusesByCaseIdsResponse = CaseStatusesResponse;
