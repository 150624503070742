import { GraphQLQuery, GraphQLResponse } from 'app/interfaces';
import { caseStatusFragmentFields } from './case-status.fragment';
import { CaseStatus } from '@markmachine/features/case-status/models/status.model';

const query = `
query CaseStatuses($serialNumbers: [Int!]!, $withContent: Boolean!) {
	allCaseStatuses(filter: {
	  serialNumber: { in: $serialNumbers }
	}) {
    nodes {
      ${caseStatusFragmentFields}
      content @include(if: $withContent)
    }
  }
}`;

export class CaseStatusesQuery implements GraphQLQuery {
  query = query;
  constructor(public variables: {
    serialNumbers: number[],
    withContent?: boolean
  }) {
    this.variables.withContent = !!variables.withContent;
  }
}

export type CaseStatusesResponse = GraphQLResponse<{
  caseStatuses: {
    nodes: CaseStatus[];
  }
}>;
