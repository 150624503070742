/**
 * Return a new object with a value set at a path.
 *
 * Immutable; stack-consuming
 */
export function set(source: object, path: string[], value: any) {
  if (path.length === 0) {
    return source;
  } else if (path.length === 1) {
    return { ...source, [path[0]]: value };
  }
  const [prop, ...rest] = path;
  return { ...source, [prop]: set(source[prop] ?? {}, rest, value) };
}
