import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ElementRef
} from '@angular/core';
import { get, isEqual } from 'lodash-es';
import { combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import {
  FeaturesChangeEvent,
  FilterChangeEvent,
  HeaderResizeEvent,
  HeaderVellipsisMenuClick,
  PaginatorChangeEvent,
  ReorderHeadersEvent,
  TableScrollEvent,
  VisibilityChangeEvent
} from '../../models/event-models';
import {
  ColReorderModel,
  ColResizeModel,
  FeaturesModel,
  FilterModel,
  FrozenModel,
  PaginatorModel,
  ScrollModel,
  SelectionOptions,
  SortModel,
  VisibilityModel,
  RowNumberModel
} from '../../models/feature-models';
import { SupertableHeader } from '../../models/header-models';
import { RowModel } from '../../models/row-model';
import { TableComponent } from '../table/table.component';
import { PaginatorComponent } from '../paginator/paginator.component';


@Component({
  selector: 'mm-supertable',
  templateUrl: './supertable.component.html',
  styleUrls: ['./supertable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SupertableComponent implements OnDestroy, OnInit, AfterViewInit, OnChanges {
  constructor() { }

  @Input() actions: SupertableHeader[];
  @Input() headers: SupertableHeader[];
  @Input() tableData: any[];
  @Input() rowIdField: string;
  @Input() features: FeaturesModel;

  @Output() featuresChange = new EventEmitter<FeaturesChangeEvent>();

  @Output() headerVellipsisMenuClick = new EventEmitter<HeaderVellipsisMenuClick>();

  @ViewChild(TableComponent, { static: true }) table: TableComponent;
  @ViewChild(TableComponent, { read: ElementRef }) tableRef: ElementRef<HTMLElement>;
  @ViewChild(PaginatorComponent) paginator: PaginatorComponent;


  destroy$ = new Subject();

  headers$ = new ReplaySubject<SupertableHeader[]>(1);
  tableData$ = new ReplaySubject<any[]>(1);
  rowIdField$ = new ReplaySubject<string>(1);

  filterChanges$ = new ReplaySubject<FilterModel>(1);
  paginatorChanges$ = new ReplaySubject<PaginatorModel>(1);
  reorderColsChanges$ = new ReplaySubject<ColReorderModel>(1);
  resizeColsChanges$ = new ReplaySubject<ColResizeModel>(1);
  sortChanges$ = new ReplaySubject<SortModel>(1);
  frozenChanges$ = new ReplaySubject<FrozenModel>(1);
  visibilityChanges$ = new ReplaySubject<VisibilityModel>(1);
  scrollChanges$ = new ReplaySubject<ScrollModel>(1);
  selectionChanges$ = new ReplaySubject<SelectionOptions>(1);
  showRowNumber$ = new ReplaySubject<RowNumberModel>(1);

  features$: Observable<FeaturesModel>;

  namedHeaders$: Observable<SupertableHeader[]>;
  visibleHeaders$: Observable<SupertableHeader[]>;
  sortHeaders$: Observable<SupertableHeader[]>;
  filterHeaders$: Observable<SupertableHeader[]>;
  orderedHeaders$: Observable<SupertableHeader[]>;

  allRows$: Observable<RowModel[]>;
  filteredRows$: Observable<RowModel[]>;
  orderedRows$: Observable<RowModel[]>;
  pagedRows$: Observable<RowModel[]>;

  enableFilter$: Observable<boolean>;

  enablePaginator$: Observable<boolean>;
  numVisibleRows$: Observable<number>;

  enableVisibility$: Observable<boolean>;

  enableFrozen$: Observable<boolean>;

  ngOnDestroy() {
    this.destroy$.next();
  }

  ngOnInit() {
    this.features$ = combineLatest([
      this.filterChanges$, this.paginatorChanges$, this.reorderColsChanges$, this.resizeColsChanges$,
      this.sortChanges$, this.frozenChanges$, this.visibilityChanges$, this.scrollChanges$, this.selectionChanges$, this.showRowNumber$
    ]).pipe(map(([filter, paginator, reorderCols, resizeCols, sort, frozen, visibility, scroll, selection, showRowNumber]) => {
      return { filter, paginator, reorderCols, resizeCols, sort, frozen, visibility, scroll, selection, showRowNumber };
    }));

    this.namedHeaders$ = this.headers$.pipe(map(headers => {
      return headers.filter(header => !!(header as any).name);
    }));

    this.visibleHeaders$ = combineLatest([this.headers$, this.features$]).pipe(map(([headers, features]) => {
      const { enabled, hiddenIds } = features.visibility;
      if (enabled) {
        return headers.filter(header => !(hiddenIds as string[]).find(id => id === header.id));
      }
      return headers;
    }));

    this.sortHeaders$ = this.visibleHeaders$.pipe(map(headers => headers.filter(header => !!header.compare)));

    this.filterHeaders$ = this.visibleHeaders$.pipe(map(headers => headers.filter(header => !!header.matches)));

    this.orderedHeaders$ = combineLatest([this.visibleHeaders$, this.features$]).pipe(map(([visible, features]) => {
      const { enabled, orderedIds } = features.reorderCols;
      if (enabled) {
        const ordered: SupertableHeader[] = (orderedIds as string[])
          .map(id => visible.find(header => header.id === id) as SupertableHeader)
          .filter(Boolean);
        const last = ordered.length > 0 ? ordered[ordered.length - 1] : null;
        const unordered = visible.filter(header => !ordered.includes(header));
        if (!!last && last.type === 'row-options') {
          return [...ordered.slice(0, ordered.length - 1), ...unordered, last];
        } else {
          return [...ordered, ...unordered];
        }
      }
      return visible;
    }));

    this.allRows$ = combineLatest([this.tableData$, this.rowIdField$]).pipe(map(([table, idField]) => {
      return table.map(row => ({ id: row[idField], data: row }));
    }));

    this.filteredRows$ = combineLatest([this.filterHeaders$, this.allRows$, this.features$]).pipe(map(([filters, rows, features]) => {
      const { enabled, disabledIds, text } = features.filter;
      if (enabled) {
        return rows.filter(row => !!filters.filter(({id}) =>
          !disabledIds?.includes(id))?.find((filter: any) => filter.matches(row.data, text as string))
        );
      }
      return rows;
    }));

    this.orderedRows$ = combineLatest([this.sortHeaders$, this.filteredRows$, this.features$]).pipe(map(([sorters, filtered, features]) => {
      if (features.sort.enabled && (features.sort.direction === 'ascending') || features.sort.direction === 'descending') {
        const sorter = sorters.find(_sorter => _sorter.id === features.sort.headerId) as SupertableHeader;
        if (!!sorter) {
          return [...filtered].sort((row1, row2) => (sorter as any).compare(row1.data, row2.data, features.sort.direction ?? 'ascending'));
        }
      }
      return filtered;
    }));

    this.pagedRows$ = combineLatest([this.orderedRows$, this.features$]).pipe(map(([ordered, features]) => {
      const { enabled, pageIndex, pageSize } = features.paginator;
      if (enabled ?? pageIndex ?? pageSize) {
        const offset = pageIndex * pageSize;
        return ordered.slice(offset, offset + pageSize);
      }
      return ordered;
    }));

    this.enableFilter$ = this.features$.pipe(map(features => !!features.filter.enabled));

    this.numVisibleRows$ = this.filteredRows$.pipe(map(rows => rows.length));

    this.enablePaginator$ = this.features$.pipe(map(features => !!features.paginator.enabled));

    this.enableVisibility$ = this.features$.pipe(map(features => !!features.visibility.enabled));

    this.enableFrozen$ = this.features$.pipe(map(features => !!features.frozen.enabled));
  }

  ngAfterViewInit() {
    this.features$
      .pipe(
        distinctUntilChanged(isEqual),
        takeUntil(this.destroy$)
      )
      .subscribe(features => this.featuresChange.emit({ features }));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.headers) {
      this.headers$.next(changes.headers.currentValue);
    }
    if (!!changes.tableData) {
      this.tableData$.next(changes.tableData.currentValue);
    }
    if (!!changes.rowIdField) {
      this.rowIdField$.next(changes.rowIdField.currentValue);
    }
    if (!!changes.features) {
      this.setFeatures(changes.features.currentValue);
    }
  }

  get selection() {
    return this.table.selection;
  }

  get isAllSelected() {
    return this.tableData.find(row => !this.selection.isSelected(row.id));
  }

  setFeatures(features: FeaturesModel) {
    this.filterChanges$.next(features.filter as FilterModel);
    this.paginatorChanges$.next(features.paginator as PaginatorModel);
    this.reorderColsChanges$.next(features.reorderCols as ColReorderModel);
    this.resizeColsChanges$.next(features.resizeCols as ColResizeModel);
    this.sortChanges$.next(features.sort as SortModel);
    this.frozenChanges$.next(features.frozen as FrozenModel);
    this.visibilityChanges$.next(features.visibility as VisibilityModel);
    this.scrollChanges$.next(features.scroll as ScrollModel);
    this.selectionChanges$.next(features.selection);
    this.showRowNumber$.next(features.showRowNumber);
  }

  onPaginatorChange(event: PaginatorChangeEvent) {
    this.paginatorChanges$.next({
      enabled: event.features.paginator.enabled,
      pageIndex: event.pageEvent.pageIndex,
      pageSize: event.pageEvent.pageSize,
      pageSizeOptions: event.features.paginator.pageSizeOptions
    });
  }

  onFilterChange(event: FilterChangeEvent) {
    this.filterChanges$.next({
      disabledIds: event.disabledIds,
      enabled: event.features.filter.enabled,
      text: event.text
    });
    this.paginatorChanges$.next({
      enabled: event.features.paginator.enabled,
      pageIndex: 0,
      pageSize: event.features.paginator.pageSize,
      pageSizeOptions: event.features.paginator.pageSizeOptions
    });
  }

  onVisibilityChange(event: VisibilityChangeEvent) {
    this.visibilityChanges$.next({
      enabled: event.features.visibility.enabled,
      hiddenIds: event.hiddenIds
    });
  }

  onReorderHeaders(event: ReorderHeadersEvent) {
    const { enabled, colIndex, rowIndex } = event.features.frozen;
    const { headerIds, newIndex, oldIndex } = event;
    this.reorderColsChanges$.next({ enabled, orderedIds: headerIds });
    const minIndex = newIndex < oldIndex ? newIndex : oldIndex;
    if (colIndex ?? 0 >= minIndex) {
      this.frozenChanges$.next({ colIndex: minIndex - 1, enabled, rowIndex });
    }
  }

  onResetFrozen() {
    this.table.updateStickyColumnStyles(-1, 0);
    this.frozenChanges$.next({
      colIndex: -1,
      enabled: true,
      rowIndex: 0
    });
  }

  onHeaderResize({ headerId, width }: HeaderResizeEvent) {
    const widths = {
      ...get(this, 'features.resizeCols.widths', {}),
      [headerId]: width
    };
    this.resizeColsChanges$.next({ widths, enabled: true });
  }

  onTableScroll(event: TableScrollEvent) {
    this.scrollChanges$.next({
      enabled: event.features.scroll.enabled,
      scrollX: event.scrollX,
      scrollY: event.scrollY
    });
  }

  onHeaderVellipsisMenuClick(event: HeaderVellipsisMenuClick) {
    switch (event.menuOption) {
      case 'Sort Ascending':
        this.sortChanges$.next({
          direction: 'ascending',
          enabled: event.features.sort.enabled,
          headerId: event.headerId
        });
        break;
      case 'Sort Descending':
        this.sortChanges$.next({
          direction: 'descending',
          enabled: event.features.sort.enabled,
          headerId: event.headerId
        });
        break;
      case 'Unsort':
        this.sortChanges$.next({
          direction: 'none',
          enabled: event.features.sort.enabled,
          headerId: event.headerId
        });
        break;
      case 'Freeze Column':
        this.frozenChanges$.next({
          colIndex: event.headerIndex,
          enabled: event.features.frozen.enabled,
          rowIndex: event.features.frozen.rowIndex
        });
        break;
      case 'Freeze Row':
        this.frozenChanges$.next({
          colIndex: event.features.frozen.colIndex,
          enabled: event.features.frozen.enabled,
          rowIndex: 0
        });
        break;
      case 'Hide':
        this.visibilityChanges$.next({
          enabled: event.features.visibility.enabled,
          hiddenIds: [...event.features.visibility.hiddenIds ?? [], event.headerId]
        });
        break;
      case 'Un-hide All':
        this.visibilityChanges$.next({
          enabled: event.features.visibility.enabled,
          hiddenIds: []
        });
        break;
      default:
        this.headerVellipsisMenuClick.emit(event);
        break;
    }
  }
}
