import { Injectable } from '@angular/core';
import { FormControlElementRefDirective } from '@markmachine/shared/directives/form-control-element-ref.directive';

@Injectable({
  providedIn: 'root'
})
export class FormControlElementRefService {
  registery = new Map<string, FormControlElementRefDirective>();

  constructor() { }

  register(path: string, ref: FormControlElementRefDirective) {
    this.registery.set(path, ref);
  }

  unregister(path: string) {
    this.registery.delete(path);
  }
}
