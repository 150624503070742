import { GraphQLQuery, GraphQLResponse } from 'app/interfaces';
import { VersionId, StatusEnum } from '@markmachine/features/version/models/version.model';

const query = `
mutation UnlockVersion($versionId: UUID!) {
  unlockVersion(input: {versionId: $versionId}) {
    caseVersion {
      id
      status
    }
  }
}
`;

export class UnlockVersionMutation implements GraphQLQuery {
  query = query;
  constructor(public variables: { versionId: VersionId }) {}
}

export type UnlockVersionResponse = GraphQLResponse<{
  unlockVersion: {
    caseVersion: {
      id: VersionId;
      status: StatusEnum;
    };
  };
}>;
