<div class="clearance-search-result">
  <p *ngIf="statusCode">
   <span class="data status">{{statusCode | mapDisplayValues:'tsdr-status-code'}}</span>
  </p>
  <p *ngIf="regDate && regDate !== ''">
    <span class="label">Reg:</span>
    <span class="data"><mm-date [date]="regDate"></mm-date></span>
  </p>
  <p *ngIf="pubDate && pubDate !== ''">
    <span class="label">Pub:</span>
    <span class="data"><mm-date [date]="pubDate"></mm-date></span>
  </p>
  <p *ngIf="filedDate && filedDate !== ''">
    <span class="label">Filed:</span>
    <span class="data"><mm-date [date]="filedDate"></mm-date></span>
  </p>
  <p *ngIf="filingBasis && filingBasis !== ''">
    <span class="label">Current Basis:</span>
    <span class="data">{{filingBasis}}</span>
  </p>
</div>
