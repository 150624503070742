/**
 * User Table Module
 *
 * Provides state management for UserTable. A usertable is a user-specific defininition
 * and settings for a (potentially custom) table. User tables don't contain any table
 * rows/data themselves. They just describe the structure and user-persisted settings for
 * a given table.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromUserTable from './reducers/user-table.reducer';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { UserTableEffects } from './effects/user-table.effects';
import { PersistSupertableFeaturesDirective } from './directives/persist-supertable-features.directive';

@NgModule({
  declarations: [PersistSupertableFeaturesDirective],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromUserTable.storeKey, fromUserTable.reducer),
    EffectsModule.forFeature([UserTableEffects]),
  ],
  exports: [PersistSupertableFeaturesDirective]
})
export class UserTableModule { }
