import { UserCase } from '@markmachine/features/user-case/models/user-case.model';
import { GraphQLQuery, GraphQLResponse } from 'app/interfaces';
import { userCaseLargeFragment } from './user-case.fragment';

const graphqlQuery = `
mutation DeleteUserCases($caseIds: [UUID]!) {
  deleteUserCasesByCaseIds(input:{caseIds: $caseIds}) {
    userCases {
      ...userCaseLargeFragment
    }
  }
}
${userCaseLargeFragment}
`;

export class DeleteUserCasesMutation implements GraphQLQuery {
  query = graphqlQuery;
  constructor(public variables: { caseIds: string[] }) {}
}

export type DeleteUserCasesResponse = GraphQLResponse<{
  deleteUserCasesByCaseIds: { userCases: UserCase[] };
}>;
