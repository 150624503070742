import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { getTitle } from '@markmachine/core/reducers/layout.reducer';
import { AppState } from '@markmachine/interfaces';
import { createEffect } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';

@Injectable()
export class LayoutEffects {

  setTitle$ = createEffect(() => this.store.pipe(
    select(getTitle),
    tap(title => this.titleService.setTitle(title))
  ), { dispatch: false });

  constructor(
    private store: Store<AppState>,
    private titleService: Title
  ) {}
}
