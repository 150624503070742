import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { Tip } from '@markmachine/core/models/tip';
import { TipService } from '@markmachine/features/tip/services/tip.service';
import { BehaviorSubject, combineLatest, of, ReplaySubject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'mm-tree-controller',
  templateUrl: './tree-controller.component.html',
  styleUrls: ['./tree-controller.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeControllerComponent implements OnDestroy, OnInit {
  // Internal state-change event streams
  readonly #destroySub = new Subscription();
  readonly #expanded = new BehaviorSubject<boolean>(false);
  readonly #tipId = new ReplaySubject<Tip['id']>(1);

  // Public simple observables of internal state
  readonly expanded$ = this.#expanded.asObservable().pipe(distinctUntilChanged());
  readonly tipId$ = this.#tipId.asObservable().pipe(distinctUntilChanged());

  // Template observables with additional data
  readonly children$ = combineLatest([this.tipId$, this.expanded$]).pipe(
    switchMap(([id, expanded]) => (expanded ? this.service.getTipChildren(id) : of([])))
  );
  readonly tip$ = this.tipId$.pipe(switchMap((id) => this.service.getTip(id)));

  // Effects that we will want to subscribe to
  /** Load tip whenever tipId changes */
  readonly #loadTip = this.tipId$.pipe(tap((id) => this.service.loadTip(id)));
  /** Load children whenever tipId changes and this tip is expanded. */
  readonly #loadChildren = combineLatest([this.tipId$, this.expanded$]).pipe(
    filter(([_, expanded]) => expanded),
    tap(([parentId]) => this.service.loadTipChildren(parentId))
  );

  @Input() set tipId(value: Tip['id']) {
    this.#tipId.next(value);
  }

  get tipId() {
    return this.tipId;
  }

  @Input() set expanded(value: boolean) {
    this.#expanded.next(value);
  }

  get expanded(): boolean {
    return this.#expanded.value;
  }

  @Input() hideToggle = false;
  @Input() hideAllToggles = false;

  @Output() childExpanded = new EventEmitter();
  @ViewChildren(TreeControllerComponent) children: QueryList<TreeControllerComponent>;

  constructor(private service: TipService) {}

  ngOnInit() {
    // Subscribe to effects
    this.#destroySub.add(this.#loadTip.subscribe());
    this.#destroySub.add(this.#loadChildren.subscribe());
  }

  ngOnDestroy() {
    // Unsubscribe to effects
    this.#destroySub.unsubscribe();
  }

  expand() {
    this.expanded = true;
  }

  collapse() {
    this.expanded = false;
  }

  collapseOtherChildren(tipId: string) {
    this.children
      // Children eligible to collapse
      .filter((child) => child.expanded && tipId !== child.tipId)
      // Collapse them
      .forEach((child) => child.collapse());
  }
}
