import { HttpDownloadProgressEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CaseFileRequestService } from '@markmachine/features/case-file/services/case-file-request.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { merge, of } from 'rxjs';
import { catchError, filter, map, mergeMap, share, switchMap } from 'rxjs/operators';
import * as FileActions from '../actions/file.actions';
import { CaseFile } from '../models/file.model';


@Injectable()
export class FileEffects {
  RemoveFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FileActions.removeFile),
      switchMap(({ idCase, idCaseFile }) => {
        return this._req.deleteFile(idCase, idCaseFile).pipe(
          map(file => FileActions.removeFileSuccess({ casefile: file })),
          catchError(() => of(FileActions.removeFileFailure({ idCase, idCaseFile })))
        );
      })
    )
  );

  UploadFile$ = createEffect(() => this.actions$.pipe(
    ofType(FileActions.uploadFile),
    mergeMap(({ idCase, path, file, attachment }) => {
      const upload$ = this._req.uploadFileIncremental(idCase, file, path).pipe(share());
      const progress$ = upload$.pipe(
        filter(event => event.type === HttpEventType.DownloadProgress),
        map((event: HttpDownloadProgressEvent) => !!event.total ? Math.round(100 * event.loaded / event.total) : 0),
        map(progress => FileActions.uploadFileProgress({ file, progress }))
      );
      const success$ = upload$.pipe(
        filter(event => event.type === HttpEventType.Response),
        map((event: HttpResponse<CaseFile>) => FileActions.uploadFileSuccess({ file, casefile: event.body as CaseFile, attachment }))
      );
      return merge(progress$, success$);
    })
  ));

  constructor(private actions$: Actions, private _req: CaseFileRequestService) {}
}
