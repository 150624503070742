import { Version } from '@markmachine/features/version/models/version.model';
import { versionMetadataFragment } from './version-metadata.fragment';
import { GraphQLQuery, GraphQLResponse } from '@markmachine/interfaces';

const query = `
query CaseVersionById($versionId: UUID!) {
  caseVersionById(id: $versionId) {
    content ...versionMetadataFragment
  }
}
${versionMetadataFragment}
`;

export class VersionByIdQuery implements GraphQLQuery {
  query = query;

  constructor(public variables: { versionId: string }) {}
}

export type VersionByIdResponse = GraphQLResponse<{
  caseVersionById: Version;
}>;
