<mm-context-menu-item *ngFor="let menuItem of menuItems$ | async"
  [menuItem]="menuItem"></mm-context-menu-item>

<!-- Admin-only items -->
<ng-container *ngIf="isAdmin$ | async">
  <mat-divider *ngIf="menuItemsNotEmpty$ | async"></mat-divider>

  <button mat-menu-item
    (click)="setClipboard()">
    <mat-icon color="warn">extension</mat-icon>
    Copy menu path
  </button>
</ng-container>
