import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TipsContextMenuItem } from '@markmachine/features/context-menu/models/context-menu-item.model';
import { ExpansionPanelHelper } from '../../models/expansion-panel-helper.model';

/**
 * TipHelper implements the body of ExpansionPanelHelper for tips.
 *
 * AKA FullTips (vs QuickTips)
 */
@Component({
  selector: 'mm-tip-helper',
  templateUrl: './tip-helper.component.html',
  styleUrls: ['./tip-helper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TipHelperComponent implements ExpansionPanelHelper {
  @Input() menuItem: TipsContextMenuItem;
  @Output() hide = new EventEmitter<void>();
}
