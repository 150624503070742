import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { CaseFile } from '../models/file.model';

export const loadFiles = createAction('[File] Load Files', props<{ files: CaseFile[] }>());
export const addFile = createAction('[File] Add File', props<{ file: CaseFile }>());
export const upsertFile = createAction('[File] Upsert File', props<{ file: CaseFile }>());
export const addFiles = createAction('[File] Add Files', props<{ files: CaseFile[] }>());
export const upsertFiles = createAction('[File] Upsert Files', props<{ files: CaseFile[] }>());
export const updateFile = createAction('[File] Update File', props<{ file: Update<CaseFile> }>());
export const updateFiles = createAction('[File] Update Files', props<{ files: Update<CaseFile>[] }>());
export const deleteFile = createAction('[File] Delete File', props<{ id: string }>());
export const deleteFiles = createAction('[File] Delete Files', props<{ ids: string[] }>());
export const clearFiles = createAction('[File] Clear Files');
export const uploadFile = createAction('[File] Upload File', props<{ idCase: string; file: File; path?: string; attachment?: string }>());
export const uploadFileProgress = createAction('[File] UploadFileProgress', props<{ file: File; progress: number }>());
export const uploadFileSuccess = createAction('[File] UploadFileSuccess', props<{ casefile: CaseFile; file: File; attachment?: string }>());
export const uploadFileFailure = createAction('[File] UploadFileFailure', props<{ idCase: string; file: File; path: string }>());
export const removeFile = createAction('[File] Remove File', props<{ idCase: string; idCaseFile: string }>());
export const removeFileSuccess = createAction('[File] Remove File Success', props<{ casefile: CaseFile }>());
export const removeFileFailure = createAction('[File] Remove File Failure', props<{ idCase: string; idCaseFile: string }>());
