<h2 mat-dialog-title>{{ extensionCount + 1 | ordinal | titlecase }} Extension Request</h2>

<mat-dialog-content class="mat-typography"
  fxLayout="column">

  <mm-request-extension-form [root]="root"
    [extensionCount]="extensionCount"></mm-request-extension-form>

</mat-dialog-content>

<mat-dialog-actions fxLayoutAlign="flex-end">

  <button mat-button
    mat-dialog-close
    (click)="remove()"
    color="warn">REMOVE REQUEST</button>

  <button mat-button
    mat-dialog-close>DONE</button>

</mat-dialog-actions>