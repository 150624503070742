/**
 * Class Selector Module
 *
 * Provides the default class selector used in the class-list editor.
 * This selector just selects class codes.
 *
 * SEE ALSO: goods-services-selector, which provides tools for selecting actual goods and services within and across classes.
 *
 * Imported By: CaseModule
 */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ClassSelectorComponent } from './components/class-selector/class-selector.component';
import { ClassSelectorService } from './services/class-selector.service';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule
  ],
  exports: [ClassSelectorComponent],
  declarations: [ClassSelectorComponent],
  providers: [ClassSelectorService]
})
export class ClassSelectorModule { }
