import { UserTable } from '@markmachine/features/user-table/models/user-table.model';
import { Update } from '@ngrx/entity';
import { GraphQLQuery, GraphQLResponse } from 'app/interfaces';

const graphqlQuery = `
mutation UpdateUserTable($id: ID!, $changes: UserTablePatch!) {
  updateUserTable(input:{nodeId:$id, userTablePatch: $changes}) {
    userTable {
        nodeId
        userId
        key
        createdAt
        updatedAt
        title
        columns
        options
    }
  }
}
`;

export class UpdateUserTableMutation implements GraphQLQuery {
  query = graphqlQuery;

  constructor(public variables: Update<UserTable>) {}
}

export type UpdateUserTableResponse = GraphQLResponse<{
  updateUserTable: { userTable: UserTable };
}>;
