import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { CaseFile } from '@markmachine/features/case/models/file.model';

@Component({
  selector: 'mm-case-file-list-edit',
  templateUrl: './case-file-list-edit.component.html',
  styleUrls: ['./case-file-list-edit.component.scss']
})
export class FileListEditComponent {
  @Input() casefiles: CaseFile[] = [];
  @Input() multiple = true;
  @Input() tipAnchor: string;
  @Input() disabled = false;
  @Output() remove = new EventEmitter<string>();
  @Output() delete = new EventEmitter<string>();
  @Output() upload = new EventEmitter<FileList>();
  @ViewChild('upload', { read: ElementRef }) uploadElement: ElementRef<HTMLInputElement>;

  /** Open the native file choose dialog */
  chooseFiles() {
    this.uploadElement.nativeElement.click();
  }

  public deleteFile(idCaseFile: string): void {
    this.delete.emit(idCaseFile);
  }

  public removeFile(idCaseFile: string): void {
    this.remove.emit(idCaseFile);
  }

  public uploadFiles($event: FileList) {
    this.upload.emit($event);
    // Clear file input so attempting to select the same file a second time works.
    // HTML Input File Selection doesn't fire when selecting the same file.
    // Since we allow removal, (add -> remove -> add) doesn't work without first
    // clearing the input.
    // https://stackoverflow.com/a/12102992
    if (this.uploadElement.nativeElement) {
      this.uploadElement.nativeElement.value = null as any;
    }
  }

  progressBarMode(status: string, progress: number): string | null {
    switch (status) {
      case 'uploading':
        return progress === -1 ? 'indeterminate' : 'determinate';
      case 'deleting':
        return 'query';
      default:
        return null;
    }
  }
}
