import { GraphQLQuery, GraphQLResponse } from 'app/interfaces';
import { UserProfile } from '@markmachine/features/account/models/user-profile.model';
import { VersionContent } from '@markmachine/features/version/models/version-content.model';

const query = `
mutation VersionUpdate($caseVersionId: UUID!, $title: String!) {
  updateCaseVersionById(input: {id: $caseVersionId, caseVersionPatch: {title: $title}}) {
    caseVersion {
      id
      title
    }
  }
}
`;

export class RenameVersionMutation implements GraphQLQuery {
  query = query;
  constructor(public variables: { caseVersionId: string; title: string }) {}
}

export type RenameVersionResponse = GraphQLResponse<{
  updateCaseVersionById: {
    caseVersion: {
      id: string;
      title: string;
    };
  };
}>;
