import { UserCase } from '@markmachine/features/user-case/models/user-case.model';
import { GraphQLQuery, GraphQLResponse } from 'app/interfaces';
import { userCaseFragmentFields } from './user-case.fragment';

const graphqlQuery = `
mutation CreateUserCase($userCase: UserCaseInput!){
  createUserCase(input: { userCase: $userCase }){
    userCase{
      ${userCaseFragmentFields}
    }
  }
}
`;

export class CreateUserCaseMutation implements GraphQLQuery {
  query = graphqlQuery;
  constructor(public variables: { userCase: Partial<UserCase> }) {}
}

export type CreateUserCaseResponse = GraphQLResponse<{
  createUserCase: { userCase: UserCase };
}>;
