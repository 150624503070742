import { Injectable } from '@angular/core';
import { ascending } from '@markmachine/core/functions/utilities';
import { filter } from 'fuzzaldrin-plus';
import { isUndefined } from 'lodash-es';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';

export interface Option {
  display: string;
  value: string;
  hide?: boolean;
}

interface CountryOption extends Option {
  mailing: boolean;
  incorporation: boolean;
  citizenship: boolean;
}

export const USA_VALUE = 'United States (USX)';

@Injectable({
  providedIn: 'root'
})
export class InputOptionService {
  constructor() { }

  public aliasKind: Option[] = [
    { value: 'DBA', display: 'DBA (doing business as)' },
    { value: 'AKA', display: 'AKA (also known as)' },
    { value: 'TA', display: 'TA (trading as)' },
    { value: 'Formerly', display: 'Formerly' }
  ];

  public usStates: Option[] = [
    { value: 'Alabama (AL)', display: 'Alabama' },
    { value: 'Alaska (AK)', display: 'Alaska' },
    { value: 'Arizona (AZ)', display: 'Arizona' },
    { value: 'Arkansas (AR)', display: 'Arkansas' },
    { value: 'California (CA)', display: 'California' },
    { value: 'Colorado (CO)', display: 'Colorado' },
    { value: 'Connecticut (CT)', display: 'Connecticut' },
    { value: 'Delaware (DE)', display: 'Delaware' },
    { value: 'District of Columbia (DC)', display: 'District of Columbia' },
    { value: 'Florida (FL)', display: 'Florida' },
    { value: 'Georgia (GA)', display: 'Georgia' },
    { value: 'Hawaii (HI)', display: 'Hawaii' },
    { value: 'Idaho (ID)', display: 'Idaho' },
    { value: 'Illinois (IL)', display: 'Illinois' },
    { value: 'Indiana (IN)', display: 'Indiana' },
    { value: 'Iowa (IA)', display: 'Iowa' },
    { value: 'Kansas (KS)', display: 'Kansas' },
    { value: 'Kentucky (KY)', display: 'Kentucky' },
    { value: 'Louisiana (LA)', display: 'Louisiana' },
    { value: 'Maine (ME)', display: 'Maine' },
    { value: 'Maryland (MD)', display: 'Maryland' },
    { value: 'Massachusetts (MA)', display: 'Massachusetts' },
    { value: 'Michigan (MI)', display: 'Michigan' },
    { value: 'Minnesota (MN)', display: 'Minnesota' },
    { value: 'Mississippi (MS)', display: 'Mississippi' },
    { value: 'Missouri (MO)', display: 'Missouri' },
    { value: 'Montana (MT)', display: 'Montana' },
    { value: 'Nebraska (NE)', display: 'Nebraska' },
    { value: 'Nevada (NV)', display: 'Nevada' },
    { value: 'New Hampshire (NH)', display: 'New Hampshire' },
    { value: 'New Jersey (NJ)', display: 'New Jersey' },
    { value: 'New Mexico (NM)', display: 'New Mexico' },
    { value: 'New York (NY)', display: 'New York' },
    { value: 'North Carolina (NC)', display: 'North Carolina' },
    { value: 'North Dakota (ND)', display: 'North Dakota' },
    { value: 'Ohio (OH)', display: 'Ohio' },
    { value: 'Oklahoma (OK)', display: 'Oklahoma' },
    { value: 'Oregon (OR)', display: 'Oregon' },
    { value: 'Pennsylvania (PA)', display: 'Pennsylvania' },
    { value: 'Rhode Island (RI)', display: 'Rhode Island' },
    { value: 'South Carolina (SC)', display: 'South Carolina' },
    { value: 'South Dakota (SD)', display: 'South Dakota' },
    { value: 'Tennessee (TN)', display: 'Tennessee' },
    { value: 'Texas (TX)', display: 'Texas' },
    { value: 'Utah (UT)', display: 'Utah' },
    { value: 'Vermont (VT)', display: 'Vermont' },
    { value: 'Virginia (VA)', display: 'Virginia' },
    { value: 'Washington (WA)', display: 'Washington' },
    { value: 'West Virginia (WV)', display: 'West Virginia' },
    { value: 'Wisconsin (WI)', display: 'Wisconsin' },
    { value: 'Wyoming (WY)', display: 'Wyoming' },
    { value: 'Armed Forces Americas (AA)', display: 'Armed Forces Americas' },
    { value: 'Armed Forces Europe/Middle East/Canada (AE)', display: 'Armed Forces Europe/Middle East/Canada' },
    { value: 'Armed Forces Pacifi (AP)', display: 'Armed Forces Pacific' }
  ];

  public attorneyBarStates: Option[] = [
    { display: 'Alabama', value: 'AL' },
    { display: 'Alaska', value: 'AK' },
    { display: 'American Samoa', value: 'AS' },
    { display: 'Arizona', value: 'AZ' },
    { display: 'Arkansas', value: 'AR' },
    { display: 'California', value: 'CA' },
    { display: 'Colorado', value: 'CO' },
    { display: 'Connecticut', value: 'CT' },
    { display: 'Delaware', value: 'DE' },
    { display: 'District of Columbia', value: 'DC' },
    { display: 'Florida', value: 'FL' },
    { display: 'Georgia', value: 'GA' },
    { display: 'Guam', value: 'GU' },
    { display: 'Hawaii', value: 'HI' },
    { display: 'Idaho', value: 'ID' },
    { display: 'Illinois', value: 'IL' },
    { display: 'Indiana', value: 'IN' },
    { display: 'Iowa', value: 'IA' },
    { display: 'Kansas', value: 'KS' },
    { display: 'Kentucky', value: 'KY' },
    { display: 'Louisiana', value: 'LA' },
    { display: 'Maine', value: 'ME' },
    { display: 'Maryland', value: 'MD' },
    { display: 'Massachusetts', value: 'MA' },
    { display: 'Michigan', value: 'MI' },
    { display: 'Minnesota', value: 'MN' },
    { display: 'Mississippi', value: 'MS' },
    { display: 'Missouri', value: 'MO' },
    { display: 'Montana', value: 'MT' },
    { display: 'Nebraska', value: 'NE' },
    { display: 'Nevada', value: 'NV' },
    { display: 'New Hampshire', value: 'NH' },
    { display: 'New Jersey', value: 'NJ' },
    { display: 'New Mexico', value: 'NM' },
    { display: 'New York', value: 'NY' },
    { display: 'North Carolina', value: 'NC' },
    { display: 'North Dakota', value: 'ND' },
    { display: 'Northern Mariana Islands', value: 'MP' },
    { display: 'Ohio', value: 'OH' },
    { display: 'Oklahoma', value: 'OK' },
    { display: 'Oregon', value: 'OR' },
    { display: 'Pennsylvania', value: 'PA' },
    { display: 'Puerto Rico', value: 'PR' },
    { display: 'Rhode Island', value: 'RI' },
    { display: 'South Carolina', value: 'SC' },
    { display: 'South Dakota', value: 'SD' },
    { display: 'Tennessee', value: 'TN' },
    { display: 'Texas', value: 'TX' },
    { display: 'Utah', value: 'UT' },
    { display: 'US Virgin Islands', value: 'VI' },
    { display: 'Vermont', value: 'VT' },
    { display: 'Virginia', value: 'VA' },
    { display: 'Wake Island', value: 'WK' },
    { display: 'Washington', value: 'WA' },
    { display: 'West Virginia', value: 'WV' },
    { display: 'Wisconsin', value: 'WI' },
    { display: 'Wyoming', value: 'WY' },
    { display: 'Armed Forces Americas', value: 'AA' },
    { display: 'Armed Forces Europe/Middle East/Canada', value: 'AE' },
    { display: 'Armed Forces Pacific', value: 'AP' },
  ];

  /** Primary domestic entity types */
  public entityTypes: Option[] = [
    // The first options are currently available in TEAS as top-level choices
    { value: '01', display: 'Individual' },
    { value: '03', display: 'Corporation' },
    { value: '16', display: 'Limited Liability Company' },
    { value: '02', display: 'Partnership' },
    { value: '13', display: 'Limited Partnership' },
    { value: '05', display: 'Joint Venture' },
    { value: '19', display: 'Sole Proprietorship' },
    { value: '17', display: 'Trust' },
    { value: '18', display: 'Estate' },
    // {value: '99', display: 'Other'},
  ];

  /**
   * UNSELECTABLE domestic entity types that might appear in abandoned cases.
   *
   * These options should not be selectable at all, but might appear in historical data.
   */
  public entityTypesHistorical: Option[] = [
    // Historical unselectable options
    { value: '06', display: 'Incorporated Association', hide: true },
    { value: '09', display: 'Joint Stock Company', hide: true },
    { value: '12', display: 'Foundation', hide: true },
    { value: '15', display: 'Federal Agency', hide: true },
  ];

  /**
   * Historical domestic entity types that have MOVED to "other" domestic entities.
   *
   * These options are already present in `otherDomesticEntities` and should only
   * be chosen through the "other" mechanism. However, existing case data might
   * feature these values and will need to be translated.
   */
  public entityTypesMovedToOther: Option[] = [
    { value: '04', display: 'Joint Ownership', hide: true },
    { value: '07', display: 'Unincorporated Association', hide: true },
    { value: '08', display: 'Trustee', hide: true },
    { value: '10', display: 'Limited Liability Joint Stock Company', hide: true },
    { value: '11', display: 'Company', hide: true },
    { value: '14', display: 'State Agency', hide: true },
  ];

  public entityTypesDefault: Option = this.entityTypes[0];

  public otherEntitiesDomestic: Option[] = [
    { value: 'agency of the United States government', display: 'agency of the United States government' },
    { value: '06', display: 'incorporated association' },
    { value: '07', display: 'unincorporated association' },
    { value: 'body politic and corporate', display: 'body politic and corporate' },
    { value: 'business trust', display: 'business trust' },
    { value: 'charitable corporation', display: 'charitable corporation' },
    { value: 'charitable trust', display: 'charitable trust' },
    { value: 'charitable, non-profit corporation', display: 'charitable, non-profit corporation' },
    { value: 'chartered bank', display: 'chartered bank' },
    {
      value: 'congressionally chartered nonprofit organization',
      display: 'congressionally chartered nonprofit organization'
    },
    { value: 'cooperative association', display: 'cooperative association' },
    { value: 'cooperative corporation', display: 'cooperative corporation' },
    { value: 'credit union', display: 'credit union' },
    { value: '15', display: 'federal agency' },
    { value: 'federally chartered credit union', display: 'federally chartered credit union' },
    { value: 'federally chartered corporation', display: 'federally chartered corporation' },
    { value: 'federally-recognized Indian tribe', display: 'federally-recognized Indian tribe' },
    { value: '12', display: 'foundation' },
    { value: 'instrumentality', display: 'instrumentality' },
    { value: '09', display: 'joint stock company' },
    { value: 'limited liability limited partnership', display: 'limited liability limited partnership' },
    { value: 'limited liability partnership', display: 'limited liability partnership' },
    { value: 'municipal corporation', display: 'municipal corporation' },
    { value: 'national banking association', display: 'national banking association' },
    { value: 'non-profit association', display: 'non-profit association' },
    { value: 'non-profit corporation', display: 'non-profit corporation' },
    { value: 'non-profit organization', display: 'non-profit organization' },
    { value: 'non-stock corporation', display: 'non-stock corporation' },
    { value: 'professional corporation', display: 'professional corporation' },
    { value: 'public benefit corporation', display: 'public benefit corporation' },
    { value: '14', display: 'state agency' },
    { value: 'state-recognized Indian tribe', display: 'state-recognized Indian tribe' },
    { value: 'state university', display: 'state university' },
    { value: 'stock company', display: 'stock company' },
    { value: 'tax exempt corporation', display: 'tax exempt corporation' },
    { value: 'Other', display: 'Other' }
  ];

  /* tslint:disable:max-line-length */
  public otherEntitiesForeign: Option[] = [
    { value: 'agrupamento complementar de empresas (ace)', display: 'agrupamento complementar de empresas (ace)' },
    { value: 'akciova spolecnost (a.s.)', display: 'akciova spolecnost (a.s.)' },
    { value: 'aksjeselskap (as)', display: 'aksjeselskap (as)' },
    { value: 'aktiebolag (ab)', display: 'aktiebolag (ab)' },
    { value: 'aktiebolag, publikt (apb)', display: 'aktiebolag, publikt (apb)' },
    { value: 'aktiengesellschaft', display: 'aktiengesellschaft (ag)' },
    { value: 'aktieselskab (a/s)', display: 'aktieselskab (a/s)' },
    { value: 'aktsiaselts (a.s.)', display: 'aktsiaselts (a.s.)' },
    { value: 'aktzionero druzhestvo (ad)', display: 'aktzionero druzhestvo (ad)' },
    { value: 'allmennaksjeselskap (asa)', display: 'allmennaksjeselskap (asa)' },
    { value: 'andelslag (al)', display: 'andelslag (al)' },
    { value: 'andelsselskab (amba)', display: 'andelsselskab (amba)' },
    { value: 'anonim sirketi (a.s.)', display: 'anonim sirketi (a.s.)' },
    { value: 'anonymos etairia (ae)', display: 'anonymos etairia (ae)' },
    { value: 'anpartsselskab (aps)', display: 'anpartsselskab (aps)' },
    { value: 'ansvarlig selskap (ans)', display: 'ansvarlig selskap (ans)' },
    { value: 'aps & co. k/s', display: 'aps & co. k/s' },
    { value: 'aruba vrijgestelde vennootschap (avv)', display: 'aruba vrijgestelde vennootschap (avv)' },
    { value: 'asociación civil (a.c.)', display: 'asociación civil (a.c.)' },
    { value: 'asociación en participación (a. en p.)', display: 'asociación en participación (a. en p.)' },
    { value: 'association', display: 'association' },
    { value: 'associazione', display: 'associazione' },
    { value: 'be\'eravon mugbal (b.m.)', display: 'be\'eravon mugbal (b.m.)' },
    { value: 'beslote korporasie (bk)', display: 'beslote korporasie (bk)' },
    { value: 'besloten vennootschap (b.v.)', display: 'besloten vennootschap (b.v.)' },
    {
      value: 'besloten vennootschap met beperkte aansprakelijkheid (bvba)',
      display: 'besloten vennootschap met beperkte aansprakelijkheid (bvba)'
    },
    { value: 'betiti társaság (bt)', display: 'betiti társaság (bt)' },
    { value: 'commanditaire vennootschap (c.v.)', display: 'commanditaire vennootschap (c.v.)' },
    { value: 'commanditaire vennootschap op aandelen (cva)', display: 'commanditaire vennootschap op aandelen (cva)' },
    { value: 'commanditaire vennootschap op andelen (cvoa)', display: 'commanditaire vennootschap op andelen (cvoa)' },
    { value: 'compania anonima (ca)', display: 'compania anonima (ca)' },
    { value: '11', display: 'company' },
    { value: 'cwmni & cyfyngedig cyhoeddus (ccc)', display: 'cwmni & cyfyngedig cyhoeddus (ccc)' },
    { value: 'cyfyngedig (cyf.)', display: 'cyfyngedig (cyf.)' },
    { value: 'delniska druzba (d.d.)', display: 'delniska druzba (d.d.)' },
    { value: 'dionicko drustvo (d.d.)', display: 'dionicko drustvo (d.d.)' },
    { value: 'ditta individuale (di)', display: 'ditta individuale (di)' },
    { value: 'drustvo s ogranicenom odgovornoscu (d.o.o.)', display: 'drustvo s ogranicenom odgovornoscu (d.o.o.)' },
    { value: 'druzba z neomejeno odgovornostjo (d.n.o.)', display: 'druzba z omejeno odgovornostjo (d.o.o.)' },
    { value: 'druzhestvo s ogranichena otgovornost (ood)', display: 'druzhestvo s ogranichena otgovornost (ood)' },
    {
      value: 'ednolichno druzhestvo s ogranichena otgovornost (eood)',
      display: 'ednolichno druzhestvo s ogranichena otgovornost (eood)'
    },
    { value: 'einfache gesellschaft (eg)', display: 'einfache gesellschaft (eg)' },
    { value: 'eingetragene erwerbsgesellschaft (eeg)', display: 'eingetragene erwerbsgesellschaft (eeg)' },
    { value: 'eingetragener verein (e.v.)', display: 'eingetragener verein (e.v.)' },
    { value: 'einzelfirma', display: 'einzelfirma' },
    {
      value: 'empresa individual de responsabilidad limitada (eirl)',
      display: 'empresa individual de responsabilidad limitada (eirl)'
    },
    {
      value: 'entreprise unipersonnelle à responsabilité limitée (eurl)',
      display: 'entreprise unipersonnelle à responsabilité limitée (eurl)'
    },
    { value: 'etairia periorismenis efthinis (epe)', display: 'etairia periorismenis efthinis (epe)' },
    { value: 'eterrorrythmos (ee)', display: 'eterrorrythmos (ee)' },
    { value: 'exempted limited partnership (elp)', display: 'exempted limited partnership (elp)' },
    { value: 'fondation', display: 'fondation' },
    { value: 'fondazione', display: 'fondazione' },
    { value: '12', display: 'foundation' },
    { value: 'genossenschaft (gen)', display: 'genossenschaft (gen)' },
    { value: 'gesellschaft burgerlichen rechts (gbr)', display: 'gesellschaft burgerlichen rechts (gbr)' },
    { value: 'gesellschaft mit beschränkter haftung (gmbh)', display: 'gesellschaft mit beschränkter haftung (gmbh)' },
    { value: 'gesmgh', display: 'gesmgh' },
    { value: 'gewone commanditaire vennootschap (gcv)', display: 'gewone commanditaire vennootschap (gcv)' },
    { value: 'gmbh & co. kg', display: 'gmbh & co. kg' },
    { value: 'groupement d\'intéret économique (gie)', display: 'groupement d\'intéret économique (gie)' },
    { value: 'godo kaisha (g.k.)', display: 'godo kaisha (g.k.)' },
    { value: 'gomei kaisha', display: 'gomei kaisha' },
    { value: 'goshi kaisha', display: 'goshi kaisha' },
    { value: 'handelsbolag (hb)', display: 'handelsbolag (hb)' },
    { value: 'hlutafelag (hf)', display: 'hlutafelag (hf)' },
    { value: 'interessentskab (i/s)', display: 'interessentskab (i/s)' },
    { value: 'javno trgovacko drustvo (j.t.d.)', display: 'javno trgovacko drustvo (j.t.d.)' },
    { value: '09', display: 'joint stock company' },
    { value: 'julkinen osakeyhtiö (oyj)', display: 'julkinen osakeyhtiö (oyj)' },
    { value: 'kabushiki kaisha (k.k.)', display: 'kabushiki kaisha (k.k.)' },
    { value: 'kollektivgesellschaft (kolg)', display: 'kollektivgesellschaft (kolg)' },
    { value: 'kollektiv sirket (kol. srk)', display: 'kollektiv sirket (kol. srk)' },
    { value: 'komanditna delniska druzba (k.d.d.)', display: 'komanditna delniska druzba (k.d.d.)' },
    { value: 'komanditna druzba (k.d.)', display: 'komanditna druzba (k.d.)' },
    { value: 'komanditni spolecnost (k.s.)', display: 'komanditni spolecnost (k.s.)' },
    { value: 'komanditno drushestwo (kd)', display: 'komanditno drushestwo (kd)' },
    { value: 'komanditno drushestwo s akzii (kda)', display: 'komanditno drushestwo s akzii (kda)' },
    { value: 'komanditno drustvo (k.d.)', display: 'komanditno drustvo (k.d.)' },
    { value: 'komandit sirket (kom. srk)', display: 'komandit sirket (kom. srk)' },
    { value: 'kommandiittiyhtiö (ky)', display: 'kommandiittiyhtiö (ky)' },
    { value: 'kommandit-aktiengesellschaft (kag)', display: 'kommandit-aktiengesellschaft (kag)' },
    { value: 'kommanditaktieselskab (ka/s)', display: 'kommanditaktieselskab (ka/s)' },
    { value: 'kommanditbolag (kb)', display: 'kommanditbolag (kb)' },
    { value: 'kommanditgesellschaft (kg)', display: 'kommanditgesellschaft (kg)' },
    { value: 'kommanditgesellschaft auf aktien (kgaa)', display: 'kommanditgesellschaft auf aktien (kgaa)' },
    { value: 'kommanditselskab (k/s)', display: 'kommanditselskab (k/s)' },
    { value: 'kommandittselskap (ks)', display: 'kommandittselskap (ks)' },
    { value: 'korlátolt felelösségû társaság (kft)', display: 'korlátolt felelösségû társaság (kft)' },
    { value: 'közkereseti társaság (kkt)', display: 'közkereseti társaság (kkt)' },
    { value: 'közös vállalat (kv)', display: 'közös vállalat (kv)' },
    { value: 'limited company (ltd.)', display: 'limited company (ltd.)' },
    { value: 'limited corporation', display: 'limited corporation' },
    { value: 'limited duration company (ldc)', display: 'limited duration company (ldc)' },
    { value: 'limited liability company', display: 'limited liability company' },
    { value: '10', display: 'limited liability joint stock company' },
    { value: 'naamloze vennootschap (nv)', display: 'naamloze vennootschap (nv)' },
    {
      value: 'obschestvo s ogranichennoy otveststvennostyu (ooo)',
      display: 'obschestvo s ogranichennoy otveststvennostyu (ooo)'
    },
    { value: 'offene handelsgesellschaft (ohg)', display: 'offene handelsgesellschaft (ohg)' },
    { value: 'omorrythmos (oe)', display: 'omorrythmos (oe)' },
    { value: 'osakeyhtiö (oy)', display: 'osakeyhtiö (oy)' },
    { value: 'osaühing (oü)', display: 'osaühing (oü)' },
    { value: 'otkrytoe aktsionernoe obshchestvo (oao)', display: 'otkrytoe aktsionernoe obshchestvo (oao)' },
    { value: 'penenaman modal asing (pma)', display: 'penenaman modal asing (pma)' },
    { value: 'penanaman modal dalam negeri (pmdn)', display: 'penanaman modal dalam negeri (pmdn)' },
    { value: 'perseroan terbatas, terbuka (pt tbk)', display: 'perseroan terbatas, terbuka (pt tbk)' },
    { value: 'perseroan terbuka (pt)', display: 'perseroan terbuka (pt)' },
    { value: 'partnership limited by shares', display: 'partnership limited by shares' },
    { value: 'private company limited by shares (prc)', display: 'private company limited by shares (prc)' },
    {
      value: 'private company limited by shares (prp. ltd.)',
      display: 'private company limited by shares (prp. ltd.)'
    },
    { value: 'private limited company', display: 'private limited company' },
    { value: 'private limited liability company', display: 'private limited liability company' },
    { value: 'private unlimited company', display: 'private unlimited company' },
    { value: 'professional limited liability company', display: 'professional limited liability company' },
    { value: 'proprietary company (pty.)', display: 'proprietary company (pty.)' },
    {
      value: 'proprietary limited company (p/l or pty. ltd.)',
      display: 'proprietary limited company (p/l or pty. ltd.)'
    },
    { value: 'public company limited by shares (pc ltd.)', display: 'public company limited by shares (pc ltd.)' },
    { value: 'public limited company (plc)', display: 'public limited company (plc)' },
    { value: 'publikt aktiebolag (apb)', display: 'publikt aktiebolag (apb)' },
    { value: 'raison individuelle (ri)', display: 'raison individuelle (ri)' },
    { value: 'részvénytársaság (rt)', display: 'részvénytársaság (rt)' },
    { value: 'riiklik aktsiaselts (ras)', display: 'riiklik aktsiaselts (ras)' },
    { value: 'selskap med delt ansar (da)', display: 'selskap med delt ansar (da)' },
    { value: 'sendirian berhad (sdn bhd)', display: 'sendirian berhad (sdn bhd)' },
    { value: 'sociedad anonima (sa)', display: 'sociedad anonima (sa)' },
    { value: 'sociedad anonima (sa de cv))', display: 'sociedad anonima (sa de cv))' },
    {
      value: 'sociedad anonima financiera de inversion (safi)',
      display: 'sociedad anonima financiera de inversion (safi)'
    },
    {
      value: 'sociedad anónima inscrita de capital abierto (s.a.i.c.a.)',
      display: 'sociedad anónima inscrita de capital abierto (s.a.i.c.a.)'
    },
    {
      value: 'sociedad de responsabilidad limitada (s. de r.l.)',
      display: 'sociedad de responsabilidad limitada (s. de r.l.)'
    },
    { value: 'sociedad de responsabilidad limitada (sl)', display: 'sociedad de responsabilidad limitada (sl)' },
    { value: 'sociedad de responsabilidad limitada (srl)', display: 'sociedad de responsabilidad limitada (srl)' },
    { value: 'sociedad en comandita (s. en c.)', display: 'sociedad en comandita (s. en c.)' },
    { value: 'sociedad en comandita simple (scs)', display: 'sociedad en comandita simple (scs)' },
    { value: 'sociedad en commandita (s.c.)', display: 'sociedad en commandita (s.c.)' },
    { value: 'sociedad en nombre colectivo (snc)', display: 'sociedad en nombre colectivo (snc)' },
    { value: 'sociedad en nombre colectivo (s.n.c.)', display: 'sociedad en nombre colectivo (s.n.c.)' },
    { value: 'sociedad limitada nueva empresa (slne)', display: 'sociedad limitada nueva empresa (slne)' },
    { value: 'sociedad regular colectiva (src)', display: 'sociedad regular colectiva (src)' },
    { value: 'sociedade em comandita simples (s.c.s.)', display: 'sociedade em comandita simples (s.c.s.)' },
    { value: 'sociedade em conta de participacão (scp)', display: 'sociedade em conta de participacão (scp)' },
    {
      value: 'sociedade gestora de participações socialis (sgps)',
      display: 'sociedade gestora de participações socialis (sgps)'
    },
    { value: 'sociedade por ações (s.a.)', display: 'sociedade por ações (s.a.)' },
    {
      value: 'sociedade por quotas de responsabiliadade limitada (ltda)',
      display: 'sociedade por quotas de responsabiliadade limitada (ltda)'
    },
    { value: 'sociedade por quotas limitada (lda)', display: 'sociedade por quotas limitada (lda)' },
    { value: 'sociedades anônimas (s/a)', display: 'sociedades anônimas (s/a)' },
    { value: 'società a garanzia limitata (sagl)', display: 'società a garanzia limitata (sagl)' },
    { value: 'società a responsabilità limitata (srl)', display: 'società a responsabilità limitata (srl)' },
    { value: 'societa cooperativa (sc)', display: 'societa cooperativa (sc)' },
    { value: 'societá in accomandita per azioni (sapa)', display: 'societá in accomandita per azioni (sapa)' },
    { value: 'societá in accomandita semplice (sas)', display: 'societá in accomandita semplice (sas)' },
    { value: 'società in nome collettivo (snc)', display: 'società in nome collettivo (snc)' },
    { value: 'società per azioni (spa)', display: 'società per azioni (spa)' },
    { value: 'società semplice (ss)', display: 'società semplice (ss)' },
    { value: 'societas Europae (se)', display: 'societas Europae (se)' },
    { value: 'societate cu raspondere limitata (srl)', display: 'societate cu raspondere limitata (srl)' },
    { value: 'societate in còmandita pe actiuni (sca)', display: 'societate in còmandita pe actiuni (sca)' },
    { value: 'societate in còmandita simpla (scs)', display: 'societate in còmandita simpla (scs)' },
    { value: 'societate in nume colectiv (snc)', display: 'societate in nume colectiv (snc)' },
    { value: 'societate pe actiuni (sa)', display: 'societate pe actiuni (sa)' },
    { value: 'société anonyme (sa)', display: 'société anonyme (sa)' },
    { value: 'société à responsabilité limitée (sarl)', display: 'société à responsabilité limitée (sarl)' },
    { value: 'société civile (sc)', display: 'société civile (sc)' },
    { value: 'société coopérative (scop)', display: 'société coopérative (scop)' },
    {
      value: 'société de participation financiére (soparfi)',
      display: 'société de participation financiére (soparfi)'
    },
    { value: 'société en commandite (sc)', display: 'société en commandite (sc)' },
    { value: 'société en commandite par actions (sca)', display: 'société en commandite par actions (sca)' },
    { value: 'société en commandite simple (scs)', display: 'société en commandite simple (scs)' },
    { value: 'société en nom collectif (senc)', display: 'société en nom collectif (senc)' },
    { value: 'société en nom collectif (snc)', display: 'société en nom collectif (snc)' },
    { value: 'société en participation (sp)', display: 'société en participation (sp)' },
    { value: 'société par actions simplifiée (sas)', display: 'société par actions simplifiée (sas)' },
    {
      value: 'société privée à responsabilité limitée (sprl)',
      display: 'société privée à responsabilité limitée (sprl)'
    },
    { value: 'société simple (ss)', display: 'société simple (ss)' },
    { value: 'spolecnost s rucenim omezenym (spol s.r.o.)', display: 'spolecnost s rucenim omezenym (spol s.r.o.)' },
    { value: 'spólka akcyjna (sa)', display: 'spólka akcyjna (sa)' },
    { value: 'spólka komandytowa (sk)', display: 'spólka komandytowa (sk)' },
    { value: 'spólka prawa cywilnego (sc)', display: 'spólka prawa cywilnego (sc)' },
    {
      value: 'spólka z ograniczona odpowiedzialnoscia (sp. z.o.o.)',
      display: 'spólka z ograniczona odpowiedzialnoscia (sp. z.o.o.)'
    },
    { value: 'stiftung', display: 'stiftung' },
    { value: 'tiha druzba (td)', display: 'tiha druzba (td)' },
    { value: 'Türk limited sirket (tls)', display: 'Türk limited sirket (tls)' },
    { value: 'vennootschap onder firma (vof)', display: 'vennootschap onder firma (vof)' },
    { value: 'verein', display: 'verein' },
    { value: 'verejna obchodni spolecnost (v.o.s.)', display: 'verejna obchodni spolecnost (v.o.s.)' },
    { value: 'yugen kaisha', display: 'yugen kaisha' },
    { value: 'Other', display: 'Other' }
  ];
  /* tslint:enable:max-line-length */

  public phoneCountries: Option[] = [
    { display: 'United States', value: '+0-' },
    { display: 'Afghanistan', value: '+93-' },
    { display: 'Albania', value: '+355-' },
    { display: 'Algeria', value: '+213-' },
    { display: 'Andorra', value: '+376-' },
    { display: 'Angola', value: '+244-' },
    { display: 'Argentina', value: '+54-' },
    { display: 'Armenia', value: '+374-' },
    { display: 'Aruba', value: '+297-' },
    { display: 'Australia', value: '+61-' },
    { display: 'Austria', value: '+43-' },
    { display: 'Azerbaijan', value: '+994-' },
    { display: 'Bahrain', value: '+973-' },
    { display: 'Bangladesh', value: '+880-' },
    { display: 'Belarus', value: '+375-' },
    { display: 'Belgium', value: '+32-' },
    { display: 'Belize', value: '+501-' },
    { display: 'Benin', value: '+229-' },
    { display: 'Bhutan', value: '+975-' },
    { display: 'Bolivia', value: '+591-' },
    { display: 'Bosnia and Herzegovina', value: '+387-' },
    { display: 'Botswana', value: '+267-' },
    { display: 'Brazil', value: '+55-' },
    { display: 'British Indian Ocean Territory', value: '+246-' },
    { display: 'Brunei Darussalam', value: '+673-' },
    { display: 'Bulgaria', value: '+359-' },
    { display: 'Burkina Faso', value: '+226-' },
    { display: 'Burundi', value: '+257-' },
    { display: 'Cambodia', value: '+855-' },
    { display: 'Cameroon', value: '+237-' },
    { display: 'Canada', value: '+1-' },
    { display: 'Cape Verde', value: '+238-' },
    { display: 'Caribbean Netherlands', value: '+599-' },
    { display: 'Central African Republic', value: '+236-' },
    { display: 'Chad', value: '+235-' },
    { display: 'Chile', value: '+56-' },
    { display: 'China', value: '+86-' },
    { display: 'Christmas Island', value: '+61-' },
    { display: 'Colombia', value: '+57-' },
    { display: 'Comoros', value: '+269-' },
    { display: 'Congo, Democratic Republic of the', value: '+243-' },
    { display: 'Congo, Republic of the', value: '+242-' },
    { display: 'Cook Islands', value: '+682-' },
    { display: 'Costa Rica', value: '+506-' },
    { display: 'Croatia', value: '+385-' },
    { display: 'Cuba', value: '+53-' },
    { display: 'Cyprus', value: '+357-' },
    { display: 'Czech Republic', value: '+420-' },
    { display: 'Denmark', value: '+45-' },
    { display: 'Djibouti', value: '+253-' },
    { display: 'Ecuador', value: '+593-' },
    { display: 'Egypt', value: '+20-' },
    { display: 'El Salvador', value: '+503-' },
    { display: 'Equatorial Guinea', value: '+240-' },
    { display: 'Eritrea', value: '+291-' },
    { display: 'Estonia', value: '+372-' },
    { display: 'Ethiopia', value: '+251-' },
    { display: 'Falkland Islands', value: '+500-' },
    { display: 'Faroe Islands', value: '+298-' },
    { display: 'Fiji', value: '+679-' },
    { display: 'Finland', value: '+358-' },
    { display: 'France', value: '+33-' },
    { display: 'French Guiana', value: '+594-' },
    { display: 'French Polynesia', value: '+689-' },
    { display: 'Gabon', value: '+241-' },
    { display: 'Gambia', value: '+220-' },
    { display: 'Georgia', value: '+995-' },
    { display: 'Germany', value: '+49-' },
    { display: 'Ghana', value: '+233-' },
    { display: 'Gibraltar', value: '+350-' },
    { display: 'Greece', value: '+30-' },
    { display: 'Greenland', value: '+299-' },
    { display: 'Guadeloupe', value: '+590-' },
    { display: 'Guatemala', value: '+502-' },
    { display: 'Guernsey', value: '+44-' },
    { display: 'Guinea', value: '+224-' },
    { display: 'Guinea-Bissau', value: '+245-' },
    { display: 'Guyana', value: '+592-' },
    { display: 'Haiti', value: '+509-' },
    { display: 'Honduras', value: '+504-' },
    { display: 'Hong Kong', value: '+852-' },
    { display: 'Hungary', value: '+36-' },
    { display: 'Iceland', value: '+354-' },
    { display: 'India', value: '+91-' },
    { display: 'Indonesia', value: '+62-' },
    { display: 'Iran', value: '+98-' },
    { display: 'Iraq', value: '+964-' },
    { display: 'Ireland', value: '+353-' },
    { display: 'Israel', value: '+972-' },
    { display: 'Italy', value: '+39-' },
    { display: 'Japan', value: '+81-' },
    { display: 'Jersey', value: '+44-' },
    { display: 'Jordan', value: '+962-' },
    { display: 'Kazakhstan', value: '+7-' },
    { display: 'Kenya', value: '+254-' },
    { display: 'Kiribati', value: '+686-' },
    { display: 'Korea, North', value: '+850-' },
    { display: 'Korea, South', value: '+82-' },
    { display: 'Kuwait', value: '+965-' },
    { display: 'Kyrgyzstan', value: '+996-' },
    { display: 'Laos', value: '+856-' },
    { display: 'Latvia', value: '+371-' },
    { display: 'Lebanon', value: '+961-' },
    { display: 'Lesotho', value: '+266-' },
    { display: 'Liberia', value: '+231-' },
    { display: 'Libya', value: '+218-' },
    { display: 'Liechtenstein', value: '+423-' },
    { display: 'Lithuania', value: '+370-' },
    { display: 'Luxembourg', value: '+352-' },
    { display: 'Macau', value: '+853-' },
    { display: 'Macedonia', value: '+389-' },
    { display: 'Madagascar', value: '+261-' },
    { display: 'Malawi', value: '+265-' },
    { display: 'Malaysia', value: '+60-' },
    { display: 'Maldives', value: '+960-' },
    { display: 'Mali', value: '+223-' },
    { display: 'Malta', value: '+356-' },
    { display: 'Marshall Islands', value: '+692-' },
    { display: 'Martinique', value: '+596-' },
    { display: 'Mauritania', value: '+222-' },
    { display: 'Mauritius', value: '+230-' },
    { display: 'Mayotte', value: '+262-' },
    { display: 'Mexico', value: '+52-' },
    { display: 'Micronesia', value: '+691-' },
    { display: 'Moldova', value: '+373-' },
    { display: 'Monaco', value: '+377-' },
    { display: 'Mongolia', value: '+976-' },
    { display: 'Montenegro', value: '+382-' },
    { display: 'Morocco', value: '+212-' },
    { display: 'Mozambique', value: '+258-' },
    { display: 'Myanmar', value: '+95-' },
    { display: 'Namibia', value: '+264-' },
    { display: 'Nauru', value: '+674-' },
    { display: 'Nepal', value: '+977-' },
    { display: 'Netherlands', value: '+31-' },
    { display: 'New Zealand', value: '+64-' },
    { display: 'Nicaragua', value: '+505-' },
    { display: 'Niger', value: '+227-' },
    { display: 'Nigeria', value: '+234-' },
    { display: 'Northern Ireland', value: '+44-' },
    { display: 'Norway', value: '+47-' },
    { display: 'Oman', value: '+968-' },
    { display: 'Pakistan', value: '+92-' },
    { display: 'Palau', value: '+680-' },
    { display: 'Panama', value: '+507-' },
    { display: 'Papua New Guinea', value: '+675-' },
    { display: 'Paraguay', value: '+595-' },
    { display: 'Peru', value: '+51-' },
    { display: 'Philippines', value: '+63-' },
    { display: 'Poland', value: '+48-' },
    { display: 'Portugal', value: '+351-' },
    { display: 'Qatar', value: '+974-' },
    { display: 'Romania', value: '+40-' },
    { display: 'Russia', value: '+7-' },
    { display: 'Rwanda', value: '+250-' },
    { display: 'San Marino', value: '+378-' },
    { display: 'Saudi Arabia', value: '+966-' },
    { display: 'Seychelles', value: '+248-' },
    { display: 'Sierra Leone', value: '+232-' },
    { display: 'Singapore', value: '+65-' },
    { display: 'Slovakia', value: '+421-' },
    { display: 'Slovenia', value: '+386-' },
    { display: 'Solomon Islands', value: '+677-' },
    { display: 'Somalia', value: '+252-' },
    { display: 'South Africa', value: '+27-' },
    { display: 'Spain', value: '+34-' },
    { display: 'Sri Lanka', value: '+94-' },
    { display: 'Sudan', value: '+249-' },
    { display: 'Suriname', value: '+597-' },
    { display: 'Swaziland', value: '+268-' },
    { display: 'Sweden', value: '+46-' },
    { display: 'Switzerland', value: '+41-' },
    { display: 'Syria', value: '+963-' },
    { display: 'Taiwan', value: '+886-' },
    { display: 'Tajikistan', value: '+992-' },
    { display: 'Tanzania', value: '+255-' },
    { display: 'Thailand', value: '+66-' },
    { display: 'Togo', value: '+228-' },
    { display: 'Tonga', value: '+676-' },
    { display: 'Turkey', value: '+90-' },
    { display: 'Turkmenistan', value: '+993-' },
    { display: 'Tuvalu', value: '+688-' },
    { display: 'Uganda', value: '+256-' },
    { display: 'Ukraine', value: '+380-' },
    { display: 'United Arab Emirates', value: '+971-' },
    { display: 'United Kingdom', value: '+44-' },
    { display: 'Uruguay', value: '+598-' },
    { display: 'Vanuatu', value: '+678-' },
    { display: 'Venezuela', value: '+58-' },
    { display: 'Vatican City State (Holy See)', value: '+39-' },
    { display: 'Vietnam', value: '+84-' },
    { display: 'Wallis and Futuna', value: '+681-' },
    { display: 'Zambia', value: '+260-' },
    { display: 'Zimbabwe', value: '+263-' },
    { display: 'Other', value: '+00-' }
  ];

  public usa: Option = { display: 'United States', value: USA_VALUE };

  /* tslint:disable:max-line-length */
  private countries: CountryOption[] = [
    { display: 'Afghanistan', value: 'Afghanistan (AFX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Albania', value: 'Albania (ALX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Algeria', value: 'Algeria (DZX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'American Samoa', value: 'American Samoa (ASX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Andorra', value: 'Andorra (ADX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Angola', value: 'Angola (AOX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Anguilla', value: 'Anguilla (AIX)', mailing: true, incorporation: true, citizenship: true },
    {
      display: 'Antigua and Barbuda',
      value: 'Antigua and Barbuda (AGX)',
      mailing: true,
      incorporation: true,
      citizenship: true
    },
    { display: 'Argentina', value: 'Argentina (ARX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Armenia', value: 'Armenia (AMX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Aruba', value: 'Aruba (AWX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Australia', value: 'Australia (AUX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Austria', value: 'Austria (ATX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Azerbaijan', value: 'Azerbaijan (AZX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'BES Islands', value: 'BES Islands (BQX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Bahamas', value: 'Bahamas (BSX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Bahrain', value: 'Bahrain (BHX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Bangladesh', value: 'Bangladesh (BDX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Barbados', value: 'Barbados (BBX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Belarus', value: 'Belarus (BYX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Belgium', value: 'Belgium (BEX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Belize', value: 'Belize (BZX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Benin', value: 'Benin (BJX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Bermuda', value: 'Bermuda (BMX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Bhutan', value: 'Bhutan (BTX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Bolivia', value: 'Bolivia (BOX)', mailing: true, incorporation: true, citizenship: true },
    {
      display: 'Bosnia and Herzegovina',
      value: 'Bosnia and Herzegovina (BAX)',
      mailing: true,
      incorporation: true,
      citizenship: true
    },
    { display: 'Botswana', value: 'Botswana (BWX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Bouvet Island', value: 'Bouvet Island (BVX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Brazil', value: 'Brazil (BRX)', mailing: true, incorporation: true, citizenship: true },
    {
      display: 'British Indian Ocean Territory',
      value: 'British Indian Ocean Territory (IOX)',
      mailing: true,
      incorporation: true,
      citizenship: true
    },
    {
      display: 'British Virgin Islands',
      value: 'British Virgin Islands (VGX)',
      mailing: true,
      incorporation: true,
      citizenship: true
    },
    {
      display: 'Brunei Darussalam',
      value: 'Brunei Darussalam (BNX)',
      mailing: true,
      incorporation: true,
      citizenship: true
    },
    { display: 'Bulgaria', value: 'Bulgaria (BGX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Burkina Faso', value: 'Burkina Faso (BFX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Burundi', value: 'Burundi (BIX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Cabo Verde', value: 'Cabo Verde (CVX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Cambodia', value: 'Cambodia (KHX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Cameroon', value: 'Cameroon (CMX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Canada', value: 'Canada (CAX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Cayman Islands', value: 'Cayman Islands (KYX)', mailing: true, incorporation: true, citizenship: true },
    {
      display: 'Centralican Republic',
      value: 'Centralican Republic (CFX)',
      mailing: true,
      incorporation: true,
      citizenship: true
    },
    { display: 'Chad', value: 'Chad (TDX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Chile', value: 'Chile (CLX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'China', value: 'China (CNX)', mailing: true, incorporation: true, citizenship: true },
    {
      display: 'Christmas Island',
      value: 'Christmas Island (CXX)',
      mailing: true,
      incorporation: true,
      citizenship: true
    },
    { display: 'Cocos Islands', value: 'Cocos Islands (CCX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Colombia', value: 'Colombia (COX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Comoros', value: 'Comoros (KMX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Congo', value: 'Congo (CGX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Cook Islands', value: 'Cook Islands (CKX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Costa Rica', value: 'Costa Rica (CRX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Croatia', value: 'Croatia (HRX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Cuba', value: 'Cuba (CUX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Curacao', value: 'Curacao (CWX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Cyprus', value: 'Cyprus (CYX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Czech Republic', value: 'Czech Republic (CZX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Côte d\'Ivoire', value: 'Côte d\'Ivoire (CIX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Denmark', value: 'Denmark (DKX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Djibouti', value: 'Djibouti (DJX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Dominica', value: 'Dominica (DMX)', mailing: true, incorporation: true, citizenship: true },
    {
      display: 'Dominican Republic',
      value: 'Dominican Republic (DOX)',
      mailing: true,
      incorporation: true,
      citizenship: true
    },
    { display: 'Ecuador', value: 'Ecuador (ECX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Egypt', value: 'Egypt (EGX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'El Salvador', value: 'El Salvador (SVX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'England', value: 'England (GB2)', mailing: true, incorporation: true, citizenship: true },
    {
      display: 'Equatorial Guinea',
      value: 'Equatorial Guinea (GQX)',
      mailing: true,
      incorporation: true,
      citizenship: true
    },
    { display: 'Eritrea', value: 'Eritrea (ERX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Estonia', value: 'Estonia (EEX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Ethiopia', value: 'Ethiopia (ETX)', mailing: true, incorporation: true, citizenship: true },
    {
      display: 'Falkland Islands',
      value: 'Falkland Islands (FKX)',
      mailing: true,
      incorporation: true,
      citizenship: true
    },
    { display: 'Faroe Islands', value: 'Faroe Islands (FOX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Fiji', value: 'Fiji (FJX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Finland', value: 'Finland (FIX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'France', value: 'France (FRX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'French Guiana', value: 'French Guiana (GFX)', mailing: true, incorporation: true, citizenship: true },
    {
      display: 'French Polynesia',
      value: 'French Polynesia (PFX)',
      mailing: true,
      incorporation: true,
      citizenship: true
    },
    { display: 'Gabon', value: 'Gabon (GAX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Gambia', value: 'Gambia (GMX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Georgia', value: 'Georgia (GEX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Germany', value: 'Germany (DEX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Ghana', value: 'Ghana (GHX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Gibraltar', value: 'Gibraltar (GIX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Great Britain', value: 'Great Britain (GB3)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Greece', value: 'Greece (GRX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Greenland', value: 'Greenland (GLX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Grenada', value: 'Grenada (GDX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Guadeloupe', value: 'Guadeloupe (GPX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Guam', value: 'Guam (GUX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Guatemala', value: 'Guatemala (GTX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Guernsey', value: 'Guernsey (GGX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Guinea', value: 'Guinea (GNX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Guinea-Bissau', value: 'Guinea-Bissau (GWX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Guyana', value: 'Guyana (GYX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Haiti', value: 'Haiti (HTX)', mailing: true, incorporation: true, citizenship: true },
    {
      display: 'Heard Island and McDonald Islands',
      value: 'Heard Island and McDonald Islands (HMX)',
      mailing: true,
      incorporation: true,
      citizenship: true
    },
    { display: 'Honduras', value: 'Honduras (HNX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Hong Kong', value: 'Hong Kong (HKX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Hungary', value: 'Hungary (HUX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Iceland', value: 'Iceland (ISX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'India', value: 'India (INX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Indonesia', value: 'Indonesia (IDX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Iran', value: 'Iran (IRX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Iraq', value: 'Iraq (IQX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Ireland', value: 'Ireland (IEX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Isle of Man', value: 'Isle of Man (GB4)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Israel', value: 'Israel (ILX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Italy', value: 'Italy (ITX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Jamaica', value: 'Jamaica (JMX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Japan', value: 'Japan (JPX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Jersey', value: 'Jersey (JEX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Johnston Atoll', value: 'Johnston Atoll (JTX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Jordan', value: 'Jordan (JOX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Kazakhstan', value: 'Kazakhstan (KZX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Kenya', value: 'Kenya (KEX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Kiribati', value: 'Kiribati (KIX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Korea, North', value: 'Korea, North (KPX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Korea, South', value: 'Korea, South (KRX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Kuwait', value: 'Kuwait (KWX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Kyrgyzstan', value: 'Kyrgyzstan (KGX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Laos', value: 'Laos (LAX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Latvia', value: 'Latvia (LVX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Lebanon', value: 'Lebanon (LBX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Lesotho', value: 'Lesotho (LSX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Liberia', value: 'Liberia (LRX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Libya', value: 'Libya (LYX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Liechtenstein', value: 'Liechtenstein (LIX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Lithuania', value: 'Lithuania (LTX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Luxembourg', value: 'Luxembourg (LUX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Macau', value: 'Macau (MOX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Macedonia', value: 'Macedonia (MKX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Madagascar', value: 'Madagascar (MGX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Malawi', value: 'Malawi (MWX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Malaysia', value: 'Malaysia (MYX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Maldives', value: 'Maldives (MVX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Mali', value: 'Mali (MLX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Malta', value: 'Malta (MTX)', mailing: true, incorporation: true, citizenship: true },
    {
      display: 'Marshall Islands',
      value: 'Marshall Islands (MHX)',
      mailing: true,
      incorporation: true,
      citizenship: true
    },
    { display: 'Martinique', value: 'Martinique (MQX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Mauritania', value: 'Mauritania (MRX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Mauritius', value: 'Mauritius (MUX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Mayotte', value: 'Mayotte (YTX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Mexico', value: 'Mexico (MXX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Micronesia', value: 'Micronesia (FMX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Moldova', value: 'Moldova (MDX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Monaco', value: 'Monaco (MCX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Mongolia', value: 'Mongolia (MNX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Montenegro', value: 'Montenegro (MEX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Montserrat', value: 'Montserrat (MSX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Morocco', value: 'Morocco (MAX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Mozambique', value: 'Mozambique (MZX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Myanmar', value: 'Myanmar (MMX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Namibia', value: 'Namibia (NAX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Nauru', value: 'Nauru (NRX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Navassa Island', value: 'Navassa Island (NVX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Nepal', value: 'Nepal (NPX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Netherlands', value: 'Netherlands (NLX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'New Zealand', value: 'New Zealand (NZX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Nicaragua', value: 'Nicaragua (NIX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Niger', value: 'Niger (NEX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Nigeria', value: 'Nigeria (NGX)', mailing: true, incorporation: true, citizenship: true },
    {
      display: 'Northern Ireland',
      value: 'Northern Ireland (GB5)',
      mailing: true,
      incorporation: true,
      citizenship: true
    },
    {
      display: 'Northern Mariana Islands',
      value: 'Northern Mariana Islands (MPX)',
      mailing: true,
      incorporation: true,
      citizenship: true
    },
    { display: 'Norway', value: 'Norway (NOX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Oman', value: 'Oman (OMX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Other', value: 'Other (Other)', mailing: false, incorporation: true, citizenship: true },
    { display: 'Pakistan', value: 'Pakistan (PKX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Palau', value: 'Palau (PWX)', mailing: true, incorporation: true, citizenship: true },
    {
      display: 'Palestinian Authority',
      value: 'Palestinian Authority (PXX)',
      mailing: false,
      incorporation: false,
      citizenship: true
    },
    { display: 'Panama', value: 'Panama (PAX)', mailing: true, incorporation: true, citizenship: true },
    {
      display: 'Papua New Guinea',
      value: 'Papua New Guinea (PGX)',
      mailing: true,
      incorporation: true,
      citizenship: true
    },
    { display: 'Paraguay', value: 'Paraguay (PYX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Peru', value: 'Peru (PEX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Philippines', value: 'Philippines (PHX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Poland', value: 'Poland (PLX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Portugal', value: 'Portugal (PTX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Puerto Rico', value: 'Puerto Rico (PRX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Qatar', value: 'Qatar (QAX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Romania', value: 'Romania (ROX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Russia', value: 'Russia (RUX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Rwanda', value: 'Rwanda (RWX)', mailing: true, incorporation: true, citizenship: true },
    {
      display: 'Saint Kitts and Nevis',
      value: 'Saint Kitts and Nevis (KNX)',
      mailing: true,
      incorporation: true,
      citizenship: true
    },
    { display: 'Saint Lucia', value: 'Saint Lucia (LCX)', mailing: true, incorporation: true, citizenship: true },
    {
      display: 'Saint Vincent and Grenadines',
      value: 'Saint Vincent and Grenadines (VCX)',
      mailing: true,
      incorporation: true,
      citizenship: true
    },
    { display: 'Samoa', value: 'Samoa (OOX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'San Marino', value: 'San Marino (SMX)', mailing: true, incorporation: true, citizenship: true },
    {
      display: 'Sao Tome and Principe',
      value: 'Sao Tome and Principe (STX)',
      mailing: true,
      incorporation: true,
      citizenship: true
    },
    { display: 'Saudi Arabia', value: 'Saudi Arabia (SAX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Scotland', value: 'Scotland (GB6)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Senegal', value: 'Senegal (SNX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Serbia', value: 'Serbia (RSX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Seychelles', value: 'Seychelles (SCX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Sierra Leone', value: 'Sierra Leone (SLX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Singapore', value: 'Singapore (SGX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Slovakia', value: 'Slovakia (SKX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Slovenia', value: 'Slovenia (SIX)', mailing: true, incorporation: true, citizenship: true },
    {
      display: 'Solomon Islands',
      value: 'Solomon Islands (SBX)',
      mailing: true,
      incorporation: true,
      citizenship: true
    },
    { display: 'Somalia', value: 'Somalia (SOX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'South Africa', value: 'South Africa (ZAX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Spain', value: 'Spain (ESX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Sri Lanka', value: 'Sri Lanka (LKX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'St. Maarten', value: 'St. Maarten (SXX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Stateless', value: 'Stateless (ZZX)', mailing: false, incorporation: true, citizenship: true },
    { display: 'Sudan', value: 'Sudan (SDX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Suriname', value: 'Suriname (SRX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Swaziland', value: 'Swaziland (SZX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Sweden', value: 'Sweden (SEX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Switzerland', value: 'Switzerland (CHX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Syria', value: 'Syria (SYX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Taiwan', value: 'Taiwan (TWX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Tajikistan', value: 'Tajikistan (TJX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Tanzania', value: 'Tanzania (TZX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Thailand', value: 'Thailand (THX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Timor-Leste', value: 'Timor-Leste (TLX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Togo', value: 'Togo (TGX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Tonga', value: 'Tonga (TOX)', mailing: true, incorporation: true, citizenship: true },
    {
      display: 'Trinidad and Tobago',
      value: 'Trinidad and Tobago (TTX)',
      mailing: true,
      incorporation: true,
      citizenship: true
    },
    { display: 'Tunisia', value: 'Tunisia (TNX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Turkey', value: 'Turkey (TRX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Turkmenistan', value: 'Turkmenistan (TMX)', mailing: true, incorporation: true, citizenship: true },
    {
      display: 'Turks/Caicos Islands',
      value: 'Turks/Caicos Islands (TCX)',
      mailing: true,
      incorporation: true,
      citizenship: true
    },
    { display: 'Tuvalu', value: 'Tuvalu (TVX)', mailing: true, incorporation: true, citizenship: true },
    {
      display: 'US Virgin Islands',
      value: 'US Virgin Islands (VIX)',
      mailing: true,
      incorporation: true,
      citizenship: true
    },
    { display: 'Uganda', value: 'Uganda (UGX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Ukraine', value: 'Ukraine (UAX)', mailing: true, incorporation: true, citizenship: true },
    {
      display: 'United Arab Emirates',
      value: 'United Arab Emirates (AEX)',
      mailing: true,
      incorporation: true,
      citizenship: true
    },
    { display: 'United Kingdom', value: 'United Kingdom (GBX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'United States', value: 'United States (USX)', mailing: true, incorporation: true, citizenship: true },
    {
      display: 'United States',
      value: 'United States (USA)',
      mailing: false,
      incorporation: false,
      citizenship: false
    },
    { display: 'Uruguay', value: 'Uruguay (UYX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Uzbekistan', value: 'Uzbekistan (UZX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Vanuatu', value: 'Vanuatu (VUX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Vatican City', value: 'Vatican City (VAX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Venezuela', value: 'Venezuela (VEX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Vietnam', value: 'Vietnam (VNX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Wake Island', value: 'Wake Island (WKX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Wales', value: 'Wales (GB7)', mailing: true, incorporation: true, citizenship: true },
    {
      display: 'Wallis and Futuna',
      value: 'Wallis and Futuna (WFX)',
      mailing: true,
      incorporation: true,
      citizenship: true
    },
    {
      display: 'West Bank/Gaza',
      value: 'West Bank/Gaza (PSX)',
      mailing: true,
      incorporation: true,
      citizenship: false
    },
    { display: 'Western Sahara', value: 'Western Sahara (EHX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Yemen', value: 'Yemen (YEX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Zambia', value: 'Zambia (ZMX)', mailing: true, incorporation: true, citizenship: true },
    { display: 'Zimbabwe', value: 'Zimbabwe (ZWX)', mailing: false, incorporation: true, citizenship: true },
    { display: 'Zimbabwe', value: 'Zimbabw (ZWX)', mailing: true, incorporation: false, citizenship: false }
  ];
  /* tslint:enable:max-line-length */

  public mailingCountries: Option[] = this.countries
    .filter(c => c.mailing)
    .map(({ display, value }) => ({ display, value }))
    .sort(ascending('display'));

  public incoporationCountries: Option[] = this.countries
    .filter(c => c.incorporation)
    .map(({ display, value }) => ({ display, value }))
    .sort(ascending('display'));

  public citizenshipCountries: Option[] = this.countries
    .filter(c => c.citizenship)
    .map(({ display, value }) => ({ display, value }))
    .sort(ascending('display'));

  public status: Option[] = [
    { value: '000', display: '' },
    { value: '400', display: 'US Transformation Pending' },
    { value: '401', display: 'No US Transformation' },
    { value: '402', display: 'No US Transformation' },
    { value: '403', display: 'Registration Pending' },
    { value: '600', display: 'Abandoned' },
    { value: '601', display: 'Abandoned' },
    { value: '602', display: 'Abandoned' },
    { value: '603', display: 'Abandoned' },
    { value: '604', display: 'Abandoned' },
    { value: '605', display: 'Abandoned' },
    { value: '606', display: 'Abandoned' },
    { value: '607', display: 'Abandoned' },
    { value: '608', display: 'Abandoned' },
    { value: '609', display: '' },
    { value: '612', display: 'Awaiting Petition Decision' },
    { value: '614', display: 'Abandoned' },
    { value: '616', display: 'Revived - Awaiting PTO Action' },
    { value: '618', display: 'Abandoned' },
    { value: '620', display: '' },
    { value: '622', display: 'Serial Number Error' },
    { value: '624', display: '' },
    { value: '625', display: '' },
    { value: '626', display: 'Registration Cancelled/Expired' },
    { value: '630', display: 'New - Awaiting PTO Action' },
    { value: '631', display: 'New - Awaiting PTO Action' },
    { value: '632', display: 'Incomplete Application' },
    { value: '638', display: 'New - Awaiting PTO Action' },
    { value: '640', display: 'Non-Final OA to be Issued' },
    { value: '641', display: 'Non-Final OA Issued' },
    { value: '642', display: 'Cancelled' },
    { value: '643', display: 'Prev. Action/Allow Withdrawn' },
    { value: '644', display: 'Final OA to be Issued' },
    { value: '645', display: 'Final OA Issued' },
    { value: '646', display: 'EX Amend to be Issued' },
    { value: '647', display: 'EX Amend Issued' },
    { value: '648', display: 'Final OA to be Issued' },
    { value: '649', display: 'Final OA Issued' },
    { value: '650', display: '' },
    { value: '651', display: 'Suspended' },
    { value: '652', display: 'Suspended' },
    { value: '653', display: 'Suspended' },
    { value: '654', display: 'Suspended' },
    { value: '655', display: 'EX Amend to be Issued' },
    { value: '656', display: 'EX Amend/Priority OA Issued' },
    { value: '657', display: '' },
    { value: '658', display: 'Priority (Non-Final) OA Issued' },
    { value: '659', display: '' },
    { value: '660', display: 'Final OA Issued' },
    { value: '661', display: 'Non-Final OA Response Filed' },
    { value: '663', display: 'Final OA Response Filed' },
    { value: '664', display: '' },
    { value: '665', display: 'Unresponsive Notice to Issue' },
    { value: '666', display: 'Unresponsive Notice Issued' },
    { value: '667', display: '' },
    { value: '668', display: '' },
    { value: '672', display: 'Revived - Awaiting PTO Action' },
    { value: '673', display: '' },
    { value: '680', display: 'Awaiting Publication' },
    { value: '681', display: 'Awaiting Publication' },
    { value: '682', display: '' },
    { value: '686', display: 'Published for Opposition' },
    { value: '688', display: 'Notice of Allowance Issued' },
    { value: '689', display: 'Notice of Allowance Withdrawn' },
    { value: '690', display: 'Notice of Allowance Cancelled' },
    { value: '692', display: 'Abandoned' },
    { value: '693', display: '' },
    { value: '694', display: 'Abandoned' },
    { value: '700', display: 'Registered' },
    { value: '701', display: 'Registration Renewed' },
    { value: '702', display: 'Registration Renewed' },
    { value: '703', display: 'Registration Renewed' },
    { value: '704', display: 'Registration Renewed (Partial)' },
    { value: '705', display: 'Registration Renewed (Partial)' },
    { value: '710', display: 'Registration Cancelled' },
    { value: '711', display: 'Registration Cancelled' },
    { value: '712', display: 'Registration Cancelled' },
    { value: '713', display: 'Registration Cancelled' },
    { value: '714', display: '' },
    { value: '715', display: 'Registration Restored' },
    { value: '716', display: '' },
    { value: '717', display: '' },
    { value: '718', display: '' },
    { value: '719', display: '' },
    { value: '720', display: '' },
    { value: '721', display: '' },
    { value: '722', display: '' },
    { value: '724', display: '' },
    { value: '725', display: 'Extension Request Refused' },
    { value: '730', display: '1st Extension Request Granted' },
    { value: '731', display: '2nd Extension Request Granted' },
    { value: '732', display: '3rd Extension Request Granted' },
    { value: '733', display: '4th Extension Request Granted' },
    { value: '734', display: '5th Extension Request Granted' },
    { value: '740', display: '' },
    { value: '744', display: 'Statement of Use Filed' },
    { value: '745', display: 'Statement of Use OA Issued' },
    { value: '746', display: 'Reply to SOU OA Filed' },
    { value: '748', display: 'Statement of Use Filed' },
    { value: '752', display: '' },
    { value: '753', display: '' },
    { value: '756', display: '' },
    { value: '757', display: 'Office Action Issued' },
    { value: '760', display: 'Appeal Pending' },
    { value: '762', display: 'Appeal Terminated' },
    { value: '763', display: '' },
    { value: '764', display: '' },
    { value: '765', display: 'Concurrent Use Request Granted' },
    { value: '766', display: 'Concurrent Use Request Denied' },
    { value: '771', display: 'Concurrent Use Request Pending' },
    { value: '772', display: '' },
    { value: '773', display: 'Opposition Ext. Terminated' },
    { value: '774', display: 'Opposition Pending' },
    { value: '775', display: '' },
    { value: '777', display: 'Opposition Terminated' },
    { value: '778', display: '' },
    { value: '779', display: '' },
    { value: '780', display: 'Cancellation Terminated' },
    { value: '790', display: 'Cancellation Pending' },
    { value: '794', display: 'App Back to Examining Attorney' },
    { value: '800', display: 'Registration Renewed' },
    { value: '801', display: 'Opposition Pending' },
    { value: '802', display: 'Extension to Oppose Requested' },
    { value: '803', display: '' },
    { value: '804', display: 'Appeal Pending' },
    { value: '806', display: '' },
    { value: '807', display: 'Non-Final OA Issued' },
    { value: '808', display: '' },
    { value: '809', display: 'Final OA Issued' },
    { value: '810', display: '' },
    { value: '811', display: 'EX Amendment Issued' },
    { value: '812', display: 'Final OA to be Issued' },
    { value: '813', display: 'Final OA Issued' },
    { value: '814', display: 'Non-Final OA Response Filed' },
    { value: '815', display: 'Final OA Response Filed' },
    { value: '816', display: '' },
    { value: '817', display: 'Unresponsive Notice Issued' },
    { value: '818', display: 'Pending Registration' },
    { value: '819', display: 'Pending Registration' },
    { value: '820', display: '' },
    { value: '821', display: 'EX Amend/Priority OA Issued' },
    { value: '822', display: '' },
    { value: '823', display: 'Priority (Non-Final) OA Issued' },
    { value: '824', display: '' },
    { value: '825', display: 'Final OA Issued' },
    { value: '900', display: 'Abandoned' },
    { value: '404', display: '' },
    { value: '405', display: '' },
    { value: '414', display: '' },
    { value: '415', display: '' },
    { value: '706', display: 'Registration Renewed' },
    { value: '707', display: 'Registration Renewed (Partial)' },
    { value: '708', display: 'Registration Renewed (Partial)' },
    { value: '709', display: 'Registration Cancelled' },
    { value: '739', display: '' },
    { value: '969', display: 'Non-App/Registration Data' }
  ];

  // public condenseCountries() {
  //   const initFn = option => ({[option.value]: {
  //     display: option.display, value: option.value,
  //     mailing: false,
  //     incorporation: false,
  //     citizenship: false,
  //   }});
  //   const result = Object.assign({},
  //     ...this.incoporationCountries.map(initFn),
  //     ...this.mailingCountries.map(initFn),
  //     ...this.citizenshipCountries.map(initFn),
  //   );
  //   this.incoporationCountries.forEach(e => result[e.value].incorporation = true);
  //   this.citizenshipCountries.forEach(e => result[e.value].citizenship = true);
  //   this.mailingCountries.forEach(e => result[e.value].mailing = true);
  //   return Object.keys(result).map(k => result[k]).sort(
  //     (a, b) => a.display < b.display ? -1 : a.display > b.display ? 1 : 0);
  // }

  public displayWith(option: Option): string {
    return option && option.display;
  }

  public filter(options: Option[], value: string): Option[] {
    if (isUndefined(value) || value === '') {
      return options;
    }
    return filter(options, value, { key: 'display' });
  }

  public countryValue(searchDisplay: string): string {
    return this.countries.find(({ display }) => display === searchDisplay)?.value as string;
  }

  public searchOptions(options: Option[], search: string, key: 'display' | 'value' = 'display'): Option {
    const found = filter(options, search, { key: key, maxResults: 1 });
    if (found && found.length > 0) {
      return found[0];
    }
    return { display: search, value: search };
  }

  /** Filter options using the source observable. */
  createOptionFilter(source: Observable<string>, options: Option[], startValue: string = ''): Observable<Option[]> {
    return source.pipe(startWith(startValue), distinctUntilChanged(), map(value => this.filter(options, value)));
  }

  entityTypeDisplay(value: string): string {
    const ENTITYLISTS = [this.entityTypes, this.otherEntitiesDomestic, this.otherEntitiesForeign];
    value = value.toLocaleLowerCase();
    for (const entityList of ENTITYLISTS) {
      for (const entity of entityList) {
        if (entity.value.toLocaleLowerCase() === value || entity.display.toLocaleLowerCase() === value) {
          return entity.display;
        }
      }
    }
    return value;
  }

  stateDisplay(value: string): string {
    if (!value) {
      return value;
    }
    const STATELISTS = [this.usStates, this.countries];
    for (const stateList of STATELISTS) {
      for (const state of stateList) {
        if (state.value === value) {
          return state.display;
        }
      }
    }
    return value;
  }
}
