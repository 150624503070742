import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countryCode'
})
export class CountryCodePipe implements PipeTransform {
  transform(value: string) {
    // The USPTO uses +0- for the US country code, which is incorrect and confusing.
    if (value === '+0-') {
      return '+1-';
    }
    return value;
  }
}
