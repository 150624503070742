import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedMaterialModule } from '@markmachine/shared/materials/shared-materials.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ContextMenuContentComponent } from './components/context-menu-content/context-menu-content.component';
import { ContextMenuIconButtonComponent } from './components/context-menu-icon-button/context-menu-icon-button.component';
import { ContextMenuPathDirective } from './directives/context-menu-path.directive';
import { ContextMenuEffects } from './effects/context-menu.effects';
import * as fromContextMenu from './reducers/context-menu.reducer';
import { ContextMenuTableComponent } from './components/context-menu-table/context-menu-table.component';
import { ContextMenuItemComponent } from './components/context-menu-item/context-menu-item.component';

@NgModule({
  declarations: [
    ContextMenuPathDirective,
    ContextMenuContentComponent,
    ContextMenuIconButtonComponent,
    ContextMenuItemComponent,
    ContextMenuTableComponent
  ],
  exports: [ContextMenuPathDirective, ContextMenuIconButtonComponent, ContextMenuTableComponent],
  imports: [
    CommonModule,
    SharedMaterialModule,
    StoreModule.forFeature(fromContextMenu.storeKey, fromContextMenu.reducer),
    EffectsModule.forFeature([ContextMenuEffects])
  ]
})
export class ContextMenuModule {}
