import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { asBoolean } from '@markmachine/core/functions/utilities';
import { FormArray } from '@angular/forms';
import { ClassBasis } from '@markmachine/features/version/models/version-content.model';

@Component({
  selector: 'mm-class-edit-dialog',
  templateUrl: './class-edit-dialog.component.html',
  styleUrls: ['./class-edit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClassEditDialogComponent implements AfterViewInit, OnInit {
  BASIS = ClassBasis;
  selectedIndex: any;
  title = 'Edit Class';
  @ViewChild(MatTabGroup, { static: true }) tabGroup: MatTabGroup;

  _deleteClass: () => void;
  group: FormGroup;
  asBoolean = asBoolean;
  extensionCount = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.group = this.data.group;
    this.selectedIndex = this.data.selectedIndex;
    this.title = this.data.title || this.title;
    this.extensionCount = this.data.extensionCount;
  }

  ngAfterViewInit() {
    this.tabGroup.selectedIndex = this.data.selectedIndex;
  }

  deleteClass() {
    const array = this.group.parent as FormArray;
    const index = Object.values(array.controls).findIndex(c => c === this.group);
    array.removeAt(index);
  }

  basisField(basis: ClassBasis): string {
    return `filing-basis-current-${basis}-in`;
  }

  hasBasis(basis: ClassBasis): boolean {
    return this.asBoolean(this.group.get(this.basisField(basis))?.value);
  }

  addBasis(basis: ClassBasis): void {
    this.group.patchValue({[this.basisField(basis)]: true});
    this.group.updateValueAndValidity();
    this.cd.markForCheck();
  }

  deleteBasis(basis: ClassBasis): void {
    this.group.patchValue({[this.basisField(basis)]: false});
    this.group.updateValueAndValidity();
    this.cd.markForCheck();
  }

  isNew(): boolean {
    return this.asBoolean(this.group.get('_isNew')?.value);
  }

  isPriorUseAllowed(): boolean {
    return (this.isNew() || this.hasBasis(this.BASIS.PriorUse))
      && !this.hasBasis(this.BASIS.IntentToUse);
  }

  isItuAllowed(): boolean {
    return !this.hasBasis(this.BASIS.PriorUse);
  }

  priorUseTitle(): string {
    const canAou = this.asBoolean(this.group.get('$eligible-for-allegation-of-use')?.value);
    const has1a = this.hasBasis(this.BASIS.PriorUse);
    const isNew = this.isNew();
    if (canAou && has1a) {
      return 'Allegation of Use';
    } else if (has1a || isNew) {
      return 'Prior Use';
    } else {
      // This title should not be displayed, but we provide an appropriate
      // title just in case we make a mistake later.
      return 'Prior Use (Not Permitted)';
    }
  }
}
