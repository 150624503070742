<mm-tree-leaf [tip]="tip$ | ngrxPush"
  [expanded]="expanded"
  [hideToggle]="hideToggle || hideAllToggles"
  (opened)="expand()"
  (closed)="collapse()"></mm-tree-leaf>

<ng-container *ngrxLet="children$ as children">
  <div *ngIf="children | isEmpty | not"
    [class.tip-children-container]="!hideAllToggles">
    <mm-tree-controller *ngFor="let child of children"
      [hideAllToggles]="hideAllToggles"
      [tipId]="child.id"
      (childExpanded)="collapseOtherChildren(child.id)"></mm-tree-controller>
  </div>
</ng-container>
