import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'mm-tip-dialog',
  templateUrl: './tip-dialog.component.html',
  styleUrls: ['./tip-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TipDialogComponent implements OnInit {
  tipIds: string[];

  constructor(@Inject(MAT_DIALOG_DATA) private data: { tipIds: string[] }) { }

  ngOnInit() {
    this.tipIds = this.data.tipIds;
  }
}
