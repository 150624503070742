import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { slug } from '@markmachine/core/functions/utilities';
import { ExpansionPanelComponent } from '@markmachine/features/expansion-panel/components/expansion-panel/expansion-panel.component';
import { Subscription } from 'rxjs';

/**
 * Simplified interface for the most common usage of the Expansion Panel on the site.
 *
 * - This panel will automatically include the context menu system, calculating a menu path from the `title` input.
 *
 * - Additional menu items can be included in the context menu (see example).
 *
 * Example usage:
 * <mm-common-expansion-panel title="My Panel's Title">
 *   <button mat-menu-item><mat-icon color="primary">add_circle_outline</mat-icon> Add Something To From Menu</button>
 *   <p>Hello, World!</p>
 * </mm-common-expansion-panel>
 */
@Component({
  selector: 'mm-common-expansion-panel',
  templateUrl: './common-expansion-panel.component.html',
  styleUrls: ['./common-expansion-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonExpansionPanelComponent implements OnDestroy, OnInit {
  @Input() expansionPanelTitle: string;
  @Input() expanded = false;
  @Input() disabled = false;
  @ViewChild(ExpansionPanelComponent, { static: true }) panel: ExpansionPanelComponent;
  @Input() mmContextMenuPath: string;
  @Output() expansionChange = new EventEmitter();
  private _subscriptions: Subscription[] = [];

  ngOnInit() {
    this._subscriptions.push(this.panel.expansionChange.subscribe(value => this.expansionChange.next(value)));
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  menuPath() {
    return this.mmContextMenuPath || slug(this?.expansionPanelTitle ?? '');
  }

  open() {
    this.panel.open();
  }

  focus() {
    this.panel.focus();
  }
}
