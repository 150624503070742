import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Tip } from '@markmachine/core/models/tip';
import { select, Store } from '@ngrx/store';
import { forkJoin } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
import { FollowAnchor } from '../../actions/tip.actions';
import { getTipIdByAnchor } from '../../reducers/tip.reducers';
import { TipArrayComponent } from '../tip-array/tip-array.component';

/** Provide forward/back buttons for a distant TipArrayComponent */
@Component({
  selector: 'mm-tip-navigation',
  templateUrl: './tip-navigation.component.html',
  styleUrls: ['./tip-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TipNavigationComponent implements OnInit {
  @Input() navigationFor: TipArrayComponent;
  @Input() initialAnchors: Tip['anchor'][];
  history: string[][] = [];
  historyIndex = 0;

  constructor(private cd: ChangeDetectorRef, private store: Store) {}

  ngOnInit() {
    if (this.initialAnchors) {
      this.initializeAnchors(this.initialAnchors);
    }
  }

  private anchorTipId$(anchor: Tip['anchor']) {
    return this.store.pipe(
      select(getTipIdByAnchor(), { anchor }),
      tap(tip => !tip && this.store.dispatch(FollowAnchor({ anchor }))),
      filter(tip => !!tip),
      first()
    );
  }

  private initializeAnchors(anchors: Tip['anchor'][]) {
    const tipIds$ = forkJoin(anchors.map(anchor => this.anchorTipId$(anchor)));
    tipIds$.subscribe(tipIds => {
      this.navigationFor.tipIds = tipIds;
      this.cd.markForCheck();
    });
  }

  followAnchor(anchor: string) {
    this.anchorTipId$(anchor).subscribe(tipId => {
      // Initialize history
      if (this.history.length === 0) {
        this.history.push(this.navigationFor.tipIds);
      }
      // Trim the future and push the new tip onto history stack
      this.historyIndex += 1;
      this.history = [...this.history.slice(0, this.historyIndex), [tipId]];
      this.navigationFor.tipIds = [tipId];
      this.cd.markForCheck();
    });
  }

  forward() {
    if (this.historyIndex >= this.history.length - 1) {
      return;
    }
    // Move the cursor forward and set the view
    this.historyIndex += 1;
    this.navigationFor.tipIds = this.history[this.historyIndex];
  }

  backward() {
    if (this.historyIndex < 1) {
      return;
    }
    // Move the cursor back and set the view
    this.historyIndex -= 1;
    this.navigationFor.tipIds = this.history[this.historyIndex];
  }

  get isForwardDisabled() {
    return this.historyIndex >= this.history.length - 1;
  }

  get isBackwardDisabled() {
    return this.historyIndex < 1;
  }
}
