<div [formGroup]="group"
  fxLayout="column"
  fxLayoutGap="16px">
  <mat-form-field appearance="outline">
    <mat-label>First Use Anywhere Date</mat-label>
    <input formControlName="first-use-anywhere-date"
      matInput
      required
      mmUsptoIndefiniteDate>
    <mm-tip-button matSuffix anchor="03a49bcc-5530-4972-8d46-b22dc0e9c382"></mm-tip-button>
    <mat-hint>Enter date in MM/DD/YYYY format</mat-hint>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>First Use in Commerce Date</mat-label>
    <input formControlName="first-use-in-commerce-date"
      matInput
      required
      mmUsptoIndefiniteDate>
    <mm-tip-button matSuffix anchor="03a49bcc-5530-4972-8d46-b22dc0e9c382"></mm-tip-button>
    <mat-hint>Enter date in MM/DD/YYYY format</mat-hint>
  </mat-form-field>

  <section formGroupName="specimen"
    fxLayout="column" fxLayoutGap="24px">

    <h4 fxLayout="row">
      <span fxFlex="grow">Specimen</span>
      <mm-tip-button matSuffix
        fxFlex="none"
        style="padding-right: 16px;"
        anchor="specimens-general"></mm-tip-button>
    </h4>

    <mm-case-file-control formControlName="files"></mm-case-file-control>

    <mat-form-field appearance="outline">
      <mat-label>Description of Specimen Attachments</mat-label>
      <textarea formControlName="description-text"
        matInput
        matTextareaAutosize
        required></textarea>
      <mm-tip-button matSuffix
        anchor="2ebf610f-e389-426c-883b-af26fc16591f"></mm-tip-button>
    </mat-form-field>

    <div *ngIf="serialNumber$ | async | not" fxLayout="row">
      <mat-checkbox formControlName="color">
        This is a color specimen.
      </mat-checkbox>
    </div>

    <ng-container *ngIf="!(isUnfiled$ | async)">
      <div fxLayout="row">
        <mat-checkbox formControlName="declaration">
          Substitute or additional specimen(s) are being submitted, or the originally-submitted specimen(s) are now being verified.
        </mat-checkbox>
        <p fxFlex="none">
          <button mat-button (click)="showDeclaration()">SHOW DECLARATION</button>
        </p>
      </div>
    </ng-container>


  </section>

</div>
