import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SubmissionMethod } from '@markmachine/features/version/models/version.model';
import { VersionRequestService } from '@markmachine/features/version/services/version-request.service';
import { ConfirmDialogComponent } from '@markmachine/shared/components/confirm-dialog/confirm-dialog.component';
import {
  chooseSubmissionMethod,
  deactivateSubmission,
  revertVersion,
  updateSubmissionScript
} from '@markmachine/views/submission-page/actions/submission-page.actions';
// tslint:disable-next-line: max-line-length
import { RevertConfirmDialogComponent } from '@markmachine/views/submission-page/components/revert-confirm-dialog/revert-confirm-dialog.component';
import * as fromSubmission from '@markmachine/views/submission-page/reducers';
import { getDraftVersion } from '@markmachine/views/submission-page/reducers';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/* tslint:disable:max-line-length */
const PHONE_SCRIPT = `
[Dial 703-272-0000]

Hello Examining Attorney John Doe:

This is ApplicantX, and I’m calling regarding application serial number 87639291.

I’ve reviewed the Office Action dated 32 October 2017, and in response would like you to enter an Examiner’s Amendment that:
  1.  Disclaims SHOPPE apart from the mark as it’s currently shown in the application;
  2.  Amends the description of the mark to read: “The mark consists of the word "accesshealthcare" is stylized font, with the word "access" in red letters in red and "healthcare" in blue letters”; and
  3.  States that stippling is a feature of the mark and does not indicate color.

Will that resolve all the outstanding Issues?

Thank you.`.trim();

const EMAIL_SCRIPT = `
To:  JohnDoe@uspto.gov
From:  ApplicantX@gmail.com
Subject:  Application 87639291

Dear Examining Attorney John Doe:

In response to the Office Action dated 32 October 2017, please enter an Examiner’s Amendment that:
  1.  Disclaims SHOPPE apart from the mark as currently shown in the application;
  2.  Amends the description of the mark to read: “The mark consists of the word "accesshealthcare" is stylized font, with the word "access" in red letters in red and "healthcare" in blue letters”; and
  3.  States that stippling is a feature of the mark and does not indicate color.

Thank you.

Sincerely,

ApplicantX
555-555-1212 [if selected in their MaMa Profile]
`.trim();
/* tslint:enable:max-line-length */

// Not provided in root because #loadFailure needs an dialog component provided as an entryComponent
@Injectable()
export class SubmissionService {
  submissionMethod$: Observable<SubmissionMethod>;
  submissionScript$: Observable<string>;
  constructor(
    private dialog: MatDialog,
    private versionRequest: VersionRequestService,
    private store: Store<fromSubmission.State>
  ) {
    this.submissionMethod$ = store.pipe(select(getDraftVersion), map(draft => draft.submissionMethod));
    this.submissionScript$ = this.submissionMethod$.pipe(
      map(method => {
        switch (method) {
          case 'EMAIL':
            return EMAIL_SCRIPT;
          case 'PHONE':
            return PHONE_SCRIPT;
          default:
            return '';
        }
      })
    );
  }

  changeSubmissionMethod(method: SubmissionMethod) {
    this.store.dispatch(chooseSubmissionMethod({ method }));
  }

  changeSubmissionScript(script: string): void {
    this.store.dispatch(updateSubmissionScript({ script }));
  }

  deactivateSubmission(): void {
    this.store.dispatch(deactivateSubmission());
  }

  /**
   * Revert the draft content to the latest current version.
   */
  revertDraft(): void {
    this.store.dispatch(revertVersion());
  }

  /**
   * Prompt to revert draft content to the latest current version
   */
  revertDraftPrompt() {
    const dialogRef = this.dialog.open(RevertConfirmDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.revertDraft();
      }
    });
  }

  /** Confirm deleting a class, then ask its parent to remove it. */
  deleteClass(classGroup: FormGroup, callback?: () => any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: {
      title: 'Delete class?',
      message: 'Are you sure you want to permanently delete this class from the application?',
      confirm: 'DELETE'
    }});
    dialogRef.beforeClosed().subscribe(result => {
      if (result) {
        const array = classGroup.parent as FormArray;
        const index = Object.values(array.controls).findIndex(c => c === classGroup);
        array.removeAt(index);
        if (callback) { callback(); }
      }
    });
  }
}
