import { Routes } from '@angular/router';
import { SignInGuard } from './features/account/guards/sign-in.guard';

export const routes: Routes = [
  //
  // Public Routes
  //
  {
    path: 'landing',
    loadChildren: () => import('./views/landing-routing/landing-routing.module').then(m => m.LandingRoutingModule),
    canActivate: [SignInGuard]
  },
  {
    path: 'search',
    loadChildren: () =>
      import('./views/clearance-search-routing/clearance-search-routing.module').then(m => m.ClearanceSearchRoutingModule),
    canActivate: [SignInGuard]
  },
  {
    path: 'apply',
    loadChildren: () => import('./views/apply-routing/apply-routing.module').then(m => m.ApplyRoutingModule),
    canActivate: [SignInGuard]
  },
  {
    path: 'docket',
    loadChildren: () => import('./views/docket-routing/docket-routing.module').then(m => m.DocketRoutingModule),
    canActivate: [SignInGuard]
  },
  {
    path: 'fix',
    loadChildren: () => import('./views/fix-routing/fix-routing.module').then(m => m.FixRoutingModule),
    canActivate: [SignInGuard]
  },
  // {
  //   path: 'manage',
  //   loadChildren: () => import('./views/manage-routing/manage-routing.module').then(m => m.ManageRoutingModule),
  //   canActivate: [SignInGuard]
  // },
  {
    path: 'account',
    loadChildren: () => import('./views/account-routing/account-routing.module').then(m => m.AccountRoutingModule),
    canActivate: [SignInGuard]
  },

  /** Root Homepage */
  {
    path: 'home',
    loadChildren: () => import('./views/homepage-routing/homepage-routing.module').then(m => m.HomepageRoutingModule),
    canActivate: [SignInGuard]
  },
  { path: '', pathMatch: 'full', redirectTo: '/home' },

  //
  // Internal Routes
  //

  {
    path: 'admin',
    loadChildren: () => import('./views/admin-routing/admin-routing.module').then(m => m.AdminRoutingModule),
    canActivate: [SignInGuard],
    data: { requireAdmin: true }
  },
  {
    path: 'guides',
    loadChildren: () => import('./views/guide-routing/guide-routing.module').then(m => m.GuideRoutingModule),
    canActivate: [SignInGuard],
    data: { requireAdmin: true }
  },
  {
    path: 'tips',
    loadChildren: () => import('./views/tip-routing/tip-routing.module').then(m => m.TipRoutingModule),
    canActivate: [SignInGuard],
    data: { requireAdmin: true }
  },
  {
    path: 'teas',
    loadChildren: () => import('./views/teas-routing/teas-routing.module').then(m => m.TeasRoutingModule),
    canActivate: [SignInGuard],
    data: { requireAdmin: true }
  },

  /**
   * Fallthrough
   * Pass off any unmatched paths to the Article Routing Module
   * TODO: Consider not doing this. It leads to a lot of confusing results when URLS are broken. Maybe put articles under /articles/.
   */
  {
    path: '',
    loadChildren: () => import('./views/article-routing/article-routing.module').then(m => m.ArticleRoutingModule),
    canActivate: [SignInGuard]
  }
];
