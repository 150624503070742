<ng-container [formGroup]="formGroup">
  <ng-container *ngIf="includeCountry">
    <mat-form-field>
      <mat-label>Phone Country</mat-label>
      <mat-select formControlName="phone-country">
        <mat-option *ngFor="let opt of options.phoneCountries"
          [value]="opt.value">{{opt.display}}</mat-option>
      </mat-select>
      <mat-error *ngIf="!!formGroup.get('phone-country')?.errors">
        {{ "owners.phone-country" | validationErrorMessage }}
      </mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="countryCode">
      <mat-label>Country Code</mat-label>
      <input class="country-code"
        matInput
        [value]=countryCode
        disabled
        type="text">
    </mat-form-field>
  </ng-container>
  <mat-form-field>
    <mat-label>Phone Number</mat-label>
    <input formControlName="phone"
      matInput
      type="tel">
  </mat-form-field>
  <mat-form-field *ngIf="includeExtension">
    <mat-label>Phone Extension</mat-label>
    <input formControlName="phone-extension"
      matInput
      maxlength="6"
      pattern="\d*"
      type="text">
  </mat-form-field>
</ng-container>