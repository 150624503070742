import { caseFragmentFields } from '@markmachine/features/case/services/operations/case.fragment';
import { Case } from '@markmachine/features/case/models/case.model';
import { GraphQLQuery, GraphQLResponse } from '@markmachine/interfaces';

const graphqlQuery = `
query CaseById($caseId: UUID!) {
  caseById(id: $caseId) {
    ${caseFragmentFields}
  }
}
`;

export class CaseByIdQuery implements GraphQLQuery {
  query = graphqlQuery;
  constructor(public variables: { caseId: string }) {}
}

export type CaseByIdResponse = GraphQLResponse<{
  caseById: Case;
}>;
