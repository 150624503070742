import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ACCOUNT_ACCESS_KEY, ACCOUNT_FLAGS_KEY } from '@markmachine/core/core.config';
import * as Action from '@markmachine/features/account/actions/account.actions';
import { AccessToken } from '@markmachine/features/account/models/access-token.model';
import { TutorialNames, UserProfile, UserProfileFlags } from '@markmachine/features/account/models/user-profile.model';
import * as fromAccount from '@markmachine/features/account/reducers/account.reducer';
import * as UserCaseAction from '@markmachine/features/user-case/actions/user-case.actions';
import { UserCase } from '@markmachine/features/user-case/models/user-case.model';
import { getDocketRowsByUserCaseNodeId, getUserCasesWithStatus } from '@markmachine/features/user-case/reducers/user-case.reducer';
import { select, Store } from '@ngrx/store';
import { AppState } from 'app/interfaces';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

const jwtHelperService = new JwtHelperService({});

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  constructor(private store: Store<AppState>) {}

  static decodeToken(accessToken): AccessToken {
    return jwtHelperService.decodeToken(accessToken);
  }

  /**
   * Obtain authentication token from local storage
   * Used during app initialization
   */
  static tokenGetter() {
    return localStorage.getItem(ACCOUNT_ACCESS_KEY);
  }

  /**
   * Store authentication token in local storage
   * Used in sign-in
   * @param accessToken jwt access token
   */
  static tokenSetter(accessToken) {
    return localStorage.setItem(ACCOUNT_ACCESS_KEY, accessToken);
  }

  /**
   * Remove authentication token from local storage
   * Used to in sign-out
   */
  static tokenRemover() {
    return localStorage.removeItem(ACCOUNT_ACCESS_KEY);
  }

  /**
   * Load profile flags from local storage
   */
  static flagsGetter(): UserProfileFlags {
    const localFlags = localStorage.getItem(ACCOUNT_FLAGS_KEY);
    return localFlags ? JSON.parse(localFlags) : new UserProfileFlags();
  }

  /**
   * Set flags in local storage
   * @param flags flags to persist
   */
  static flagsSetter(flags: UserProfileFlags) {
    return localStorage.setItem(ACCOUNT_FLAGS_KEY, JSON.stringify(flags));
  }

  /**
   * Remove flags from local storage
   */
  static flagsRemover() {
    return localStorage.removeItem(ACCOUNT_FLAGS_KEY);
  }

  /**
   * Check if authentication token is expired
   * @param token jwt access token
   */
  static tokenExpired(accessToken) {
    return jwtHelperService.isTokenExpired(accessToken);
  }

  isTutorialDisabled(tutorialName: TutorialNames): Observable<boolean> {
    return this.store.pipe(
      select(fromAccount.getFlagTutorialInteractions(), { tutorialName }),
      map(tutorialInteraction => !!tutorialInteraction.permanentlyDismissed)
    );
  }

  /** Set a tutorial flag to null */
  undismissTutorial(tutorialName: TutorialNames) {
    this.store.dispatch(Action.undismissTutorial({ tutorialName }));
  }

  /** Set a tutorial flag to a timestamp */
  dismissTutorial(tutorialName: TutorialNames) {
    this.store.dispatch(Action.dismissTutorial({ tutorialName }));
  }

  /**
   * Initiate the sign-in process
   */
  signIn() {
    this.store.dispatch(Action.signIn());
  }

  /**
   * Initiate the sign-out process
   */
  signOut() {
    this.store.dispatch(Action.signOut());
  }

  get userId$(): Observable<string> {
    return this.store.pipe(select(fromAccount.getUserId));
  }

  /**
   * Get the currently configured "Return URL", if any
   */
  get returnUrl$(): Observable<string> {
    return this.store.pipe(select(fromAccount.getReturnUrl));
  }

  /**
   * Instantly navigate to the Return URL, /account/profile
   */
  public navigateToReturnUrl(): void {
    this.store.dispatch(Action.navigateToReturnUrl());
  }

  /**
   * Register, obtaining an access token via side-effect.
   * @param email Email address that doesn't already have an account
   * @param password New password for the account
   */
  register(email: string, password: string) {
    this.store.dispatch(Action.register({ email, password }));
  }

  /**
   * Provide an observable of any registration errors
   */
  get registrationError$() {
    return this.store.pipe(select(fromAccount.getRegistrationError));
  }

  /**
   * Sign in, obtaining an access token via side-effect.
   * @param email Email address for pre-existing account
   * @param password Password for account
   */
  authorize(email: string, password: string) {
    this.store.dispatch(Action.authorize({ email, password }));
  }

  /**
   * Change password for signed-in user.
   * @param password New password
   */
  confirmChangePassword(password: string) {
    this.store.dispatch(Action.confirmChangePassword({ password }));
  }

  /**
   * Provide an observable of any authentication errors
   */
  get authorizationError$() {
    return this.store.pipe(select(fromAccount.getAuthorizationError));
  }

  /**
   * Provide an observable signed-in status
   */
  get signedIn$(): Observable<boolean> {
    return this.store.pipe(
      select(fromAccount.getAuthorizationToken),
      map(token => {
        if (!token) {
          return false;
        }
        const tokenExpired = jwtHelperService.isTokenExpired(token);
        return !tokenExpired;
      })
    );
  }

  /** Get authorization token */
  get authorizationToken$() {
    return this.store.pipe(select(fromAccount.getAuthorizationToken));
  }

  /** Get admin status */
  get isAdmin$() {
    return this.store.pipe(select(fromAccount.getIsAdmin));
  }

  get accountIcon$() {
    return this.isAdmin$.pipe(map(isAdmin => (isAdmin ? 'supervisor_account' : 'account_circle')));
  }

  /** Get account full name */
  get fullName$() {
    return this.store.pipe(select(fromAccount.getAccountName));
  }

  /**
   * Set the user profile in the local store
   * @param profile Complete user profile
   */
  setUserProfile(profile: UserProfile): void {
    this.store.dispatch(Action.loadProfileSuccess({ profile }));
  }

  /**
   * Obtain an observable of the user profile
   */
  get userProfile$() {
    return this.store.pipe(select(fromAccount.getProfileState));
  }

  /**
   * Modify the stored user profile via the Mark Machine API
   * @param value Changes to user profile
   */
  updateUserProfile(profile: UserProfile) {
    this.store.dispatch(Action.updateProfile({ profile }));
  }

  /**
   * Obtain an observable of whether an update request is pending
   */
  get userProfileIsUpdating$() {
    return this.store.pipe(select(fromAccount.getUserProfileIsUpdating), debounceTime(200));
  }

  // **************************************************************************
  // User Cases
  // **************************************************************************

  /**
   * Obtain an observable of the user's current cases
   */
  get userCases$(): Observable<UserCase[]> {
    return this.store.pipe(select(getUserCasesWithStatus));
  }

  /**
   * Obtain a specific user case by ID
   */
  userCaseByNodeId$(nodeId: string): Observable<UserCase> {
    return this.store.pipe(select(getDocketRowsByUserCaseNodeId(), { nodeId }));
  }

  /**
   * Add user cases to the profile by serial number
   * @param serialNumbers Serial numbers
   */
  addUserCasesBySerialNumbers(serialNumbers: number[]) {
    this.store.dispatch(UserCaseAction.addUserCasesBySerialNumbers({ serialNumbers }));
  }

  /**
   * Remove cases from profile via Mark Machine API
   * @param nodeIds Case ids for each case to remove
   */
  removeUserCases(nodeIds: string[]): void {
    this.store.dispatch(UserCaseAction.deleteUserCases({ nodeIds }));
  }

  /**
   * Clone case from profile via Mark Machine API
   * @param nodeId Case id for case to clone
   */
  cloneUserCase(nodeId: string): void {
    this.store.dispatch(UserCaseAction.cloneUserCase({ nodeId }));
  }

  /**
   * Update fields in user case
   * @param caseId Primary key of case to update
   * @param userCasePatch Patch to apply to case
   */
  updateUserCase(id: string, changes: Partial<UserCase>) {
    this.store.dispatch(UserCaseAction.updateUserCase({ userCase: { id, changes } }));
  }

  addUserCases(userCases: UserCase[]): void {
    this.store.dispatch(UserCaseAction.createUserCases({ userCases }));
  }

  updateUserCases(userCases: UserCase[]): void {
    const updates = userCases.map(uc => ({ id: uc.nodeId, changes: uc }));
    this.store.dispatch(UserCaseAction.updateUserCases({ userCases: updates }));
  }

  /**
   * Create a new case via Mark Machine API
   */
  createCase() {
    this.store.dispatch(UserCaseAction.createNewCase());
  }
}
