import { Component } from '@angular/core';
import { IconService } from 'app/core/services/icon.service';

@Component({
  selector: 'mm-root',
  styles: [':host { flex: 1 }'],
  template: '<router-outlet></router-outlet>'
})
export class AppComponent {
  constructor(private icon: IconService) {}
}
