import { animate, state, style, transition, trigger, animateChild, query, group } from '@angular/animations';
import { AnimationCurves, AnimationDurations } from '@angular/material/core';

export const ENTERING_ANIMATION = AnimationDurations.ENTERING + ' ' + AnimationCurves.DECELERATION_CURVE;
export const EXITING_ANIMATION = AnimationDurations.EXITING + ' ' + AnimationCurves.ACCELERATION_CURVE;

export const flyInOut = trigger('flyInOut', [
  state('*', style({ transform: 'translateX(0) translateY(0)'})),
  state('void', style({ transform: 'translateX(100%)'})),
  transition(':enter', animate(ENTERING_ANIMATION)),
  transition(':leave', animate(EXITING_ANIMATION)),
])

export const fadeInOut = trigger('fadeInOut', [
  state('*', style({ opacity: 1 })),
  state('void', style({ opacity: 0 })),
  transition(':enter', animate(ENTERING_ANIMATION)),
  transition(':leave', animate(EXITING_ANIMATION)),
]);

export const slideInDownAnimation = trigger('routeAnimation', [
  state(
    '*',
    style({
      opacity: 1
    })
  ),
  transition(':enter', [
    style({
      opacity: 0
    }),
    animate(ENTERING_ANIMATION)
  ]),
  transition(':leave', [
    style({
      opacity: 1
    }),
    animate(EXITING_ANIMATION)
  ])
]);

export const routeAnimation = trigger('routeAnimation', [
  state('*', style({
    // opacity: 1,
    transform: 'translateY(0)'
  })),
  state('void', style({
    // opacity: 0,
    transform: 'translateY(100%)'
  })),
  transition(':enter', animate(ENTERING_ANIMATION)),
  transition(':leave', animate(EXITING_ANIMATION))
]);

export const extraToolbar = trigger('extraToolbar', [
  state('*', style({
    opacity: 1,
    height: '*',
    'overflow-y': 'hidden'
  })),
  state('void', style({
    opacity: 0,
    height: 0,
    'overflow-y': 'hidden'
  })),
  transition(':enter', [
    style({'overflow-y': 'hidden'}),
    animate(ENTERING_ANIMATION)
  ]),
  transition(':leave', [
    style({'overflow-y': 'hidden'}),
    animate(EXITING_ANIMATION)
  ])
]);

export const expansionAnimation = trigger('expandedState', [
  state(
    'inactive',
    style({
      height: '0px'
    })
  ),
  state('active', style({})),
  transition('inactive => active', animate('375ms ease-in-out')),
  transition('active => inactive', animate('375ms ease-out'))
]);

/** Time and timing curve for expansion panel animations. */
export const EXPANSION_PANEL_ANIMATION_TIMING = '225ms cubic-bezier(0.4,0.0,0.2,1)';

/** Animation that rotates the indicator arrow. */
export const outlineAnimation = {
  indicatorRotation: trigger('expanded', [
    state('collapsed', style({ transform: 'rotate(0deg)' })),
    state('expanded', style({ transform: 'rotate(90deg)' })),
    transition(
      'expanded <=> collapsed',
      group([query('@indicatorRotate', animateChild(), { optional: true }), animate(EXPANSION_PANEL_ANIMATION_TIMING)])
    )
  ]),

  /** Animation that expands and collapses the panel content. */
  bodyExpansion: trigger('bodyExpansion', [
    state('collapsed', style({ height: '0px', visibility: 'hidden' })),
    state('expanded', style({ height: '*', visibility: 'visible' })),
    transition('expanded <=> collapsed', animate(EXPANSION_PANEL_ANIMATION_TIMING))
  ])
};

export const indicatorQuarterRotate = trigger('indicatorRotate', [
  state('collapsed', style({ transform: 'rotate(0deg)' })),
  state('expanded', style({ transform: 'rotate(90deg)' })),
  transition('expanded <=> collapsed', animate(EXPANSION_PANEL_ANIMATION_TIMING))
]);
