// The file contents for the current environment will overwrite these during build.

export let build = {
  SENTRY_ORG: 'SENTRY_ORG',
  SENTRY_PROJECT: 'SENTRY_PROJECT',
  VERCEL_ENV: 'VERCEL_ENV',
  VERCEL_GIT_COMMIT_REF: 'VERCEL_GIT_COMMIT_REF',
  VERCEL_GIT_COMMIT_SHA: 'VERCEL_GIT_COMMIT_SHA',
  VERCEL_GIT_REPO_SLUG: 'VERCEL_GIT_REPO_SLUG',
  VERSION: 'VERSION',
};
build = {
    "SENTRY_ORG": "sentry",
    "SENTRY_PROJECT": "angular",
    "VERCEL_ENV": "production",
    "VERCEL_GIT_COMMIT_REF": "develop",
    "VERCEL_GIT_COMMIT_SHA": "d0970e260a57bdcc098901e7355e984f7459a43e",
    "VERCEL_GIT_REPO_SLUG": "markmachine-client",
    "VERSION": "angular@d0970e260a57bdcc098901e7355e984f7459a43e"
}
