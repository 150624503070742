/**
 * Template Module
 *
 * Provides the site-wide template that any top-level page should use.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MarkMachineTemplateComponent } from './mark-machine-template/mark-machine-template.component';
import { HeaderModule } from '@markmachine/features/header/header.module';
import { FooterModule } from '@markmachine/features/footer/footer.module';

@NgModule({
  declarations: [MarkMachineTemplateComponent],
  imports: [
    CommonModule,
    HeaderModule,
    FooterModule,
  ],
  exports: [
    MarkMachineTemplateComponent
  ]
})
export class MarkMachineTemplateModule { }
