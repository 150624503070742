import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'mm-expansion-panel-title',
  templateUrl: './expansion-panel-title.component.html',
  styleUrls: ['./expansion-panel-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpansionPanelTitleComponent {
  constructor() { }

}
