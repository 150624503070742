import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TEMPLATE_NAMES, TemplateNameId } from '@markmachine/features/version-form/services/case-form-statements.service';
import { transform } from 'lodash-es';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'mm-ongoing-efforts',
  templateUrl: './ongoing-efforts.component.html',
  styleUrls: ['./ongoing-efforts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // Register as a form control provider
  providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: forwardRef(() => OngoingEffortsComponent) }]
})
export class OngoingEffortsComponent implements OnDestroy, ControlValueAccessor {
  // Component's visible form controls
  group: FormGroup;
  destroy$ = new Subject();

  // Local reference for ongoingEfforts, used for comparisons to outgoing values
  ongoingEfforts = null;

  additionalCheckbox =
    'without limitation, product or service research or development, market research, ' +
    'manufacturing activities, promotional activities, steps to acquire distributors, ' +
    'steps to obtain governmental approval, or other similar activities';

  // Checkboxes provided by USPTO TEAS
  checkboxNames = TEMPLATE_NAMES;

  // Registered callbacks
  _onChange = value => {};
  _onTouch = () => {};

  /** Write incoming model values to the view */
  writeValue(ongoingEfforts: any): void {
    // Update the local reference for filtering outgoing comparisons
    this.ongoingEfforts = ongoingEfforts;
    const patch = this.getPatch(ongoingEfforts);
    // Never emit when we receive a value from the model; just udpate the view
    this.group.patchValue(patch, { emitEvent: false });
    // Really, update the view, please.
    this.cd.markForCheck();
  }
  /** Register callback for writing from view to model */
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  /** Register callback for emitting control blurs from view to model */
  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }
  /** Set the disabled state of the view */
  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled !== this.group.disabled) {
      this.group.disable();
    }
  }

  constructor(private cd: ChangeDetectorRef, private fb: FormBuilder) {
    this.group = this.constructForm();
    // Now that the group has been constructed, construct outgoing values
    // and send them to the registered onChange handler
    this.group.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        map(value => this.getOngoingEfforts(value)),
        // Our constructed strings might not have the same identity,
        // so distinctUntilChanged() can't be used here.
        filter(ongoingEfforts => ongoingEfforts !== this.ongoingEfforts)
      )
      .subscribe(ongoingEfforts => {
        this._onChange(ongoingEfforts);
        // If we don't call the touch handler, we might not emit values
        this._onTouch();
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  /** Construct visible form elements */
  constructForm() {
    // Outer FormGroup is a workaround to get { updateOn: 'blur' } until:
    // https://github.com/angular/angular/issues/19163
    return new FormGroup(this.fb.group(this.getPatch('')).controls, { updateOn: 'blur' });
  }

  /** Construct patch to update visible form controls */
  getPatch(incoming: string): any {
    const parts = incoming.split(/\s*;\s*/g);
    const boxNames = parts.filter((p: TemplateNameId) => this.checkboxNames.includes(p));
    const remainder = parts.filter((p: TemplateNameId) => !boxNames.includes(p));
    const other = remainder.join('; ');
    const patch = transform(
      this.checkboxNames as any,
      (result, curr: string) => {
        result[curr] = parts.includes(curr);
      },
      { other } as any
    );
    return patch;
  }

  /** Construct outgoing Ongoing Efforts value */
  getOngoingEfforts(value: any): string {
    // Assemble checkbox values in order
    const parts = this.checkboxNames.filter(name => !!value[name]);
    const other = value['other'].trim();
    if (other.length > 0) {
      parts.push(other);
    }
    // Join with ' ; ' so we exactly match USPTO's construction as seen in receipts
    return parts.join(' ; ');
  }
}
