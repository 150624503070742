<h2 mat-dialog-title>
  <span>Tips</span>
  <mm-tip-navigation [navigationFor]="tipArray"
    #navigation></mm-tip-navigation>
</h2>

<mat-dialog-content class="mat-typography">

  <mm-tip-array [tipIds]="tipIds"
    #tipArray
    (mmFollowAnchor)="navigation.followAnchor($event)"></mm-tip-array>

</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button
    mat-dialog-close>DONE</button>
</mat-dialog-actions>
