import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { deleteUserNote, updateUserNote } from '@markmachine/features/user-note/actions/user-note.actions';
import { UserNote } from '@markmachine/features/user-note/models/user-note.model';
import { AppState } from '@markmachine/interfaces';
import { Store } from '@ngrx/store';
import { pick } from 'lodash-es';

@Component({
  selector: 'mm-case-note-helper-item',
  templateUrl: './case-note-helper-item.component.html',
  styleUrls: ['./case-note-helper-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CaseNoteHelperItemComponent implements OnChanges, OnInit {
  @Input() note: UserNote & { id: string };
  @Input() expanded = false;
  @Output() expansionChange = new EventEmitter();

  formGroup: FormGroup;

  constructor(private fb: FormBuilder, private store: Store<AppState>) { }

  ngOnInit() {
    this.formGroup = this.fb.group(pick(this.note, ['id', 'createdBy', 'note']), { updateOn: 'blur' });
    this.formGroup.valueChanges.subscribe(note => this.updateNote(note));
  }

  ngOnChanges({ note }: SimpleChanges) {
    if (this.formGroup && note) {
      // Only patch the note if the id has changed; otherwise, assume we are the only source for updates.
      if (note.currentValue.id !== this.formGroup.get('id')?.value) {
        this.formGroup.patchValue(note.currentValue, { emitEvent: false });
      }
    }
  }

  removeNote(id: string ) {
    this.store.dispatch(deleteUserNote({ id }));
  }

  updateNote({ id, note }: Partial<UserNote>) {
    id = id as string;
    this.store.dispatch(updateUserNote({ userNote: { id, changes: { note }}}));
  }
}
