import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Resolve, RouterStateSnapshot } from '@angular/router';
import { loadStatuses, refreshStatuses } from '@markmachine/features/case-status/actions/status.actions';
import { loadCases } from '@markmachine/features/case/actions/case.actions';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { loadUserCases } from '../actions/user-case.actions';
import { UserCase } from '../models/user-case.model';
import { UserCaseService } from '../services/user-case.service';

@Injectable()
export class UserCasesGuard implements Resolve<UserCase[]>, CanActivate {
  constructor(
    private service: UserCaseService,
    private store: Store<any>
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<UserCase[]> {
    return this.service.requestAllUserCases().pipe(
      map(nodes => {
        const userCases = nodes.map(n => n.userCase);
        const statuses = nodes.map(n => n.status).filter(s => !!s);
        const cases = nodes.map(n => n.case_);
        if (statuses.length > 0) {
          this.store.dispatch(loadStatuses({ statuses }));
        }
        this.store.dispatch(loadCases({ cases }));
        this.store.dispatch(loadUserCases({ userCases }));
        const caseIds = cases.map(c => c.id).filter(id => !!id);
        this.store.dispatch(refreshStatuses({ caseIds }));
        return userCases;
      }),
    );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.resolve(route, state).pipe(map(() => true));
  }
}
