import { Injectable } from '@angular/core';
import { Mutation } from '@markmachine/features/graphql/services/mutation.service';
import { AuthenticatedUserProfile } from '../models/user-profile.model';

interface ChangePasswordResponse {
  changePassword: {
    jwtToken: string;
    query: {
      currentProfile: AuthenticatedUserProfile
    };
  };
}

@Injectable({ providedIn: 'root' })
export class ChangePasswordMutation extends Mutation<ChangePasswordResponse, { password: string }> {
  document = `
  mutation ChangePassword($password: String!) {
    changePassword (input: { password: $password }) {
      jwtToken
      query {
        currentProfile {
          userId
          flags
        }
      }
    }
  }
  `;
}
