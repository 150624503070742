/**
 * UPSTO Indefinite Date Module
 *
 * The USPTO has a unique way of expressing dates. Dates are formatted similarly to
 * ISO 8601/RFC 3339. However, any (ANY) field might be expressed as a sequence
 * of zeros to indicate that a field is unknown. Typically this is done with decreasing
 * specificity, where ISO 8601 would require be 2001-04, USPTO would be 2001-04-00.
 *
 * This module provides utilties for dealing with these dates, including a directive
 * for use with date input fields to massage input and output.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsptoIndefiniteDateDirective } from '@markmachine/features/uspto-indefinite-date/directives/uspto-indefinite-date.directive';
import { UsptoIndefiniteDatePipe } from '@markmachine/features/uspto-indefinite-date/pipes/uspto-indefinite-date.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [UsptoIndefiniteDateDirective, UsptoIndefiniteDatePipe],
  exports: [UsptoIndefiniteDateDirective, UsptoIndefiniteDatePipe]
})
export class UsptoIndefiniteDateModule { }
