import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { UserTable } from '../models/user-table.model';

export const addUserTable = createAction(
  '[UserTable] AddUserTable',
  props<{ userTable: Partial<UserTable> }>()
);

export const addUserTableSuccess = createAction(
  '[UserTable] AddUserTableSuccess',
  props<{ userTable: UserTable }>()
);

export const addUserTableFailure = createAction(
  '[UserTable] AddUserTableFailure',
  props<{ errorMessage: string }>()
);

export const updateUserTable = createAction(
  '[UserTable] UpdateUserTable',
  props<{ userTable: Update<UserTable> }>()
);

export const updateUserTableSuccess = createAction(
  '[UserTable] UpdateUserTableSuccess',
  props<{ userTable: Update<UserTable> }>()
);

export const updateUserTableFailure = createAction(
  '[UserTable] UpdateUserTableFailure',
  props<{ errorMessage: string }>()
);

export const deleteUserTable = createAction(
  '[UserTable] DeleteUserTable',
  props<{ nodeId: string }>()
);

export const loadUserTables = createAction(
  '[UserTable] LoadUserTables',
  props<{ userTables: UserTable[] }>()
);
