import { Pipe, PipeTransform } from '@angular/core';
import { isEmpty } from 'lodash-es';

/**
 * Checks if value is an empty object, collection, map, or set.
 *
 * Objects are considered empty if they have no own enumerable string keyed properties.
 *
 * Array-like values such as arguments objects, arrays, buffers, strings, or jQuery-like collections are considered empty if they have a
 * length of 0. Similarly, maps and sets are considered empty if they have a size of 0.
 *
 * Implemented as Lodash #isEmpty https://lodash.com/docs/4.17.15#isEmpty
 */
@Pipe({
  name: 'isEmpty'
})
export class IsEmptyPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    return isEmpty(value);
  }

}
