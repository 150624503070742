import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as UserTableActions from '../actions/user-table.actions';
import { UserTable } from '../models/user-table.model';

export interface State extends EntityState<UserTable> {
  // additional entities state properties
}

export const adapter: EntityAdapter<UserTable> = createEntityAdapter<UserTable>({
  selectId: ut => ut.nodeId // Primary key selector for User Case entities
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export const reducer = createReducer(
  initialState,
  on(UserTableActions.addUserTableSuccess, (state, { userTable }) => adapter.addOne(userTable, state)),

  on(UserTableActions.addUserTableFailure, (state) => state),

  on(UserTableActions.updateUserTable, (state, { userTable }) => adapter.updateOne(userTable, state)),

  on(UserTableActions.updateUserTableSuccess, (state, { userTable }) => adapter.updateOne(userTable, state)),

  on(UserTableActions.loadUserTables, (state, { userTables }) => adapter.setAll(userTables, state)),

  on(UserTableActions.deleteUserTable, (state, { nodeId }) => adapter.removeOne(nodeId, state)),
);

export const storeKey = 'user-tables';
export const getUserTableState = createFeatureSelector<State>(storeKey);

export const { selectAll: getAllUserTables, selectEntities: getUserTableEntities } = adapter.getSelectors(getUserTableState);

export const getUserTableByKey = createSelector(
  getAllUserTables,
  (userTables, key) => {
    const userTable = userTables.find(userTable_ => userTable_.key === key);
    return { ...userTable, columns: userTable.columns || [] };
  }
);
