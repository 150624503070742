import { GraphQLQuery, GraphQLResponse } from 'app/interfaces';
import { Tip } from '@markmachine/core/models/tip';

import tipFields from './tip.fragment';

const QUERY = `
query GetTip($id: UUID!) {
  tip: tipById(id: $id) {
    ...tipFields
  }
}
${tipFields}
`;

export class GetTipQuery implements GraphQLQuery {
  query = QUERY;
  constructor(public variables: { id: string }) {}
}

export type GetTipResponse = GraphQLResponse<{
  tip: Tip | null;
}>;
