import { Injectable } from '@angular/core';
import { AccountService } from '@markmachine/features/account/services/account.service';
import { map } from 'rxjs/operators';

@Injectable()
export class AccountMenuService {
  private _mainMenu = [
    {
      title: 'Cases',
      link: '/docket',
      icon: 'folder_special'
    },
    {
      title: 'Payment history',
      link: '/account/payments',
      icon: 'history'
    },
    {
      title: 'Permissions',
      link: '/account/permissions',
      icon: 'security'
    },
    {
      title: 'Profile',
      link: '/account/profile',
      icon: 'account_box'
    }
  ];
  private _footerMenu = [
    {
      title: 'Send feedback',
      link: '/account/feedback',
      icon: 'feedback'
    },
    {
      title: 'Sign out',
      link: '/account/sign-out',
      // icon: 'power_settings_new'
      icon: 'exit_to_app'
    }
  ];
  private _signInMenu = [
    {
      title: 'Sign in',
      link: '/account/sign-in',
      icon: '',
    }
  ];

  constructor(private account: AccountService) { }

  public mainMenu$() {
    return this.account.signedIn$.pipe(map(isSignedIn => isSignedIn ? this._mainMenu : []));
  }
  public footerMenu$() {
    return this.account.signedIn$.pipe(map(isSignedIn => isSignedIn ? this._footerMenu : this._signInMenu));
  }

}
