/**
 * Case Review Module
 *
 * Provides components for summarizing and comparing different versions of cases.
 *
 * TODO: Break DiffComponent and CommaListPipe into separate modules.
 *
 * Imported by Case Module
 */
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CaseFileModule } from '@markmachine/features/case-file/case-file.module';
import { AllegationOfUseComparisonComponent } from './components/allegation-of-use-comparison/allegation-of-use-comparison.component';
import {
  AllegationOfUseListComparisonComponent
} from './components/allegation-of-use-list-comparison/allegation-of-use-list-comparison.component';
import { ColorClaimComparisonComponent } from './components/mark-comparison/color-claim-comparison.component';
import { SigantureComparisonComponent } from './components/signature-comparison/signature-comparison.component';
import { SigantureListComparisonComponent } from './components/signature-comparison/signature-list-comparison.component';
import { UseInAnotherFormComparisonComponent } from './components/statements/use-in-another-form-comparison.component';
import { SharedMaterialModule } from '@markmachine/shared/materials/shared-materials.module';
import { CommaListPipe } from '@markmachine/shared/pipes/comma-list.pipe';
import { SharedModule } from '@markmachine/shared/shared.module';
import { UsptoIndefiniteDatePipe } from '@markmachine/features/uspto-indefinite-date/pipes/uspto-indefinite-date.pipe';
import { ArgumentComparisonComponent } from './components/argument-comparison/argument-comparison.component';
import { ClassComparisonComponent } from './components/class-comparison/class-comparison.component';
import { ClassListComparisonComponent } from './components/class-list-comparison/class-list-comparison.component';
import { CompareVersionsComponent } from './components/compare-versions/compare-versions.component';
import { CorrespondentComparisonComponent } from './components/correspondent-comparison/correspondent-comparison.component';
import { DiffComponent } from './components/diff/diff.component';
import { ExtensionRequestComparisonComponent } from './components/extension-request-comparison/extension-request-comparison.component';
import { ForeignFilesComparisonComponent } from './components/foreign-files-comparison/foreign-files-comparison.component';
import { MarkComparisonComponent } from './components/mark-comparison/mark-comparison.component';
import { OwnerComparisonComponent } from './components/owner-comparison/owner-comparison.component';
import { OwnerListComparisonComponent } from './components/owner-list-comparison/owner-list-comparison.component';
import { SpecimenComparisonComponent } from './components/specimen-comparison/specimen-comparison.component';
import { AcquiredDistinctivenessComparisonComponent } from './components/statements/acquired-distinctiveness-comparison.component';
import { ActivePriorRegistrationsComponent } from './components/statements/active-prior-registrations.component';
import { ConcurrentUseComparisonComponent } from './components/statements/concurrent-use-comparison.component';
import { DisclaimerComparisonComponent } from './components/statements/disclaimer-comparison.component';
import { LivingIndividualConsentComparisonComponent } from './components/statements/living-individual-consent-comparison.component';
import { MiscellaneousStatementsComparisonComponent } from './components/statements/miscellaneous-statements-comparison.component';
import { RegisterComparisonComponent } from './components/statements/register-comparison.component';
import { SignificanceComparisonComponent } from './components/statements/significance-comparison.component';
import { StatementsComparisonSectionComponent } from './components/statements/statements-comparison-section.component';
import { StipplingComparisonComponent } from './components/statements/stippling-comparison.component';
import { TranslationComparisonComponent } from './components/statements/translation-comparison.component';
import { TransliterationComparisonComponent } from './components/statements/transliteration-comparison.component';
import { ExpansionPanelHelperModule } from '@markmachine/features/expansion-panel-helper/expansion-panel-helper.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ExpansionPanelModule } from '@markmachine/features/expansion-panel/expansion-panel.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    SharedMaterialModule,
    CaseFileModule,
    ReactiveFormsModule,
    ExpansionPanelHelperModule,
    ExpansionPanelModule,
    FlexLayoutModule
  ],
  declarations: [
    AcquiredDistinctivenessComparisonComponent,
    ActivePriorRegistrationsComponent,
    ArgumentComparisonComponent,
    ColorClaimComparisonComponent,
    CompareVersionsComponent,
    ConcurrentUseComparisonComponent,
    DiffComponent,
    DisclaimerComparisonComponent,
    LivingIndividualConsentComparisonComponent,
    MiscellaneousStatementsComparisonComponent,
    SignificanceComparisonComponent,
    StatementsComparisonSectionComponent,
    StipplingComparisonComponent,
    RegisterComparisonComponent,
    TranslationComparisonComponent,
    TransliterationComparisonComponent,
    MarkComparisonComponent,
    CorrespondentComparisonComponent,
    OwnerComparisonComponent,
    OwnerListComparisonComponent,
    ClassListComparisonComponent,
    ClassComparisonComponent,
    SpecimenComparisonComponent,
    ForeignFilesComparisonComponent,
    SigantureComparisonComponent,
    SigantureListComparisonComponent,
    AllegationOfUseComparisonComponent,
    AllegationOfUseListComparisonComponent,
    ExtensionRequestComparisonComponent,
    UseInAnotherFormComparisonComponent
  ],
  exports: [
    CompareVersionsComponent,
    StatementsComparisonSectionComponent,
    RegisterComparisonComponent,
    AcquiredDistinctivenessComparisonComponent,
    DisclaimerComparisonComponent,
    LivingIndividualConsentComparisonComponent,
    SignificanceComparisonComponent,
    ActivePriorRegistrationsComponent,
    StipplingComparisonComponent,
    TranslationComparisonComponent,
    TransliterationComparisonComponent,
    ConcurrentUseComparisonComponent,
    MiscellaneousStatementsComparisonComponent,
    UseInAnotherFormComparisonComponent
  ],
  providers: [CommaListPipe, DatePipe, UsptoIndefiniteDatePipe]
})
export class CaseReviewModule {}
