import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FALLBACK_MARK_IMAGE } from '@markmachine/core/core.config';
import { environment } from 'environments/environment';
const { REST_API } = environment;

/**
 * Renders the image of the mark for Case Lists.
 *
 * Under the OnPush change detection strategy, this component will only be re-rendered if the
 * input changes.  Since the identity of the src string is stable, this avoids an expensive
 * redraw (or worse, re-fetch) of the image.
 */
@Component({
  selector: 'mm-mark-image',
  templateUrl: './mark-image.component.html',
  styleUrls: ['./mark-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarkImageComponent {
  @Input()
  set serialNumber(value: number) {
    // Guard against non-interned strings
    const newSrc = `${REST_API}/uspto/img/${value}/large`;
    if (newSrc !== this.src) {
      this.src = newSrc;
    }
  }
  src?: string;
  fallback = FALLBACK_MARK_IMAGE;
}
