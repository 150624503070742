import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Trademark } from '@markmachine/features/clearance-search/models/knockout-response.model';

@Component({
  selector: 'mm-clearance-search-results-owner',
  templateUrl: './clearance-search-results-owner.component.html',
  styleUrls: ['./clearance-search-results-owner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClearanceSearchResultsOwnerComponent implements OnInit {
  @Input()
  set row(value: Trademark) {
    this.owners = value.owners ? value.owners.map(owner => `${owner}`).join('\n\n') : '';
    this.addresses = value.addresses ? value.addresses.map(addresses_ => `${addresses_}`).join('\n\n') : '';
    this.attorney = value.attorneyOfRecord as string;
  }

  owners: string;
  addresses: string;
  attorney: string;
  constructor() { }

  ngOnInit() {
  }

}
