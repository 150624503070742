/**
 * Naitve String Date Adapter Module
 *
 * Provides a material date picker adapter for handling using timezone-naive
 * string representations of dates and times.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NaiveStringDateAdapter, NAIVE_STRING_DATE_FORMATS } from './naive-string-date-adapter';

@NgModule({
  imports: [MatDatepickerModule],
  exports: [MatDatepickerModule, MatNativeDateModule],
  providers: [
    { provide: DateAdapter, useClass: NaiveStringDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: NAIVE_STRING_DATE_FORMATS }
  ]
})
export class NaiveStringDateAdapterModule {}
