<h2 mat-dialog-title>
  {{ title }}
</h2>

<mat-dialog-content>
  <p>{{ message }}</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button
    color="warn"
    [mat-dialog-close]="true">{{ confirm | uppercase }}</button>
  <button mat-button
    [mat-dialog-close]="false">{{ cancel | uppercase }}</button>
</mat-dialog-actions>