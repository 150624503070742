import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { getVersions } from '@markmachine/views/submission-page/reducers';
import { loadVersionFailure, loadVersionSuccess } from '@markmachine/features/version/actions/version.actions';
import { VersionRequestService } from '@markmachine/features/version/services/version-request.service';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class CaseVersionGuard implements CanActivate {
  constructor(
    private _case: VersionRequestService,
    private store: Store
  ) {}

  private hasVersionInStore(versionId: string): Observable<boolean> {
    return this.store.pipe(select(getVersions), map(versions => !!versions[versionId]?.content ?? true));
  }

  private hasVersionInApi(versionId: string): Observable<boolean> {
    return this._case.getVersion(versionId).pipe(
      map(version => {
        this.store.dispatch(loadVersionSuccess({ version }));
        return true;
      }),
      catchError(err => {
        this.store.dispatch(loadVersionFailure());
        return of(false);
      })
    );
  }

  private hasVersion(versionId: string) {
    return this.hasVersionInStore(versionId).pipe(
      switchMap(inStore => (inStore ? of(true) : this.hasVersionInApi(versionId)))
    );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.hasVersion(next.params['versionId']);
  }
}
