import { createAction, props } from '@ngrx/store';

export const developerWarning = createAction(
  '[Log] Developer Warning New',
  props<{ title: string; message: string; details?: any }>()
);

export const userAlert = createAction(
  '[Log] User Alert New',
  props<{ title: string; message: string; details?: any }>()
);
