import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { Class } from '@markmachine/features/version/models/version-content.model';
import { AppState } from '@markmachine/interfaces';
import { NoticeDialogComponent } from '@markmachine/shared/components/notice-dialog/notice-dialog.component';
import { getSerialNumber, isUnfiled } from '@markmachine/views/submission-page/reducers';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const SPEC_DEC = `
**"The substitute (or new, or originally submitted, if appropriate) specimen(s) was/were in use in
commerce at least as early as the filing date of the application"** [for an application based on
Section 1(a), Use in Commerce] OR **"The substitute (or new, or originally submitted, if appropriate)
specimen(s) was/were in use in commerce prior either to the filing of the Amendment to Allege Use
or expiration of the filing deadline for filing a Statement of Use"** [for an application based on
Section 1(b) Intent-to-Use]. OR **"The attached specimen is a true copy of the specimen that was
originally submitted with the application, amendment to allege use, or statement of use"**
[for an illegible specimen]. The signatory being warned that willful false statements and the
like are punishable by fine or imprisonment, or both, under 18 U.S.C. §1001, and that such
willful false statements and the like may jeopardize the validity of the application or
submission or any registration resulting therefrom, declares that all statements made of his or
her own knowledge are true and all statements made on information and belief are believed to be
true.`;

@Component({
  selector: 'mm-basis-1a-edit',
  templateUrl: './basis-1a-edit.component.html',
  styleUrls: ['./basis-1a-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Basis1aEditComponent implements OnInit {
  @Input() group: FormGroup;
  @ViewChild(MatCheckbox) checkbox: MatCheckbox;

  isUnfiled$: Observable<boolean>;
  serialNumber$: Observable<number>;

  get specimen(): FormGroup {
    return this.group.get('specimen') as FormGroup;
  }

  get required$(): Observable<boolean> {
    return this.group.valueChanges.pipe(map((value: Class) => !!value['filing-basis-current-1a-in']));
  }

  constructor(
    private dialog: MatDialog,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    this.serialNumber$ = this.store.pipe(select(getSerialNumber));
    this.isUnfiled$ = this.store.pipe(select(isUnfiled));
  }

  showDeclaration() {
    this.dialog.open(NoticeDialogComponent, { data: {
      title: 'Specimen Declaration',
      message: SPEC_DEC } });
  }
}
