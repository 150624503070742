<ng-container [ngSwitch]="header.type">

  <ng-container *ngSwitchCase="'row-options'"></ng-container>

  <mat-checkbox *ngSwitchCase="'checkbox'"
    [checked]="header.rowChecked(row.data)"
    (change)="header.onRowChange(row.data, $event.checked)">
  </mat-checkbox>

  <ng-container *ngSwitchCase="'icon-button'">
    <button mat-icon-button color="primary" (click)="header.click(row.data)">
      <mat-icon>{{ header.icon(row.data) }}</mat-icon>
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="'readonly-text'">
    {{ header.value(row.data) }}
  </ng-container>

  <ng-container *ngSwitchCase="'context-menu-location'">
    <a (click)="header.activate(row.data)"
      class="context-menu-location">{{ header.value(row.data) }}</a>
  </ng-container>

  <mm-inline-input *ngSwitchCase="'input'"
    [type]="header.inputType"
    [value]="header.value(row.data)"
    [inputStyle]="header.inputStyle"
    (edit)="header.onEdit(row.data, $event.value)">
  </mm-inline-input>

  <ng-container *ngSwitchCase="'user-case'" [ngSwitch]="header.caseField">

    <ng-container *ngSwitchCase="'mark'">
      <div class="mark-container">
        <mm-mark-image *ngIf="row.data.caseByCaseId?.serialNumber; let serialNumber; else newApplication"
          [serialNumber]="serialNumber">
        </mm-mark-image>
      </div>
      <ng-template #newApplication>
        <span [matTooltip]="row.data.description">{{row.data.description | truncate:25}}</span>
      </ng-template>
    </ng-container>

    <ng-container *ngSwitchCase="'serial'">
      <ng-container [ngSwitch]="determineRouterLink(row.data)">
        <a *ngSwitchCase="'response'" [routerLink]="getCaseUrl(row.data)">{{ row.data.caseByCaseId?.serialNumber }}</a>
        <a *ngSwitchCase="'new-app'" [routerLink]="getCaseUrl(row.data)">Unsubmitted Application</a>
        <ng-container *ngSwitchCase="'pending'">
          {{ row.data.caseByCaseId?.serialNumber }} (analyzing)
        </ng-container>
        <span *ngSwitchCase="'permanent-failure'" [matTooltip]="row.data.status.error">
          {{ row.data.caseByCaseId?.serialNumber }}
          <mat-icon color="warn">error</mat-icon>
        </span>
      </ng-container>
    </ng-container>

    <!-- <ng-container *ngSwitchCase="'status'">
        <span [matTooltip]="row.data.status?.statusDesc">
            {{ row.data.status?.statusCode | mapDisplayValues:'tsdr-status-code' }}
        </span>
    </ng-container> -->

    <ng-container *ngSwitchCase="'status-cf'">
      <span>
          {{ row.data.caseByCaseId?.casefile?.statusCode | mapDisplayValues:'tsdr-status-code' }}
      </span>
    </ng-container>

    <ng-container *ngSwitchCase="'statusUpdated'">
        <span [matTooltip]="row.data.status?.updatedAt">
            {{ row.data.status?.updatedAt | safeDate: 'dd MMMM y, h:mm a' }}
        </span>
    </ng-container>
    
    <ng-container *ngSwitchDefault>ERROR</ng-container>

  </ng-container>

  <div *ngSwitchCase="'docket-event'"
    class="docket-event">
    <ng-container *ngIf="isCurrentEventValid(row.data)">
      <p [title]="header.what(row.data)">{{ header.what(row.data) }}</p>
      <p>{{ header.who(row.data) }}</p>
      <p>
        <mm-date [date]="header.when(row.data)"></mm-date>
      </p>
    </ng-container>
  </div>

  <ng-container *ngSwitchCase="'clearance-search-mark'">
    <mm-clearance-search-results-mark [row]="header.value(row.data)"></mm-clearance-search-results-mark>
  </ng-container>

  <ng-container *ngSwitchCase="'clearance-search-goods-services'">
    <mm-clearance-search-results-goods-services [row]="header.value(row.data)"></mm-clearance-search-results-goods-services>
  </ng-container>

  <ng-container *ngSwitchCase="'clearance-search-details'">
    <mm-clearance-search-results-details [row]="header.value(row.data)"></mm-clearance-search-results-details>
  </ng-container>

  <ng-container *ngSwitchCase="'clearance-search-owner'">
    <mm-clearance-search-results-owner [row]="header.value(row.data)"></mm-clearance-search-results-owner>
  </ng-container>

  <ng-container *ngSwitchDefault>
    ERROR
  </ng-container>

</ng-container>
