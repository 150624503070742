import { Pipe, PipeTransform } from '@angular/core';

const ordinals: string[] = ['th', 'st', 'nd', 'rd'];

const spelledOut: string[] = [
  'zeroth',
  'first',
  'second',
  'third',
  'fourth',
  'fifth',
  'sixth',
  'seventh',
  'eighth',
  'ninth',
];

@Pipe({
  name: 'ordinal'
})
export class OrdinalPipe implements PipeTransform {

  /**
   * OrdinalPipe.transform
   * Append "nd, rd, th" etc to a number
   * Usage: `42 | ordinal`
   * Result: `42nd`
   *
   * @return {string}    Merged text
   */
  transform(value: any): any {
    if (value < 10) {
      return spelledOut[value];
    }
    const v = value % 100;
    return value + (ordinals[(v - 20) % 10] || ordinals[v] || ordinals[0]);
  }
}
