import { Injectable } from '@angular/core';
import { AppState } from '@markmachine/interfaces';
import { Store, select } from '@ngrx/store';
import { environment } from 'environments/environment';
import * as LogActions from '@markmachine/features/log/actions/log.actions';

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  constructor(private store: Store<AppState>) {}

  /** Notify developers of an exception */
  developerWarning(error: Error, message?: string, title?: string) {
    if (!environment.production) {
      if (message) {
        console.log(message);
      }
      console.error(error);
    }
    this.store.dispatch(LogActions.developerWarning({
      title: title || 'Developer Warning',
      message: message || error.message,
      details: error
    }));
  }
}
