/**
 * User Case Module
 *
 * Provides state management and components for viewing and editing user cases.
 * UserCases associate a given USPTO (or new Mark Machine) Case with a particular
 * user account. They contain user-specific metadata about about a case and are
 * the primary piece of data shown in a user's case docket.
 */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UserTableModule } from '@markmachine/features/user-table/user-table.module';
import { SharedMaterialModule } from '@markmachine/shared/materials/shared-materials.module';
import { SharedModule } from '@markmachine/shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ContextMenuModule } from '../context-menu/context-menu.module';
import { ExpansionPanelHelperModule } from '../expansion-panel-helper/expansion-panel-helper.module';
import { ExpansionPanelModule } from '../expansion-panel/expansion-panel.module';
import { SupertableModule } from '../supertable/supertable.module';
import { AddButtonDialogComponent } from './components/add-button-dialog/add-button-dialog.component';
import { AddColumnDialogComponent } from './components/add-column-dialog/add-column-dialog.component';
import { AddUserCasesDialogComponent } from './components/add-user-cases-dialog/add-user-cases-dialog.component';
import { CasesContainerComponent } from './components/cases-container/cases-container.component';
import { ChosenReactionContainerComponent } from './components/chosen-reaction-container/chosen-reaction-container.component';
import { CustomColumnDialogComponent } from './components/custom-column-dialog/custom-column-dialog.component';
import { CustomEventPanelComponent } from './components/custom-event-panel/custom-event-panel.component';
import { DocketDetailsDialogComponent } from './components/docket-details-dialog/docket-details-dialog.component';
import { DocketDetailsPanelComponent } from './components/docket-details-panel/docket-details-panel.component';
import { DownloadUserCasesDialogComponent } from './components/download-user-cases-dialog/download-user-cases-dialog.component';
import { EditColumnDialogComponent } from './components/edit-column-dialog/edit-column-dialog.component';
import { EditTableCellDialogComponent } from './components/edit-table-cell-dialog/edit-table-cell-dialog.component';
import { EditTitleDialogComponent } from './components/edit-title-dialog/edit-title-dialog.component';
import { FormatColumnDialogComponent } from './components/format-column-dialog/format-column-dialog.component';
import { NewAppDialogComponent } from './components/new-app-dialog/new-app-dialog.component';
import { RemoveColumnDialogComponent } from './components/remove-column-dialog/remove-column-dialog.component';
import { UploadUserCasesDialogComponent } from './components/upload-user-cases-dialog/upload-user-cases-dialog.component';
import { UserCaseEffects } from './effects/user-case.effects';
import * as fromUserCase from './reducers/user-case.reducer';



@NgModule({
  declarations: [
    CasesContainerComponent,
    AddUserCasesDialogComponent,
    EditTableCellDialogComponent,
    DownloadUserCasesDialogComponent,
    CustomColumnDialogComponent,
    AddColumnDialogComponent,
    RemoveColumnDialogComponent,
    EditColumnDialogComponent,
    EditTitleDialogComponent,
    FormatColumnDialogComponent,
    UploadUserCasesDialogComponent,
    DocketDetailsPanelComponent,
    DocketDetailsDialogComponent,
    CustomEventPanelComponent,
    AddButtonDialogComponent,
    NewAppDialogComponent,
    ChosenReactionContainerComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    SharedMaterialModule,
    SharedModule,
    StoreModule.forFeature(fromUserCase.storeKey, fromUserCase.reducer),
    EffectsModule.forFeature([UserCaseEffects]),
    SupertableModule,
    UserTableModule,
    ExpansionPanelModule,
    ExpansionPanelHelperModule,
    ContextMenuModule
  ],
  exports: [
    CasesContainerComponent,
    CustomEventPanelComponent,
    ChosenReactionContainerComponent
  ]
})
export class UserCaseModule {}
