import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ErrorDialogComponent } from '@markmachine/features/log/components/error-dialog/error-dialog.component';
import { DIALOG_OPTIONS } from '@markmachine/core/core.config';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  constructor(private dialog: MatDialog) {}

  /** Show a dialog and return the dialogRef. */
  showDialog(message: string, details: Error, title: string): MatDialogRef<ErrorDialogComponent> {
    console.log(`Open Dialog: ${message}`);
    console.log(details);
    return this.dialog.open(ErrorDialogComponent, {
      data: { message, details, title },
      ...DIALOG_OPTIONS
    });
  }
}
