import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { outlineAnimation } from '@markmachine/core/animations';

@Component({
  selector: 'mm-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [outlineAnimation.indicatorRotation]
})
export class ExpansionPanelComponent {
  @Input() disabled = false;
  @Input() expanded = false;
  @Input() helper: 'tip' | null = null;
  @Output() expansionChange = new EventEmitter<boolean>();
  @ViewChild('expander', { read: ElementRef }) expander: ElementRef;

  constructor(protected cd: ChangeDetectorRef) {}

  toggle() {
    if (!this.disabled) {
      this.expanded = !this.expanded;
      this.expansionChange.next(this.expanded);
    }
  }

  open() {
    this.expanded = true;
    this.expansionChange.next(true);
    this.cd.markForCheck();
  }

  close() {
    this.expanded = false;
    this.expansionChange.next(false);
    this.cd.markForCheck();
  }

  focus() {
    this.expander.nativeElement.focus();
  }

  // TODO: Add animation functions for expanding and collapsing the panel
  // because it isn't possible through CSS because of auto height
}
