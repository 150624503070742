import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import * as TipActions from '@markmachine/features/tip/actions/tip.actions';
import { TipDialogComponent } from '@markmachine/features/tip/components/tip-dialog/tip-dialog.component';
import { TipNotFoundDialogComponent } from '@markmachine/features/tip/components/tip-not-found-dialog/tip-not-found-dialog.component';
import { DialogHistoryItem, getDialogHistory, getTipById, getTipIdByAnchor, getTipsByAnchors, getTipsByParentId } from '@markmachine/features/tip/reducers/tip.reducers';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Tip } from 'app/core/models/tip';
import { isEmpty } from 'lodash-es';
import { Observable, pipe } from 'rxjs';
import { filter, first, map, switchMapTo } from 'rxjs/operators';

const isSingleEmptyValue = () => pipe(
  first(),
  filter((x) => isEmpty(x))
);

@Injectable({
  providedIn: 'root'
})
export class TipService {
  constructor(private actions: Actions, private dialog: MatDialog, private store: Store) {}

  loadTipByAnchor(anchor: string): void {
    this.getTipIdByAnchor(anchor)
      .pipe(isSingleEmptyValue())
      .subscribe(() => this.store.dispatch(TipActions.LoadTipByAnchor({ anchor })));
  }
  loadTipsByAnchors(anchors: string[]): void {
    // TODO: Optimize with a single action => effect
    anchors.forEach(anchor => this.loadTipByAnchor(anchor));
  }
  loadTip(id: string): void {
    this.getTip(id)
      .pipe(isSingleEmptyValue())
      .subscribe(() => this.store.dispatch(TipActions.LoadTip({ id })));
  }
  loadTipChildren(id: string): void {
    this.getTip(id).pipe(
      filter(tip => tip?.hasChildren),
      switchMapTo(this.getTipChildren(id)),
      isSingleEmptyValue()
    )
    .subscribe(() => this.store.dispatch(TipActions.LoadTipChildren({ id })));
  }
  getTipIdByAnchor(anchor: string): Observable<string> {
    return this.store.pipe(select(getTipIdByAnchor(), { anchor }));
  }
  getTipIdsByAnchors(anchors: string[]): Observable<string[]> {
    return this.store.pipe(
      select(getTipsByAnchors(), { anchors }),
      filter(tips => !!tips.every(tip => !!tip)),
      map(tips => tips.map(tip => tip.id))
    );
  }
  getTip(id: string): Observable<Tip> {
    return this.store.pipe(select(getTipById, { id }));
  }
  getTipChildren(id: string): Observable<Tip[]> {
    return this.store.pipe(select(getTipsByParentId, { id }));
  }
  dispatchDialog(tipIds: string[]): void {
    this.store.dispatch(TipActions.DialogOpen({ tipIds }));
  }
  dispatchDialogByAnchors(anchors: string[]): void {
    this.store.dispatch(TipActions.DialogOpenByAnchors({ anchors }));
  }
  openDialog(options: MatDialogConfig<{ tipIds: string[] }>): MatDialogRef<TipDialogComponent> {
    const defaults: MatDialogConfig = {
      minWidth: '80vw',
      minHeight: '80vh'
    };
    return this.dialog.open(TipDialogComponent, { ...defaults, ...options });
  }
  dialogOpened(): Observable<string[]> {
    return this.actions.pipe(
      ofType(TipActions.DialogOpen),
      map(({ tipIds }) => tipIds)
    );
  }
  dialogClosed(): Observable<null> {
    return this.actions.pipe(ofType(TipActions.DialogClose), map(() => null));
  }
  dialogHistory(): Observable<DialogHistoryItem[]> {
    return this.store.pipe(select(getDialogHistory));
  }
  openNotFoundDialog() {
    this.dialog.open(TipNotFoundDialogComponent);
  }
}
