import { ChangeDetectionStrategy, Component, forwardRef, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * Render the value of a form control.
 *
 * Used with ReactiveForms to just display the value of a form control
 */
@Component({
  selector: 'mm-form-control-value',
  templateUrl: './form-control-value.component.html',
  styleUrls: ['./form-control-value.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    /* Provide value accessor for form control */
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlValueComponent),
      multi: true
    }
  ]
})
export class FormControlValueComponent implements ControlValueAccessor {
  disabled: boolean;
  value: any;

  constructor(private cd: ChangeDetectorRef) {}

  /*
   * Angular ControlValueAccessor Interface
   * Makes this component into a FormControl (i.e., via ngModel or formControlName)
   */

  /** Receive a value from the form model */
  writeValue(obj: any): void {
    if (obj !== this.value) {
      this.cd.markForCheck();
    }
    this.value = obj;
  }

  /** Register callback for updating the form model */
  registerOnChange(fn: any): void {
    // We never emit changes, so we don't care.
  }
  /** Register callback for notifying of a blur event */
  registerOnTouched(fn: any): void {
    // We never emit changes, so we don't care.
  }
  /** Receive disabled state from form model */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
