import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { Case } from '../models/case.model';

export const loadCases = createAction('[Case] Load Cases', props<{ cases: Case[] }>());
export const addCase = createAction('[Case] Add Case', props<{ case: Case }>());
export const upsertCase = createAction('[Case] Upsert Case', props<{ case: Case }>());
export const addCases = createAction('[Case] Add Cases', props<{ cases: Case[] }>());
export const upsertCases = createAction('[Case] Upsert Cases', props<{ cases: Case[] }>());
export const updateCase = createAction('[Case] Update Case', props<{ case: Update<Case> }>());
export const updateCases = createAction('[Case] Update Cases', props<{ cases: Update<Case>[] }>());
export const deleteCase = createAction('[Case] Delete Case', props<{ id: string }>());
export const deleteCases = createAction('[Case] Delete Cases', props<{ ids: string[] }>());
export const clearCases = createAction('[Case] Clear Cases');
/** Request the latest record for a Case. */
export const getCase = createAction('[Case] Get Case', props<{ serialNumber: number }>());
export const getCaseFailure = createAction('[Case] Get Case Failure', props<{ errorMessage: string }>());
export const pollUnanalyzed = createAction('[Case] Poll Unanalyzed', props<{ until: Date }>());
