import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as DecActions from '../actions/declaration.actions';
import { FormDeclaration } from '../models/form-declaration.model';

export const declarationFeatureKey = 'declaration';
export type LoadState = 'pristine' | 'in-flight' | 'loaded' | 'error';

export interface State extends EntityState<FormDeclaration> {
  byVersionId: {
    [versionId: string]: {
      formNames: FormDeclaration['formName'][];
      loadState: LoadState;
      error: any;
    };
  };
}

export const adapter = createEntityAdapter<FormDeclaration>({
  selectId: ({ formName }) => formName
});

export const initialState = adapter.getInitialState({ byVersionId: {} });

export const reducer = createReducer(
  initialState,

  on(DecActions.fetchDeclarationsByVersionId, ({ byVersionId, ...state }, { versionId }) => {
    const formNames = byVersionId[versionId]?.formNames ?? [];
    byVersionId = { ...byVersionId, [versionId]: { formNames, loadState: 'in-flight', error: null } };
    return { ...state, byVersionId };
  }),
  
  on(DecActions.fetchDeclarationsByVersionIdSuccess, ({ byVersionId, ...state }, { versionId, data }) => {
    const formNames = data.map(({ formName }) => formName);
    byVersionId = { ...byVersionId, [versionId]: { formNames, loadState: 'loaded', error: null } };
    return { ...adapter.addMany(data, state), byVersionId };
  }),
  
  on(DecActions.fetchDeclarationsByVersionIdFailure, ({ byVersionId, ...state }, { versionId, error }) => {
    const formNames = byVersionId[versionId]?.formNames ?? [];
    byVersionId = { ...byVersionId, [versionId]: { formNames, loadState: 'error', error } };
    return { ...state, byVersionId };
  }),
);

export const selectDeclarationState = createFeatureSelector<State>(declarationFeatureKey);
export const { selectAll, selectEntities } = adapter.getSelectors(selectDeclarationState);

export const selectVersionState = () => createSelector(selectDeclarationState, ({ byVersionId }, { versionId }) => byVersionId[versionId]);

export const selectDeclarationLoadStateByVersionId = () => createSelector(selectVersionState(), (versionState) => versionState?.loadState);

export const selectDeclarationStateByVersionId = (state: State, versionId: string) => {
  return state.byVersionId[versionId];
}

export const selectDeclarationsByVersionId = (state: State, versionId: string) => {
  const formNames = selectDeclarationStateByVersionId(state, versionId)?.formNames ?? [];
  const declarations = formNames.map(formName => state.entities[formName]);
  return declarations;
};


const selectIdsByVersionId = () => createSelector(
  selectVersionState(),
  (versionState) => versionState.formNames
);

export const selectByVersionId = () => createSelector(
  selectEntities,
  selectIdsByVersionId(),
  (entities, formNames) => formNames.map((formName: FormDeclaration['formName']) => entities[formName])
);
