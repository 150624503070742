import { UserTable } from '@markmachine/features/user-table/models/user-table.model';
import { GraphQLQuery, GraphQLResponse } from 'app/interfaces';

const graphqlQuery = `
mutation CreateUserTable($userTable: UserTableInput!){
  createUserTable(input: { userTable: $userTable }){
    userTable{
        nodeId
        userId
        key
        createdAt
        updatedAt
        title
        columns
        options
    }
  }
}
`;

export class CreateUserTableMutation implements GraphQLQuery {
  query = graphqlQuery;
  constructor(public variables: { userTable: Partial<UserTable> }) {}
}

export type CreateUserTableResponse = GraphQLResponse<{
  createUserTable: { userTable: UserTable };
}>;
