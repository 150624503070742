import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UserNoteEffects } from './effects/user-note.effects';
import * as fromUserNote from './reducers/user-note.reducer';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromUserNote.storeKey, fromUserNote.reducer),
    EffectsModule.forFeature([UserNoteEffects])
  ]
})
export class UserNoteModule { }
