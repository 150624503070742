<div [formGroup]="group"
  fxLayout="column"
  fxLayoutGap="8px">

  <p class="ongoing-efforts-text">The applicant has made the following ongoing efforts to use the mark in commerce on or in connection with each of those goods/services covered by the extension request:</p>

  <mat-checkbox *ngFor="let checkboxName of checkboxNames"
    [formControlName]="checkboxName"
    [name]="checkboxName"
    value="Yes"
    (blur)="_onTouch()">{{checkboxName}}</mat-checkbox>

  <p class="ongoing-efforts-text">Note 1:  Selections made above apply to, and will appear with, all the goods/services covered by the extension request.</p>

  <p class="ongoing-efforts-text">Note 2:  Rather than selecting one of the statements above, the applicant may satisfy the requirement for a showing of good cause by asserting (in the "Other" field) the following: "The applicant believes that it has made valid use of the mark in commerce, and is in the process of preparing (or is concurrently filing) a Statement of Use (SOU), but that if the USPTO finds the SOU to be fatally defective, the applicant will need additional time to file a new SOU." Or, in the "Other" field, the applicant may list any ongoing efforts not covered by the listed statements.</p>

  <mat-form-field appearance="outline">

    <textarea formControlName="other"
      (blur)="_onTouch()"
      matTextareaAutosize
      matInput></textarea>

    <mat-label>Other</mat-label>

  </mat-form-field>

</div>
