/**
 * Account Module
 *
 * Provides account-related services and components. This includes:
 *   - Authorization (JWT)
 *   - User Profile
 *   - User Settings
 *   - Account Pages (TODO: Move to account-routing.ts)
 */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CaseStatusModule } from '@markmachine/features/case-status/case-status.module';
import { CaseModule } from '@markmachine/features/case/case.module';
import { UserCaseModule } from '@markmachine/features/user-case/user-case.module';
import { SharedMaterialModule } from '@markmachine/shared/materials/shared-materials.module';
import { SharedModule } from '@markmachine/shared/shared.module';
import { MarkMachineTemplateModule } from '@markmachine/templates/mark-machine-template/mark-machine-template.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { VersionModule } from '../version/version.module';
import { AccountPageComponent } from './components/account-page/account-page.component';
import { FeedbackContainerComponent } from './components/feedback-container/feedback-container.component';
import { PaymentsContainerComponent } from './components/payments-container/payments-container.component';
import { PermissionsContainerComponent } from './components/permissions-container/permissions-container.component';
import { ProfileContainerComponent } from './components/profile-container/profile-container.component';
import { ReturnButtonComponent } from './components/return-button/return-button.component';
import { SignInContainerComponent } from './components/sign-in-container/sign-in-container.component';
import { SignInDialogComponent } from './components/sign-in-dialog/sign-in-dialog.component';
import { SignOutContainerComponent } from './components/sign-out-container/sign-out-container.component';
import { AccountEffects } from './effects/account.effects';
import * as fromAccount from './reducers/account.reducer';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedMaterialModule,
    SharedModule,
    CaseStatusModule,
    CaseModule,
    VersionModule,
    UserCaseModule,
    MarkMachineTemplateModule,
    StoreModule.forFeature(fromAccount.storeKey, fromAccount.reducer),
    EffectsModule.forFeature([AccountEffects])
  ],
  declarations: [
    AccountPageComponent,
    PermissionsContainerComponent,
    ProfileContainerComponent,
    FeedbackContainerComponent,
    PaymentsContainerComponent,
    SignInContainerComponent,
    SignOutContainerComponent,
    ReturnButtonComponent,
    SignInDialogComponent
  ],
  exports: [
    SignInContainerComponent,
    SignOutContainerComponent
  ]
})
export class AccountModule {}
