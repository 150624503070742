import { createFeatureSelector, createReducer, createSelector } from '@ngrx/store';
import * as fromRouter from './router.reducer';

export interface State {
  siteTitle: string;
}

export const initialState: State = {
  siteTitle: 'Mark Machine',
};

export const reducer = createReducer(
  initialState,
);

//
// Layout Selectors
//
export const storeKey = 'layout';
export const getLayoutState = createFeatureSelector<State>(storeKey);
export const getTitle = createSelector(
  getLayoutState,
  fromRouter.getRouterTitle,
  ({ siteTitle }, subtitle) => (subtitle ? `${siteTitle} — ${subtitle}` : siteTitle)
);
