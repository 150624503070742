import { reviseControls } from '@angular-prelude/forms';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GuideSelection } from '@markmachine/features/version/models/version-content.model';
import { isEqual, merge } from 'lodash-es';

const TEMPLATE = new GuideSelection();

@Injectable()
export class CaseFormGuideService {
  /** Update the FormGroup. */
  update(group: FormGroup, value: GuideSelection = TEMPLATE): void {
    if (!isEqual(group.value, value)) {
      const sanitizedValues: GuideSelection = merge({}, TEMPLATE, value);
      reviseControls(group, sanitizedValues);
    }
  }
}
