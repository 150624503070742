import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { SupertableHeader } from '../../models/header-models';
import { FeaturesModel } from '../../models/feature-models';
import { VisibilityChangeEvent } from '../../models/event-models';

@Component({
  selector: 'mm-visibility-menu',
  templateUrl: './visibility-menu.component.html',
  styleUrls: ['./visibility-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VisibilityMenuComponent implements OnInit {

  @Input() headers: SupertableHeader[];
  @Input() features: FeaturesModel;

  @Output() change = new EventEmitter<VisibilityChangeEvent>();

  constructor() { }

  ngOnInit() {
  }

  isVisible(header: SupertableHeader) {
    return !this.features?.visibility?.hiddenIds?.includes(header.id);
  }

  toggleVisible(header: SupertableHeader) {
    const hiddenIds: string[] = this.isVisible(header)
      ? [...this.features?.visibility?.hiddenIds ?? [], header.id]
      : this.features.visibility?.hiddenIds?.filter((id) => header.id !== id) ?? [];
    this.change.emit({ features: this.features, hiddenIds });
  }
}
