/**
 * Shared Module
 *
 * Shared contains code that will be used in feature modules.  It is NOT
 * imported in AppModule.  It is only imported by feature modules.
 *
 * This is the natural home of pipes, directives, and common dumb components.
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RouterEffects } from '@markmachine/core/effects/router.effects';
import { AccountToolbarButtonComponent } from '@markmachine/shared/components/account-toolbar-button/account-toolbar-button.component';
import { ConfidentialRibbonComponent } from '@markmachine/shared/components/confidential-ribbon/confidential-ribbon.component';
import { ConfirmDialogComponent } from '@markmachine/shared/components/confirm-dialog/confirm-dialog.component';
import { NotFoundComponent } from '@markmachine/shared/components/not-found/not-found.component';
import { NoticeDialogComponent } from '@markmachine/shared/components/notice-dialog/notice-dialog.component';
import { RadioClearValueDirective } from '@markmachine/shared/directives/radio-clear-value.directive';
import { SafariCheckboxDirective } from '@markmachine/shared/directives/safari-checkbox.directive';
import { ScrollIntoViewDirective } from '@markmachine/shared/directives/scroll-into-view.directive';
import { SharedMaterialModule } from '@markmachine/shared/materials/shared-materials.module';
import { CommaListPipe } from '@markmachine/shared/pipes/comma-list.pipe';
import { CountryCodePipe } from '@markmachine/shared/pipes/country-code.pipe';
import { MapDisplayValuesPipe } from '@markmachine/shared/pipes/map-display-values.pipe';
import { MarkdownPipe } from '@markmachine/shared/pipes/markdown.pipe';
import { MustachePipe } from '@markmachine/shared/pipes/mustache.pipe';
import { OrdinalPipe } from '@markmachine/shared/pipes/ordinal.pipe';
import { PrettyprintPipe } from '@markmachine/shared/pipes/prettyprint.pipe';
import { SanitizeHtmlPipe } from '@markmachine/shared/pipes/sanitize-html.pipe';
import { SanitizeUrlPipe } from '@markmachine/shared/pipes/sanitize-url.pipe';
import { SortByKeyPipe } from '@markmachine/shared/pipes/sort-by-key.pipe';
import { TruncatePipe } from '@markmachine/shared/pipes/truncate.pipe';
import { EffectsModule } from '@ngrx/effects';
import { ClassBadgeComponent } from './components/class-badge/class-badge.component';
import { ClearValueButtonComponent } from './components/clear-value-button/clear-value-button.component';
import { ContentContainerComponent } from './components/content-container/content-container.component';
import { DateComponent } from './components/date/date.component';
import { FormControlValueComponent } from './components/form-control-value/form-control-value.component';
import { HomepageBannerComponent } from './components/homepage-banner/homepage-banner.component';
import { LoadFailureDialogComponent } from './components/load-failure-dialog/load-failure-dialog.component';
import { MarkImageComponent } from './components/mark-image/mark-image.component';
import { Tm5IconComponent } from './components/tm5-icon/tm5-icon.component';
import { DefaultImageDirective } from './directives/default-image.directive';
import { SaveOnEnterDirective } from './directives/save-on-enter.directive';
import { DefaultPipe } from './pipes/default.pipe';
import { HighlightTextPipe } from './pipes/highlight-text.pipe';
import { IsEmptyPipe } from './pipes/is-empty.pipe';
import { NotPipe } from './pipes/not.pipe';
import { SafeDatePipe } from './pipes/safe-date.pipe';
import { ValidationErrorMessagePipe } from './pipes/validation-error-message.pipe';
import { FormControlElementRefDirective } from './directives/form-control-element-ref.directive';
import { ControlValueAndChangesPipe } from './pipes/control-value-and-changes.pipe';


const COMPONENTS = [
  NotFoundComponent,
  ConfidentialRibbonComponent,
  AccountToolbarButtonComponent,
  ConfirmDialogComponent,
  NoticeDialogComponent,
  ClearValueButtonComponent,
  Tm5IconComponent,
  ClassBadgeComponent,
  MarkImageComponent,
  ContentContainerComponent,
  DateComponent,
  FormControlValueComponent,
  HomepageBannerComponent,
  LoadFailureDialogComponent
];

const PIPES = [
  CommaListPipe,
  CountryCodePipe,
  MapDisplayValuesPipe,
  MarkdownPipe,
  MustachePipe,
  OrdinalPipe,
  PrettyprintPipe,
  SanitizeHtmlPipe,
  SanitizeUrlPipe,
  SortByKeyPipe,
  TruncatePipe,
  HighlightTextPipe,
  DefaultPipe,
  NotPipe,
  IsEmptyPipe,
  SafeDatePipe,
  ValidationErrorMessagePipe,
  ControlValueAndChangesPipe,
];

const DIRECTIVES = [
  RadioClearValueDirective,
  ScrollIntoViewDirective,
  SafariCheckboxDirective,
  DefaultImageDirective,
  SaveOnEnterDirective,
  FormControlElementRefDirective
];

const PROVIDERS = [
  CountryCodePipe
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedMaterialModule,
    EffectsModule.forFeature([RouterEffects]),
    ReactiveFormsModule
  ],
  declarations: [...COMPONENTS, ...PIPES, ...DIRECTIVES],
  exports: [...COMPONENTS, ...PIPES, ...DIRECTIVES],
  providers: [...PROVIDERS]
})
export class SharedModule {}
