import { Injectable } from '@angular/core';
import { getFlagDeveloperWarnings } from '@markmachine/features/account/reducers/account.reducer';
import * as LogActions from '@markmachine/features/log/actions/log.actions';
import { LogService } from '@markmachine/features/log/services/log.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, tap, withLatestFrom } from 'rxjs/operators';


@Injectable()
export class LogEffects {

  DeveloperWarning$ = createEffect(() => this.actions$.pipe(
    ofType(LogActions.developerWarning),
    // Are developer warnings enabled?
    withLatestFrom(this.showDeveloperWarnings),
    filter(([_, showDeveloperWarnings]) => !!showDeveloperWarnings),
    // If so, show dialog.
    tap(([{ title, message, details }, _]) => this.service.showDialog(message, details, title)),
  ), { dispatch: false });

  UserAlert$ = createEffect(() => this.actions$.pipe(
    ofType(LogActions.userAlert),
    tap(({ title, message, details }) => this.service.showDialog(message, details, title))
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private service: LogService,
    private store: Store
  ) { }

  get showDeveloperWarnings(): Observable<boolean> {
    return this.store.pipe(select(getFlagDeveloperWarnings));
  }
}
