<p>Request an extension for each of the below classes with an Intent-to-Use basis:</p>

<blockquote>
  <b>For a trademark or service mark:</b> The applicant has a continued bona fide intention, and is entitled, to use the
  mark in commerce on or in connection with all of the goods/services listed in the Notice of Allowance or as
  subsequently modified for this specific class; <b>for a collective/certification mark:</b> the applicant has a continued bona
  fide intention, and is entitled, to exercise legitimate control over the use of the mark in commerce on or in
  connection with the goods/services/collective membership organization listed in the Notice of Allowance, or as
  subsequently modified for this specific class.
</blockquote>

<ng-container [formGroup]="statements"
  [ngSwitch]="isOngoingEffortsRequired">

  <h2>Ongoing Efforts</h2>

  <p *ngSwitchCase="false">A statement of ongoing efforts is not required at this time.</p>
  
  <mm-ongoing-efforts *ngSwitchCase="true"
    formControlName="ongoing-efforts"></mm-ongoing-efforts>
  
  <h2>Related Statement of Use</h2>

  <mat-checkbox formControlName="extension-request-insurance"
    name="extension-request-insurance"
    value="Yes">
    A Statement of Use has been or is being submitted with this Extension request.
  </mat-checkbox>

</ng-container>

<div *ngFor="let cls of classGroups; let index=index"
  [formGroup]="cls"
  fxLayout="column"
  fxLayoutAlign="stretch">

  <h2 fxLayout="row">
    <span fxFlex="auto">Class {{cls.get('class-code').value | number}}</span>
    <button mat-stroked-button
      fxFlex="none"
      (click)="delete(index)"
      color="warn">DELETE CLASS</button>
  </h2>

  <mat-form-field appearance="outline">
    <mat-label>Listing of Goods/Services</mat-label>
    <textarea formControlName="listing"
      matTextareaAutosize
      matAutosizeMinRows="3"
      matInput></textarea>
  </mat-form-field>

</div>
