import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { FeaturesModel } from '../../models/feature-models';
import { PageEvent } from '@angular/material/paginator';
import { PaginatorChangeEvent } from '../../models/event-models';

@Component({
  selector: 'mm-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginatorComponent implements OnInit {

  @Input() features: FeaturesModel;
  @Input() length: number;

  @Output() page = new EventEmitter<PaginatorChangeEvent>();

  constructor() { }

  ngOnInit() {
  }

  onPage(event: PageEvent) {
    this.page.emit({ features: this.features, pageEvent: event });
  }
}
