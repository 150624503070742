import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { controlValueAndChanges } from '@markmachine/core/functions/controlValueAndChanges';
import { combineLatest, Observable } from 'rxjs';

type Path = Parameters<AbstractControl['get']>[0];

@Pipe({
  name: 'controlValueAndChanges',
})
export class ControlValueAndChangesPipe implements PipeTransform {
  transform(control: AbstractControl, path?: Path): Observable<unknown> {
    if (path === undefined) {
      return controlValueAndChanges(control);
    }
    return controlValueAndChanges(control.get(path));
    // TODO: do it for multiple paths
    // return combineLatest(paths.map((path) => controlValueAndChanges(value.get(path))));
  }
}
