/**
 * Log Module
 *
 * Provides visual feedback for log actions.
 */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LogEffects } from '@markmachine/features/log/effects/log.effects';
import { SharedMaterialModule } from '@markmachine/shared/materials/shared-materials.module';
import { EffectsModule } from '@ngrx/effects';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { ErrorDialogRedirectComponent } from './components/error-dialog-redirect/error-dialog-redirect.component';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([LogEffects]),
    SharedMaterialModule
  ],
  declarations: [
    ErrorDialogComponent,
    ErrorDialogRedirectComponent
  ]
})
export class LogModule {}
