import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { InputOptionService } from '@markmachine/features/case/services/input-option.service';
import { Class } from '@markmachine/features/version/models/version-content.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'mm-basis-44d-edit',
  templateUrl: './basis-44d-edit.component.html',
  styleUrls: ['./basis-44d-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Basis44dEditComponent implements OnInit {
  @Input() group: FormGroup;
  required$: Observable<boolean>;

  constructor(private cd: ChangeDetectorRef, public options: InputOptionService) {}

  ngOnInit() {
    this.required$ = this.group.valueChanges.pipe(map((value: Class) => !!value['filing-basis-current-44d-in']));
  }
}
