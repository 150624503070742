<button mat-icon-button
  [matMenuTriggerFor]="expansionMenu"
  class="action">
  <mat-icon [class.active]="active">more_vert</mat-icon>
</button>

<mat-menu #expansionMenu="matMenu">
  <ng-template matMenuContent>
    <ng-content></ng-content>
    <mm-context-menu-content></mm-context-menu-content>
  </ng-template>
</mat-menu>