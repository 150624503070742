import { Version } from '@markmachine/features/version/models/version.model';
import { versionMetadataFragmentFields } from '@markmachine/features/version/services/operations/version-metadata.fragment';
import { GraphQLQuery, GraphQLResponse } from 'app/interfaces';

const query = `
mutation VersionUpdate($caseVersionId: UUID!, $caseVersionPatch: CaseVersionPatch!) {
  updateCaseVersionById(input: { id: $caseVersionId, caseVersionPatch: $caseVersionPatch }) {
    caseVersion {
      content
      ${versionMetadataFragmentFields}
    }
  }
}
`;

export class UpdateVersionMutation implements GraphQLQuery {
  query = query;

  constructor(public variables: { caseVersionId: string; caseVersionPatch: Partial<Version> }) {}
}

export type UpdateVersionResponse = GraphQLResponse<{
  updateCaseVersionById: {
    caseVersion: Version;
  };
}>;
