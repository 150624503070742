import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CaseStatusesByCaseIdsQuery,
  CaseStatusesByCaseIdsResponse
} from '@markmachine/features/case-status/services/operations/case-statuses-by-case-ids.query';
import { unpackGraphqlResponse } from '@markmachine/core/graphql-operators';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { CaseStatus } from '../models/status.model';
import { CaseStatusesQuery, CaseStatusesResponse } from './operations/case-statuses.query';
import { RefreshCaseStatusesMutation, RefreshCaseStatusesResponse } from './operations/refresh-case-statuses.mutation';

const { GRAPHQL } = environment;

@Injectable({
  providedIn: 'root'
})
export class StatusRequestService {
  constructor(private http: HttpClient) { }

  /** Fetch current case statuses and request refreshes */
  refreshStatuses(caseIds: string[]): Observable<CaseStatus[]> {
    return this.http
      .post<RefreshCaseStatusesResponse>(GRAPHQL, new RefreshCaseStatusesMutation({ caseIds, withContent: true }))
      .pipe(unpackGraphqlResponse('data.refreshCaseStatusesByCaseIds.caseStatuses'));
  }

  getStatusesBySerialNumbers(serialNumbers: number[]): Observable<CaseStatus[]> {
    return this.http
      .post<CaseStatusesResponse>(GRAPHQL, new CaseStatusesQuery({ serialNumbers, withContent: true }))
      .pipe(unpackGraphqlResponse('data.allCaseStatuses.nodes'));
  }

  getStatusesByCaseIds(caseIds: string[]): Observable<CaseStatus[]> {
    return this.http
      .post<CaseStatusesByCaseIdsResponse>(GRAPHQL, new CaseStatusesByCaseIdsQuery({ caseIds, withContent: true }))
      .pipe(unpackGraphqlResponse('data.caseStatusesByCaseIds.nodes'));
  }
}
