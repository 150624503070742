import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'mm-context-menu-icon-button',
  templateUrl: './context-menu-icon-button.component.html',
  styleUrls: ['./context-menu-icon-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContextMenuIconButtonComponent implements OnInit {
  @Input() active = false;

  constructor() { }

  ngOnInit() {
  }

}
