import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  forwardRef,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CaseFileService } from '@markmachine/features/case-file/services/case-file.service';
import { CaseFile } from '@markmachine/features/case/models/file.model';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { FileListEditComponent } from '../case-file-list-edit/case-file-list-edit.component';
import { isArray, isEqual, isNull } from 'lodash-es';

class CaseFileControlValueError {
  message = 'Unexpected value type';
  constructor(message: string) {
    this.message = message;
  }
}

/**
 * Provide a Form Control for files that uploads files to
 * for a case.
 *
 * Adapts Form Control Value Accessor interface to the
 * NGRX store and Case-specific store interface.
 *
 */
@Component({
  selector: 'mm-case-file-control',
  templateUrl: './case-file-control.component.html',
  styleUrls: ['./case-file-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    /* Provide value accessor for form control */
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CaseFileControlComponent),
      multi: true
    }
  ]
})
export class CaseFileControlComponent implements ControlValueAccessor, OnChanges, OnDestroy, OnInit {
  @Input() multiple = true;
  @ViewChild(FileListEditComponent) fileList: FileListEditComponent;

  private _onUploadedSub: Subscription;
  private _onChangeSub: Subscription;

  casefiles$: Observable<CaseFile[]>; // CaseFiles matching _fileIds and _pendingFiles
  private _fileIds = new BehaviorSubject<string[]>([]); // File ids passed to FormControl
  private _pendingFiles: File[] = []; // Files uploaded from this component

  private _isDisabled: boolean; // ControlValueAccessor
  private _onTouched: any; // ControlValueAccessor
  private _onChange: any; // ControlValueAccessor

  constructor(private _fileService: CaseFileService) {}

  get disabled() {
    return this._isDisabled;
  }

  /** Open the native file choose dialog */
  chooseFiles() {
    this.fileList.chooseFiles();
  }

  /**
   * Request a CaseFile be deleted from a case
   * @param idCaseFile `id` field of CaseFile to delete
   */
  deleteFile(idCaseFile: string): void {
    this._onTouched();
    this._fileService.deleteFile(idCaseFile);
  }

  /**
   * Remove a CaseFile from a case
   * @param idCaseFile `id` field of a CaseFile to remove from a case
   */
  removeFile(idCaseFile: string): void {
    this._onTouched();
    const oldIds = this._fileIds.value;
    const newIds = oldIds.filter(id => id !== idCaseFile);
    this._fileIds.next(newIds);
  }

  /**
   * Upload one or more files
   * @param newFiles FileList, provided by <input type="file">
   */
  uploadFiles(newFiles: FileList): void {
    this._onTouched(); // Touch
    for (let i = 0; i < newFiles.length; i++) {
      this._pendingFiles.push(newFiles[i]);
    }
    this._fileService.uploadFiles(newFiles);
  }

  /*
   * Angular ControlValueAccessor Interface
   * Makes this component into a FormControl (i.e., via ngModel or formControlName)
   */

  /** Receive a value from the form model */
  writeValue(newValue: string[]): void {
    if (isEqual(newValue, this._fileIds.value)) {
      return;
    }
    if (isNull(newValue)) {
      newValue = [];
    }
    if (!isArray(newValue)) {
      throw new CaseFileControlValueError(`Expected array but saw ${newValue}`);
    }
    this._fileIds.next(newValue);
  }

  /** Register callback for updating the form model */
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  /** Register callback for notifying of a blur event */
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  /** Receive disabled state from form model */
  setDisabledState?(_isDisabled: boolean): void {
    this._isDisabled = _isDisabled;
  }

  /*
   * Private methods
   */

  /** Emit an id for each successfully completed upload */
  private _completedPendingUploads(): Observable<string> {
    return this._fileService
      .completedUploadActions()
      .pipe(
        filter(action => this._pendingFiles.includes(action.file)),
        tap(action => (this._pendingFiles = this._pendingFiles.filter(fs => fs !== action.file))),
        map(action => action.casefile.id)
      );
  }

  /** OnChanges - Angular Lifecycle Hook */
  ngOnChanges(changes: SimpleChanges): void {}

  /** OnInit - Angular Lifecycle Hook */
  ngOnInit() {
    // Create an observable of casefiles, including refreshes after form control changes
    this.casefiles$ = combineLatest([this._fileService.casefiles(), this._fileIds]).pipe(
      map(([cfs, fileIds]) => {
        if (!isArray(cfs) || !isArray(fileIds)) {
          console.log(`ERROR: Expected array for case-file-control form control value`);
          return [];
        }
        return cfs.filter(cf => (fileIds || []).includes(cf.id) || this._pendingFiles.includes(cf.file as File));
      })
    );

    // After a pending upload completes, append it to the component's fileIds
    this._onUploadedSub = this._completedPendingUploads().subscribe(id => {
      if (this._onChange) {
        const newFileIds = [...this._fileIds.value, id];
        this._fileIds.next(newFileIds);
      }
    });

    // Notify form control's host of changes to file list
    this._onChangeSub = this._fileIds.pipe(filter(() => !!this._onChange), distinctUntilChanged()).subscribe(ids => {
      if (this._onChange) {
        this._onChange(ids);
      }
      if (this._onTouched) {
        this._onTouched();
      }
    });
  }

  /** OnDestroy - Angular Lifecycle Hook */
  ngOnDestroy() {
    this._onUploadedSub.unsubscribe();
    this._onChangeSub.unsubscribe();
  }
}
