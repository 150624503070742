import { Component, OnInit, EventEmitter, ChangeDetectionStrategy, Input, Output } from '@angular/core';
import { InputEditEvent } from '../../models/event-models';
import { InputStyle } from '../../models/header-models';

@Component({
  selector: 'mm-inline-input',
  templateUrl: './inline-input.component.html',
  styleUrls: ['./inline-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InlineInputComponent {

  badInput = false;

  @Input() type: string;
  @Input() value: any;
  @Input() inputStyle: InputStyle;

  @Output() edit = new EventEmitter<InputEditEvent>();

  onTextChange(event) {
    this.edit.emit({ value: event.target.value });
  }

  onDateChange(event) {
    if (!event.value) {
      this.badInput = true;
    } else {
      this.badInput = false;
      this.edit.emit({ value: event.value });
    }
  }

  getInputClass() {
    return {
      'justify-left': this.inputStyle && this.inputStyle.justify === 'left',
      'justify-center': this.inputStyle && this.inputStyle.justify === 'center',
      'justify-right': this.inputStyle && this.inputStyle.justify === 'right',
      'bad-input': this.badInput
    };
  }
}
