<h2 mat-dialog-title>Allege Use</h2>

<mat-dialog-content class="mat-typography"
  fxLayout="column">

  <mm-allege-use-form [root]="root"></mm-allege-use-form>

</mat-dialog-content>

<mat-dialog-actions fxLayoutAlign="flex-end">

  <button mat-button
    mat-dialog-close
    (click)="remove()"
    color="warn"
    matTooltip="Remove the Allegation of Use">REMOVE ALLEGATION</button>

  <button mat-button
    mat-dialog-close
    matTooltip="Accept the Allegation of Use">DONE</button>

</mat-dialog-actions>