import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ContextMenuItem } from '../../models/context-menu-item.model';
import { ContextMenuService } from '../../services/context-menu.service';

@Component({
  selector: 'mm-context-menu-item',
  templateUrl: './context-menu-item.component.html',
  styleUrls: ['./context-menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContextMenuItemComponent {
  @Input() menuItem: ContextMenuItem;
  @Input() disabled = false;

  constructor(private service: ContextMenuService) {}

  activate() {
    this.service.activate(this.menuItem);
  }
}
