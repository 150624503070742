/**
 * Implementation based on ankitgrover's gist
 * https://gist.github.com/adamrecsko/0f28f474eca63e0279455476cc11eca7
 **/
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'highlightText' })
export class HighlightTextPipe implements PipeTransform {
  /* use this for single match search */
  static SINGLE_MATCH = 'Single-Match';
  /* use this for single match search with a restriction that target should start with search string */
  static SINGLE_AND_STARTS_WITH_MATCH = 'Single-And-StartsWith-Match';
  /* use this for global search */
  static MULTI_MATCH = 'Multi-Match';

  constructor(private domSanitizer: DomSanitizer) {}
  transform(
    data: string,
    highlightText: string,
    option: string = 'Multi-Match',
    caseSensitive: boolean = false
  ): SafeHtml {
    if (highlightText && data && option) {
      let regex: any = '';
      const caseFlag: string = !caseSensitive ? 'i' : '';
      highlightText = this.escape(highlightText);
      switch (option) {
        case 'Single-Match': {
          regex = new RegExp(highlightText, caseFlag);
          break;
        }
        case 'Single-And-StartsWith-Match': {
          regex = new RegExp('^' + highlightText, caseFlag);
          break;
        }
        case 'Multi-Match': {
          regex = new RegExp(highlightText, 'g' + caseFlag);
          break;
        }
        default: {
          // default will be a global case-insensitive match
          regex = new RegExp(highlightText, 'gi');
        }
      }
      const rawHTML = data.replace(
        regex,
        match => `<span style="background: rgba(250, 250, 210, .75);
        border-radius: 25%;">${match}</span>`
      );
      return this.domSanitizer.bypassSecurityTrustHtml(rawHTML);
    } else {
      return data;
    }
  }

  escape(highlightText: string): string {
    return highlightText.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  }
}
