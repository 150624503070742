<h2 mat-dialog-title>Sorry for the inconvenience</h2>

<mat-dialog-content class="mat-typography">
  <p>
    Your case isn't accessible at the moment.  Please try again in a few minutes.
  </p>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row-reverse">
  <button mat-button [mat-dialog-close]="exitData">CANCEL</button>
</mat-dialog-actions>
