import { createEntityAdapter, Dictionary, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { groupBy, sortBy } from 'lodash-es';
import * as GuideWidgetActions from '../actions/guide-widget.actions';
import { GuideWidget } from '../models/guide-widget.model';

export interface State extends EntityState<GuideWidget> {
  // additional entities state properties
}

export const adapter: EntityAdapter<GuideWidget> = createEntityAdapter<GuideWidget>({
  selectId: ({ id }) => id
});

export const initialState: State = adapter.getInitialState({
  // additional entity state properties
});

export const reducer = createReducer(
   initialState,

    on(GuideWidgetActions.addGuideWidget,
      GuideWidgetActions.createNewGuideWidget,
      (state, action) => adapter.addOne(action.guideWidget, state)),

    on(GuideWidgetActions.upsertGuideWidget,
      (state, action) => adapter.upsertOne(action.guideWidget, state)),

    on(GuideWidgetActions.addGuideWidgets,
      (state, action) => adapter.addMany(action.guideWidgets, state)),

    on(GuideWidgetActions.upsertGuideWidgets,
      (state, action) => adapter.upsertMany(action.guideWidgets, state)),

    on(GuideWidgetActions.updateGuideWidget,
      (state, action) => adapter.updateOne(action.guideWidget, state)),

    on(GuideWidgetActions.updateGuideWidgets,
      (state, action) => adapter.updateMany(action.guideWidgets, state)),

    on(GuideWidgetActions.deleteGuideWidget,
      (state, action) => adapter.removeOne(action.id, state)),

    on(GuideWidgetActions.deleteGuideWidgets,
      (state, action) => adapter.removeMany(action.ids, state)),

    on(GuideWidgetActions.loadGuideWidgets,
      (state, action) => adapter.setAll(action.guideWidgets, state)),

    on(GuideWidgetActions.clearGuideWidgets,
      (state) => adapter.removeAll(state)),

);

export const storeKey = 'guide-widget';
export const getGuideWidgetState = createFeatureSelector<State>(storeKey);
export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors(getGuideWidgetState);

type GuideWidgetByGuideId = Record<GuideWidget['idReply'], GuideWidget[]>;

/** Lens for selecting widgets by guide id. */
export const getGuideWidgetsGroup = createSelector(
  selectAll,
  widgets => groupBy(sortBy(widgets, ['page', 'order']), w => w.idReply) as GuideWidgetByGuideId
);

/** Select widgets for a specific guide. */
export const getWidgetsByReplyId = () => createSelector(
  getGuideWidgetsGroup,
  (widgets: GuideWidgetByGuideId, { idReply }: { idReply: string }) => widgets[idReply] ?? []
);
