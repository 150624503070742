import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogRedirectComponent } from '@markmachine/features/log/components/error-dialog-redirect/error-dialog-redirect.component';
import * as Sentry from '@sentry/angular';
import { Subject } from 'rxjs';
import { exhaustMap } from 'rxjs/operators';
import { DIALOG_OPTIONS } from '../core.config';


/**
 * Capture errors; send to Sentry.
 *
 * Dervied from:
 * https://github.com/getsentry/sentry-javascript/blob/57423eedba5fc4cae72bea885fffe1bf10b5f264/packages/angular/src/errorhandler.ts#L24
 */
@Injectable({ providedIn: 'root' })
export class SentryErrorHandlerService extends ErrorHandler implements ErrorHandler {
  readonly #options: Sentry.ErrorHandlerOptions = {
    logErrors: true,
    showDialog: true,
    dialogOptions: {
      ...DIALOG_OPTIONS,
      backdropClass: 'mm-backdrop',
      closeOnNavigation: false,
      disableClose: false,
    }
  };

  // Emit options for opening new report dialogs
  readonly #dialogOptions = new Subject();

  // Open new report dialogs
  readonly #dialogEffect = this.#dialogOptions
    .asObservable()
    // Ignore additional events received before dialog is closed
    .pipe(exhaustMap((dialogOptions) => this.dialog.open(ErrorDialogRedirectComponent, dialogOptions).afterClosed()))
    .subscribe();

  constructor(private dialog: MatDialog) {
    super();
  }

  extractError(error: unknown) {
    // Try to unwrap zone.js error. **PRIVATE API**
    // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
    const ERROR_ORIGINAL_ERROR = 'ngOriginalError';
    error = error?.[ERROR_ORIGINAL_ERROR] ?? error;

    // We can handle messages and Error objects directly.
    if (typeof error === 'string' || error instanceof Error) {
      return error;
    }

    // If it's http module error, extract as much information from it as we can.
    if (error instanceof HttpErrorResponse) {
      // The `error` property of http exception can be either an `Error` object, which we can use directly...
      if (error.error instanceof Error) {
        return error.error;
      }

      // ... or an`ErrorEvent`, which can provide us with the message but no stack...
      if (error.error instanceof ErrorEvent && error.error.message) {
        return error.error.message;
      }

      // ...or the request body itself, which we can use as a message instead.
      if (typeof error.error === 'string') {
        return `Server returned code ${error.status} with body "${error.error}"`;
      }

      // If we don't have any detailed information, fallback to the request message itself.
      return error.message;
    }

    // Nothing was extracted, fallback to default error message.
    return null;
  }

  handleError(error) {
    const extractedError = this.extractError(error) || 'Handled unknown error';

    // Capture handled exception and send it to Sentry.
    const eventId = Sentry.captureException(extractedError);
    if (this.#options.showDialog) {
      // Sentry.showReportDialog({ ...this.#options.dialogOptions, eventId });
      this.showReportDialog({ data: { eventId } });
    }

    if (!this.#options.logErrors) {
      // Let Angular's ErrorHandler handle the error, which just logs to console.error.
      super.handleError(error);
    }
  }

  showReportDialog(dialogOptions) {
    this.#dialogOptions.next({
      ...this.#options.dialogOptions,
      ...dialogOptions,
      data: {
        ...this.#options.dialogOptions.data,
        ...dialogOptions?.data,
      }
    });
  }
}
