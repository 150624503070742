import { AuthenticatedUserProfile } from '@markmachine/features/account/models/user-profile.model';
import { GraphQLQuery, GraphQLResponse } from 'app/interfaces';

export class AuthorizeUserQuery implements GraphQLQuery {
  query = `
  mutation AuthenticateUser($email: Email!, $password: String!) {
    authenticate(input: {email: $email, password: $password}) {
      jwtToken
      query {
        currentProfile {
          userId
          flags
        }
      }
    }
  }
  `;
  constructor(public variables: { email: string, password: string }) { }
}

export type AuthorizeUserResponse = GraphQLResponse<{
  authenticate: {
    jwtToken: string;
    query: {
      currentProfile: AuthenticatedUserProfile
    };
  };
}>;
