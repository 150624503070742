/**
 * Main entry point to the module system. This module is bootstrapped from `main.ts`.
 *
 * This module imports Angular Core and sets up root-only imports.
 *
 * For the rest of the Mark Machine application setup, see `core.module.ts`.
 */
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { CustomRouterStateSerializer } from '@markmachine/core/reducers/custom-router-state-serializer';
import { ReactiveComponentModule } from '@ngrx/component';
import { EffectsModule } from '@ngrx/effects';
import * as fromRouterStore from '@ngrx/router-store';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as Sentry from '@sentry/angular';
import { AppComponent } from './app.component';
import { reducers } from './app.reducers';
import { routes } from './app.routes';
import { CoreModule } from './core/core.module';
import * as fromLayout from './core/reducers/layout.reducer';
import * as fromRouter from './core/reducers/router.reducer';
import { SentryErrorHandlerService } from './core/services/sentry-error-handler.service';
import { JwtTokenInterceptor } from './features/account/services/token.interceptor';
import { SharedRoutingModule } from './shared/shared-routing.module';
import * as teasPageStateReducer from './views/teas-routing/reducers/state.reducer';
import { environment } from 'environments/environment';


const SENTRY_PROVIDERS = [
  { provide: ErrorHandler, useClass: SentryErrorHandlerService },
  { provide: Sentry.TraceService, deps: [Router] },
  { provide: APP_INITIALIZER, useFactory: () => () => {}, deps: [Sentry.TraceService], multi: true },
];


@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    // @angular Core
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    // @angular Libraries
    FlexLayoutModule,

    // @ngrx
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    StoreModule.forFeature(fromLayout.storeKey, fromLayout.reducer),
    StoreModule.forFeature(fromRouter.storeKey, fromRouterStore.routerReducer),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 50,
      actionSanitizer: teasPageStateReducer.actionSanitizer,
      stateSanitizer: teasPageStateReducer.stateSanitizer,
    }),
    ReactiveComponentModule,

    // @markmachine
    CoreModule,

    // @markmachine/routes
    RouterModule.forRoot(routes, { anchorScrolling: 'enabled', onSameUrlNavigation: 'reload', scrollPositionRestoration: 'enabled' }),
    SharedRoutingModule,

    // @ngrx: After Routes
    StoreRouterConnectingModule.forRoot({ serializer: CustomRouterStateSerializer })
  ],
  providers: [
    // @sentry
    ...(environment.SENTRY_DSN ? SENTRY_PROVIDERS : []),

    // @angular/components
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline', floatLabel: 'always' } },

    // @markmachine
    { provide: HTTP_INTERCEPTORS, useClass: JwtTokenInterceptor, multi: true },
  ]
})
export class AppModule {}
