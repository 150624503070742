import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { GoodService } from '../models/good-service.model';

export const loadGoodServices = createAction(
  '[GoodServiceSelector] LoadGoodServices',
  props<{ payload: { goodServices: GoodService[] }}>()
);

export const addGoodService = createAction(
  '[GoodServiceSelector] AddGoodService',
  props<{ payload: { goodService: GoodService }}>()
);

export const upsertGoodService = createAction(
  '[GoodServiceSelector] UpsertGoodService',
  props<{ payload: { goodService: GoodService }}>()
);

export const addGoodServices = createAction(
  '[GoodServiceSelector] AddGoodServices',
  props<{ payload: { goodServices: GoodService[] }}>()
);

export const upsertGoodServices = createAction(
  '[GoodServiceSelector] UpsertGoodServices',
  props<{ payload: { goodServices: GoodService[] }}>()
);

export const updateGoodService = createAction(
  '[GoodServiceSelector] UpdateGoodService',
  props<{ payload: { goodService: Update<GoodService> }}>()
);

export const updateGoodServices = createAction(
  '[GoodServiceSelector] UpdateGoodServices',
  props<{ payload: { goodServices: Update<GoodService>[] }}>()
);

export const deleteGoodService = createAction(
  '[GoodServiceSelector] DeleteGoodService',
  props<{ payload: { id: string }}>()
);

export const deleteGoodServices = createAction(
  '[GoodServiceSelector] DeleteGoodServices',
  props<{ payload: { ids: string[] }}>()
);

export const clearGoodServices = createAction(
  '[GoodServiceSelector] ClearGoodServices',
);

export const SearchGoodServices = createAction(
  '[GoodServiceSelector] SearchGoodServices',
  props<{ payload: { searchTerm: string }}>()
);

export const searchGoodServicesError = createAction(
  '[GoodServiceSelector] SearchGoodServicesError',
);

export const selectGoodService = createAction(
  '[GoodServiceSelector] SelectGoodService',
  props<{ payload: { selectedGoodService: GoodService }}>()
);

export const deselectGoodService = createAction(
  '[GoodServiceSelector] DeselectGoodService',
  props<{ payload: { selectedGoodService: GoodService }}>()
);

export const deselectAllGoodsServices = createAction(
  '[GoodServiceSelector] DeselectAllGoodsServices',
);

export const openGoodServiceDialog = createAction(
  '[GoodServiceSelector] OpenGoodServiceDialog',
  props<{ payload: { isUnfiled: boolean } }>()
);

export const closeGoodServiceDialog = createAction(
  '[GoodServiceSelector] CloseGoodServiceDialog',
);

export const goodServiceDialogSuccess = createAction(
  '[GoodServiceSelector] GoodServiceDialogSuccess',
  props<{ payload: { selectedGoodsServices: GoodService[] }}>()
);
