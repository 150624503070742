<mm-expansion-panel [formGroup]="formGroup"
  [expanded]="expanded"
  (expansionChange)="expansionChange.next(note.id)">

  <mm-expansion-panel-title>{{ note.createdAt | safeDate:'dd MMM y hh:mm a' }}</mm-expansion-panel-title>

  <mm-expansion-panel-actions>
    <button mat-icon-button>
      <mat-icon>link</mat-icon>
    </button>
    <button mat-icon-button
      (click)="removeNote(note.id)">
      <mat-icon>remove_circle_outline</mat-icon>
    </button>
  </mm-expansion-panel-actions>

  <!-- We need to use a form control instead of just setting the value in order for matInput to track changes. -->
  <mat-form-field>
    <textarea matInput
      formControlName="note"></textarea>
  </mat-form-field>

</mm-expansion-panel>