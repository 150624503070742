/**
 * Header Modules
 *
 * Provides site-wide header components.
 */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedMaterialModule } from '@markmachine/shared/materials/shared-materials.module';
import { SharedModule } from '@markmachine/shared/shared.module';
import { AccountToolsComponent } from './components/account-tools/account-tools.component';
import { HeaderComponent } from './components/header/header.component';
import { LogoComponent } from './components/logo/logo.component';
import { MobileAccountToolsComponent } from './components/mobile-account-tools/mobile-account-tools.component';
import { MobileNavMenuComponent } from './components/mobile-nav-menu/mobile-nav-menu.component';
import { NavItemComponent } from './components/nav-item/nav-item.component';
import { ServicesNavListComponent } from './components/services-nav-list/services-nav-list.component';
import { SiteSearchComponent } from './components/site-search/site-search.component';

@NgModule({
  declarations: [
    HeaderComponent,
    LogoComponent,
    NavItemComponent,
    SiteSearchComponent,
    AccountToolsComponent,
    ServicesNavListComponent,
    MobileNavMenuComponent,
    MobileAccountToolsComponent
  ],
  exports: [
    HeaderComponent
  ],
  imports: [
    CommonModule,
    SharedMaterialModule,
    SharedModule,
    RouterModule
  ]
})
export class HeaderModule {}
