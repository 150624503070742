import { ChangeDetectionStrategy, Component, Input, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { ClassSelectorService } from '@markmachine/features/class-selector/services/class-selector.service';
import { AppState } from '@markmachine/interfaces';
import { getApplicationType, isUnfiled } from '@markmachine/views/submission-page/reducers';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'mm-class-selector',
  templateUrl: './class-selector.component.html',
  styleUrls: ['./class-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClassSelectorComponent implements ControlValueAccessor {
  @Input() label = 'Class code';
  @Input() required = false;
  @Input() mmHint: string | null;
  @Input() mmError: string | null;
  disabled: boolean;
  selected: string;
  private _onChange: (value: string) => void;
  private _onTouch: () => void;
  showUnknown$: Observable<boolean>;

  constructor(public service: ClassSelectorService, private store: Store<AppState>, @Optional() @Self() public ngControl: NgControl) {
    const isUnfiled$ = this.store.pipe(select(isUnfiled), map(sn => !!sn));
    const isTeasPlus$ = this.store.pipe(select(getApplicationType), map(apptype => apptype === 'plus' ? true : false));
    this.showUnknown$ = combineLatest([isUnfiled$, isTeasPlus$]).pipe(map(([a, b]) => a && !b));
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }

  selectionChange(change: MatSelectChange) {
    if (this.selected !== change.value) {
      this.selected = change.value;
      const outValue = change.value;
      if (this._onChange) { this._onChange(outValue); }
      if (this._onTouch) { this._onTouch(); }
    }
  }

  get selectedTitle(): string {
    return this.service.getTitle(this.selected);
  }

  /*
   * Control Value Accessor Interface
   */
  writeValue(obj: string ): void {
    this.selected = obj;
  }
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
