import { GraphQLQuery, GraphQLResponse } from 'app/interfaces';
import { Tip } from '@markmachine/core/models/tip';

import tipFields from './tip.fragment';

const QUERY = `
query GetTipChildren($id: UUID!) {
  tip: tipById(id: $id) {
    children: tipsByIdParent(orderBy:INDEX_ASC) {
      nodes {
        ...tipFields
      }
    }
  }
}
${tipFields}
`;

export class GetTipChildrenQuery implements GraphQLQuery {
  query = QUERY;
  constructor(public variables: { id: string }) {}
}

export type GetTipChildrenResponse = GraphQLResponse<{
  tip: { children: { nodes: Tip[] } };
}>;
