import { Pipe, PipeTransform } from '@angular/core';

import * as marked from 'marked';

@Pipe({
  name: 'markdown'
})
export class MarkdownPipe implements PipeTransform {
  /**
   * PipeTransform Interface
   * @param  {any} value Markdown text to convert to HTML
   * @param  {any} args  Values to insert into any named elements
   * @return {string}    HTML markup
   */
  transform(value: any, ...args: any[]): string {
    if (!value) {
      return '';
    } else if (args.length === 0) {
      return marked(value);
    } else {
      args = Object.assign({}, ...args);
      // Modify markup to insert args
      const root = document.createElement('div');
      root.innerHTML = marked(value);
      const namedElements = Array.from(root.querySelectorAll('[name]'));
      namedElements.forEach((element: HTMLInputElement) => {
        this.modifyValue(element, args);
      });
      return root.innerHTML;
    }
  }

  /**
   * Modify DOM elements to insert named arguments
   * @param {HTMLInputElement} element DOM element to modify
   * @param {any}              values  Any mapable containing element name
   */
  private modifyValue(element: HTMLInputElement, values: any): void {
    if (Object.keys(values).indexOf(element.name) < 0) {
      return;
    } else if (element.tagName === 'INPUT') {
      element.setAttribute('value', values[element.name]);
      element.value = values[element.name];
      if (element.type === 'checkbox') {
        element.setAttribute('checked', 'checked');
        element.checked = values[element.name] === 'on';
      }
    } else if (element.tagName === 'TEXTAREA') {
      // Textareas need innerHTML set for markup and value set for DOM
      element.innerHTML = values[element.name];
      element.value = values[element.name];
    } else {
      // Other stuff *probably* works this way.
      element.setAttribute('value', values[element.name]);
      element.value = values[element.name];
    }
  }
}
