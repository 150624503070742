import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { AllegationOfUse, Class, Specimen } from '@markmachine/features/version/models/version-content.model';
import { updateFormArray } from '@markmachine/core/functions/utilities';
import * as jsdiff from 'diff';

@Injectable()
export class CaseFormAllegationsService {
  constructor(private fb: FormBuilder) {}

  /**
   * Update length and contents of FormArray for classes.
   * @param array FormArray for classes
   * @param values New values for classes
   */
  update(array: FormArray, values: Class[], current: Class[]): void {
    const allegations = this._allegations(values, current);
    updateFormArray(array, allegations, (current_, i) => this.create(current_[i]));
  }

  /**
   * Create a new class
   * @param newClass Fields to set in class
   */
  create(newClass: Class): FormGroup {
    // @see case-form-classes.service.ts#create
    const specimen = this.fb.group({});
    for (const [key, value] of Object.entries({ ...new Specimen(), ...newClass['specimen'] })) {
      specimen.addControl(key, this.fb.control({ value }));
    }
    const group = this.fb.group({ ...new AllegationOfUse(), ...newClass, specimen });
    return group;
  }

  private _allegations(values: Class[], current: Class[]): AllegationOfUse[] {
    if (!current) {
      return [];
    }
    const allegations = current
      // Only classes that are currently 1b can allege use
      .filter(cls => cls['filing-basis-current-1b-in'])
      .map(cls => this._allegation(values, cls));
    // Only allege use if an eligible class is amending to 1a
    const isAllegingUse = !!allegations.find(a => a['$pending-allegation-of-use']);
    // Otherwise, return an empty list
    return isAllegingUse ? allegations : [];
  }

  /**
   * Calculate an AllegationOfUse, if any
   * @param values draft class
   * @param current current (USPTO) class
   */
  private _allegation(values: Class[], current: Class): AllegationOfUse {
    const value = values.find(v => v['class-code'] === current['class-code']);
    return this._createAllegationFromClass(value, current);
  }

  /**
   * Create an AllegationOfUse from Classes.
   * @param value Form Value of draft Class
   * @param current Current (USPTO) value of Class
   * @param init initialization values for AllegationOfUse
   */
  private _createAllegationFromClass(value: Class | null, current: Class): AllegationOfUse {
    let allegation = new AllegationOfUse();
    if (!value) {
      // If there's no `current` class, delete this class.
      return { ...allegation, 'allegation-of-use': 'delete-class' };
    }
    // Copy fields from class to the new allegation
    for (const key in allegation) {
      if (allegation.hasOwnProperty(key)) {
        allegation[key] = value[key];
      }
    }
    if (allegation['allegation-of-use'] === 'remove-goods-services') {
      // If the class disposition is to remove the good/services, copy the over the "remaining" listing
      allegation = { ...allegation, '$listing-remaining': value['listing'] };
    } else if (allegation['allegation-of-use'] === 'delete-class') {
      // If the class disposition is to delete the class, omit first-use and specimen
      allegation = { ...allegation, 'first-use-anywhere-date': null, 'first-use-in-commerce-date': null, specimen: new Specimen() };
    }
    return allegation;
  }

  /**
   * Calculate goods/services deleted from draft
   * @param draft proposed listing of goods/services
   * @param current current (USPTO) listing of goods/services
   */
  private _deletedListing(draft: string, current: string) {
    // Find narrowings (deletions) of good/services
    // NOTE: grammar is handled naively at the moment
    const changes: string[] = jsdiff
      .diffWords(current, draft, { ignoreCase: true })
      .filter(change => change.removed)
      .map(change => change.value)
      .map(value => value.replace(/[^\w()-]/, '').trim());
    // Fall through to null if the result is the empty string
    return changes.length > 0 ? changes.join('; ') : undefined;
  }
}
