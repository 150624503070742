import { GraphQLQuery, GraphQLResponse } from '@markmachine/interfaces';
import { UserTable } from '@markmachine/features/user-table/models/user-table.model';

const graphqlQuery = `
query UserTables {
    allUserTables {
        nodes {
            nodeId
            userId
            key
            createdAt
            updatedAt
            title
            columns
            options
        }
    }
}
`;

export class AllUserTablesQuery implements GraphQLQuery {
  query = graphqlQuery;
  constructor(public variables: {}) {}
}

export type AllUserTablesResponse = GraphQLResponse<{
  allUserTables: {
    nodes: UserTable[];
  };
}>;
