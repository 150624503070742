import {
  OnInit,
  ChangeDetectionStrategy,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
  Output,
  EventEmitter,
  OnDestroy,
  ElementRef
} from '@angular/core';
import { ContextMenuItem } from '@markmachine/features/context-menu/models/context-menu-item.model';
import { get } from 'lodash-es';
import { ExpansionPanelHelper } from '../../models/expansion-panel-helper.model';
import { TipHelperComponent } from '../tip-helper/tip-helper.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CaseNoteHelperComponent } from '../case-note-helper/case-note-helper.component';

/**
 * Handles general "helper" logic and instantiates specific helpers.
 */
@Component({
  selector: 'mm-expansion-panel-helper',
  templateUrl: './expansion-panel-helper.component.html',
  styleUrls: ['./expansion-panel-helper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpansionPanelHelperComponent implements OnInit, OnChanges, OnDestroy, ExpansionPanelHelper {
  @Input() menuItem: ContextMenuItem;
  @Output() hide = new EventEmitter<void>();
  // A static ViewChild is available at the ngOnInit lifecycle (ng8+)
  @ViewChild('container', { static: true, read: ViewContainerRef }) container: ViewContainerRef;
  componentRef: ComponentRef<ExpansionPanelHelper>;
  destroy$ = new Subject();

  constructor(private resolver: ComponentFactoryResolver) {}

  ngOnInit() {
    // Construct the specific helper for the menuItem
    const factory = this.resolveHelperComponent(this.menuItem);
    this.componentRef = this.container.createComponent(factory);
    this.componentRef.instance.menuItem = this.menuItem;
    this.componentRef.instance.hide.pipe(takeUntil(this.destroy$)).subscribe(() => this.hide.next());
    this.componentRef.changeDetectorRef.markForCheck();
  }

  ngOnChanges(changes: SimpleChanges) {
    // If the helper body already exists but the menu item has changed, update and mark dirty.
    if (this.componentRef && get(changes, ['menuItem', 'currentValue', 'props'])) {
      this.componentRef.instance.menuItem = changes.menuItem.currentValue;
      const changableInstance = this.componentRef.instance as unknown as OnChanges;
      if (changableInstance.ngOnChanges /* is changable */) {
        changableInstance.ngOnChanges(changes);
      }
      this.componentRef.changeDetectorRef.markForCheck();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  /**
   * Resolve a component factory given the menu action.
   *
   * TODO: Consider moving to an ExpansionPanelHelperService for a helper registration system.
   */
  resolveHelperComponent({ action }: ContextMenuItem): ComponentFactory<ExpansionPanelHelper> {
    const components = {
      // "Action": HelperComponent
      Tips: TipHelperComponent,
      Notes: CaseNoteHelperComponent
    };
    return this.resolver.resolveComponentFactory(get(components, action));
  }

  scrollIntoView() {
    this.componentRef.injector.get<ElementRef>(ElementRef).nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}
