/**
 * Expansion Panel Module
 *
 * Provides Mark Machine's version of an expansion panel.
 * This expansion panel is inspired by the Material Expansion panel,
 * but adheres to Mark Machine's styles and works better as a nested
 * outline.
 */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedMaterialModule } from '@markmachine/shared/materials/shared-materials.module';
import { ExpansionPanelActionComponent } from './components/expansion-panel-action/expansion-panel-action.component';
import { ExpansionPanelActionsComponent } from './components/expansion-panel-actions/expansion-panel-actions.component';
import { ExpansionPanelTitleComponent } from './components/expansion-panel-title/expansion-panel-title.component';
import { ExpansionPanelComponent } from './components/expansion-panel/expansion-panel.component';
import { ExpansionPanelWarningComponent } from './components/expansion-panel-warning/expansion-panel-warning.component';

@NgModule({
  declarations: [
    ExpansionPanelComponent,
    ExpansionPanelTitleComponent,
    ExpansionPanelActionsComponent,
    ExpansionPanelActionComponent,
    ExpansionPanelWarningComponent,
  ],
  imports: [CommonModule, SharedMaterialModule, FlexLayoutModule],
  exports: [
    ExpansionPanelComponent,
    ExpansionPanelTitleComponent,
    ExpansionPanelActionsComponent,
    ExpansionPanelActionComponent,
    ExpansionPanelWarningComponent
  ]
})
export class ExpansionPanelModule {}
