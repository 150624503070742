import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'mm-tm5-icon',
  templateUrl: './tm5-icon.component.html',
  styleUrls: ['./tm5-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Tm5IconComponent implements OnInit {

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    this.matIconRegistry.addSvgIcon(
      'tm5',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/tm5.svg')
    );
  }

  ngOnInit() {
  }

}
