import { set } from '@markmachine/core/functions/set';
import { UserAuthorization } from '@markmachine/core/models/user-authorization';
import { UserProfile } from '@markmachine/features/account/models/user-profile.model';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { get } from 'lodash-es';
import * as AccountActions from '../actions/account.actions';

export interface State {
  authorization: UserAuthorization;
  profile: UserProfile;
  authorizationError?: string;
  profileIsUpdating?: boolean;
  registrationError?: string;
  returnUrl: string;
}

export const initialState: State = {
  authorization: new UserAuthorization(),
  profile: new UserProfile(),
  profileIsUpdating: false,
  authorizationError: undefined,
  registrationError: undefined,
  returnUrl: ''
};

export const reducer = createReducer(
  initialState,
  // **************************************************************************
  // User Account Management
  // **************************************************************************
  on(AccountActions.register, (state) => ({ ...state, authorizationError: undefined, registrationError: undefined })),
  on(AccountActions.registerSuccess, (state, { accessToken, profile }) => ({ ...state, profile, authorization: { accessToken } } as State)),
  on(AccountActions.registerFailure, (state, { errorMessage: registrationError }) => ({ ...state, registrationError })),
  on(AccountActions.authorize, (state) => ({ ...state, authorizationError: undefined, registrationError: undefined })),
  on(AccountActions.authorizeSuccess, (state, { accessToken, profile }) => ({
    ...state, profile: { ...state.profile, ...profile }, authorization: { accessToken }
  })),
  on(AccountActions.authorizeFailure, (state, { errorMessage: authorizationError }) => ({ ...state, authorizationError })),
  on(AccountActions.deauthorize, (state) => ({ ...state, authorization: { accessToken: undefined } })),
  on(AccountActions.setReturnUrlAction, (state, { returnUrl }) => ({ ...state, returnUrl })),

  // **************************************************************************
  // User Profile
  // **************************************************************************
  on(AccountActions.loadProfileSuccess, (state, { profile }) => ({ ...state, profile })),
  // flags may be a partial, this avoids overwriting values
  on(AccountActions.updateProfile, (state, { profile }) => {
    return { ...state, profile: {
      ...state.profile,
      ...profile,
      flags: { ...state.profile.flags, ...profile.flags }
    }, profileIsUpdating: true };
  }),
  on(AccountActions.updateProfileSuccess, (state) => ({ ...state, profileIsUpdating: false })),
  on(AccountActions.updateProfileFailure, (state) => ({ ...state, profileIsUpdating: false })),
  on(AccountActions.dismissTutorial, (state, { tutorialName }) => {
    const path = ['profile', 'flags', 'tutorialInteractions', tutorialName, 'permanentlyDismissed'];
    return set(state, path, new Date().toISOString());
  }),
  on(AccountActions.undismissTutorial, (state, { tutorialName }) => {
    const path = ['profile', 'flags', 'tutorialInteractions', tutorialName, 'permanentlyDismissed'];
    return set(state, path, '');
  })
);


// **************************************************************************
// Selectors
// **************************************************************************

export const storeKey = 'account';
export const getAccountState = createFeatureSelector<State>(storeKey);

export const getAuthorizationState = createSelector(getAccountState, state => state.authorization);
export const getProfileState = createSelector(getAccountState, state => state.profile);
export const getAuthorizationError = createSelector(getAccountState, state => state.authorizationError);
export const getAuthorizationToken = createSelector(getAuthorizationState, state => state.accessToken);
export const getRegistrationError = createSelector(getAccountState, state => state.registrationError);
export const getUserProfileIsUpdating = createSelector(getAccountState, state => !!state.profileIsUpdating);
export const getUserProfileFlags = createSelector(getProfileState, state => state.flags);
export const getFlagDeveloperWarnings = createSelector(getUserProfileFlags, flags => flags.developerWarnings);
export const getFlagDebugMode = createSelector(getUserProfileFlags, flags => flags.debugMode);
export const getFlagTutorialInteractions = () => createSelector(
  getAccountState,
  (state: State, props) => {
    const path = ['profile', 'flags', 'tutorialInteractions', props.tutorialName];
    return get(state, path, get(initialState, path));
  }
);

export const getAuthorizationTokenBody = createSelector(getAuthorizationToken, token => {
  if (!token) {
    return null;
  }
  const [header, body, signature] = token.split('.');
  return JSON.parse(atob(body));
});

/** user_id from access token */
export const getUserId = createSelector(getAuthorizationTokenBody, body => body && body.user_id);

/** administrator status from access token */
export const getIsAdmin = createSelector(getAuthorizationTokenBody, body => body && body.role === 'markmachine_admin');

export const getReturnUrl = createSelector(getAccountState, state => state.returnUrl);

export const getAccountName = createSelector(getProfileState, profile => profile.fullName);
