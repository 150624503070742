import { Injectable } from '@angular/core';
import { CaseFileHeader } from '@markmachine/features/version/models/version-content.model';
import { merge } from 'lodash-es';
import { FormControl, FormGroup } from '@angular/forms';


const TEMPLATE = new CaseFileHeader();

@Injectable()
export class CaseFormHeaderService {
  /** Update the FormGroup. */
  update(group: FormGroup, values: CaseFileHeader): void {
    const newValues = this.sanitizeValues(values);
    this.initializeGroup(group, newValues);
    group.patchValue(newValues, { emitEvent: false });
    this.mutate(group, newValues);
  }

  /** Perform secondary mutations on CaseFileHeader */
  mutate(group: FormGroup, values: CaseFileHeader): void {
    if (!group.get('application-type').value) {
      const isTeasPlus = values['currentlyAsTeasPlusApp'];
      group.patchValue({ 'application-type': isTeasPlus ? 'plus' : 'standard' });
    }
  }

  /**
   * Populate Form for CaseFileHeader with controls.
   *
   * TODO: It would be safer to generalize this method to work directly off of inspection of the model's structure.
   */
  initializeGroup(group: FormGroup, values: CaseFileHeader = new CaseFileHeader()): void {
    // Initialize the scalar value that live directly within statements
    const valueKeys = Object.keys(TEMPLATE);
    for (const key of valueKeys) {
      if (group.get(key)) {
        continue;
      }
      group.setControl(key, new FormControl({ updateOn: 'change', value: values[key] }));
    }
  }

  /** Make sure fields have sane values. */
  private sanitizeValues(values: CaseFileHeader): CaseFileHeader {
    const newValues = merge(new CaseFileHeader(), values);
    return newValues;
  }
}
