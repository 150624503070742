import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  HostListener,
  HostBinding
} from '@angular/core';
import { Tip } from '@markmachine/core/models/tip';
import { outlineAnimation } from '@markmachine/core/animations';

@Component({
  selector: 'mm-tree-leaf',
  templateUrl: './tree-leaf.component.html',
  styleUrls: ['./tree-leaf.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [outlineAnimation.indicatorRotation]
})
export class TreeLeafComponent implements OnInit {
  @Input() tip: Tip;
  @Input() expanded: boolean;
  @Input() hideToggle = false;
  @Output() opened = new EventEmitter<boolean>();
  @Output() closed = new EventEmitter<boolean>();

  /** Apply clickable class if expandable and has children */
  @HostBinding('class.clickable')
  get clickable() {
    return !this.hideToggle && this.tip && this.tip.hasChildren;
  }

  @HostListener('click')
  clickListener() {
    // Toggle if the node has children; otherwise toggle is nonsense
    if (this.clickable) {
      this.toggleExpanded();
    }
  }

  toggleExpanded() {
    if (this.expanded) {
      this.closed.next();
    } else {
      this.opened.next();
    }
  }

  get expansionState() {
    return this.expanded ? 'expanded' : 'collapsed';
  }

  ngOnInit() {}
}
