import { ChangeDetectorRef, Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { EXPANSION_PANEL_SCROLL_DELAY } from '@markmachine/core/core.config';
import { FormControlElementRefService } from '@markmachine/features/version/services/form-control-element-ref.service';
import { extend } from 'lodash-es';
import { validatorRegistry } from './validator-registry';

interface InvalidControlDescription {
  path: string;
  control: FormControl;
  message: string;
  name: string;
  registeredValidator: any;
}

@Injectable({
  providedIn: 'root'
})
export class CaseFormValidationService {
  /*
  Create a function to updateControls that takes a control and inspects
  the paths and attaches validators based on the spec defined in the registry.

  The registry should contain keys and validator functions.
  */

  validatorRegistry = validatorRegistry;

  constructor(
    private service: FormControlElementRefService
  ) { }

  _recurseGetControlPath(control, path: string[]): string[] {
    if (!control || !control.parent) {
      return path;
    }
    path.push(Object.keys(control.parent.controls).find((key) => control === control.parent.controls[key]));
    return this._recurseGetControlPath(control.parent, path);
  }

  getFullControlPath(control, includeArrayIndices= false) {
    const paths = this._recurseGetControlPath(control, []);
    // filter out array indices
    if (!includeArrayIndices) {
      return paths.filter((segment) => isNaN(parseInt(segment, 10))).reverse().join('.');
    } else {
      return paths.reverse().join('.');
    }
  }

  setFormControlsValidators(formControls) {
    Object.keys(formControls.controls).forEach(key => {
        if ((formControls.controls[key] instanceof FormGroup) || (formControls.controls[key] instanceof FormArray)) {
          // recurse nested FormGroups and FormArrays
          this.setFormControlsValidators(formControls.controls[key]);
        } else {
          this.setControlValidators(formControls.controls[key]);
        }
      });
  }

  setControlValidators(control) {
    const fullPath = this.getFullControlPath(control);
    if (!!this.validatorRegistry[fullPath]) {
      control.setValidators(this.validatorRegistry[fullPath].validators);
    }
  }

  _recurseGetInvalidFormControls(formControls: FormGroup | FormArray, invalidControls= {}): Record<string, InvalidControlDescription> {
    if (!formControls.controls) {
      return invalidControls;
    }
    Object.keys(formControls.controls).forEach(key => {
      const control = formControls.controls[key];

      if ((control instanceof FormGroup) || (control instanceof FormArray)) {
        invalidControls = extend(invalidControls, this._recurseGetInvalidFormControls(control, invalidControls));
      } else if (control instanceof FormControl) {
        // the registryKey ignores array indices while the fullPath includes them
        const registryKey = this.getFullControlPath(control);
        const controlPath = this.getFullControlPath(control, true);

        if (control.status === 'INVALID') {
          if (!!this.validatorRegistry[registryKey]) {
            invalidControls[controlPath] = {
              path: controlPath,
              control,
              message: (!!this.validatorRegistry[registryKey]) ? this.validatorRegistry[registryKey].errorMessage : 'There\'s a problem with this control',
              name: (!!this.validatorRegistry[registryKey]) ? this.validatorRegistry[registryKey].fieldName : controlPath,
              registeredValidator: (!!this.validatorRegistry[registryKey])
            };
          }
        }
      }
    });
    return invalidControls;
  }

  getInvalidFormControls(formGroup) {
    const invalidControls = this._recurseGetInvalidFormControls(formGroup);
    return invalidControls;
  }

  revealField(path: string) {
    const fieldDirective = this.service.registery.get(path);

    if (fieldDirective) {
      // use directive to open containing panels
      fieldDirective.opener.open();
      // mark it all as touched so that mat-errors are
      // shown
      fieldDirective.control.control.markAsTouched();
      fieldDirective.control.control.updateValueAndValidity();
      fieldDirective.changeDetectorRef.detectChanges();
      // scroll to the field element
      setTimeout(() => {
        fieldDirective.elementRef.nativeElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
      }, EXPANSION_PANEL_SCROLL_DELAY);
    } 
  }
}
