import { GraphQLQuery, GraphQLResponse } from 'app/interfaces';
import { caseStatusFragmentFields } from './case-status.fragment';
import { CaseStatus } from '@markmachine/features/case-status/models/status.model';

const query = `
mutation RefreshCaseStatuses($caseIds: [UUID]!, $until: Datetime!, $withContent: Boolean!) {
  refreshCaseStatusesByCaseIds(input: {caseIds: $caseIds, ts: $until}) {
    caseStatuses {
      ${caseStatusFragmentFields}
      content @include(if: $withContent)
    }
  }
}`;

export class RefreshCaseStatusesMutation implements GraphQLQuery {
  query = query;
  constructor(public variables: {
    caseIds: string[],
    until?: string,
    withContent?: boolean
  }) {
    this.variables.until = variables.until || new Date().toISOString();
    this.variables.withContent = !!variables.withContent;
  }
}

export type RefreshCaseStatusesResponse = GraphQLResponse<{
  refreshCaseStatusesByCaseIds: {
    caseStatuses: CaseStatus[];
  }
}>;
