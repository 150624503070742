import { Directive, HostListener, HostBinding, Input } from '@angular/core';

/** Set a default image when there is an error retrieving an image. */
@Directive({
  selector: 'img[mmDefault]',
})
export class DefaultImageDirective {
  @Input() mmDefault: string;
  @HostBinding() @Input() src: string;
  @HostListener('error') onError() {
    this.src = this.mmDefault;
  }
}
