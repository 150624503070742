import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { go } from '@markmachine/core/actions/router.actions';
import { AccountRequestService } from '@markmachine/features/account/services/account-request.service';
import { AccountService } from '@markmachine/features/account/services/account.service';
import { AppState } from '@markmachine/interfaces';
import { ConfirmDialogData, ConfirmDialogComponent } from '@markmachine/shared/components/confirm-dialog/confirm-dialog.component';
import { NoticeDialogComponent, NoticeDialogData } from '@markmachine/shared/components/notice-dialog/notice-dialog.component';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular';
import { concat, EMPTY, of } from 'rxjs';
import { catchError, concatMap, exhaustMap, filter, map, mapTo, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as AccountActions from '../actions/account.actions';
import { SignInDialogComponent } from '../components/sign-in-dialog/sign-in-dialog.component';
import { ChangePasswordMutation } from '../operations/change-password.mutation';
import { getProfileState } from '../reducers/account.reducer';

@Injectable()
export class AccountEffects implements OnInitEffects {
  /**
   * Initialize persistent sections of store from localStorage
   */
  initializeFromLocalStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.init),
      switchMap(() => {
        // Load token from local storage, if available
        const accessToken = AccountService.tokenGetter();
        const flags = AccountService.flagsGetter();
        try {
          const decodedToken = AccountService.decodeToken(accessToken);
          const { user_id, exp } = decodedToken;
          Sentry.configureScope((scope) => scope.setUser({ id: user_id }));
          const isExpired = exp * 1000 < new Date().getTime();
          if (accessToken && user_id && !isExpired) {
            return of(AccountActions.authorizeSuccess({ accessToken, profile: { userId: user_id, flags } }));
          }
        } catch (err) {
          return EMPTY;
        }
        return EMPTY;
      })
    )
  );

  // **************************************************************************
  // User Account Management
  // **************************************************************************

  /**
   * Navigate to sign-in page
   */
  signIn$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AccountActions.signIn),
        tap(() => this.dialog.open(SignInDialogComponent))
      ),
    { dispatch: false }
  );

  /**
   * Remove authentication token from this machine and navigate somewhere.
   *
   * It's important to navigate away from the sign-out page for two reasons:
   *   1.  activity indicates succesful sign-out; and
   *   2.  provokes router guards, bumping the user out of protected content.
   */
  signOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.signOut),
      tap(() => AccountService.tokenRemover()),
      tap(() => AccountService.flagsRemover()),
      concatMap(() => [AccountActions.deauthorize(), go({ path: ['/'] })])
    )
  );

  /**
   * Register user with API
   */
  registerUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.register),
      switchMap(({ email, password }) =>
        this.accountQuery.requestRegistration(email, password).pipe(
          map(({ jwtToken: accessToken, profile }) => AccountActions.registerSuccess({ accessToken, profile })),
          catchError((error) => of(AccountActions.registerFailure({ errorMessage: error.message })))
        )
      )
    )
  );

  /**
   * Authorize user with API
   */
  authorizeUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.authorize),
      switchMap(({ email, password }) =>
        this.accountQuery.requestAuthorization(email, password).pipe(
          // Store the authorization access token
          tap(({ accessToken }) => {
            AccountService.tokenSetter(accessToken);
            const { user_id: id } = AccountService.decodeToken(accessToken);
            Sentry.configureScope((scope) => scope.setUser({ id, email }));
          }),
          map(({ accessToken, profile }) => AccountActions.authorizeSuccess({ accessToken, profile })),
          catchError((error) => of(AccountActions.authorizeFailure({ errorMessage: error.message })))
        )
      )
    )
  );

  /** Prompt to confirm password change before attempting. */
  confirmChangePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.confirmChangePassword),
      exhaustMap(({ password }) =>
        this.confirmDialog({
          title: 'Change password?',
          message: 'You will need to use this new password the next time you log in.',
          confirm: 'Change Password',
          cancel: 'Cancel',
        })
          .afterClosed()
          .pipe(filter(Boolean), mapTo(AccountActions.changePassword({ password })))
      )
    )
  );

  /** Attempt to change password. */
  changePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.changePassword),
      concatMap(({ password }) =>
        this.changePasswordMutation.mutate({ password }).pipe(
          tap(({ data: { changePassword } }) => AccountService.tokenSetter(changePassword.jwtToken)),
          concatMap(({ data: { changePassword } }) => [
            AccountActions.changePasswordSuccess(),
            AccountActions.authorizeSuccess({
              accessToken: changePassword.jwtToken,
              profile: changePassword.query.currentProfile,
            }),
          ]),
          catchError((error) =>
            concat([
              AccountActions.changePasswordFailure({ errorMessage: error.message }),
              AccountActions.authorizeFailure({ errorMessage: error.message }),
            ])
          )
        )
      )
    )
  );

  /** Notice that password change succeeded. */
  changePasswordSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.changePasswordSuccess),
      tap(() => this.showNotice({
        title: 'Password changed!',
        message: 'You will need to use your new password the next time you log in.'
      }))
    ), { dispatch: false }
  );

  /** Notice that password change failed. */
  changePasswordFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.changePasswordFailure),
      tap(() => this.showNotice({
        title: 'Error: password was not changed!',
        message: 'You will need to use your old password the next time you log in.',
      }))
    ), { dispatch: false }
  );

  /**
   * Navigate to Return URL
   */
  returnUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.navigateToReturnUrl),
      withLatestFrom(this.account.returnUrl$),
      map(([_, returnUrl]) => go({ path: [returnUrl] }))
    )
  );

  // **************************************************************************
  // User Profile
  // **************************************************************************

  adaptUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.dismissTutorial, AccountActions.undismissTutorial),
      withLatestFrom(this.store.pipe(select(getProfileState))),
      map(([_, profile]) => AccountActions.updateProfile({ profile }))
    )
  );

  /**
   * Update user profile via API
   */
  updateUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AccountActions.updateProfile),
      // Locally persist flag changes
      tap(({ profile: { flags } }) => flags && AccountService.flagsSetter(flags)),
      // Fetch UserID so we can update our own profile (FIXME: should not need userId)
      withLatestFrom(this.account.userProfile$.pipe(map((profile) => profile.userId))),
      // Perform HTTP request to update profile
      switchMap(([{ profile: patch }, userId]) => this.accountQuery.requestUserProfileUpdate(userId, patch)),
      // If authentication succeeded, emit success
      map((profile) => AccountActions.updateProfileSuccess({ profile })),
      // If authentication failed, emit failure
      catchError(() => of(AccountActions.updateProfileFailure()))
    )
  );

  updateUserProfileSuccessSnackbar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AccountActions.updateProfileSuccess),
        tap(() => this.snack('Your profile was saved!'))
      ),
    { dispatch: false }
  );

  updateUserProfileFailureSnackbar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AccountActions.updateProfileFailure),
        tap(() => this.snack('Your profile was NOT saved!'))
      ),
    { dispatch: false }
  );

  ngrxOnInitEffects(): Action {
    return AccountActions.init();
  }

  constructor(
    private actions$: Actions,
    private account: AccountService,
    private accountQuery: AccountRequestService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private store: Store<AppState>,
    private changePasswordMutation: ChangePasswordMutation
  ) {}

  /**
   * Dispatch a simple snackbar message about the result of an effect
   * @param message Snackbar message
   */
  private snack(message: string) {
    this.snackbar.open(message, undefined, { duration: 2000 });
  }

  /**
   * Open a simple notice about the results of an effect.
   */
  private showNotice(data: NoticeDialogData): MatDialogRef<NoticeDialogComponent> {
    return this.dialog.open<NoticeDialogComponent, NoticeDialogData>(NoticeDialogComponent, { data });
  }

  /** Open a simple confirmation dialog. */
  private confirmDialog(data: ConfirmDialogData): MatDialogRef<ConfirmDialogComponent, boolean> {
    return this.dialog.open<ConfirmDialogComponent, ConfirmDialogData>(ConfirmDialogComponent, { data });
  }
}
