import { Injectable } from '@angular/core';
import { Case } from '@markmachine/features/case/models/case.model';
import { caseFragment } from '@markmachine/features/case/services/operations/case.fragment';
import { Mutation } from '@markmachine/features/graphql/services/mutation.service';
import { NewUserCase, UserCase } from '../../models/user-case.model';
import { userCaseFragment } from './user-case.fragment';


interface CreateNewCaseResponse {
  createCase: { case: Case; };
  createUserCase: { userCase: UserCase; };
}


interface CreateNewCaseInput {
  case: Case;
  userCase: NewUserCase;
}


@Injectable({ providedIn: 'root' })
export class CreateNewUserCaseMutation extends Mutation<CreateNewCaseResponse, CreateNewCaseInput> {
  document = `
  mutation CreateNewCase (
    $case: CaseInput!
    $userCase: UserCaseInput!
  ) {
    createCase(input: { case: $case }) {
      case { ...caseFragment }
    }
    createUserCase(input: { userCase: $userCase }) {
      userCase { ...userCaseFragment }
    }
  }
  ${caseFragment}
  ${userCaseFragment}
  `;
}
