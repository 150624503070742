import { GraphQLQuery, GraphQLResponse } from 'app/interfaces';
import { ContextMenuItem } from '../../models/context-menu-item.model';
import { contextMenuItemFragment } from './context-menu.fragment';

const QUERY = `
mutation UpdateContextMenuByPathAndLabel($input: UpdateContextMenuByPathAndLabelInput!) {
  updateContextMenuByPathAndLabel(input: $input) {
		 contextMenu {
      ...contextMenuFields
    }
  }
}
${contextMenuItemFragment}
`;

export class UpdateContextMenuByPathAndLabelMutation implements GraphQLQuery {
  query = QUERY;
  constructor(public variables: { input: { path: string; label: string; contextMenuPatch: Partial<ContextMenuItem> } }) {}
}

export type UpdateContextMenuByPathAndLabelResponse = GraphQLResponse<{
  updateContextMenuByPathAndLabel: {
    contextMenu: ContextMenuItem;
  };
}>;
