/**
 * Shared Routing Module
 *
 * Provides /404
 * NOTE: Should probably be moved somewhere else.
 */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from './shared.module';
import { NotFoundComponent } from './components/not-found/not-found.component';


const routes: Routes = [
  {
    path: '404',
    pathMatch: 'full',
    component: NotFoundComponent,
  },
];


@NgModule({
  declarations: [],
  imports: [SharedModule, RouterModule.forChild(routes)],
  providers: [],
  exports: [RouterModule]
})
export class SharedRoutingModule { }
