import { Injectable } from '@angular/core';
import { ClassDescription, SelectOption } from '@markmachine/features/class-selector/interfaces';
import classes from '../data/classes.json';


@Injectable()
export class ClassSelectorService {
  classes: ClassDescription[] = classes;
  UNKNOWN: SelectOption = this.classes.find(({ code }) => code === 'UNK');
  constructor() {}

  get allOptions(): SelectOption[] {
    return this.classes;
  }

  get goodsOptions(): SelectOption[] {
    return this.classes
      .filter(cls => cls.category === 'goods' );
  }

  get servicesOptions(): SelectOption[] {
    return this.classes
      .filter(cls => cls.category === 'services' );
  }

  getTitle(code: string): string {
    return (this.classes.find(cls => cls.code === code) as ClassDescription).title;
  }
}
