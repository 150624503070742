import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { FormGroup, FormArray } from '@angular/forms';

@Component({
  selector: 'mm-request-extension-dialog',
  templateUrl: './request-extension-dialog.component.html',
  styleUrls: ['./request-extension-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestExtensionDialogComponent implements OnInit {
  root: FormGroup;
  extensionCount: number;

  get classes() {
    return this.root.get('classes') as FormArray;
  }

  constructor(@Inject(MAT_DIALOG_DATA) private data) {
    this.root = data.root;
    this.extensionCount = data.extensionCount;
  }

  private setExtensions(requested: boolean) {
    this.classes.controls.filter(cls => cls.get('$eligible-for-extension')?.value).forEach(cls => {
      cls.patchValue({ '$extension-requested': requested });
    });
  }

  ngOnInit() {
    this.setExtensions(true);
  }

  remove() {
    this.setExtensions(false);
  }
}
