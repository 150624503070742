/**
 * ## [Custom Router State Serializer][1]
 *
 * During each navigation cycle, a RouterNavigationAction is dispatched with a snapshot of the state in its payload, the
 * RouterStateSnapshot. The RouterStateSnapshot is a large complex structure, containing many pieces of information about the current
 * state and what's rendered by the router. This can cause performance issues when used with the Store Devtools. In most cases, you
 * may only need a piece of information from the RouterStateSnapshot. In order to pair down the RouterStateSnapshot provided during
 * navigation, you provide a custom serializer for the snapshot to only return what you need to be added to the payload and store.
 *
 * To use the time-traveling debugging in the Devtools, you must return an object containing the url when using the routerReducer.
 *
 * [1]: https://ngrx.io/guide/router-store/configuration
 */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { RouterStateUrl } from './router.reducer';

@Injectable()
export class CustomRouterStateSerializer implements RouterStateSerializer<RouterStateUrl> {
    serialize(routerState: RouterStateSnapshot): RouterStateUrl {
        const { url } = routerState;
        const queryParams = {};
        const params = {};
        const data = {};
        const routes = [routerState.root];
        do {
            const route = routes.shift() as ActivatedRouteSnapshot;
            routes.push(...route.children);
            Object.assign(params, route.params, ...route.url.map(({ parameters }) => parameters));
            Object.assign(data, route.data);
            Object.assign(queryParams, route.queryParams);
        } while (routes.length > 0);

        // Only return an object that contains the url and query params
        // instead of the entire snapshot
        return { url, params, queryParams, data };
    }
}
