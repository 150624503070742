import { Injectable } from '@angular/core';
import { DateAdapter, NativeDateAdapter, MatDateFormats } from '@angular/material/core';
// import { MomentDateAdapter } from "@angular/material-moment-adapter";

const DATE_REGEX = /(\d{4})-?(\d{2})-?(\d{2})/;
const DATETIME_REGEX = /(\d{4})-?(\d{2})-?(\d{2})\D?(\d{2}):?(\d{2}):?(\d{2})(z|Z|[+-]\d{1,2}(?::?\d{2})?)?/;

export const NAIVE_STRING_DATE_FORMATS: MatDateFormats = {
  parse: { dateInput: { month: 'short', year: 'numeric', day: 'numeric' } },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' }
  }
};

@Injectable()
export class NaiveStringDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: object): string {
    // if (displayFormat && displayFormat !== "input") {
    //   throw new Error("Method not implemented for displayFormat !== 'input'.");
    // }
    const day = `0${date.getUTCDate()}`.slice(-2);
    const month = `0${date.getUTCMonth() + 1}`.slice(-2);
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }
  deserialize(value: any): Date | null {
    if (DATE_REGEX.test(value)) {
      const groups = DATE_REGEX.exec(value);
      if (groups) {
        const [year, month, day] = groups.slice(1);
        return new Date(`${year}-${month}-${day}`);
      }
    }
    return super.deserialize(value);
  }

}
