import { ChangeDetectionStrategy, Component, Input, EventEmitter } from '@angular/core';
import { Tip } from '@markmachine/core/models/tip';

/**
 * Render a tip
 */
@Component({
  selector: 'mm-tip-renderer',
  templateUrl: './tip-renderer.component.html',
  styleUrls: ['./tip-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false
})
export class TipRendererComponent {
  @Input() tip: Tip;
  @Input() fields: any;
  @Input() formValues: any;
}
