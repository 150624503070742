/**
 * Tip Module
 *
 * Provides state management, components, and directives for viewing and accessing
 * Mark Machine tips. Tips are small bits of markdown text organized into a hierarchical
 * outline.
 */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TipsEffects } from '@markmachine/features/tip/effects/tip.effects';
import { reducer, storeKey } from '@markmachine/features/tip/reducers/tip.reducers';
import { SharedMaterialModule } from '@markmachine/shared/materials/shared-materials.module';
import { SharedModule } from '@markmachine/shared/shared.module';
import { ReactiveComponentModule } from '@ngrx/component';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { QuickTipComponent, QuickTipDirective } from './components/quick-tip/quick-tip.component';
import { TipArrayComponent } from './components/tip-array/tip-array.component';
import { TipButtonComponent } from './components/tip-button/tip-button.component';
import { TipDialogComponent } from './components/tip-dialog/tip-dialog.component';
import { TipNavigationComponent } from './components/tip-navigation/tip-navigation.component';
import { TipNotFoundDialogComponent } from './components/tip-not-found-dialog/tip-not-found-dialog.component';
import { TipRendererComponent } from './components/tip-renderer/tip-renderer.component';
import { TreeControllerComponent } from './components/tree-controller/tree-controller.component';
import { TreeLeafComponent } from './components/tree-leaf/tree-leaf.component';
import { FollowAnchorDirective } from './directives/follow-anchor.directive';
import { OpenLinksInDialogDirective } from './directives/open-links-in-dialog.directive';
import { ShowTipDirective } from './directives/show-tip.directive';
import { TipAnchorHijackDirective } from './directives/tip-anchor-hijack.directive';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    SharedMaterialModule,
    ReactiveComponentModule,
    StoreModule.forFeature(storeKey, reducer),
    EffectsModule.forFeature([TipsEffects])
  ],
  declarations: [
    TipRendererComponent,
    ShowTipDirective,
    TipAnchorHijackDirective,
    TreeControllerComponent,
    TreeLeafComponent,
    TipArrayComponent,
    TipDialogComponent,
    TipNotFoundDialogComponent,
    OpenLinksInDialogDirective,
    TipButtonComponent,
    TipNavigationComponent,
    FollowAnchorDirective,
    QuickTipComponent,
    QuickTipDirective,
  ],
  exports: [
    ShowTipDirective,
    TipAnchorHijackDirective,
    OpenLinksInDialogDirective,
    TipRendererComponent,
    TreeControllerComponent,
    TipButtonComponent,
    TipArrayComponent,
    TipDialogComponent,
    TipNavigationComponent,
    FollowAnchorDirective,
    QuickTipDirective,
  ],
})
export class TipModule {}
