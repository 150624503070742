import { Directive, OnDestroy } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * Patch for Safari's checkbox behavior
 *
 * Safari does not emit `blur` events on checkboxes.  To use the { updateOn: 'blur' } FormControl
 * option and have checkboxes work in Safari, we must emit an event in response to the MatCheckbox
 * changing.
 */
@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'mat-checkbox[formControlName], mat-checkbox[formControl], mat-checkbox[ngModel]'
})
export class SafariCheckboxDirective implements OnDestroy {
  destroy$ = new Subject();

  constructor(private checkbox: MatCheckbox, private control: NgControl) {
    // Listen to changes to the checkbox view
    checkbox.change.pipe(takeUntil(this.destroy$)).subscribe(({ source: { checked, value } }) => {
      // The view has changed but the form control might not have been updated in Safari.
      // The control's value (or intended value) might be a string or it might be a boolean.
      if (!!control.value !== !!checked) {
        this.control.control?.setValue(checked ? value || true : null);
      }
    });
  }

  /** Destroy subscription when the directive instance is destroyed. */
  ngOnDestroy() {
    this.destroy$.next();
  }
}
