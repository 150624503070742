import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CheckboxHeader, SupertableHeaderTypes } from '../../models/header-models';
import { RowModel } from '../../models/row-model';

@Component({
  selector: 'mm-header-cell',
  templateUrl: './header-cell.component.html',
  styleUrls: ['./header-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderCellComponent implements OnInit {

  @Input() header: any;
  @Input() rows: RowModel[];

  ngOnInit() {
  }

  isAllChecked(): boolean {
    for (const row of this.rows) {
      if (!(this.header as CheckboxHeader).rowChecked(row.data)) {
        return false;
      }
    }
    return true;
  }

  isAnyChecked(): boolean {
    for (const row of this.rows) {
      if ((this.header as CheckboxHeader).rowChecked(row.data)) {
        return true;
      }
    }
    return false;
  }

  getChecked() {
    return this.isAllChecked();
  }

  getIndeterminate() {
    return !this.isAllChecked() && this.isAnyChecked();
  }
}
