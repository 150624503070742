import { GraphQLQuery, GraphQLResponse } from 'app/interfaces';
import { Tip } from '@markmachine/core/models/tip';

import tipFields from './tip.fragment';

const QUERY = `
query GetTipByAnchor($anchor: String!) {
  tip: tipByAnchor(anchor: $anchor) {
    ...tipFields
    children: tipsByIdParent(orderBy:INDEX_ASC) {
      nodes {
        ...tipFields
      }
    }
  }
}

${tipFields}
`;

export class GetTipByAnchorQuery implements GraphQLQuery {
  query = QUERY;
  constructor(public variables: { anchor: string }) {}
}

export type GetTipByAnchorResponse = GraphQLResponse<{
  tip: Tip;
  children: {
    nodes: Tip[]
  }
}>;
