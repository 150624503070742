import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { InputOptionService } from '@markmachine/features/case/services/input-option.service';
import { Owner } from '@markmachine/features/version/models/version-content.model';
import { updateFormArray } from '@markmachine/core/functions/utilities';
import { isMatch, isNull, isUndefined } from 'lodash-es';
import { Patch } from '@markmachine/core/patch';
import { CaseFormValidationService } from './case-form-validation.service';


@Injectable()
export class CaseFormOwnersService {
  constructor(
    private fb: FormBuilder,
    private options: InputOptionService,
    private validationService: CaseFormValidationService
  ) {}

  /**
   * Append a new Owner control group to a FormArray
   * @param array FormArray to append a new owner to
   * @param owner initial values to apply to new owner
   */
  append(array: FormArray, owner?: Partial<Owner>): void {
    const newOwner: Owner = { ...new Owner(), ...owner };
    const group = this.fb.group(newOwner);
    array.push(group);
  }

  /**
   * Update length and contents of FormArray for owners.
   * @param array FormArray for owners
   * @param values New values for owners
   */
  update(array: FormArray, values: Partial<Owner>[]): void {
    updateFormArray(array, values, (values_, i) => this.create(values_[i]));
    array.controls.forEach(formGroup => this.validationService.setFormControlsValidators(formGroup));
    this.mutateArray(array, values);
  }

  /**
   * Create a new owner
   * @param owner Fields to set in owner
   */
  create(owner: Partial<Owner> = {}): FormGroup {
    const values = { ...new Owner(), ...owner };
    const group = this.fb.group(values, { updateOn: 'blur' });
    // Override `updateOn` policy for specific controls
    group.setControl('$entity-type', new FormControl({ value: values['$entity-type'] }, { updateOn: 'change' }));
    return group;
  }

  /**
   * Perform secondary mutations on FormOwners.
   * @param array FormArray for owners
   * @param values New values for owners
   */
  mutateArray(array: FormArray, values: Partial<Owner>[]): void {
    for (let i = 0; i < array.length; i++) {
      this.mutate(array.at(i) as FormGroup, values[i]);
    }
  }

  /**
   * Perform secondary mutation on a FormOwner, such as clearing old country fields.
   * @param group FormGroup for an individual owner
   * @param value New value for FormGroup
   */
  mutate(group: FormGroup, value: Partial<Owner>): void {
    let emitEvent = false;
    let patch: Partial<Owner> = { ...value };

    // Initialize MarkMachine-only fields
    patch = { ...patch, ...this.initializeEntityType(patch) };
    patch = { ...patch, ...this.initializeEntityCountry(patch) };

    // Calculate secondary changes
    // NOTE: Do not modify user-editable fields; they will become "frozen".
    patch = { ...patch, ...this.trackEntityTypeField(patch) };
    patch = { ...patch, ...this.trackEntityCountryField(patch) }; // depends on updated entity type

    emitEvent = !isMatch(patch, group.value); // Inspect the patch for equivalent values in group.value
    group.patchValue(patch, { emitEvent });
  }

  initializeEntityType({
    'other-entity-type-text': otherText,
    'legal-entity-type-code': code,
    '$entity-type': entityType
  }: Partial<Owner>): Pick<Owner, '$entity-type'> {
    entityType ??= ''; // Default to empty string

    if (entityType) {
      // Is entity type already initialized?
      return { '$entity-type': entityType };
    } else if ((code ?? '99') === '99') {
      // Is entity code uninitialized or coded "other"?
      return { '$entity-type': otherText ?? entityType };
    }

    // Look up cryptic codes and translate. "Other" types are handled by `trackEntityTypeField`.
    let entityTypeOption = this.options.entityTypes.find(({ value }) => value === code);
    entityTypeOption ??= this.options.entityTypesMovedToOther.find(({ value }) => value === code);
    return { '$entity-type': entityTypeOption?.display ?? entityType };
  }

  initializeEntityCountry(value: Partial<Owner>): Partial<Owner> {
    if (!!value['$entity-country']) {
      return {};
    }
    const { 'legal-entity-type-code': legalEntityTypeCode } = value;
    const entityCountry = value[this.entityCountryKey(legalEntityTypeCode as string)];
    const usState = this.options.usStates.find(state => state.value === entityCountry);
    if (!legalEntityTypeCode) {
      return {
        '$legal-entity-domestic-or-foreign': 'domestic'
      };
    } else if (usState) {
      return {
        '$entity-country': this.options.usa.value,
        '$entity-state': usState.value,
        '$legal-entity-domestic-or-foreign': 'domestic'
      };
    } else {
      return {
        '$entity-country': entityCountry,
        '$legal-entity-domestic-or-foreign': 'foreign'
      };
    }
  }

  entityCountryKey(legalEntityTypeCode: string): string {
    switch (legalEntityTypeCode) {
      case '01': {
        return 'citizenship-country-name';
      }
      case '03': {
        return 'incorporated-in-state-code';
      }
      default: {
        return 'state-organized';
      }
    }
  }

  trackEntityTypeField({ '$entity-type': entityType }: Partial<Owner>): Partial<Owner> {
    if (!entityType) {
      return {};
    }
    const entityTypeLower = entityType.toLowerCase().trim();
    const patch: Patch<Owner> = {
      'legal-entity-type-code': '99',
      'other-entity-type-text': '',
      '$entity-type-other-domestic': '',
      '$entity-type-other-foreign': ''
    };

    // Patch driver fields with standard entity values
    let entityTypeOption = this.options.entityTypes.find(e => e.display.toLowerCase() === entityTypeLower);
    if (entityTypeOption) {
      patch['legal-entity-type-code'] = entityTypeOption.value;
    }

    // Patch driver fields with formerly-standard entity values
    const movedToOther = this.options.entityTypesMovedToOther.find(e => e.display.toLowerCase() === entityTypeLower);
    if (movedToOther) {
      patch['other-entity-type-text'] = movedToOther.display;
      patch['legal-entity-type-code'] = movedToOther.value;
      patch['$entity-type-other-domestic'] = movedToOther.value;
      patch['$entity-type-other-foreign'] = '';
    }

    // Patch driver fields with foreign entity values
    if (!movedToOther && !entityTypeOption) {
      const domesticType = this.options.otherEntitiesDomestic.find(e => e.display.toLowerCase() === entityTypeLower);
      const foreignType = this.options.otherEntitiesForeign.find(e => e.display.toLowerCase() === entityTypeLower);
      patch['other-entity-type-text'] = !domesticType && !foreignType ? entityType : '';
      patch['$entity-type-other-domestic'] = domesticType?.value ?? '';
      patch['$entity-type-other-foreign'] = foreignType?.value ?? '';
      entityTypeOption ??= domesticType ?? foreignType;
    }
    // Fix the capitalization of $entity-type if we found a match
    // This subtly confirms the user's selection was accepted
    if (entityTypeOption && entityType !== entityTypeOption.display) {
      patch['$entity-type'] = entityTypeOption.display;
    }
    return patch;
  }

  trackEntityCountryField({
    'legal-entity-type-code': legalEntityTypeCode,
    '$entity-country': entityCountry,
    '$entity-state': entityState
  }: Partial<Owner>): Partial<Owner> {
    const countryKey = this.entityCountryKey(legalEntityTypeCode as string);
    const isIndividual = legalEntityTypeCode === '01';
    const isDomestic = entityCountry === this.options.usa.value;
    return {
      [countryKey]: (isIndividual || !isDomestic ? entityCountry : entityState) || null
    };
  }
}
