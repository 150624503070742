<section class="panel" [class.tip-helper]="helper==='tip'" [attr.aria-expanded]="expanded" aria-live="polite">
  <div class="panel-header">
    <button #expander class="expander" (click)="toggle()">
      <div class="expander-container">
        <mat-icon class="chevron" [class.expanded]="expanded" [class.disabled]="disabled">chevron_right</mat-icon>
        <ng-content select="mm-expansion-panel-title"></ng-content>
      </div>
    </button>
    <ng-content select="mm-expansion-panel-actions"></ng-content>
  </div>
  <div class="panel-body" [fxShow]="expanded">
    <ng-content select="[mmExpansionPanelHelper]"></ng-content>
    <ng-content></ng-content>
  </div>
</section>
