import { UserCase } from '@markmachine/features/user-case/models/user-case.model';
import { GraphQLQuery, GraphQLResponse } from 'app/interfaces';
import { userCaseLargeFragment } from './user-case.fragment';

const graphqlQuery = `
mutation AddUserCase($serialNumbers: [Int]!) {
  createUserCasesBySerialNumbers(input:{serialNumbers: $serialNumbers}) {
    userCases {
      ...userCaseLargeFragment
    }
  }
}
${userCaseLargeFragment}
`;

export class AddUserCasesMutation implements GraphQLQuery {
  query = graphqlQuery;
  constructor(public variables: {serialNumbers: number[]}) {}
}

export type AddUserCasesResponse = GraphQLResponse<{
  createUserCasesBySerialNumbers: {
    userCases: UserCase[];
  };
}>;
