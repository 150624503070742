import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { Signature } from '@markmachine/features/version/models/version-content.model';
import { updateFormArray } from '@markmachine/core/functions/utilities';
import { isEmpty } from 'lodash-es';

@Injectable()
export class CaseFormSignaturesService {
  constructor(private fb: FormBuilder) {}

  /**
   * Append a new Signature control group to a FormArray
   * @param array FormArray to append a new signature to
   * @param signature initial values to apply to new signature
   */
  append(array: FormArray, signature?: Partial<Signature>): void {
    const group = this.create(signature);
    array.push(group);
  }

  /**
   * Update length and contents of FormArray for signatures.
   * @param array FormArray for signatures
   * @param values New values for signatures
   */
  update(array: FormArray, values: Partial<Signature>[]): void {
    updateFormArray(array, values, (values_, i) => this.create(values_[i]));
    this.mutateArray(array, values);
  }

  /**
   * Create a new signature
   * @param signature Fields to set in signature
   */
  create(signature: Partial<Signature> = {}): FormGroup {
    const values = { ...new Signature(), ...signature };
    const required: Array<keyof Signature> = ['signature-name', 'authorization', 'signatory-date', 'signatory-name', 'signatory-position'];
    const group = this.fb.group({
      ...values,
      'signature-name': [values['signature-name'], [Validators.required, Validators.pattern('^/.+/$')]],
      'authorization': [values['authorization'], [this.authorizationRequired()]],
      'signatory-date': [values['signatory-date'], []],
      'signatory-name': [values['signatory-name'], [Validators.required]],
      'signatory-position': [values['signatory-position'], [Validators.required]]
    });
    return group;
  }

  /** Control validator for authorization */
  authorizationRequired(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (!control.parent || (control.parent.get('signature-type')?.value !== 'R')) {
        return null;
      } else if (isEmpty(control.value)) {
        return { required: true };
      }
      return null;
    };
  }

  /**
   * Perform secondary mutations on Declaration Signatures.
   * @param array FormArray for owners
   * @param values New values for owners
   */
  mutateArray(array: FormArray, values: Partial<Signature>[]): void {
    for (let i = 0; i < array.length; i++) {
      this.mutate(array.at(i) as FormGroup, values[i]);
    }
  }

  /**
   * Perform secondary mutation on a Declaration Signature.
   * @param group FormGroup for an individual owner
   * @param value New value for FormGroup
   */
  mutate(group: FormGroup, value: Partial<Signature>): void {
    const { 'signature-type': signatureType, 'signatory-date': date } = group.value;
    const today = new Date().toISOString().slice(0, 10);
    // Automatically update signatory-date (for response signatures only)
    if (signatureType === 'R' && date !== today) {
      const patch = { 'signatory-date': today };
      group.patchValue(patch);
    }
  }
}
