<div [formGroup]="group"
  fxLayout="column"
  fxLayoutGap="16px">
  <div fxLayout="row" fxLayoutGap="24px">
    <mat-form-field appearance="outline">
      <mat-label>Foreign Application Country</mat-label>
      <mat-select formControlName="foreign-application-country-name"
        [required]="required$ | async">
        <mat-option>None</mat-option>
        <mat-option *ngFor="let opt of options.mailingCountries"
          [value]="opt.value">{{opt.display}}</mat-option>
      </mat-select>
      <mm-tip-button matSuffix anchor="dff5c76f-9a04-4f0e-bddb-e61e11560a3a"></mm-tip-button>
    </mat-form-field>
  </div>
  <div fxLayout="row">
    <mat-form-field appearance="outline">
      <mat-label>Foreign Application Number</mat-label>
      <input formControlName="foreign-application-number"
        [required]="required$ | async"
        matInput
        type="text">
    </mat-form-field>
  </div>
  <div fxLayout="row">
    <mat-form-field appearance="outline">
      <mat-label>Foreign Application Filing Date</mat-label>
      <input formControlName="foreign-filing-date"
        matInput
        mmUsptoIndefiniteDate
        [required]="required$ | async">
      <mat-hint>Enter date in MM/DD/YYYY format</mat-hint>
    </mat-form-field>
  </div>
  <mat-radio-group formControlName="foreign-application-priority-only">
    <div fxLayout="row">
      <mat-label>Foreign Application Priority Claim</mat-label>
    </div>
    <div fxLayout="row">
      <mat-radio-button value="False">
        At this time, the applicant intends to rely on Section 44(e) as a basis for registration and requests that the
        application be suspended to await the submission of the foreign registration. If ultimately the applicant does
        not rely on §44(e) as a basis for registration, a valid claim of priority may be retained.
      </mat-radio-button>
    </div>
    <div fxLayout="row">
      <mat-radio-button value="True">
        At this time, the applicant has another basis for registration (Section 1(a) or Section 1(b)) and does NOT
        intend to rely on Section 44(e) as the basis for registration, but is only asserting a valid claim of priority.
        The application should not be suspended to await the submission of the foreign registration.
      </mat-radio-button>
    </div>
  </mat-radio-group>
</div>
