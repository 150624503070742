<span>
  <mat-form-field [class.has-value]="formControl.value | isEmpty | not">
    <mat-label>Filter Results</mat-label>
    <input id="filter-input"
      matInput
      [formControl]="formControl">
  </mat-form-field>
  <button mat-icon-button
    [matMenuTriggerFor]="filterMenu">
    <mat-icon>filter_list</mat-icon>
  </button>
  <mat-menu #filterMenu="matMenu">
    <ng-template matMenuContent>
      <mat-checkbox *ngFor="let header of filterableHeaders"
        class="mat-menu-item"
        [checked]="isEnabled(header)"
        (change)="toggleHeader(header)">
        {{ header.name }}
      </mat-checkbox>
    </ng-template>
  </mat-menu>
</span>