import * as fromRouterStore from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';
import * as fromLayout from './core/reducers/layout.reducer';
import * as fromRouter from './core/reducers/router.reducer';

/**
 * Our initial app reducers
 * - most of the reducers are loaded on demand from feature modules
 */
export const reducers: ActionReducerMap<any> = {
};
