import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import {
  AcquiredDistinctiveness,
  LivingConsent,
  Significance,
  Statements,
  StatementsMisc,
  Stippling,
  Translation,
  Transliteration,
  UseInAnotherForm,
  OngoingEfforts
} from '@markmachine/features/version/models/version-content.model';
import { MISC_STATEMENTS } from './case-form-statements-misc.function';
import { get, isMatch, isNil, merge, isEqual, pick } from 'lodash-es';
import { CaseFormValidationService } from './case-form-validation.service';

/** Checkboxes provided by USPTO */
export const TEMPLATE_NAMES = [
  'product or service research or development',
  'market research',
  'manufacturing activities',
  'promotional activities',
  'steps to acquire distributors',
  'steps to obtain required governmental approval',
  'training regarding standards (only for collective or certification marks)',
  'steps to acquire authorized users (only for certification marks)',
  'steps to acquire members (only for collective marks)',
  'development of standards (only for collective or certification marks)'
] as const;

export type TemplateNameId = typeof TEMPLATE_NAMES[number];

export function calculateOutput(values: Partial<StatementsMisc>) {
  // Remove unwanted conditional statements on output,
  // while preserving entered values
  values = deleteDependencies({ ...values });

  // Sort some keys before others so that they appear in a preferred order
  const head_keys = [
    '$owner-is-banking-institution',
    '$owner-federally-chartered-bank',
    '$banking-institution-state-law',
    '$banking-institution-US-state',
  ];
  // Put the "additional misc statements" field at the very end
  const tail_keys = ['text'];
  const rest_keys = Object.keys(values)
    .filter((v) => !head_keys.includes(v) && !tail_keys.includes(v))
    // Produce a stable ordering for output
    .sort();
  const keys = [...head_keys, ...rest_keys, ...tail_keys];
  const outputs = keys
    // Only include known keys with set values
    .filter(key => !isNil(values[key]) && MISC_STATEMENTS[key])
    // Calculate statements
    .map(key => MISC_STATEMENTS[key](values[key], values))
    .filter(statement => !isNil(statement));
  return outputs;
}

/**
 * Delete conditional statements from output
 *
 * Method is used to exclude conditional statements to $output
 * when the antecedent statements are not selected.
 *
 * This enables the form to preserve those decendent form entries
 * while properly excluding them from output when the user changes
 * their mind about those antecedent values.
 *
 * NOTE: Mutates object; copy before using
 */
function deleteDependencies(values: any): Partial<StatementsMisc> {
  const isNoOrNull = x => isNil(x) || x === 'No';
  if (isNoOrNull(values['$minor-applicant'])) {
    delete values['$minor-applicant-eligible'];
    delete values['$minor-applicant-parent'];
  }
  if (values['$minor-applicant-eligible'] === 'Yes') {
    delete values['$minor-applicant-parent'];
  }
  if (isNoOrNull(values['$owner-is-banking-institution'])) {
    delete values['$banking-institution-state-law'];
    delete values['$owner-federally-chartered-bank'];
  }
  if (isNoOrNull(values['$suspension-pending-cancellation'])) {
    delete values['$suspension-pending-cancellation-proceeding-no'];
    delete values['$suspension-pending-cancellation-REG-numbers'];
  }
  if (isNoOrNull(values['$varietal'])) {
    delete values['$varietal-patent'];
    delete values['$varietal-patent-no'];
  }
  if (isNoOrNull(values['$varietal-patent'])) {
    delete values['$varietal-patent-no'];
  }
  if (isNoOrNull(values['$certification-statement-part-one'])) {
    delete values['$certification-statement-part-two'];
  }
  return values;
}

/** Calculate output statement from  */
export function pickStatementOutput(value: StatementsMisc, ...fields: string[]) {
  // TODO: Replace pick? => Object.fromEntries(fields.map(f => [f, value[f]]));
  const partialStatementsMisc = pick(value, fields);
  const $output = calculateOutput(partialStatementsMisc).join('\n\n');
  const files = partialStatementsMisc.files ?? [];
  return { $output, files };
}

@Injectable()
export class CaseFormStatementsService {
  constructor(private validationService: CaseFormValidationService) {}

  /** Update the FormGroup. */
  update(group: FormGroup, values: Statements): void {
    const newValues = this.sanitizeValues(values);
    this.initializeGroup(group, newValues);
    this.validationService.setFormControlsValidators(group);
    group.patchValue(newValues, { emitEvent: false });
    this.mutate(group, newValues);
  }

  /** Perform secondary mutations on Statements */
  mutate(group: FormGroup, values: Statements): void {
    let patch: Statements = values;

    // Calculate secondary changes
    // NOTE: Do not modify user-editable fields; they will become "frozen".
    // NOTE: Patch ordering is significant. Some patches depend on others.
    patch = { ...patch, miscellaneous: this.patchMiscellaneous(patch) };
    patch = { ...patch, 'acquired-distinctiveness': this.patchAcquiredDistinctiveness(patch) };
    patch = { ...patch, miscellaneous: this.patchMiscellaneousOutput(patch) };
    patch = { ...patch, '$ongoing-efforts': this.patchOngoingEfforts(patch) };

    const shouldPatch = !isMatch(patch, group.value) || !isEqual(group.value['$ongoing-efforts'], patch['$ongoing-efforts']);

    if (shouldPatch) {
      group.patchValue(patch, { emitEvent: true });
    }
  }

  patchOngoingEfforts(patch: Statements): OngoingEfforts {
    const incoming = patch['ongoing-efforts'] || '';
    const parts = incoming.split(/\s*;\s*/g);
    const checkboxes = parts.filter((p: TemplateNameId) => TEMPLATE_NAMES.includes(p));
    const remainder = parts.filter((p: TemplateNameId) => !checkboxes.includes(p));
    const other = remainder.join('; ');
    return { checkboxes, other };
  }

  /**
   * Populate Form for Statements with controls.
   *
   * TODO: It would be safer to generalize this method to work directly off of
   * inspection of the model's structure.
   */
  initializeGroup(group: FormGroup, values: Statements = new Statements()): void {
    // Initialize the scalar value that live directly within statements
    const valueKeys = [
      'active-prior-registrations-1',
      'active-prior-registrations-2',
      'active-prior-registrations-3',
      'active-prior-registrations-more',
      'concurrent-use',
      'disclaimer',
      'extension-request-insurance',
      'living-consent-unnecessary',
      'ongoing-efforts',
      'principal-register',
      'supplemental-register',
      'supplemental-register-SOU-filed'
    ];
    for (const key of valueKeys) {
      if (group.get(key)) {
        continue;
      }
      group.setControl(key, new FormControl(values[key]));
    }

    // Initialize the subgroups of Statements
    const subgroups = {
      miscellaneous: StatementsMisc,
      'acquired-distinctiveness': AcquiredDistinctiveness,
      'living-consent': LivingConsent,
      significance: Significance,
      stippling: Stippling,
      translation: Translation,
      transliteration: Transliteration,
      'use-in-another-form': UseInAnotherForm,
      '$ongoing-efforts': OngoingEfforts
    };
    for (const [groupkey, GroupConstructor] of Object.entries(subgroups)) {
      // Create/Get the FormGroup
      if (!group.get(groupkey)) {
        group.setControl(groupkey, new FormGroup({}));
      }
      const subgroup = group.get(groupkey) as FormGroup;
      // Monkey patch acquired-distincteness until backend is patched (api.case_version.format > 2)
      if (groupkey === 'acquired-distinctiveness' && get(values, ['acquired-distinctiveness', 0], null)) {
        values = {
          ...values,
          'acquired-distinctiveness': get(values, ['acquired-distinctiveness', 0], new AcquiredDistinctiveness())
        };
      }
      // Get an iterator over the group values
      values = merge({}, new GroupConstructor(), values[groupkey]);
      const entries = Object.entries(values);

      for (const [key, value] of entries) {
        if (subgroup.get(key)) {
          continue;
        } else {
          subgroup.setControl(key, new FormControl(value));
        }
      }
    }
  }

  /** Make sure fields have sane values. */
  private sanitizeValues(values: Statements): Statements {
    return merge(new Statements(), values);
  }

  /** Load Guide fields from USPTO data. */
  private initializeSignficance(values: Significance) {
    const isNoSigUninitialized = values['no-significance-text'] && !values['$no-significance-text'];
    if (isNoSigUninitialized) {
      return { ...values, '$no-significance-text': values['no-significance-text'] };
    }
    return values;
  }

  /** Perform secondary mutations on Miscellaneous */
  private patchMiscellaneous(values: Statements): StatementsMisc {
    let miscellaneous = values.miscellaneous;

    if (miscellaneous['$owner-is-banking-institution'] === null) {
      miscellaneous = {
        ...miscellaneous,
        '$banking-institution-state-law': '',
        '$banking-institution-US-state': '',
        '$owner-federally-chartered-bank': '',
      };
    }

    if (miscellaneous['$minor-applicant'] === null) {
      miscellaneous = { ...miscellaneous, '$minor-applicant-eligible': '', '$minor-applicant-parent': '' };
    }

    if (miscellaneous['$varietal'] === null) {
      miscellaneous = { ...miscellaneous, '$varietal-patent': '', '$varietal-patent-no': '' };
    }

    return miscellaneous;
  }

    /** Perform secondary mutations on Acquired Distinctiveness */
    private patchAcquiredDistinctiveness(values: Statements): AcquiredDistinctiveness {
      let acquiredDistinctiveness = values['acquired-distinctiveness'];

      if (acquiredDistinctiveness['whole-or-in-part'] === null) {
        acquiredDistinctiveness = new AcquiredDistinctiveness();
      }

      // based on whole
      if (!acquiredDistinctiveness['whole-based-on-use']) {
        acquiredDistinctiveness = {
          ...acquiredDistinctiveness,
          'whole-based-on-use-trademark-or-collective': null
        };
      }

      if (acquiredDistinctiveness['whole-based-on-use-trademark-or-collective'] === null) {
        acquiredDistinctiveness = {
          ...acquiredDistinctiveness,
          'whole-based-on-use-collective-type': null
        };
      }

      if (!acquiredDistinctiveness['whole-based-on-registration']) {
        acquiredDistinctiveness = {
          ...acquiredDistinctiveness,
          'whole-based-on-registration-trademark-or-collective': null
        };
      }

      if (acquiredDistinctiveness['whole-based-on-registration-trademark-or-collective'] === null) {
        acquiredDistinctiveness = {
          ...acquiredDistinctiveness,
          'whole-based-on-registration-trademark-registrations': '',
          'whole-based-on-registration-collective-type': null,
          'whole-based-on-registration-collective-membership-registrations': '',
          'whole-based-on-registration-collective-trademark-registrations': '',
          'whole-based-on-registration-certification-registrations': ''
        };
      }

      if (!acquiredDistinctiveness['whole-based-on-evidence']) {
        acquiredDistinctiveness = {
          ...acquiredDistinctiveness,
          'whole-based-on-evidence-trademark-or-collective': null
        };
      }

      if (acquiredDistinctiveness['whole-based-on-evidence-trademark-or-collective'] === null) {
        acquiredDistinctiveness = {
          ...acquiredDistinctiveness,
          'whole-based-on-evidence-collective-type': null,
          'whole-based-on-evidence-files': new Array<string>()
        };
      }

      // base on part
      if (!acquiredDistinctiveness['based-on-use']) {
        acquiredDistinctiveness = {
          ...acquiredDistinctiveness,
          'based-on-use-trademark-or-collective': null
        };
      }

      if (acquiredDistinctiveness['based-on-use-trademark-or-collective'] === null) {
        acquiredDistinctiveness = {
          ...acquiredDistinctiveness,
          'based-on-use-collective-type': null,
          'based-on-use-trademark-distinctive': '',
          'based-on-use-collective-membership-distinctive': '',
          'based-on-use-collective-trademark-distinctive': '',
          'based-on-use-certification-distinctive': ''
        };
      }

      if (!acquiredDistinctiveness['based-on-registration']) {
        acquiredDistinctiveness = {
          ...acquiredDistinctiveness,
          'based-on-registration-trademark-or-collective': null
        };
      }

      if (acquiredDistinctiveness['based-on-registration-trademark-or-collective'] === null) {
        acquiredDistinctiveness = {
          ...acquiredDistinctiveness,
          'based-on-registration-trademark-distinctive': '',
          'based-on-registration-trademark-registrations': '',
          'based-on-registration-collective-type': null,
          'based-on-registration-collective-membership-registrations': '',
          'based-on-registration-collective-trademark-registrations': '',
          'based-on-registration-certification-registrations': '',
          'based-on-registration-collective-trademark-distinctive': '',
          'based-on-registration-collective-membership-distinctive': '',
          'based-on-registration-certification-distinctive': ''
        };
      }

      if (!acquiredDistinctiveness['based-on-evidence']) {
        acquiredDistinctiveness = {
          ...acquiredDistinctiveness,
          'based-on-evidence-trademark-or-collective': null
        };
      }

      if (acquiredDistinctiveness['based-on-evidence-trademark-or-collective'] === null) {
        acquiredDistinctiveness = {
          ...acquiredDistinctiveness,
          'based-on-evidence-collective-type': null,
          'based-on-evidence-trademark-distinctive': '',
          'based-on-evidence-collective-membership-distinctive': '',
          'based-on-evidence-collective-trademark-distinctive': '',
          'based-on-evidence-certification-distinctive': '',
          'based-on-evidence-files': new Array<string>()
        };
      }

      return acquiredDistinctiveness;
    }

  /** Perform secondary mutations on Miscellaneous Output */
  private patchMiscellaneousOutput(values: Statements): StatementsMisc {
    let miscellaneous = values.miscellaneous;

    const $output = this._calculateOutput(miscellaneous);
    if ($output !== miscellaneous.$output) {
      miscellaneous = { ...miscellaneous, $output };
    }

    return miscellaneous;
  }

  /** Calculate the value of the $output field */
  private _calculateOutput(values: StatementsMisc) {
    const outputs = calculateOutput(values);
    // Remove any dupes and render as a string
    return outputs.join('\n\n').trim();
  }
}
