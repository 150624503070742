import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as fromCore from '@markmachine/core/actions/core.actions';
import { unpackGraphqlResponse } from '@markmachine/core/graphql-operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { environment } from 'environments/environment';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as fromContextMenu from '../actions/context-menu.actions';
import { unselectId } from '../reducers/context-menu.reducer';
import { CreateContextMenuMutation, CreateContextMenuResponse } from '../services/operations/create-context-menu.mutation';
import {
  DeleteContextMenuByPathAndLabelMutation,
  DeleteContextMenuByPathAndLabelResponse
} from '../services/operations/delete-context-menu-by-path-and-label.mutation';
import { GetContextMenuByPrefixQuery, GetContextMenuByPrefixResponse } from '../services/operations/get-context-menu-by-prefix.query';
import {
  UpdateContextMenuByPathAndLabelMutation,
  UpdateContextMenuByPathAndLabelResponse
} from '../services/operations/update-context-menu-by-path-and-label.mutation';
import { ContextMenuItem } from '../models/context-menu-item.model';

const { GRAPHQL } = environment;

@Injectable()
export class ContextMenuEffects {
  /**
   * Fetched ContextMenuItems and add them to the app db.
   */
  fetch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContextMenu.fetchContextMenus),
      mergeMap(action =>
        this.http.post<GetContextMenuByPrefixResponse>(GRAPHQL, new GetContextMenuByPrefixQuery({ prefix: action.prefix })).pipe(
          unpackGraphqlResponse<ContextMenuItem[]>('data.contextMenuByPrefix.nodes'),
          map(contextMenuItems => contextMenuItems.sort((a, b) => b.idx - a.idx)),
          map(contextMenuItems => fromContextMenu.addContextMenus({ contextMenuItems })),
          catchError(error => of(fromCore.networkError({ action, error })))
        )
      )
    )
  );

  /**
   * Create ContextMenuItem - server-storage side-effect
   */
  create$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContextMenu.createContextMenu),
      mergeMap(action =>
        this.http
          .post<CreateContextMenuResponse>(GRAPHQL, new CreateContextMenuMutation({ input: { contextMenu: action.contextMenuItem } }))
          .pipe(
            unpackGraphqlResponse<ContextMenuItem>('data.createContextMenu.contextMenu'),
            map(contextMenuItem => fromContextMenu.createContextMenuSuccess({ contextMenuItem })),
            catchError(error => of(fromCore.networkError({ action, error })))
          )
      )
    )
  );

  /**
   * Delete ContextMenuItem - server-storage side-effect
   */
  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContextMenu.deleteContextMenus),
      // We split the multiple-delete action into a single actions
      mergeMap(({ contextMenuItems }) => contextMenuItems.map(contextMenuItem => fromContextMenu.deleteContextMenu({ contextMenuItem }))),
      mergeMap(action => {
        // Transform action parameters into GraphQL mutation variables
        const { path, label } = action.contextMenuItem;
        const input = { path, label };
        return this.http
          .post<DeleteContextMenuByPathAndLabelResponse>(GRAPHQL, new DeleteContextMenuByPathAndLabelMutation({ input }))
          .pipe(
            unpackGraphqlResponse<ContextMenuItem>('data.deleteContextMenuByPathAndLabel.contextMenu'),
            map(contextMenuItem => fromContextMenu.deleteContextMenuSuccess({ contextMenuItem })),
            catchError(error => of(fromCore.networkError({ action, error })))
          );
      })
    )
  );

  /**
   * Update ContextMenuItem - server-storage side-effect
   */
  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromContextMenu.updateContextMenu),
      mergeMap(action => {
        // Transform action parameters into GraphQL mutation variables
        const { contextMenuItemUpdate } = action;
        const { id, changes: contextMenuPatch } = contextMenuItemUpdate;
        const { path, label } = unselectId(id as any);
        const input = { path, label, contextMenuPatch };
        return this.http
          .post<UpdateContextMenuByPathAndLabelResponse>(GRAPHQL, new UpdateContextMenuByPathAndLabelMutation({ input }))
          .pipe(
            unpackGraphqlResponse<ContextMenuItem>('data.updateContextMenuByPathAndLabel.contextMenu'),
            map(changes => fromContextMenu.updateContextMenuSuccess({ contextMenuItemUpdate: { id: id as any, changes } })),
            catchError(error => of(fromCore.networkError({ action, error })))
          );
      })
    )
  );

  constructor(private actions$: Actions, private http: HttpClient) {}
}
