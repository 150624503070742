/**
 * Case Form Service
 *
 * Generates a FormGroup for a draft CaseVersion that automatically
 * self-propogates dependent changes to other parts of the form.
 *
 * TODO: Change how #connect() works
 * - We want to be able to provide alternative values for fields. To do that, we need to provide
 *   multiple CaseVersions and assign a name to each CaseVersion.
 *
 * Possible signatures:
 *   #connect(version: Observable<Version>, alts: { [name: string]: Observable<Version> }): FormGroup
 *   #getFormGroupsByCaseId(caseId: string): { name: string; formGroup: FormGroup }[]
 *   #getFormGroupByVersionId(versionId: string): FormGroup
 */
import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { VersionContent, PendingPayment, GuideSelection } from '@markmachine/features/version/models/version-content.model';
import { Version } from '@markmachine/features/version/models/version.model';
import { get } from 'lodash-es';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { CaseFormAllegationsService } from './case-form-allegations.service';
import { CaseFormArgumentService } from './case-form-argument.service';
import { CaseFormClassesService } from './case-form-classes.service';
import { CaseFormCorrespondentService } from './case-form-correspondent.service';
import { CaseFormExtensionRequestService } from './case-form-extension.service';
import { CaseFormHeaderService } from './case-form-header.service';
import { CaseFormMarkService } from './case-form-mark.service';
import { CaseFormOwnersService } from './case-form-owners.service';
import { CaseFormPendingPaymentsService } from './case-form-pending-payments.service';
import { CaseFormSignaturesService } from './case-form-signatures.service';
import { CaseFormStatementsService } from './case-form-statements.service';
import { buildForm } from '@angular-prelude/forms';
import { CaseFormRenewal89Service } from './case-form-renewal-89.service';
import { CaseFormRenewal815Service } from './case-form-renewal-815.service';
import { CaseFormGuideService } from './case-form-guide.service';

@Injectable()
export class CaseFormService {
  public formGroup: FormGroup;
  private draftContent: Observable<VersionContent>;
  private currentContent: Observable<VersionContent>;
  private destroy$ = new Subject();

  private guard = () => <T>(source: Observable<Version>): Observable<VersionContent> =>
    source.pipe(
      filter(version => !!version),
      map(version => version.content),
      takeUntil(this.destroy$),
    )

  constructor(
    private classes: CaseFormClassesService,
    private allegations: CaseFormAllegationsService,
    private extensions: CaseFormExtensionRequestService,
    private renewals89: CaseFormRenewal89Service,
    private renewals815: CaseFormRenewal815Service,
    private mark: CaseFormMarkService,
    private argument: CaseFormArgumentService,
    private correspondent: CaseFormCorrespondentService,
    private statements: CaseFormStatementsService,
    private owners: CaseFormOwnersService,
    private signatures: CaseFormSignaturesService,
    private header: CaseFormHeaderService,
    private pendingPayments: CaseFormPendingPaymentsService,
    private guides: CaseFormGuideService,
  ) {}

  connect(draftContent: Observable<Version>, currentContent: Observable<Version>) {
    this.draftContent = draftContent.pipe(this.guard());
    this.currentContent = currentContent.pipe(this.guard());
    this.constructForm();
    this.connectStore();
    this.connectSelf();
    return this.formGroup;
  }

  disconnect() {
    this.destroy$.next();
  }

  private constructForm(): void {
    // Build the FormGroup
    // NOTE: We are not using FormBuilder due to bugs/omissions in Angular 5.x:
    //   1. doesn't support { updateOn: blur }; and
    //   2. breaks child { updateOn: blur } when parents were created with FormBuilder.
    // Tests will fail when using FormBuilder if these problems have not been fixed.
    const allegations = new FormArray([]);
    const extensions = new FormArray([]);
    const renewals89 = new FormArray([]);
    const renewals815 = new FormArray([]);
    const classes = new FormArray([]);
    const mark = new FormGroup({});
    const owners = new FormArray([]);
    const signatures = new FormArray([]);
    const responseSignatures = new FormArray([]);
    const argument = new FormGroup({});
    const correspondent = new FormGroup({});
    const correspondentAttorney = new FormGroup({});
    const correspondentDomesticRepresentative = new FormGroup({});
    const header = new FormGroup({});

    const statements = new FormGroup({});
    const pendingPayments = buildForm(new PendingPayment());
    const guides = buildForm(new GuideSelection());

    this.formGroup = new FormGroup(
      {
        'allegations-of-use': allegations,
        'extension-requests': extensions,
        'renewals-89': renewals89,
        'renewals-815': renewals815,
        classes,
        mark,
        argument,
        owners,
        statements,
        correspondent,
        'correspondent-attorney': correspondentAttorney,
        'correspondent-domestic-representative': correspondentDomesticRepresentative,
        'declaration-signatures': signatures,
        'case-file-header': header,
        'response-signatures': responseSignatures,
        'pending-payments': pendingPayments,
        guides
      }
    );
  }

  /**
   * Connect forms to the store so that they reflect store changes.
   *
   * Note: There is a risk of infinite loops here.
   * Guard against infinite loops so that events are only emitted if necessary.
   * Generally, you should not need to emit events at this time.
   */
  private connectStore(): Subscription {
    const PATHS: { service: any; valuePath: string | string[]; controlPath?: string | string[] }[] = [
      { service: this.guides, valuePath: 'guides' },
      { service: this.header, valuePath: 'case-file-header' },
      { service: this.classes, valuePath: 'classes' },
      { service: this.owners, valuePath: 'owners' },
      { service: this.mark, valuePath: 'mark' },
      { service: this.argument, valuePath: 'argument' },
      { service: this.correspondent, valuePath: 'correspondent' },
      { service: this.correspondent, valuePath: 'correspondent-attorney' },
      { service: this.correspondent, valuePath: 'correspondent-domestic-representative' },
      { service: this.signatures, valuePath: 'declaration-signatures' },
      { service: this.signatures, valuePath: 'response-signatures' },
      // Secondary mutations should happen after primary updates
      { service: this.statements, valuePath: 'statements' },
      { service: this.allegations, valuePath: 'classes', controlPath: 'allegations-of-use' },
      { service: this.extensions, valuePath: 'classes', controlPath: 'extension-requests' },
      { service: this.renewals89, valuePath: 'classes', controlPath: 'renewals-89' },
      { service: this.renewals815, valuePath: 'classes', controlPath: 'renewals-815' },
      // Fees should be calculated last
      { service: this.pendingPayments, valuePath: null, controlPath: 'pending-payments' }, // Get the whole kit 'n caboodle
    ];
    return this.draftContent.pipe(withLatestFrom(this.currentContent)).subscribe(([draft, current]) => {
      for (const { service, valuePath, controlPath } of PATHS) {
        const controls = this.formGroup.get(controlPath ?? valuePath);
        const draftPart = valuePath ? get(draft, valuePath) : draft;
        const currentPart = valuePath ? get(current, valuePath) : current;
        service.update(controls, draftPart, currentPart);
      }
    });
  }

  /**
   * Connect forms to each other, so that they reflect secondary mutations.
   *
   * Note: There is a risk of infinite loops or update sequencing errors here.
   * (a) Guard against infinite loops so that events are only emitted if necessary.
   * (b) If your changes are late-by-one in the event stream, you're subscribed to
   *     the root FormGroup.  Subscribe to its children and you'll be fine.
   */
  private connectSelf(): Subscription {
    const allegationControls = this.formGroup.get('allegations-of-use') as FormArray;
    const extensionControls = this.formGroup.get('extension-requests') as FormArray;
    const renewals89Controls = this.formGroup.get('renewals-89') as FormArray;
    const renewals815Controls = this.formGroup.get('renewals-815') as FormArray;
    const classGroup = this.formGroup.get('classes') as FormArray;
    return classGroup.valueChanges.pipe(withLatestFrom(this.currentContent.pipe(map(c => c.classes)))).subscribe(([formValue, current]) => {
      this.allegations.update(allegationControls, formValue, current);
      this.extensions.update(extensionControls, formValue, current);
      this.renewals89.update(renewals89Controls, formValue, current);
      this.renewals815.update(renewals815Controls, formValue, current);
    });
  }
}
