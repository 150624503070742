import { Directive, SkipSelf, Input, Optional, OnInit, OnChanges, SimpleChanges, OnDestroy, Host, ViewContainerRef } from '@angular/core';
import { ContextMenuService } from '../services/context-menu.service';
import { defaultTo } from 'lodash-es';
import { ReplaySubject } from 'rxjs';

@Directive({
  selector: '[mmContextMenuPath]'
})
export class ContextMenuPathDirective implements OnChanges, OnDestroy, OnInit {
  @Input() mmContextMenuPath = '';
  @Input() mmContextMenuProps: object;
  valueChanges = new ReplaySubject<{ path: string; props: any}>(1);

  constructor(
    @Optional() @SkipSelf() private parent: ContextMenuPathDirective,
    private service: ContextMenuService,
    private view: ViewContainerRef
  ) { }

  /** When the context menu binding changes, update registry. */
  ngOnChanges(changes: SimpleChanges) {
    // If path or props change, emit to valueChanges stream.
    this.updateValueChanges();
    if (changes.mmContextMenuPath) {
      this.service.unregister(this.view);
      this.service.register(this.fullPath, this.view);
    }
  }

  ngOnInit() {
    // Subscribe to parent's changes -- if the parent path or props change,
    // we'll need to update our subscribers with new derived values.
    if (this.parent) {
      this.parent.valueChanges.subscribe(() => this.updateValueChanges());
    }
  }

  /** When our host deconstructs, unregister. */
  ngOnDestroy() {
    this.service.unregister(this.view);
    this.valueChanges.complete();
  }

  /** Calculate the derived values of path and props and emit. */
  updateValueChanges() {
    this.valueChanges.next({ path: this.fullPath, props: this.props });
  }

  get fullPath(): string {
    if (this.mmContextMenuPath.startsWith('/')) {
      return this.mmContextMenuPath;
    } else if (this.parent && this.mmContextMenuPath) {
      return [this.parent.fullPath, this.mmContextMenuPath].join('/');
    } else if (this.parent) {
      return this.parent.fullPath;
    } else {
      return '/' + defaultTo(this.mmContextMenuPath, '');
    }
  }

  get props(): object | undefined {
    if (this.mmContextMenuProps || (this.parent && this.parent.props)) {
      return { ...this.parent && this.parent.props, ...this.mmContextMenuProps };
    }
  }
}
