import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormGroup, Validators, ControlContainer } from '@angular/forms';
import { InputOptionService } from '@markmachine/features/case/services/input-option.service';
import { CountryCodePipe } from '@markmachine/shared/pipes/country-code.pipe';
import { Observable } from 'rxjs';

function validatePhoneLength(formGroup: FormGroup) {
  if (formGroup.get('phone-country') && formGroup.get('phone-country')?.value) {
    let maxLength = 10;
    const countryPrefix = formGroup.get('phone-country')?.value;
    if (countryPrefix !== '+0-') {
      maxLength = 22 - countryPrefix.length;
    }
    formGroup.get('phone')?.setValidators([
      Validators.maxLength(maxLength),
      Validators.pattern('[0-9]*')
    ]);
  } else {
    formGroup.get('phone')?.clearValidators();
  }
  return null;
}

@Component({
  selector: 'mm-country-phone-edit',
  templateUrl: './country-phone-edit.component.html',
  styleUrls: ['./country-phone-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountryPhoneEditComponent implements OnInit {
  @Input() includeCountry = false;
  @Input() includeExtension = false;

  formGroup: FormGroup;

  constructor(
    private countryCodePipe: CountryCodePipe,
    public options: InputOptionService,
    private container: ControlContainer
  ) {}

  ngOnInit() {
    this.formGroup = this.container.control as FormGroup;
    this.formGroup.setValidators(validatePhoneLength);
  }

  get countryCode(): string {
    return this.countryCodePipe.transform(this.formGroup.get('phone-country')?.value);
  }
}
