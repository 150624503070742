<div *ngIf="serialNumber$ | async | not" [formGroup]="group"
  fxLayout="column"
  fxLayoutGap="16px">
  <div fxLayout="row">
    <mat-checkbox formControlName="filing-basis-1b-notice">
      I understand that the selection of Section 1(b) "Intent to Use" as the filing basis requires the applicant to do
      the following before the mark can register: (1) begin using the mark in commerce; and (2) file an Allegation of
      Use that includes a specimen and an additional fee. Furthermore, even if the application has already been
      approved, examination of the Allegation of Use may result in the examining attorney issuing a refusal.
    </mat-checkbox>
  </div>
</div>
