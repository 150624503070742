import { Pipe, PipeTransform } from '@angular/core';
import { CaseFormValidationService } from '@markmachine/features/version-form/services/case-form-validation.service';

@Pipe({
  name: 'validationErrorMessage'
})
export class ValidationErrorMessagePipe implements PipeTransform {

  constructor(
    private validation: CaseFormValidationService
  ) {}

  transform(registryKey: string): string {
    return this.validation.validatorRegistry[registryKey]?.errorMessage;
  }

}
