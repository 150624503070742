import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, shareReplay } from 'rxjs/operators';

/**
 * Observe a control's current and future values.
 *
 * Efficiently shares form changes among subscribers.
 */
export function controlValueAndChanges(control: AbstractControl): Observable<AbstractControl['value']> & AbstractControl['valueChanges'] {
  return control.valueChanges.pipe(startWith(control.value), shareReplay(1));
}
