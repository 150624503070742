import { Directive, HostListener } from '@angular/core';
import { DialogOpenByAnchors } from '@markmachine/features/tip/actions/tip.actions';
import { Store } from '@ngrx/store';

@Directive({
  selector: '[mmOpenLinksInDialog]'
})
export class OpenLinksInDialogDirective {

  constructor(private store: Store) {}

  /**
   * Take over anchor click events
   */
  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    let elem = event.target as any; // HTMLElement;
    let isAnchor = false;

    // Walk ancestors looking for anchor tags
    do {
      isAnchor = elem.tagName.toUpperCase() === 'A';
      if (isAnchor) {
        break;
      }
    // tslint:disable-next-line: no-conditional-assignment
    } while ((elem = elem.parentElement));

    // If we didn't find an anchor, quit
    if (!isAnchor) {
      return;
    }

    // Check the href
    const href = elem.getAttribute('href');
    if (href.startsWith('#')) {
      // If the href is a tip link, swallow the click and navigate
      event.stopPropagation();
      event.preventDefault();
      const anchor = href.slice(1);
      this.store.dispatch(DialogOpenByAnchors({ anchors: [anchor] }));
    }
  }
}
