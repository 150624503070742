<mat-form-field *ngIf="type === 'date'">
  <input [ngClass]="getInputClass()" matInput [value]="value" [matDatepicker]="picker" (dateChange)="onDateChange($event)">
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>

<ng-container *ngIf="type === 'text'">
  <textarea
    [ngClass]="getInputClass()"
    [value]="value"
    (change)="onTextChange($event)"
    wrap="off">
  </textarea>
</ng-container>
