import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { VellipsisMenuClick } from '../../models/event-models';

export interface VellipsisMenuOption {
  disabled?: boolean;
  name: string;
}

@Component({
  selector: 'mm-vellipsis-button',
  templateUrl: './vellipsis-button.component.html',
  styleUrls: ['./vellipsis-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VellipsisButtonComponent implements OnInit {

  @Input() vellipsisMenuOptions: VellipsisMenuOption[];

  @Output() vellipsisMenuClick = new EventEmitter<VellipsisMenuClick>();

  constructor() { }

  ngOnInit() {
  }

  isEmpty() {
    return !!this.vellipsisMenuOptions && this.vellipsisMenuOptions.length === 0;
  }

  onMenuOptionClick(option: VellipsisMenuOption) {
    this.vellipsisMenuClick.emit({ menuOption: option.name });
  }

  trackByFn(index: number, option: VellipsisMenuOption) {
    return option.name;
  }
}
