import { Action, createReducer, on, createFeatureSelector, createSelector } from '@ngrx/store';
import * as routerReducer from '@markmachine/core/reducers/router.reducer';
import * as StateActions from '../actions/state.actions';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { FormState } from '../models/state.model';
import { ActionSanitizer, StateSanitizer } from '@ngrx/store-devtools/src/config';

export const stateFeatureKey = 'teas-state';

export interface State extends EntityState<FormState> {
  loadState: 'pristine' | 'in-flight' | 'loaded';
  error: any;
}

export const adapter = createEntityAdapter<FormState>();

export const initialState = adapter.getInitialState({
  loadState: 'pristine',
  error: null
});

export const reducer = createReducer(
  initialState,

  on(StateActions.loadStates,
    state => ({ ...state, loadState: 'in-flight', error: null })),

  on(StateActions.loadStatesSuccess,
    (state, { data }) => adapter.setAll(data, { ...state, loadState: 'loaded', error: null })),

  on(StateActions.loadStatesFailure,
    (state, { error }) => ({ ...state, loadState: 'error', error })),

);

const OMITTED = '🔥🔥🔥 omitted for sanity 🔥🔥🔥';

/** Omit the huge `source` field from devtools */
const entitySanitizer = ({ source, ...rest }: FormState) => ({ ...rest, source: OMITTED });

export const actionSanitizer: ActionSanitizer = (action: Action) => {
  return action.type === StateActions.loadStatesSuccess.type
    ? { ...action, data: (action as any).data.map(entitySanitizer)}
    : action;
};

export const stateSanitizer: StateSanitizer = (state: { [stateFeatureKey]: State }) => {
  if (state[stateFeatureKey]?.ids?.length ?? 0 > 0) {
    // The `source` field is huge and there are a lot of states.  Censor it from devtools.
    const { ids, entities, ...rest } = state[stateFeatureKey];
    const entries = (ids as string[]).map((id) => [id, entitySanitizer(entities[id])]);
    return { ...state, [stateFeatureKey]: { ids, entities: Object.fromEntries(entries), ...rest } };
  }
  return state;
};


export const selectStateState = createFeatureSelector<State>(stateFeatureKey);
export const { selectAll, selectEntities } = adapter.getSelectors(selectStateState);
export const selectById = () => createSelector(selectEntities, (entities, { stateId }) => entities[stateId]);
export const selectByPageId = () => createSelector(selectAll, (all, { pageId }) => all.filter((state) => pageId === state.idPage));

export const selectActiveStateByRoute = createSelector(
  selectEntities,
  routerReducer.getRouterParams,
  (entities, { stateId }) => entities[stateId]
);

export const selectStatesByRoute = createSelector(
  selectAll,
  routerReducer.getRouterParams,
  (all, { pageId }) => all.filter((state) => pageId === state.idPage)
);
