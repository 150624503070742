import * as fromSubmissionPage from '@markmachine/views/submission-page/reducers';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { get, isArray, isEmpty, isObject } from 'lodash-es';
import * as fromContextMenu from '../actions/context-menu.actions';
import { ContextMenuItem } from '../models/context-menu-item.model';

// **************************************************************************
// State
// **************************************************************************
export interface State extends EntityState<ContextMenuItem> {
  /* Set focus on change */
  focusRow: string | null;
}

const ID_DELIMITER = '||';

export const selectId = (value: ContextMenuItem): string => [value.path, value.label].join(ID_DELIMITER);

export const unselectId = (id: string) => {
  const [path, label] = id.split(ID_DELIMITER);
  return { path, label };
};

const sortComparer = (a: ContextMenuItem, b: ContextMenuItem) =>
  a.path.localeCompare(b.path) && // by path
  (a.position < b.position ? -1 : a.position > b.position ? +1 : 0) && // by position
  a.label.localeCompare(b.label); // by label

// Use EntityAdapter to make editing menu items easier
export const adapter: EntityAdapter<ContextMenuItem> = createEntityAdapter<ContextMenuItem>({ selectId, sortComparer });
export const initialState: State = adapter.getInitialState({ focusRow: null });

// **************************************************************************
// Reducers
// **************************************************************************
export const reducer = createReducer(
  initialState,
  on(fromContextMenu.loadContextMenus, (state, { contextMenuItems }) => adapter.setAll(contextMenuItems, state)),
  on(fromContextMenu.addContextMenus, (state, { contextMenuItems }) => adapter.addMany(contextMenuItems, state)),
  on(fromContextMenu.updateContextMenu, (state, { contextMenuItemUpdate }) => adapter.updateOne(contextMenuItemUpdate, state)),
  on(fromContextMenu.createContextMenu, (state, { contextMenuItem }) => {
    state = adapter.addOne(contextMenuItem, state);
    const id = selectId(contextMenuItem);
    return { ...state, focusRow: id };
  }),
  on(fromContextMenu.createContextMenuSuccess, (state, { contextMenuItem: changes }) =>
    adapter.updateOne({ id: selectId(changes), changes }, state)
  ),
  on(fromContextMenu.deleteContextMenus, (state, { contextMenuItems }) => adapter.removeMany(contextMenuItems.map(selectId), state))
);


// **************************************************************************
// Selectors
// **************************************************************************
export const storeKey = 'context-menu';
export const getContextMenuState = createFeatureSelector<State>(storeKey);

const { selectIds, selectAll, selectEntities, selectTotal } = adapter.getSelectors(getContextMenuState);

export const getAllContextMenuItems = selectAll;

// TODO: refactor initial params into object
export const myNotesMenuItem = (path: string, position = -1, props?: { caseId: string, expandedNoteIds?: string[]}) => ({
  label: 'My Notes',
  icon: 'markmachine-notes',
  action: 'Notes',
  position,
  path,
  props
});

/** Append "My Notes" to context menu items */
const withMyNotes = (items: ContextMenuItem[], caseId: string, path: string): ContextMenuItem[] => [
  ...items,
  myNotesMenuItem(path, items.length, { caseId })
];

// TODO: Pre-calculate menu groups by path in state instead of filtering all the time.
// N.B., this isn't as bad as it seems at the moment, because we only load menu items ONCE
// and every request is memoized thereafter. This does mean that we're performing `filter`
// once per context menu item button.
export const getContextMenuItemsByPath = () =>
  createSelector(
    getAllContextMenuItems,
    fromSubmissionPage.getCaseId,
    (items: ContextMenuItem[], caseId: string, { path, props }) => {
      items = items
        .filter(item => item.path === path)
        .map(item => {
          if (isEmpty(props)) {
            return item;
          } else if (isEmpty(item.props)) {
            return { ...item, props };
          } else if (isArray(item.props)) {
            return { ...item, props: [...item.props, ...props] };
          } else if (isObject(item.props)) {
            return { ...item, props: { ...item.props, ...props } };
          }
        });
      return withMyNotes(items, get(props, ['caseId'], caseId), path);
    }
  );

/** Changes when we want to set the row focus. */
export const getContextMenuRowFocus = createSelector(getContextMenuState, s => s.focusRow);
