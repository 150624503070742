// tslint:disable: max-line-length
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedMaterialModule } from '@markmachine/shared/materials/shared-materials.module';
import { SharedModule } from '@markmachine/shared/shared.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CaseFileModule } from '../case-file/case-file.module';
import { AttorneyFormComponent } from './components/attorney-form/attorney-form.component';
import { CorrespondentCommonFormComponent } from './components/correspondent-common-form/correspondent-common-form.component';
import { CorrespondentFormComponent } from './components/correspondent-form/correspondent-form.component';
import { CountryPhoneEditComponent } from './components/country-phone-edit/country-phone-edit.component';
import { DomesticRepresentativeFormComponent } from './components/domestic-representative-form/domestic-representative-form.component';
import { ElectronicContactFormComponent } from './components/electronic-contact-form/electronic-contact-form.component';
import { MailingAddressFormComponent } from './components/mailing-address-form/mailing-address-form.component';
import { OwnerEntityFormComponent } from './components/owner-entity-form/owner-entity-form.component';
import { OwnerFormComponent } from './components/owner-form/owner-form.component';
import { OwnerTypeFormComponent } from './components/owner-type-form/owner-type-form.component';
import { SignatureFormDialogComponent } from './components/signature-form-dialog/signature-form-dialog.component';
import { SignatureFormComponent } from './components/signature-form/signature-form.component';
import { StatementAcquiredDistinctivenessFormComponent } from './components/statement-acquired-distinctiveness-form/statement-acquired-distinctiveness-form.component';
import { StatementActivePriorRegistrationsFormComponent } from './components/statement-active-prior-registrations-form/statement-active-prior-registrations-form.component';
import { StatementConcurrentUseFormComponent } from './components/statement-concurrent-use-form/statement-concurrent-use-form.component';
import { StatementDisclaimerFormComponent } from './components/statement-disclaimer-form/statement-disclaimer-form.component';
import { StatementLivingIndividualsConsentFormComponent } from './components/statement-living-individuals-consent-form/statement-living-individuals-consent-form.component';
import { StatementRegisterFormComponent } from './components/statement-register-form/statement-register-form.component';
import { StatementSignificanceFormComponent } from './components/statement-significance-form/statement-significance-form.component';
import { StatementStipplingFormComponent } from './components/statement-stippling-form/statement-stippling-form.component';
import { StatementTranslationFormComponent } from './components/statement-translation-form/statement-translation-form.component';
import { StatementTransliterationFormComponent } from './components/statement-transliteration-form/statement-transliteration-form.component';
import { StatementUseOfTheMarkInAnotherFormFormComponent } from './components/statement-use-of-the-mark-in-another-form-form/statement-use-of-the-mark-in-another-form-form.component';
import { VersionEffects } from './effects/version.effects';
import * as fromVersions from './reducers/version.reducer';
import { CorrespondentPreparerRoleComponent } from './components/correspondent-preparer-role/correspondent-preparer-role.component';
import { CaseReviewModule } from '../case/case-review/case-review.module';

const DIALOGS = [SignatureFormDialogComponent];

@NgModule({
  declarations: [
    ...DIALOGS,
    CorrespondentCommonFormComponent,
    MailingAddressFormComponent,
    ElectronicContactFormComponent,
    CountryPhoneEditComponent,
    SignatureFormComponent,
    CorrespondentFormComponent,
    AttorneyFormComponent,
    DomesticRepresentativeFormComponent,
    OwnerEntityFormComponent,
    OwnerTypeFormComponent,
    OwnerFormComponent,
    StatementDisclaimerFormComponent,
    StatementActivePriorRegistrationsFormComponent,
    StatementConcurrentUseFormComponent,
    StatementLivingIndividualsConsentFormComponent,
    StatementStipplingFormComponent,
    StatementTranslationFormComponent,
    StatementTransliterationFormComponent,
    StatementUseOfTheMarkInAnotherFormFormComponent,
    StatementAcquiredDistinctivenessFormComponent,
    StatementRegisterFormComponent,
    StatementSignificanceFormComponent,
    CorrespondentPreparerRoleComponent
  ],
  exports: [
    CorrespondentCommonFormComponent,
    MailingAddressFormComponent,
    ElectronicContactFormComponent,
    CorrespondentFormComponent,
    CorrespondentPreparerRoleComponent,
    AttorneyFormComponent,
    DomesticRepresentativeFormComponent,
    OwnerEntityFormComponent,
    OwnerTypeFormComponent,
    OwnerFormComponent,
    StatementDisclaimerFormComponent,
    StatementActivePriorRegistrationsFormComponent,
    StatementConcurrentUseFormComponent,
    StatementLivingIndividualsConsentFormComponent,
    StatementStipplingFormComponent,
    StatementTranslationFormComponent,
    StatementTransliterationFormComponent,
    StatementUseOfTheMarkInAnotherFormFormComponent,
    StatementAcquiredDistinctivenessFormComponent,
    StatementRegisterFormComponent,
    StatementSignificanceFormComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedMaterialModule,
    SharedModule,
    CaseFileModule,
    StoreModule.forFeature('versions', fromVersions.reducer),
    EffectsModule.forFeature([VersionEffects]),
    CaseReviewModule
  ]
})
export class VersionModule {}
